import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { joinTransactionNumber } from "../../../../../../helpers/prefixHelper";
import { SuccessToaster } from "../../../../../../helpers/toastHelper";
import {
  AssociatedTagOptionsType,
  ObjectType,
  ReportingTagTransactionObjectPM,
} from "../../../../../../types";
import { setLoaderState } from "../../../../../common/commonSlice";
import SelectReportingTag from "../../../../../common/components/ReportingTags/SelectReportingTag/SelectReportingTag";
import { DockedButtonGroup } from "../../../../../common/components/button/DockedButtonGroup";
import Header from "../../../../../common/components/detailsPage/Header";
import { Container } from "../../../../../common/components/htmlTags/Container";
import Table from "../../../../../common/components/table/Table";
import { SPLIT_TYPES } from "../../../../../constants/constants";
import useCommonData from "../../../../../hooks/useCommon";
import { getReportingTagDropdown } from "../../../../reportingTags/reportingTagAPIFiles/reportingTagSlice";
import { useFetchJournalListPM } from "../../../hooks/useJournalListPM";
import { useFetchPMDetails } from "../../../hooks/usePMDetails";
import { useFetchTagOptionsForBills } from "../../../hooks/useTagOptionsForBills";
import { updateReportingTagsPM } from "../../../paymentsMadeSlice";
import "./journalList.css";
import {
  filterTransactionListPayload,
  getIsTagOptionExist,
  getJVTableHeader,
  getPayloadForJournalGenerate,
  getUpdatedJournals,
} from "./utils/helper";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";

const JournalListPM = (props: any) => {
  const { editId } = useParams();
  const { getPMDetails } = useFetchPMDetails();
  const { getTagOptionsForBills } = useFetchTagOptionsForBills();
  const { getJournals } = useFetchJournalListPM();
  const { dispatch, navigate, currentUserInfo, orgCurrencyList } =
    useCommonData();
  const [journals, setJournals] = useState<ReportingTagTransactionObjectPM>({});
  const [tagOptionsForBills, setTagOptionsForBills] =
    useState<AssociatedTagOptionsType>({});
  const [currency, setCurrency] = useState({
    currency_id: null,
    currency_code: "",
  });
  const onLoadFunctions = async () => {
    const response = await getPMDetails(Number(editId));
    if (!("error" in response)) {
      setCurrency({
        currency_id: response.currency_id,
        currency_code: response.currency_code,
      });
    }
    let billIds = Array.isArray(response.unpaid_bill_lines)
      ? response.unpaid_bill_lines.map((bill: ObjectType) => {
          return bill.bill_id;
        })
      : [];
    getTagOptionsForBills(billIds, setTagOptionsForBills);
    getJournals(
      getPayloadForJournalGenerate(response),
      Number(editId),
      setJournals
    );
    dispatch(
      getReportingTagDropdown({
        orgId: currentUserInfo.organization_id,
      })
    );
  };
  useEffect(() => {
    dispatch(setLoaderState(true));
  }, []);
  useEffect(() => {
    dispatch(setLoaderState(true));
    onLoadFunctions().finally(() => {
      setTimeout(() => {
        dispatch(setLoaderState(false));
      }, 2000);
    });
  }, [currentUserInfo.organization_id]);

  const updateTagsSelection = (
    accountId: number,
    splitType: string,
    tagsList: any,
    index?: number | null,
    entityId?: number | null | undefined
  ) => {
    let newJournal = getUpdatedJournals(
      journals[String(entityId)].transaction_list,
      accountId,
      splitType,
      tagsList,
      entityId
    );
    setJournals((values) => {
      return {
        ...values,
        [String(entityId)]: {
          ...values[String(entityId)],
          transaction_list: newJournal,
        },
      };
    });
  };

  /**
   * set table column headings
   */
  const getData = (
    journals: ObjectType[],
    total: ObjectType,
    billId: number
  ) => {
    const data = journals
      ? journals.map((journal: ObjectType, index) => ({
          show: {
            account_name: (
              <>
                <span className="account_name ellipsis">
                  {journal.account_name}
                </span>
                <SelectReportingTag
                  accountId={journal.account_id}
                  accountName={journal.account_name}
                  transactionType={journal.transaction_type}
                  transactionAmount={journal.transaction_amount}
                  selectedTagsCount={journal.options_list.length}
                  currency={currency}
                  updateTagsSelection={updateTagsSelection}
                  optionsList={journal.options_list || []}
                  splitType={journal.split_type || SPLIT_TYPES.split_equally}
                  entityId={billId}
                  specificReportingTagsOptions={tagOptionsForBills}
                  module="Payments Made"
                  isSpecialTagOptionsPresent={true}
                  isTagOptionsExists={getIsTagOptionExist(
                    billId,
                    tagOptionsForBills
                  )}
                  // index={index}
                />
              </>
            ),
            debit:
              journal.transaction_type === "DEBIT"
                ? NumberFormat(
                    journal.transaction_amount,
                    currency.currency_code,
                    orgCurrencyList
                  )
                : "",
            credit:
              journal.transaction_type === "CREDIT"
                ? NumberFormat(
                    journal.transaction_amount,
                    currency.currency_code,
                    orgCurrencyList
                  )
                : "",
          },
        }))
      : [];
    data.push({
      show: {
        account_name: <></>,
        debit: `${currency.currency_code} ${NumberFormat(
          total.total_debit,
          currency.currency_code,
          orgCurrencyList
        )}`,
        credit: `${currency.currency_code} ${NumberFormat(
          total.total_credit,
          currency.currency_code,
          orgCurrencyList
        )}`,
      },
    });
    return data;
  };
  const getTableMapData = () => {
    const tableData = Object.keys(journals || {}).map((value) => {
      return {
        billNumber: journals[value].bill_number,
        billId: Number(value),
        tableData: getData(
          journals[value].transaction_list,
          {
            total_debit: journals[value].total_debit,
            total_credit: journals[value].total_credit,
          },
          Number(value)
        ),
      };
    });
    return tableData;
  };

  /**
   * set table column headings
   */
  const columns = useMemo(() => {
    return getJVTableHeader();
  }, []);

  const submitHandler = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      updateReportingTagsPM({
        orgId: currentUserInfo.organization_id,
        pmId: Number(editId),
        values: filterTransactionListPayload(journals),
      })
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      dispatch(setLoaderState(false));
      SuccessToaster(
        "Reporting tag associated successfully!",
        "reporting-tag-association-successfully"
      );
      navigate(-1);
    } else {
      dispatch(setLoaderState(false));
    }
  };

  return (
    <>
      <Container
        className="card card-user-management card-customer card-create-item main-card overflowX-hidden h-100 reportingtag-jv "
        id="invoice-reportingtag"
      >
        <Header
          onBackButtonClick={() => {
            navigate(-1);
          }}
          data={{
            num: "Apply Reporting tags to accounts",
            status: "",
          }}
          dataId={editId}
        />
        <Container className="journal-list-table-mapping-wrapper">
          {getTableMapData().map((tableData) => {
            return (
              <Container className="card-body journal-list-table">
                <h2>{joinTransactionNumber(tableData.billNumber)}</h2>
                <Container className="table-section">
                  <Container className="table-wrap">
                    <Table
                      id={""}
                      columns={columns}
                      data={tableData.tableData}
                      tableClassName={
                        "table inv-preview-table report-tags-table"
                      }
                      dataSort={false}
                      isLoading={false}
                      haveColumnClassName={false}
                      rowClassName={"table-navigation"}
                      emptyClassName={"text-center dataTables_empty"}
                      tbodyRowOnClick={() => {}}
                      tbodyColumnClick={() => {}}
                    >
                      {props.children}
                    </Table>
                  </Container>
                </Container>
              </Container>
            );
          })}
        </Container>
        <DockedButtonGroup
          saveButtonType="button"
          onSave={() => {
            submitHandler();
          }}
          onCancel={() => {
            navigate(-1);
          }}
          onDelete={() => {}}
          isDeleteButtonDisabled={true}
          isDeleteButtonEnabled={false}
          isEdit={false}
          isSaveDisabled={false}
          isModal={false}
          shouldDockToBottom={true}
          buttonText="Apply Tags"
        />
      </Container>
    </>
  );
};

export default JournalListPM;
