/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from "react";
import SubscriptionViewHeader from "./SubscriptionViewHeader";
import InvoiceTable from "./InvoiceTable";
import { setErrorID } from "../../../../../appSlice";
import useCommonData from "../../../../hooks/useCommon";
import { noAccessErrorCodes } from "../../../../constants/constants";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../helpers/numberFormatHelper";

import { DocIconSquare, Locator } from "../../../../../assets/images";
import "./subscriptionView.css";
import { useParams } from "react-router-dom";
import { setLoaderState } from "../../../../common/commonSlice";
import {
  subscriptionDetails,
  childInvoiceList,
  schedulerDetails,
  getUnbilledChargesList,
} from "../../subscriptionSlice";
import {
  SubscriptionDetailValues,
  RecurringInvoiceAddressValuesForView,
  InvoiceList,
} from "../../../../../types";
import {
  subscriptionDetailsInitialValues,
  recurringInvoiceAddressInitialValues,
} from "../../SubscriptionStateInitialization";
import AddressOverviewCard from "./AddressOverviewCard";
import TableInfo from "./TableInfo";
import TaxColumn from "../../../../common/components/detailsPage/TaxColumn";
import DetailsTableContainerSubscription from "../../../../common/components/detailsPage/DetailsTableContainerSubscription";
import DetailTotalSection from "../../../../common/components/detailsPage/DeatailsTotalSection";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import FileList from "../../../../common/components/FileList";
import UnbilledTable from "./UnbilledTable";
import useGst from "../../../../hooks/useGst";
import {
  coustomCase,
  pricingModuleRenderValue,
} from "../../../../../helpers/planHelper";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { UnbilledChangesList } from "../../subscriptionType";

const SubscriptionView = () => {
  const { subscriptionRolePermission } = usePermissionHandler();
  const [activeTab, setActiveTab] = useState("overview");
  const [billingAddress, setBillingAddress] =
    useState<RecurringInvoiceAddressValuesForView>(
      recurringInvoiceAddressInitialValues
    );
  const [shippingAddress, setShippingAddress] =
    useState<RecurringInvoiceAddressValuesForView>(
      recurringInvoiceAddressInitialValues
    );
  const { subscriptionId } = useParams();
  const { dispatch, navigate, currentUserInfo, orgCurrencyList } =
    useCommonData();
  const { isGstOrg } = useGst();
  const itemsPerPage = 10;
  const [page, setPage] = useState<number>(1);
  const [totalList, setTotalList] = useState<number>(0);
  const [itemOffset, setItemOffset] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(1);
  const [overviewSubscriptionData, setOverviewSubscriptionData] =
    useState<SubscriptionDetailValues>(subscriptionDetailsInitialValues);
  const [dateSortOrder, setDateSortOrder] = useState("");
  const [childInvoiceData, setChildInvoiceData] = useState<InvoiceList[]>([]);
  const [scheduledSubscriptionData, setScheduledSubscriptionData] = useState({
    status_change_scheduler: null,
    edit_scheduler: null,
    edit_changes: null,
  });
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [unbilledChargesData, setUnbilledChargesData] = useState<
    UnbilledChangesList[]
  >([]);
  let taxTypes = overviewSubscriptionData.total_details.taxes
    ? Object.entries(overviewSubscriptionData.total_details.taxes)
    : [];

  /**
   * Format date from YYYY-MM-DD to DD-MM-YYYY
   */
  const dateFormatHandler = (date: string) => {
    if (!date) {
      return "";
    }
    let newDate = date.split("-").reverse().join("-");
    return newDate;
  };
  /**
   * Fetch Invoice Details
   */
  useEffect(() => {
    if (subscriptionId) {
      recurringInvoiceDetail();
      unbilledDetailsChargesList();
    }
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    if (subscriptionId) {
      subscriptionSchedulerDetails();
    }
  }, [overviewSubscriptionData]);

  const subscriptionSchedulerDetails = async () => {
    if (
      subscriptionRolePermission.includes("Update") &&
      overviewSubscriptionData.organization_id ===
        currentUserInfo.organization_id
    ) {
      const responseAction = await dispatch(
        schedulerDetails({
          subscriptionId: Number(subscriptionId),
          orgId: currentUserInfo.organization_id,
        })
      );
      if (responseAction.payload) {
        if (activeTab === "overview") {
          setTimeout(function () {
            dispatch(setLoaderState(false));
          }, 500);
        }
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          setScheduledSubscriptionData(response);
        } else {
          // dispatch(setErrorID(noAccessErrorCodes.invoiceDetail));
          // navigate("/subscriptions");
        }
      }
    } else {
      setScheduledSubscriptionData({
        status_change_scheduler: null,
        edit_scheduler: null,
        edit_changes: null,
      });
      if (activeTab === "overview") dispatch(setLoaderState(false));
    }
  };

  const unbilledDetailsChargesList = async () => {
    const responseAction = await dispatch(
      getUnbilledChargesList({
        subscriptionId: Number(subscriptionId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      if (activeTab === "childInvoice") {
        setTimeout(function () {
          dispatch(setLoaderState(false));
        }, 500);
      }
      const response = responseAction.payload;
      if (!("error" in response)) {
        setUnbilledChargesData(response);
      }
    } else {
      if (activeTab === "childInvoice") dispatch(setLoaderState(false));
    }
  };
  useEffect(() => {
    let pageCount = Math.ceil(totalList / itemsPerPage);
    setPageCount(pageCount);
    if (page >= pageCount && pageCount !== 0) {
      setPage(pageCount);
    }
  }, [itemOffset, itemsPerPage, totalList, page]);

  const recurringInvoiceDetail = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      subscriptionDetails({
        subscriptionId: Number(subscriptionId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setOverviewSubscriptionData(response);
      } else {
        setTimeout(function () {
          dispatch(setLoaderState(false));
        }, 500);
        dispatch(setErrorID(noAccessErrorCodes.invoiceDetail));
        navigate("/subscriptions");
      }
    }
  };

  /**
   * Fetch Child Invoice List
   */
  const fetchChildInvoiceList = useCallback(
    async (recurringInvoiceId: number) => {
      dispatch(setLoaderState(true));
      const responseAction = await dispatch(
        childInvoiceList({
          page,
          itemsPerPage,
          dateSortOrder,
          invoiceId: Number(recurringInvoiceId),
          orgId: currentUserInfo.organization_id,
        })
      );

      if (responseAction.payload) {
        setTimeout(function () {
          dispatch(setLoaderState(false));
        }, 500);
        const response = responseAction.payload;
        setInvoiceCount(response.total);
        if (Object.keys(response).length && !("error" in response)) {
          setChildInvoiceData(response.invoices);
          setTotalList(response.total);
        }
      }
    },
    [page, dateSortOrder, currentUserInfo.organization_id]
  );

  useEffect(() => {
    if (overviewSubscriptionData.recurring_invoice_id) {
      fetchChildInvoiceList(overviewSubscriptionData.recurring_invoice_id);
    }
  }, [
    page,
    currentUserInfo.organization_id,
    dateSortOrder,
    overviewSubscriptionData.recurring_invoice_id,
  ]);

  useEffect(() => {
    const billing = overviewSubscriptionData.address.find(
      (e) => e.is_billing === true
    );

    setBillingAddress(billing ?? recurringInvoiceAddressInitialValues);
    const shipping = overviewSubscriptionData.address.find(
      (e) => e.is_shipping === true
    );
    setShippingAddress(shipping ?? recurringInvoiceAddressInitialValues);
  }, [overviewSubscriptionData.address]);

  /**
   * Setting active tab in session storage
   */
  function handleTabClick(tab: string) {
    setActiveTab(tab);
    sessionStorage.setItem("subscriptionActiveTab", tab);
  }

  useEffect(() => {
    const storedTab = sessionStorage.getItem("subscriptionActiveTab");
    if (storedTab) {
      setActiveTab(storedTab);
    }
  }, []);

  /**
   * Invoke when user click to request another page.
   */
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalList;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  /**
   * set table column headings
   */
  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "Description",
        accessor: "show.description",
        className: "description",
        Cell: ({ cell: { value } }: any) => {
          return <span className="item-bold">{value}</span>;
        },
      },
      {
        Header: "Account",
        accessor: "show.account",
        className: "account left-align",
      },
      {
        Header: "Quantity",
        accessor: "show.quantity",
        className: "quantity text-nowrap left-align",
      },
      {
        Header: "Rate",
        accessor: "show.rate",
        className: "rate text-nowrap left-align",
      },
      {
        Header: "Discount",
        accessor: "show.discount",
        className: "left-align discount text-nowrap",
        Cell: ({ cell: { value } }: any) => {
          let discAccount = value?.discountAccount
            ? value.discountAccount.split(" (")
            : null;
          return (
            <>
              <span className="amt">{value.discount}</span>
              <span className="acnt">
                {discAccount && discAccount.length > 0 ? discAccount[0] : ""}{" "}
                <br />
                {discAccount && discAccount.length === 2
                  ? "(" + discAccount[1]
                  : ""}
              </span>
            </>
          );
        },
      },
      {
        Header: "TDS",
        accessor: "show.item",
        className: "rate text-nowrap left-align",
        Cell: ({ cell: { value } }: any) => {
          return (
            <>
              <span className="amt">
                {overviewSubscriptionData.currency_code}{" "}
                {NumberFormat(
                  Number(value.tds_total),
                  overviewSubscriptionData.currency_code,
                  orgCurrencyList
                )}
              </span>
              <span className="actual-value d-block w-100 clear-both">
                {value.tds_name}{" "}
                {value.default_tds_percentage
                  ? "(" + value.default_tds_percentage + "%)"
                  : ""}
              </span>
              {value.tds_percentage !== 0 &&
              value.tds_percentage !== value.default_tds_percentage ? (
                <span className="current-value  d-block w-100 clear-both">
                  Updated Rate {`(${value.tds_percentage}%)`}
                </span>
              ) : null}
            </>
          );
        },
      },
      {
        Header: "Tax",
        accessor: "show.tax",
        className: "tax text-nowrap text-align-left left-align",
        Cell: ({ cell: { value } }: any) => {
          return (
            <TaxColumn
              values={value}
              currencyCode={overviewSubscriptionData.currency_code}
            />
          );
        },
      },
      {
        Header: "Taxable Amount",
        accessor: "show.taxableAmount",
        className: "taxableAmount text-nowrap text-align-right",
      },
    ];

    if (isGstOrg) {
      baseColumns.splice(1, 0, {
        Header: "Hsn/sac code",
        accessor: "show.hsnCode",
        className: "hsncode text-nowrap left-align",
      });
    }

    return baseColumns;
  }, [overviewSubscriptionData.currency_code, isGstOrg]);

  function convertToNormalText(str: string) {
    let words = str.toLowerCase().split("_");
    return words.join(" ");
  }

  const structureBillingCycle = (originalString: any) => {
    let updatedString = originalString.replace("(0 cycles)", "");
    let modifiedString = updatedString.replace(/\(1 cycles\)/, "").trim();
    return modifiedString;
  };

  return (
    <div className="recurring-invoice-view subscription-view">
      <div
        className="card card-dashboard customer-detail-card w-100"
        id="customer-detail-card"
      >
        <SubscriptionViewHeader
          overviewSubscriptionData={overviewSubscriptionData}
          childInvoiceListData={childInvoiceData}
          recurringInvoiceDetail={recurringInvoiceDetail}
          subscriptionId={subscriptionId || ""}
          scheduledSubscriptionData={scheduledSubscriptionData}
          subscriptionSchedulerDetails={subscriptionSchedulerDetails}
        />
        <div className="overview-wrapper tab-content-wrapper">
          <ul
            className="nav nav-pills pills-tab mt-0"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className={
                  activeTab === "overview" ? "nav-link active" : "nav-link"
                }
                id="pills-overview-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-overview"
                type="button"
                role="tab"
                aria-controls="pills-overview"
                aria-selected="true"
                onClick={() => handleTabClick("overview")}
              >
                Overview
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={
                  activeTab === "childInvoice" ? "nav-link active" : "nav-link"
                }
                id="pills-statement-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-statement"
                type="button"
                role="tab"
                aria-controls="pills-statement"
                aria-selected="false"
                onClick={() => handleTabClick("childInvoice")}
              >
                Invoices ({invoiceCount})
              </button>
            </li>
          </ul>
          <div className="tab-content pb-0" id="pills-tabContent">
            <div
              className={
                activeTab === "overview"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="pills-overview"
              role="tabpanel"
              aria-labelledby="pills-overview-tab"
              tabIndex={0}
            >
              <div className="overview-wrapper tab-content-wrapper">
                <section className="section-blocks other-dets-section">
                  <h2>
                    <img
                      src={DocIconSquare}
                      width="24"
                      height="24"
                      alt="Other details"
                    />
                    Details
                  </h2>
                  <div className="other-wrapper section-content-wrapper">
                    <div className="row g-0">
                      <div className="col">
                        <div className="field-row">
                          <div className="field-title">
                            <span>Start date</span>
                          </div>
                          <div className="field-value">
                            <span>
                              {dateFormatHandler(
                                overviewSubscriptionData.start_date
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="field-row">
                          <div className="field-title">
                            <span>Invoicing Cycle</span>
                          </div>
                          <div className="field-value">
                            <span>
                              {structureBillingCycle(
                                overviewSubscriptionData.billing_cycle
                                  ?.charAt(0)
                                  .toUpperCase() +
                                  overviewSubscriptionData.billing_cycle
                                    ?.slice(1)
                                    .toLowerCase()
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="field-row">
                          <div className="field-title">
                            <span>Subscription Period</span>
                          </div>
                          <div className="field-value">
                            <span>
                              {overviewSubscriptionData.subscription_period
                                ?.charAt(0)
                                .toUpperCase() +
                                overviewSubscriptionData.subscription_period
                                  ?.slice(1)
                                  .toLowerCase()}
                            </span>
                          </div>
                        </div>
                        <div className="field-row">
                          <div className="field-title">
                            <span>Invoice Amount</span>
                          </div>
                          <div className="field-value">
                            <span>
                              {`${
                                overviewSubscriptionData.currency_code
                              } ${NumberFormat(
                                Number(
                                  overviewSubscriptionData.total_details.total
                                ),
                                overviewSubscriptionData.currency_code,
                                orgCurrencyList
                              )}`}
                            </span>
                          </div>
                        </div>
                        <div className="field-row">
                          <div className="field-title">
                            <span>Payment Terms</span>
                          </div>
                          <div className="field-value">
                            <span>
                              {overviewSubscriptionData.payment_terms}
                            </span>
                          </div>
                        </div>
                        {isGstOrg && (
                          <div className="field-row">
                            <div className="field-title">
                              <span>Place of Supply</span>
                            </div>
                            <div className="field-value">
                              <span>
                                {overviewSubscriptionData.place_of_supply}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
                <section className="section-blocks addressSection">
                  <h2>
                    <img
                      src={Locator}
                      width="24"
                      height="24"
                      alt="Locator Icon"
                    />
                    Address
                  </h2>
                  <div className="address-wrapper section-content-wrapper flex-nowrap">
                    <div className="address-container billing-address-container">
                      <h3>Billing Address</h3>
                      <div className="address-box-container d-flex">
                        <AddressOverviewCard
                          openAddressModal={() => {}}
                          id="billing"
                          key={billingAddress.id}
                          address={{ ...billingAddress, is_primary: true }}
                          setAddressIdHandler={() => {}}
                          isSameOrg={false}
                          shouldScrollTo={false}
                          overviewSubscriptionData={overviewSubscriptionData}
                        />
                      </div>
                    </div>
                    <div
                      className={`address-container shipping-address-container`}
                    >
                      <h3>Shipping Address</h3>
                      <div className="address-box-container d-flex">
                        <AddressOverviewCard
                          openAddressModal={() => {}}
                          id="shipping"
                          key={shippingAddress.id}
                          address={{ ...shippingAddress, is_primary: true }}
                          setAddressIdHandler={() => {}}
                          isSameOrg={false}
                          shouldScrollTo={false}
                          overviewSubscriptionData={overviewSubscriptionData}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section className="section-blocks-item-table" id="invoice">
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <span>
                      <h2 className="mb-0 subscription-details-plan-addon-coupon-heading">
                        Plan, Addon & Coupon Details
                      </h2>
                    </span>
                  </div>
                  <div className="table-section">
                    <div className="table-wrap">
                      <div
                        style={{
                          fontSize: "larger",
                          paddingLeft: "23px",
                          paddingTop: "19px",
                          marginBottom: "-13px",
                        }}
                      >
                        Plans
                      </div>
                      {overviewSubscriptionData.plan_details &&
                        overviewSubscriptionData.plan_details.length > 0 &&
                        overviewSubscriptionData?.plan_details?.map((plan) => {
                          return (
                            <>
                              <div className="plan-description">
                                <h6 className="item-bold">{plan.plan_name}</h6>
                                <div className="plan-tag">
                                  <span>
                                    Frequency: {coustomCase(plan.frequency)} |
                                    Pricing model:{" "}
                                    {plan.pricing_model
                                      ? pricingModuleRenderValue.filter(
                                          (data: any) =>
                                            plan.pricing_model === data.key
                                        )[0].value
                                      : ""}
                                  </span>
                                </div>
                              </div>
                              <DetailsTableContainerSubscription
                                columns={columns}
                                data={plan.items}
                                currency_code={
                                  overviewSubscriptionData.currency_code
                                }
                                haveColumnClassName={true}
                                className="table subscription-view-plan-table"
                                hiddenColumns={["TDS"]}
                                pricingModel={plan.pricing_model}
                              />
                            </>
                          );
                        })}
                      {overviewSubscriptionData.addon_details &&
                        overviewSubscriptionData.addon_details.length > 0 && (
                          <div
                            style={{
                              fontSize: "larger",
                              paddingLeft: "23px",
                              paddingTop: "19px",
                              marginBottom: "-13px",
                            }}
                          >
                            Addons
                          </div>
                        )}
                      {overviewSubscriptionData.addon_details &&
                        overviewSubscriptionData.addon_details.length > 0 &&
                        overviewSubscriptionData?.addon_details?.map(
                          (addon) => {
                            return (
                              <>
                                <div className="plan-description">
                                  <h6 className="item-bold">
                                    {addon.addon_name}
                                  </h6>
                                  <div className="plan-tag">
                                    <span>
                                      Frequency: {coustomCase(addon.frequency)}{" "}
                                      | Pricing model:{" "}
                                      {addon.pricing_model
                                        ? pricingModuleRenderValue.filter(
                                            (data: any) =>
                                              addon.pricing_model === data.key
                                          )[0].value
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                                <DetailsTableContainerSubscription
                                  columns={columns}
                                  data={addon.items}
                                  currency_code={
                                    overviewSubscriptionData.currency_code
                                  }
                                  haveColumnClassName={true}
                                  className="table"
                                  hiddenColumns={["TDS"]}
                                  pricingModel={addon.pricing_model}
                                />
                              </>
                            );
                          }
                        )}
                      {overviewSubscriptionData.coupon_details &&
                        overviewSubscriptionData.coupon_details.length > 0 && (
                          <div
                            style={{
                              fontSize: "larger",
                              paddingLeft: "23px",
                              paddingTop: "19px",
                              marginBottom: "-13px",
                            }}
                          >
                            Coupons
                          </div>
                        )}
                      {overviewSubscriptionData.coupon_details &&
                        overviewSubscriptionData.coupon_details.length > 0 &&
                        overviewSubscriptionData?.coupon_details?.map(
                          (coupon) => {
                            return (
                              <>
                                <div className="plan-description coupon-description-container">
                                  <div>
                                    <h6 className="item-bold">{coupon.name}</h6>
                                    <div className="plan-tag">
                                      <span>
                                        Applicable{" "}
                                        {convertToNormalText(
                                          coupon.duration_type
                                        )}
                                      </span>
                                    </div>
                                    {coupon.is_exhausted && (
                                      <div style={{ color: "red" }}>
                                        Exhausted
                                      </div>
                                    )}
                                  </div>
                                  <div className="coupon-discount-amount-container">
                                    {coupon.discount_type !== "PERCENTAGE"
                                      ? overviewSubscriptionData.currency_code +
                                        " " +
                                        NumberFormat(
                                          Number(coupon.discount_value),
                                          overviewSubscriptionData.currency_code,
                                          orgCurrencyList
                                        )
                                      : coupon.discount_value + "%"}
                                  </div>
                                </div>
                              </>
                            );
                          }
                        )}
                    </div>
                    <div className="d-flex justify-content-between pt-3 mt-1 w-100 table-bottom-sect-wrap">
                      <div className="thankyou-note w-50 d-flex align-items-end">
                        {overviewSubscriptionData?.customer_notes && (
                          <div className="note-container">
                            <span className="label">Customer Notes</span>
                            <p>{overviewSubscriptionData.customer_notes}</p>
                          </div>
                        )}
                      </div>
                      <DetailTotalSection
                        currencyCode={overviewSubscriptionData.currency_code}
                        totalTax={
                          overviewSubscriptionData.total_details.tax_amount
                        }
                        paymentLabel="Payment received"
                        // payment={
                        //   overviewSubscriptionData.total_details.payment_made
                        // }
                        payment={undefined}
                        taxTypes={taxTypes}
                        subTotal={
                          overviewSubscriptionData.total_details
                            .total_taxable_amount
                        }
                        total={overviewSubscriptionData.total_details.total}
                        totalInWords={overviewSubscriptionData.amount_in_words}
                        isAmountInWordsEnabled={
                          overviewSubscriptionData.amount_in_words &&
                          overviewSubscriptionData.amount_in_words !== null
                            ? true
                            : false
                        }
                        coupon_amount={
                          overviewSubscriptionData.total_details.coupon_amount
                        }
                        couponRequired={true}
                        balanceLabel={"Balance Due"}
                        balanceDueRequired={true}
                        balanceAmount={
                          overviewSubscriptionData.total_details.balance_due
                            ? overviewSubscriptionData.total_details.balance_due
                            : zeroDisplayFormat(
                                decimalPlaceOfCurrency(
                                  overviewSubscriptionData.currency_code
                                    ? overviewSubscriptionData.currency_code
                                    : currentUserInfo.currency_code,
                                  orgCurrencyList
                                )
                              )
                        }
                        tdsItems={{
                          tdsAmount: overviewSubscriptionData.total_details
                            .transaction_tds_amount
                            ? overviewSubscriptionData.total_details
                                .transaction_tds_amount
                            : 0,
                          tdsName: overviewSubscriptionData.tds_name,
                          tdsPercentage: 0,
                          defaultTdsPercentage:
                            overviewSubscriptionData.default_tds_percentage,
                        }}
                        tcsItems={{
                          tcsAmount: overviewSubscriptionData.tcs_total,
                          tcsName: overviewSubscriptionData.tcs_name,
                          tcsPercentage: 0,
                        }}
                        roundOffValue={
                          overviewSubscriptionData.total_details.roundoff_amount
                        }
                        roundoffEnabled={
                          overviewSubscriptionData.roundoff_enabled
                        }
                      >
                        <></>
                      </DetailTotalSection>
                    </div>
                  </div>
                  {overviewSubscriptionData.files?.length ? (
                    <FileList files={overviewSubscriptionData.files} />
                  ) : null}
                </section>
              </div>
            </div>
          </div>
          <div className="tab-content pb-0" id="pills-child-invoice">
            <div
              className={
                activeTab === "childInvoice"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="pills-statement"
              role="tabpanel"
              aria-labelledby="pills-statement-tab"
              tabIndex={0}
            >
              <TableInfo
                nextInvoiceAmount={`${
                  overviewSubscriptionData.currency_code
                } ${NumberFormat(
                  Number(overviewSubscriptionData.total_details.total),
                  overviewSubscriptionData.currency_code,
                  orgCurrencyList
                )}`}
                unpaidAmount={`${
                  overviewSubscriptionData.currency_code
                } ${NumberFormat(
                  Number(overviewSubscriptionData.total_unpaid_amount) || 0,
                  overviewSubscriptionData.currency_code,
                  orgCurrencyList
                )}`}
                nextIncoiceDate={overviewSubscriptionData.next_renewal}
                recurringInvoiceStatus={
                  overviewSubscriptionData.subscription_status
                }
              />
              {unbilledChargesData && unbilledChargesData.length > 0 ? (
                <UnbilledTable
                  unbilledChargesData={unbilledChargesData}
                  overviewSubscriptionData={overviewSubscriptionData}
                  refreshUnbilledList={unbilledDetailsChargesList}
                />
              ) : null}
              <InvoiceTable
                childInvoiceData={childInvoiceData}
                dateSortOrder={dateSortOrder}
                setDateSortOrder={setDateSortOrder}
                totalList={totalList}
                handlePageClick={handlePageClick}
                itemsPerPage={itemsPerPage}
                page={page}
                pageCount={pageCount}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionView;
