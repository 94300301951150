import moment from "moment";
import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../../app/hooks";
import "../../../recurringInvoice/components/recurringInvoiceForm/Invoice.css";
import {
  FormInputField,
  FormSelectField,
  FormTextAreaField,
} from "../../../../common/components";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import Select from "react-select";

import "./defaultDetails.css";
import Item from "./Item";
import usePreservedLocation from "../../../../hooks/usePreservedLocation";
import { PlanPriceDefaultValue, ObjectType } from "../../../../../types";
import { validate } from "./ValidatePriceForm";
import ScrollToError from "../../../../common/components/scrollToError";
import {
  addonPricePointCreate,
  addonPricePointEdit,
  addonItemList
} from "../../addonSlice";
import { setLoaderState } from "../../../../common/commonSlice";
import useCommonData from "../../../../hooks/useCommon";
import { coustomCase } from "../../../../../helpers/planHelper";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { message } from "../../../../constants/messages";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";

type Props = {
  organizationId: number;
  frequency?: string;
  totalAmount?: number;
  addonId: number;
  priceDetails?: any;
  priceId?: any;
  addonDetailData: any;
  checkInternalName: (internalName: string) => void;
  isInternalNameExist: boolean;
};

interface OptionType {
  value: string;
  label: string;
}

const DefaultDetails: ForwardRefRenderFunction<any, Props> = (props, ref) => {
  const [amount, setAmount] = useState(0);
  const navigate = useNavigate();
  const initialDefaultValues = {
    frequency: props.frequency?.toUpperCase(),
    internal_name: "",
    external_name: "",
    description: "",
    items: [],
    customer_id: "",
    trial: false,
    pricing_model: "FLAT_FEE",
    billing_model: "",
    show_description: false,
    trial_count: 1,
    trial_frequency: "DAYS",
    invoicing_cycle: "FOREVER",
    addon_id: props.addonId,
    total_price: props.totalAmount,
    invoicing_cycle_fixed_count: 0,
    delete_item_pricing: [],
  };

  const [planItemListData, setPlanItemListData] = useState<any[]>([]);
  const { orgCurrencyList, currentUserInfo } = useCommonData();
  const [plan, setPlan] = useState<any>({
    label: "Flat Fee",
    value: "FLAT_FEE",
  });

  const options: OptionType[] = [
    { label: "Days", value: "DAYS" },
    { label: "Months", value: "MONTHS" },
  ];

  const pricingModelOptions: OptionType[] = [
    { label: "Flat Fee", value: "FLAT_FEE" },
    { label: "Per Unit", value: "PER_UNIT" },
    { label: "Tiered", value: "TIERED" },
    { label: "Volume", value: "VOLUME" },
    { label: "Stairstep", value: "STAIRSTEP" },
  ];

  const billingCycleOptions: OptionType[] = [
    { label: "Forever", value: "FOREVER" },
    { label: "Fixed", value: "FIXED" },
  ];

  type LocationProps = {
    consumerId?: string;
  };
  const location = usePreservedLocation();
  const dispatch = useAppDispatch();
  const [defaultFormValues, setDefaultFormValues] =
    useState<PlanPriceDefaultValue>(initialDefaultValues);
  const [formErrors, setFormErrors] = useState<ObjectType>({});

  const [selectedOption, setSelectedOption] = useState<OptionType | null>(
    options[0]
  );
  const [selectedOptionPricingModel, setSelectedOptionPricingModel] = useState(
    pricingModelOptions[0]
  );

  const [selectedOptionBillingCycle, setSelectedOptionBillingCycle] = useState(
    billingCycleOptions[0]
  );

  const addonId = props.addonId;

  const fetchPlanItemList = useCallback(async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      addonItemList({
        addonId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;

      if (Object.keys(response).length && !("error" in response)) {
        let totalAmount = 0;
        response.items.map(
          (item: {
            price: { range_from: string | number; from: string | number; to: string | number; price_per_step: string | number; price: any }[];
            rate: string;
            currency_code: string;
          }) => {
            item.price = [{ range_from: 1, from: "", to: "", price_per_step: "", price: Number(item.rate).toFixed(decimalPlaceOfCurrency(item.currency_code,
              orgCurrencyList)) }];
            item.rate = Number(item.rate).toFixed(decimalPlaceOfCurrency(item.currency_code,
              orgCurrencyList))
            return (totalAmount += Number(item.rate));
          }
        );
        setAmount(totalAmount);
        setPlanItemListData(response.items);

        // const optionPricingModel = pricingModelOptions.find((option) => option.value === props.priceDetails.pricing_model)
        // if(optionPricingModel) {
        //   setSelectedOptionPricingModel(optionPricingModel)
        //   setPlan(optionPricingModel);
        // }
      } else if ("error" in response) {
      }
    }
  }, [currentUserInfo.organization_id]);

  const capitalize = (text: string | undefined) => {
    if (text) {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }
    return "";
  };

  useEffect(() => {
    if (props.addonDetailData && props.addonDetailData.external_name) {
      setDefaultFormValues({
        ...defaultFormValues,
        internal_name: `${props.addonDetailData.external_name} - ${capitalize(
          props.frequency
        )}`,
        external_name: `${props.addonDetailData.external_name} - ${capitalize(
          props.frequency
        )}`,
      });
    }
  }, [props.addonDetailData]);

  useEffect(() => {
    const priceCopy = JSON.parse(JSON.stringify(props.priceDetails));
    delete priceCopy.items;
    setDefaultFormValues({ ...defaultFormValues, ...priceCopy });
    const optionPricingModel = pricingModelOptions.find(
      (option) => option.value === props.priceDetails.pricing_model
    );
    if (optionPricingModel) {
      setSelectedOptionPricingModel(optionPricingModel);
      setPlan(optionPricingModel);
    }
    if (props.priceDetails.invoicing_cycle)
      setSelectedOptionBillingCycle({
        label: coustomCase(props.priceDetails.invoicing_cycle),
        value: props.priceDetails.invoicing_cycle,
      });
    if (props.priceDetails.trial_frequency)
      setSelectedOption({
        label: coustomCase(props.priceDetails.trial_frequency),
        value: props.priceDetails.trial_frequency,
      });
    let totalAmount = 0;

    if (
      props.priceDetails &&
      props.priceDetails.items &&
      props.priceDetails.items.length
    ) {
      props.priceDetails.items.map(
        (item: {
          addon_item_id: any;
          steps: any[];
          price: any;
          free_quantity: any;
          item_pricing_id: any;
        }) => {
          const planData = planItemListData.find(
            (e) => item.addon_item_id === e.addon_item_id
          );
          if (planData) {
            if (
              props.priceDetails.pricing_model === "PER_UNIT" ||
              props.priceDetails.pricing_model === "FLAT_FEE"
            ) {
              planData.price = [];
              planData.price.push({
                price: item.price,
                free_quantity: item.free_quantity,
                item_pricing_id: item.item_pricing_id,
                range_from: 1,
                range_to: "",
                price_per_step: "",
              });
              totalAmount += Number(item.price);
            } else {
              planData.addon_item_id = item.addon_item_id;
              planData.price = item.steps;
              // planData.item_pricing_id=item.item_pricing_id
            }
            setAmount(totalAmount);
            return planData;
          }
        }
      );
    }
  }, [props.priceDetails, planItemListData]);

  useEffect(() => {
    fetchPlanItemList();
  }, [currentUserInfo.organization_id]);

  function isFloat(value: any) {
    const res = value.split(".");
    if (res[1]) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (props.isInternalNameExist) {
      setFormErrors({
        ...formErrors, internal_name: "Internal Name already exists. Please choose a different one",
      });
    } else {
      setFormErrors({ ...formErrors, internal_name: "" });
    }
  }, [props.isInternalNameExist])

  const updateTotalAmount = () => {
    let totalAmount = 0;
    planItemListData.forEach(
      (item: {
        price: {
          from: string;
          to: string;
          price_per_step: string;
          price: number;
        }[];
        rate: number;
      }) => {
        item.price.forEach((priceObj) => {
          if (!isNaN(priceObj.price)) {
            totalAmount += Number(priceObj.price);
          }
        });
      }
    );
    setAmount(totalAmount);
  };
  const updateTotalPricePerStepAmount = () => {
    let totalAmount = 0;
    planItemListData.forEach(
      (item: {
        price: {
          from: string;
          to: string;
          price_per_step: string;
          price: number;
        }[];
        rate: number;
      }) => {
        item.price.forEach((priceObj) => {
          totalAmount += Number(priceObj.price_per_step);
        });
      }
    );
    setAmount(totalAmount);
  };

  const handleChange = (e: any, index: any = "") => {
    let { name, value, checked } = e.target;
    if (name === "internal_name") {
      props.checkInternalName(value)
    }
    if (
      name === "invoicing_cycle_fixed_count" &&
      (value < 0 || isFloat(value))
    ) {
      return;
    }

    if (name === "trial_count" && (value < 0 || isFloat(value))) {
      return;
    }

    if (name === "trial")
      if (value == "trial_no") {
        value = false;
      } else {
        value = true;
      }
    if (name == "show_description") {
      setDefaultFormValues({ ...defaultFormValues, [name]: checked });
    } else {
      setDefaultFormValues({ ...defaultFormValues, [name]: value });
    }
  };

  const handleChangeSelect = (selectedOption: any) => {
    setSelectedOption(selectedOption);
    setDefaultFormValues({
      ...defaultFormValues,
      trial_frequency: selectedOption.value,
    });
  };

  const handleChangePrice = (e: any, index: any = "") => {
    e.preventDefault();
    const { name, value } = e.target;
    defaultFormValues.items[index].price = value;
    setDefaultFormValues({ ...defaultFormValues });
  };
  const handleFreeQuatity = (e: any, index: any = "") => {
    e.preventDefault();
    const { name, value } = e.target;
    defaultFormValues.items[index].free_quantity = value;
    setDefaultFormValues({ ...defaultFormValues });
  };

  const handleChangeFrom = (e: any, index: any = "", itemDetails: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }

    const { name, value } = e.target;

    if ( (name === "range_to" || name === "price_per_step" || name === "price" ||
    name === "free_quantity" ||
    name === "range_from") && (Number.isNaN(Number(value)))) {
      return
    }
    
    if(name === "price_per_step" || name === "price") {
      let splitValue = value.split(".")
      if ((splitValue[0]).length > 14 || (splitValue[1] && splitValue[1].length > 6)) return
    }

    itemDetails.price[index][name] =
    name === "range_to" || name === "free_quantity" ? `${value.replaceAll('.', '').trim()}` : value;
    itemDetails.price[index]["currency_id"] = itemDetails.currency_id;
    itemDetails.price[index]["hsn_or_sac_code"] = itemDetails.hsn_or_sac_code;
    itemDetails.price[index]["item_name"] = itemDetails.item_name;
    itemDetails.price[index].addon_item_id = itemDetails.addon_item_id;
    if (!defaultFormValues.items) {
      defaultFormValues.items = [];
    }
    if (!defaultFormValues.items[index]) {
      defaultFormValues.items[index] = {};
      defaultFormValues.items[index]["range_from"] = parseFloat(value);
    }
    setDefaultFormValues({ ...defaultFormValues });
    if (name === "price") {
      updateTotalAmount();
    }
    if (name === "price_per_step") {
      updateTotalPricePerStepAmount();
    }
  };

  const handleAddNewField = (itemDetails: any) => {
    const itemData = JSON.parse(JSON.stringify(itemDetails));
    const rangeFrom = itemData.price[itemData.price.length - 1];
    itemDetails.price.push({
      range_from: parseFloat(rangeFrom.range_to) + 1,
      range_to: 0,
      price_per_step: "",
    });
    setDefaultFormValues({ ...defaultFormValues });
  };

  const removeRowHandle = (
    index: any = "",
    itemDetails: any,
    item_pricing_id: any
  ) => {
    itemDetails.price.splice(index, 1);
    let delete_item_pricing = JSON.parse(
      JSON.stringify(defaultFormValues.delete_item_pricing)
    );
    if (item_pricing_id) {
      delete_item_pricing.push(item_pricing_id);
    }
    setDefaultFormValues({ ...defaultFormValues, delete_item_pricing });
  };

  const handleChangeTo = (e: any, index: any = "") => {
    e.preventDefault();
    const { name, value } = e.target;
    defaultFormValues.items[index].range_to = value;
    setDefaultFormValues({ ...defaultFormValues });
  };

  const handleChangePricePerStep = (e: any, index: any = "") => {
    e.preventDefault();
    const { name, value } = e.target;
    defaultFormValues.items[index].price_per_step = value;
    setDefaultFormValues({ ...defaultFormValues });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    
    let errors = validate(defaultFormValues, planItemListData, props.isInternalNameExist);

    let ItemObjArr: any[] = [];

    const dupItems = JSON.parse(JSON.stringify(planItemListData));

    let removePricingId = false;
    if (
      props.priceDetails &&
      props.priceDetails.pricing_model &&
      props.priceDetails.pricing_model !== defaultFormValues.pricing_model
    ) {
      removePricingId = true;
    }
    dupItems.map((planItem: { price: any[]; addon_item_id: any }) => {
      planItem.price.map((itemobj: any) => {
        delete itemobj.from;
        delete itemobj.fromErrors;
        delete itemobj.price_per_stepErrors;
        delete itemobj.to;
        if (removePricingId) {
          delete itemobj.item_pricing_id;
        }
        if (defaultFormValues.pricing_model === "PER_UNIT") {
          delete itemobj.price_per_step;
          delete itemobj.range_from;
          delete itemobj.fromErrors;
          delete itemobj.range_to;
          delete itemobj.price_per_stepErrors;
        } else if (defaultFormValues.pricing_model === "FLAT_FEE") {
          delete itemobj.price_per_step;
          delete itemobj.range_from;
          delete itemobj.fromErrors;
          delete itemobj.range_to;
          delete itemobj.price_per_stepErrors;
        }
        if (!itemobj.addon_item_id) {
          itemobj.addon_item_id = planItem.addon_item_id;
        }
        if (itemobj.range_from) {
          itemobj.range_from = parseFloat(itemobj.range_from);
        }
        if (
          defaultFormValues.pricing_model === "TIERED" ||
          defaultFormValues.pricing_model === "VOLUME" ||
          defaultFormValues.pricing_model === "STAIRSTEP"
        ) {
          if (itemobj.range_to) {
            itemobj.range_to = parseFloat(itemobj.range_to);
          } else {
            itemobj.range_to = -1;
          }
        }
        ItemObjArr.push(itemobj);
      });
    });

    defaultFormValues.items = ItemObjArr;
    setDefaultFormValues({ ...defaultFormValues });

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      ScrollToError(Object.keys(errors)[0]);
    } else {
      dispatch(setLoaderState(true));
      if (!props.priceId) {
        const responseAction = await dispatch(
          addonPricePointCreate({
            values: defaultFormValues,
            orgId: currentUserInfo.organization_id,
          })
        );
        if (responseAction.payload) {
          setTimeout(function () {
            dispatch(setLoaderState(false));
          }, 500);
          const response = responseAction.payload;

          if (Object.keys(response).length && !("error" in response)) {
            navigate(`/addons/detail/${addonId}`);
          } else if ("error" in response) {
          }
        }
      } else {
        const responseAction = await dispatch(
          addonPricePointEdit({
            values: defaultFormValues,
            orgId: currentUserInfo.organization_id,
            priceId: props.priceId,
          })
        );
        if (responseAction.payload) {
          setTimeout(function () {
            dispatch(setLoaderState(false));
          }, 500);
          const response = responseAction.payload;

          if (Object.keys(response).length && !("error" in response)) {
            navigate(`/addons/detail/${addonId}`);
          } else if ("error" in response) {
          }
        }
      }
    }
  };

  const changePlan = (value: any) => {
    setPlan(value);
    setSelectedOptionPricingModel(value);
    value = value.value;
    let totalAmount = 0;

    if (
      props.priceDetails &&
      props.priceDetails.items &&
      props.priceDetails.items.length
    ) {
      props.priceDetails.items.map(
        (item: {
          addon_item_id: any;
          steps: any[];
          price: any;
          free_quantity: any;
          item_pricing_id: any;
        }) => {
          const planData = planItemListData.find(
            (e) => item.addon_item_id === e.addon_item_id
          );
          if (planData) {
            if (value === "PER_UNIT" || value === "FLAT_FEE") {
              planData.price = [];
              planData.price.push({
                price: item.price ? item.price : planData.rate,
                free_quantity: item.free_quantity,
                item_pricing_id: item.item_pricing_id,
                range_from: 1,
                range_to: "",
                price_per_step: "",
              });
              totalAmount += item.price
                ? Number(item.price)
                : Number(planData.rate);
            } else {
              planData.addon_item_id = item.addon_item_id;
              if (item?.steps?.length) {
                planData.price = item.steps;
              } else {
                planData.price = [
                  {
                    range_from: 1,
                    from: "",
                    to: "",
                    price_per_step: "",
                    price: item.price ? item.price : planData.rate,
                  },
                ];
              }
            }

            setAmount(totalAmount);
            return planData;
          }
        }
      );
    }

    setDefaultFormValues({
      ...defaultFormValues,
      pricing_model: value,
    });
  };

  const handleBillingCycleChangeSelect = (value: any) => {
    setSelectedOptionBillingCycle(value);
    value = value.value;
    setDefaultFormValues({
      ...defaultFormValues,
      invoicing_cycle: value,
    });
  };

  return (
    <section className="form-top-section">
      <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325">
          <FormInputField
            type="text"
            name="internal_name"
            id="internal_name"
            className={
              formErrors.internal_name ? "form-control error" : "form-control"
            }
            label="Internal Name"
            placeholder="Enter profile name"
            maxLength={100}
            value={defaultFormValues.internal_name}
            onChange={handleChange}
            isRequired={true}
            error={formErrors.internal_name}
            tooltipMessage={message().addon.priceDailyAddonNameInt}
          />
        </div>
        <FormInputField
          type="text"
          name="external_name"
          id="external_name"
          className={
            formErrors.external_name ? "form-control error" : "form-control"
          }
          label="External Name"
          placeholder="Enter profile name"
          maxLength={100}
          value={defaultFormValues.external_name}
          onChange={handleChange}
          isRequired={false}
          error={formErrors.external_name}
          tooltipMessage={message().addon.priceAddonNameExt}
        />
      </div>
      <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325">
          <FormTextAreaField
            type=""
            label="Description"
            name="description"
            id="description"
            className="notes-textarea desc-area"
            placeholder="Add Description"
            value={defaultFormValues.description}
            onChange={handleChange}
          />
          <div className="show-desc-container">
            <span className="check-container show-desc-inv">
              <input
                name="show_description"
                checked={defaultFormValues.show_description}
                onChange={handleChange}
                type="checkbox"
                className="show-desc-inv-chk"
              />
              &nbsp; &nbsp; Show description in invoices
            </span>
          </div>
        </div>
        {/* <div className={`col-12 col-lg-4 ml-0 reusable-form-text-area-field`}>
          <label className={`fw-bold required`} htmlFor={""}>
            This addon has a trial period?
          </label>
          <div className="option-div radio-container">
            <div className="radio-left radio-div">
              <input
                type="radio"
                name="trial"
                className="trial-radio"
                required={true}
                onChange={handleChange}
                value={"trial_no"}
                checked={defaultFormValues.trial ? false : true}
              />{" "}
              &nbsp;&nbsp;No
            </div>
            <div className="radio-div">
              <input
                type="radio"
                name="trial"
                className="trial-radio"
                required={true}
                onChange={handleChange}
                value={"trial_yes"}
                checked={defaultFormValues.trial ? true : false}
              />{" "}
              &nbsp;&nbsp;Yes
            </div>
          </div>
          {formErrors.trial && (
            <span className="error">{formErrors.trial}</span>
          )}
          <div>
            {defaultFormValues.trial && (
              <div className="form-input-icon-wrapper trial_count_wrapper_plan_price">
                <div>
                  <input
                    type="text"
                    name="trial_count"
                    id="trial_count"
                    className="trial-period-count trial-period-count-radius"
                    placeholder=""
                    onChange={handleChange}
                    value={defaultFormValues.trial_count}
                    min={1}
                  />
                  {formErrors.trial_count && (
                    <span style={{ width: '80px' }} className="error">{formErrors.trial_count}</span>
                  )}
                </div>
                <Select
                  name="trial_frequency"
                  id="trial_frequency"
                  options={options}
                  className={`trial-period-count trial-period-type-radius state-select form-select custom-select trial-period-height`}
                  onChange={handleChangeSelect}
                  value={selectedOption}
                />
              </div>
            )}
          </div>
        </div> */}
      </div>
      <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325 pricint-model-wrap">
          <FormSelectField
            name="pricing_model"
            id="pricing_model"
            label="Pricing model"
            options={pricingModelOptions}
            wrapperClass="mw-325"
            className="state-select form-select custom-select form-control pricing-model-dp"
            value={selectedOptionPricingModel}
            onChange={changePlan}
            styles={customSelectStyle}
            placeholder={"Flat Fee"}
            isSearchable={true}
            isDisabled={props.priceDetails.pricing_exists_subscription && true || false}
            error={formErrors.pricing_model}
            isRequired={false}
            isOnlyInRow={false}
            tooltipMessage={message().plan.pricingModel}
          />
        </div>
        {/* <FormSelectField
          name="invoicing_cycle"Invoicing cycle
          id="billing_cycle"
          label="Invoicing cycle"
          options={billingCycleOptions}
          wrapperClass="mw-325"
          className="state-select form-select custom-select"
          value={selectedOptionBillingCycle}
          onChange={handleBillingCycleChangeSelect}
          styles={customSelectStyle}
          placeholder={"Forever"}
          isSearchable={true}
          isDisabled={false}
          error={formErrors.invoicing_cycle}
          isRequired={false}
          isOnlyInRow={false}
        />
        {defaultFormValues.invoicing_cycle === "FIXED" && (
          <input
            type="number"
            name="invoicing_cycle_fixed_count"
            id="billing_cycle_fixed_count"
            className="trial-period-count billing_cycle_fixed_count_class form-control"
            placeholder=""
            onChange={handleChange}
            value={defaultFormValues.invoicing_cycle_fixed_count}
          />
        )}
        {formErrors.invoicing_cycle_fixed_count && (
          <span style={{ paddingLeft: "744px", marginTop: "-15px" }} className="error">
            {formErrors.invoicing_cycle_fixed_count}
          </span>
        )} */}
      </div>
      <div className="row custom-row">
        <div className="item-container item-cont-width">
          {planItemListData.map((item, key) => (
            <Item
              sl={key}
              planItemId={item.addon_item_id}
              rate={item.rate}
              itemName={item.item_name}
              currencyCode={item.currency_code}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              defaultFormValues={defaultFormValues}
              setDefaultFormValues={setDefaultFormValues}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handleChangePrice={handleChangePrice}
              handleFreeQuatity={handleFreeQuatity}
              handleChangeFrom={handleChangeFrom}
              handleChangeTo={handleChangeTo}
              handleChangePricePerStep={handleChangePricePerStep}
              item={item}
              plan={plan}
              handleAddNewField={handleAddNewField}
              removeRowHandle={removeRowHandle}
            />
          ))}
          {defaultFormValues.pricing_model === "PER_UNIT" ||
            defaultFormValues.pricing_model === "FLAT_FEE" ? (
            <div className="total-container">{
              `Total price: ${currentUserInfo.currency_code || ''} ${NumberFormat(Number(amount),
                currentUserInfo.currency_code,
                orgCurrencyList
              )}`}
            </div>
          ) : null}
        </div>
      </div>
      <div className="row mb-0">
        <div className="col">
          <div
            className="form-button-wrapper w-100 docked-button-container"
            id="form-btn"
          >
            <button
              type="button"
              onClick={(event) => {
                handleSubmit(event);
              }}
              className="save-button"
            >
              Save
            </button>
            <button
              type="button"
              className="text-decoration-none cancel-button"
              onClick={() =>
                navigate(`/addons/detail/${props.addonId}`, {
                  replace: true,
                })
              }
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(forwardRef(DefaultDetails));
