import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useFormik } from "formik";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../../../../helpers/toastHelper";
import { SaveButton, SecondaryButton } from "../../../../../common/components";
import { FormInputField } from "../../../../../common/components/FormInputField";
import { FormTextAreaField } from "../../../../../common/components/formTextAreaField/FormTextAreaField";
import { RadioButtonGroup } from "../../../../../common/components/radioButtonGroup/radioButtonGroup";
import { colors } from "../../../../../constants/colors";
import useCommonData from "../../../../../hooks/useCommon";
import { createTaxExemption, updateTaxExemption } from "../../../gstSlice";
import { TaxExemptionFormValidation } from "./TaxExemptionFormValidation";
import { useEffect, useState } from "react";
import { TaxExemptionFormValue } from "../../../../../../types";
import { setLoaderState } from "../../../../../common/commonSlice";

type Props = {
  handleClose: () => void;
  isEdit: boolean;
  editId?: number;
  exemptionDetails?: TaxExemptionFormValue;
  isAssociated?: boolean;
  refreshList: () => void;
};
export const TaxExemptionForm = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const [initialFormValues, setInitialFormValues] =
    useState<TaxExemptionFormValue>({
      exemption_reason: "",
      exemption_type: "Customer",
      description: "",
    });
  useEffect(() => {
    if (props.editId && props.exemptionDetails) {
      setInitialFormValues({ ...props.exemptionDetails });
    }
  }, [props.editId]);
  const formik = useFormik({
    initialValues: {
      ...initialFormValues,
    },
    enableReinitialize: true,
    validationSchema: TaxExemptionFormValidation,
    onSubmit: async (values) => {
      dispatch(setLoaderState(true));
      const responseAction = props.isEdit
        ? await dispatch(
            updateTaxExemption({
              id: props.editId || 0,
              organization_id: currentUserInfo.organization_id,
              exemption_type: values.exemption_type as "Customer" | "Item",
              exemption_reason: values.exemption_reason?.trim(),
              description: values.description?.trim() || "",
            })
          )
        : await dispatch(
            createTaxExemption({
              organization_id: currentUserInfo.organization_id,
              exemption_type: values.exemption_type as "Customer" | "Item",
              exemption_reason: values.exemption_reason.trim(),
              description: values.description?.trim() || "",
            })
          );
      const response = responseAction.payload;
      if (!("error" in response)) {
        SuccessToaster(
          props.isEdit
            ? "Tax exemption updated successfully"
            : "Tax exemption created successfully",
          props.isEdit
            ? "tax-exemption-updation-success"
            : "tax-exemption-creation-success"
        );
        dispatch(setLoaderState(false));
      } else {
        dispatch(setLoaderState(false));
      }
      props.refreshList();
      props.handleClose();
    },
  });
  return (
    <form className="tax-exemption-form-wrapper" onSubmit={formik.handleSubmit}>
      <FormInputField
        name="exemption_reason"
        type="text"
        label="Exemption Reason"
        id="exemption_reason"
        className={`form-control ${
          formik.touched.exemption_reason && formik.errors.exemption_reason
            ? "error"
            : ""
        }`}
        wrapperClass=""
        value={formik.values.exemption_reason}
        onChange={formik.handleChange}
        placeholder="Enter exemption reason"
        isRequired={true}
        maxLength={300}
        disabled={false}
        error={
          formik.touched.exemption_reason && formik.errors.exemption_reason
            ? formik.errors.exemption_reason
            : ""
        }
      />
      <FormTextAreaField
        name="description"
        type="text"
        label="Description"
        id="description"
        wrapperClass="mw-325"
        className="form-control"
        value={formik.values.description}
        onChange={formik.handleChange}
        placeholder="Enter description"
        isRequired={true}
        disabled={false}
        error={
          formik.touched.description && formik.errors.description
            ? formik.errors.description
            : ""
        }
      />
      <div className="col-12 col-lg-4 mw-325 radio-button-wrap mt-3">
        <div className="link-label-grouped">
          <label className="fw-bold required mb-0 ">Type</label>
        </div>
        <RadioButtonGroup
          name="exemption_type"
          id="exemption_type"
          isDisabled={props.isAssociated === true ? true : false}
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={
            <RadioButtonCheckedIcon sx={{ color: colors.ceruleanBlue }} />
          }
          value={formik.values.exemption_type}
          valuesArray={[
            { value: "Customer", label: "Customer" },
            { value: "Item", label: "Item" },
          ]}
          formik={formik}
          disableClickRenderFunction={(value) => {
            formik.values.exemption_type !== value &&
              ErrorToaster(
                `The association type cannot be changed to ${value}, since this exemption is already associated with one or more transactions, customers or items!`
              );
          }}
        />
      </div>

      <div>
        <SaveButton
          buttonContent={props.editId ? "Update" : "Save"}
          onClick={(e) => {
            e.preventDefault();
            formik.submitForm();
          }}
          type="submit"
          isDisabled={false}
        />
        <SecondaryButton
          buttonContent="Cancel"
          onClick={props.handleClose}
          type="button"
          isDisabled={false}
        />
      </div>
    </form>
  );
};
