import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../app/hooks";
import {
  CustomerItemValues,
  InventoryItemValues,
  ItemDetailsProps,
  ItemRef,
  TCSTotalValues,
  TDSTotalValues,
  TdsPreferenceValues,
  TransactionItemValues,
  ObjectType,
  ItemGSTTax,
} from "../../../../types";
import TotalSection from "../TotalSection/TotalSection";
import ExchangeRate from "../ExchangeRate";
import "./ItemDetails.css";
import {
  latestCurrencyRateSelector,
  orgCurrencyListSelector,
} from "../../commonSlice";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../helpers/numberFormatHelper";
import AddLineItem from "../AddLineItem";
import LineItem from "../lineItems/LineItem";
import {
  initialItemValues,
  initialTCSTotalValues,
  initialTDSTotalValues,
  initialTaxValues,
} from "../../../home/invoice/components/invoiceForm/StateInitialization";
import useCommonData from "../../../hooks/useCommon";
import { tdsTaxPreference } from "../../../home/tds/tdsSlice";
import { initialPreferenceValues } from "../../../home/tds/components/tdsPreference/StateInitialization";
import { splitNumberByDecimalPoint } from "../../../../helpers/helper";
import { getRoundOffAmount } from "../../../../helpers/roundOffHelper";
import { decimalPlaceOfCurrency } from "../../../../helpers/decimalPlaceHelper";
import useGst from "../../../hooks/useGst";
import {
  defaultTaxPreference,
  gstTaxListSelector,
  defaultPreferenceSelector,
  clearGstTaxList,
  gstStateTypeSelector,
  getGstTaxList,
} from "../../../home/gst/gstSlice";
import { ErrorToaster } from "../../../../helpers/toastHelper";
import { gstRegistrationTypes, TAX_SYSTEM } from "../../../constants/constants";
import { taxExemptionList } from "../../../home/gst/gstAPI";
import {
  getUserEditedItemIndices,
  getUserEditedItemsList,
  itemDropdownRawListSelector,
} from "../../../home/items/itemSlice";
import { useGstSelect } from "../transactions/gstSelect/GstSelectProvider";
import {
  CUSTOMER_NON_TAXABLE,
  EXEMPTION_TYPES,
} from "../transactions/gstSelect/constants";

const ItemDetails: ForwardRefRenderFunction<ItemRef, ItemDetailsProps> = (
  props,
  ref
) => {
  const { editId } = useParams();
  const { currentUserInfo, dispatch } = useCommonData();
  const userEnteredItemsList = useAppSelector(getUserEditedItemsList);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const [itemFormValues, setItemFormValues] = useState<TransactionItemValues[]>(
    [initialItemValues]
  );
  const [itemFormValuesForTax, setItemFormValuesForTax] = useState<
    TransactionItemValues[]
  >([initialItemValues]);
  const [taxFormValues, setTaxFormValues] = useState<TransactionItemValues[]>([
    initialItemValues,
  ]);
  const [deleteTaxItems, setDeleteTaxItems] = useState<
    { itemId: number; taxItemId: number }[]
  >([]);
  const [deleteGstItems, setDeleteGstItems] = useState<
    { itemId: number; id: number }[]
  >([]);
  const [deleteItems, setDeleteItems] = useState<{ itemId: number }[]>([]);
  const [currencyCode, setCurrencyCode] = useState<string | undefined>("");
  const [totalTaxableAmount, setTotalTaxableAmount] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [totalTaxArray, setTotalTaxArray] = useState([]);
  const [total, setTotal] = useState(0);
  const [tdsAmount, setTdsAmount] = useState(0);
  const [tcsAmount, setTcsAmount] = useState(0);
  const [gstTotalObject, setGstTotalObject] = useState<ObjectType>({});
  const [tableMinWidth, setMinWidth] = useState(0);
  const [windowWidth, setWindowWidth] = useState(1024);
  const [taxValues, setTaxValues] = useState(true);
  const [tdsPreferenceValues, setTdsPreferenceValues] =
    useState<TdsPreferenceValues>(initialPreferenceValues);
  const [totalTdsValues, setTotalTdsValues] = useState<TDSTotalValues>(
    initialTDSTotalValues
  );
  const [totalTcsValues, setTotalTcsValues] = useState<TCSTotalValues>(
    initialTCSTotalValues
  );
  const [tableScrolLeft, setTableScrollLeft] = useState(0);
  const itemFormValuesRef = useRef<TransactionItemValues[]>([]);
  const taxFormValuesRef = useRef<TransactionItemValues[]>([]);
  const { isGstOrg } = useGst();
  const gstTaxList = useAppSelector(gstTaxListSelector);
  const [roundOffValue, setRoundOffValue] = useState(null);
  const [manuallyEditedTotal, setManuallyEditedTotal] = useState<any>(null);
  const [isItemEdited, setIsItemEdited] = useState(false);
  const stateType = useAppSelector(gstStateTypeSelector) as string;
  let taxList = useAppSelector(gstTaxListSelector) as ObjectType[];
  const gstTaxOfItemsRef = useRef<ItemGSTTax[]>([]);
  const { setGstStateType, exemptionDropdownList } = useGstSelect();

  const organization_tax_system = currentUserInfo.organization_tax_system;

  const defaultPreference = useAppSelector(
    defaultPreferenceSelector
  ) as ObjectType;

  useImperativeHandle(
    ref,
    () => ({
      itemValues: itemFormValues,
      taxValues: taxFormValues,
      totalTdsValues: totalTdsValues,
      totalTcsValues: totalTcsValues,
      deleteTaxItems,
      deleteItems,
      deleteGstItems,
    }),
    [
      itemFormValues,
      taxFormValues,
      deleteTaxItems,
      deleteItems,
      totalTdsValues,
      totalTcsValues,
    ]
  );
  useEffect(() => {
    setTotalTdsValues(
      props.totalTdsValues ? props.totalTdsValues : initialTDSTotalValues
    );
  }, [props.totalTdsValues]);

  useEffect(() => {
    setTotalTcsValues(
      props.totalTcsValues ? props.totalTcsValues : initialTCSTotalValues
    );
  }, [props.totalTcsValues]);

  useEffect(() => {
    if (
      props.itemFormValues?.length === 1 &&
      props.itemFormValues[0]?.item_name === "" &&
      props.itemFormValues[0]?.inventory_item_id === null
    ) {
    } else {
      setItemFormValues([...props.itemFormValues]);
      setTaxFormValues([...props.itemFormValues]);
    }
  }, [props.itemFormValues]);

  useEffect(() => {
    if (props.totalFormValues.transaction_tds_amount)
      setTdsAmount(props.totalFormValues.transaction_tds_amount);
  }, [props.totalFormValues]);

  useEffect(() => {
    setManuallyEditedTotal(props?.editTotal);
  }, [props.editTotal]);

  useEffect(() => {
    if (currencyCode) {
      let items = [];
      const updatedDecimals = decimalPlaceOfCurrency(
        currencyCode,
        orgCurrencyList
      );
      for (let item of itemFormValues) {
        items.push({
          ...item,
          discount: getRoundOffAmount(item.discount, updatedDecimals),
        });
      }
      setItemFormValues(items);
    }
  }, [currencyCode]);

  useEffect(() => {
    if (props.consumerId && props.isCustomerVendorChanged) {
      let items = [...itemFormValues];

      let consumerTradeDefault = props.consumerTradeDefault;
      if (consumerTradeDefault) {
        items.map((item, index) => {
          item.discount_type = consumerTradeDefault.discount_type
            ? consumerTradeDefault.discount_type
            : "Absolute";
          item.discount = consumerTradeDefault.discount;
          item.discount_account_id = consumerTradeDefault.discount_account_id;
          item.discount_account_name =
            consumerTradeDefault.discount_account_name;
          item.account_id =
            props.componentType === "invoice" ||
            props.componentType === "creditNote"
              ? consumerTradeDefault.sales_account_id
              : consumerTradeDefault.purchase_account_id;
          item.account_name =
            props.componentType === "invoice" ||
            props.componentType === "creditNote"
              ? consumerTradeDefault.sales_account_name
              : consumerTradeDefault.purchase_account_name;
        });
      } else {
        items.map((item, index) => {
          item.discount_type = "Absolute";
          item.discount = 0.0;
          item.discount_account_id = "";
          item.discount_account_name = "";
          item.account_id = "";
          item.account_name = "";
        });
      }
      setItemFormValues([...items]);
    }
  }, [props.consumerId, props.consumerTradeDefault]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    let tempManuallyEditedTotal = manuallyEditedTotal;
    if (isItemEdited) {
      tempManuallyEditedTotal = null;
      setManuallyEditedTotal(null);
    }
    calculateAmountAndTax(signal, tempManuallyEditedTotal);
    return () => {
      controller.abort();
    };
  }, [
    itemFormValues,
    currencyCode,
    totalTdsValues,
    totalTcsValues,
    props.isRoundOffEnabled,
    props.isTotalEditable,
    props.rcmEnabled,
  ]);

  // useEffect(() => {
  //   if (isItemEdited) {
  //     setManuallyEditedTotal(null);
  //   }
  // }, [JSON.stringify(itemFormValues), JSON.stringify(totalTcsValues)]);

  useEffect(() => {
    if (manuallyEditedTotal !== null) {
      const controller = new AbortController();
      const signal = controller.signal;
      calculateAmountAndTax(signal, manuallyEditedTotal);
      return () => {
        controller.abort();
      };
    }
  }, [manuallyEditedTotal]);

  useEffect(() => {
    if (
      taxValues &&
      itemFormValues[0]?.item_name !== "" &&
      itemFormValues[0]?.rate !== 0
    ) {
      setItemFormValuesForTax(itemFormValues);
      setTaxValues(false);
    }
    itemFormValuesRef.current = itemFormValues;
    taxFormValuesRef.current = taxFormValues;
  }, [itemFormValues]);

  useEffect(() => {
    if (props.currencyId) {
      let currencyValues = orgCurrencyList
        .filter((currency) => currency.id === props.currencyId)
        .map((currency) => currency);
      if (currencyValues.length) {
        setCurrencyCode(currencyValues[0].code);
      }
    }
  }, [props.currencyId]);

  useEffect(() => {
    return () => {
      dispatch(clearGstTaxList());
    };
  }, []);

  /**
   * Edit : Set the currency value based on the chosen currency of the customer.
   */
  useEffect(() => {
    if (editId && props.currencyCode !== "" && props.currencyCode !== null) {
      setCurrencyCode(props.currencyCode);
    }
  }, [props.currencyCode]);

  useEffect(() => {
    getTdsTaxPreferenceDetails();
    if (isGstOrg) {
      dispatch(
        defaultTaxPreference({ orgId: currentUserInfo.organization_id })
      );
    }
  }, [currentUserInfo.organization_id]);

  // useEffect(() => {
  //   setItemFormValues((prevItemFormValues: any) => {
  //     const modifiedItemFormValues = prevItemFormValues.map((item: any) => ({
  //       ...item,
  //       gst_tax: null,
  //     }));
  //     return modifiedItemFormValues;
  //   });
  //   setTaxFormValues((prevTaxItems) => {
  //     const modifiedTaxFormValues = prevTaxItems.map((item: any) => ({
  //       ...item,
  //       gst_tax: null,
  //     }));
  //     return modifiedTaxFormValues;
  //   });
  // }, [props.stateType]);

  /**
   * Function to pass line item details for getting totals
   */
  const calculateAmountAndTax = async (
    signal: AbortSignal,
    editedTotal: number
  ) => {
    let formItems = JSON.parse(JSON.stringify(itemFormValues));
    let filteredFormItems = formItems;
    filteredFormItems.map(async (items: any, index: any) => {
      delete items["hsn_or_sac_code"];
      delete items["tds_account_id"];
      delete items["tds_id"];
      if (!isGstOrg) {
        delete items["gst_tax"];
      }
      let itemInputs = items;

      // Modifing iteminput for gst tax calculation
      let gst_tax = itemInputs.gst_tax;
      let gst_details = null;
      if (gst_tax) {
        let selectedTaxArr = gstTaxList.filter(
          (tax: ObjectType) =>
            tax.id === gst_tax.tax_id && tax.tax_group === gst_tax.is_group
        );
        if (selectedTaxArr.length) {
          let selectedTax = selectedTaxArr[0] as ObjectType;

          let sgst_percentage = 0;
          let cgst_percentage = 0;
          let igst_percentage = 0;
          let cess_percentage = 0;
          let utgst_percentage = 0;

          if (!selectedTax.tax_group) {
            switch (selectedTax.tax_type) {
              case "SGST":
                sgst_percentage = selectedTax.rate;
                break;
              case "CGST":
                cgst_percentage = selectedTax.rate;
                break;
              case "IGST":
                igst_percentage = selectedTax.rate;
                break;
              case "CESS":
                cess_percentage = selectedTax.rate;
                break;
              case "UTGST":
                utgst_percentage = selectedTax.rate;
                break;
              default:
                break;
            }
          } else {
            let gst_tax_item = selectedTax.taxes;

            for (let i = 0; i < gst_tax_item.length; i++) {
              const single_gst_tax_item = gst_tax_item[i];
              switch (single_gst_tax_item.tax_type) {
                case "SGST":
                  sgst_percentage = single_gst_tax_item.rate;
                  break;
                case "CGST":
                  cgst_percentage = single_gst_tax_item.rate;
                  break;
                case "IGST":
                  igst_percentage = single_gst_tax_item.rate;
                  break;
                case "CESS":
                  cess_percentage = single_gst_tax_item.rate;
                  break;
                case "UTGST":
                  utgst_percentage = single_gst_tax_item.rate;
                  break;
                default:
                  break;
              }
            }
          }
          gst_details = {
            gst_id: gst_tax.tax_id,
            is_group: gst_tax.is_group,
            sgst_percentage: sgst_percentage,
            cgst_percentage: cgst_percentage,
            igst_percentage: igst_percentage,
            cess_percentage: cess_percentage,
            utgst_percentage: utgst_percentage,
          };
        }
      }

      itemInputs.gst_details = gst_details;

      let itemKey: keyof typeof itemInputs;
      for (itemKey in itemInputs) {
        if (
          (itemKey === "quantity" || itemKey === "rate") &&
          (itemInputs[itemKey] === "" || itemInputs[itemKey] === null)
        ) {
          itemInputs[itemKey] = 0;
        }
        if (itemInputs[itemKey] === "") {
          itemInputs[itemKey] = null;
        }
        if (itemKey === "discount") {
          if (itemInputs[itemKey]?.discount_value == 0) {
            itemInputs[itemKey] = itemInputs[itemKey]?.discount_value;
          } else if (itemInputs[itemKey]?.discount_percentage == 0) {
            itemInputs[itemKey] = itemInputs[itemKey]?.discount_percentage;
          } else {
            itemInputs[itemKey] = Number(itemInputs[itemKey]);
          }
        }
        if (itemKey === "tds_percentage" && itemInputs[itemKey] === null) {
          itemInputs[itemKey] = 0;
        }
      }
      let itemTax = itemInputs.taxes;
      let taxInputs: any = [];
      itemTax.map((item: any) => {
        if (item.tax_percentage === "") {
          item.tax_percentage = 0;
        }
        if (
          !(item.tax_percentage === "" || item.tax_percentage === 0) &&
          !(item.tax_id === "")
        ) {
          taxInputs.push(item);
        } else if (item.tax_id !== "") {
          taxInputs.push(item);
        }
      });
      itemInputs.taxes = taxInputs;
      //Handling decimal point starting amount in tds
      itemInputs.tds_percentage = Number(itemInputs.tds_percentage);
      itemInputs.tds_amount =
        itemInputs.tds_amount === "." ? 0 : parseFloat(itemInputs.tds_amount);
      itemInputs.currency_id = props.currencyId ? props.currencyId : null;
      const taxResponseAction = await props.taxableAmount({
        values: itemInputs,
        orgId: currentUserInfo.organization_id,
        signal: signal,
      });
      if (taxResponseAction.payload) {
        const taxResponse = taxResponseAction.payload;
        if (Object.keys(taxResponse).length && !("error" in taxResponse)) {
          let taxes = taxResponse.item_details.taxes;
          let taxIndex = 0;
          Object.keys(taxes).map((key) => {
            let taxValue = taxes[key as keyof typeof taxes];
            if (taxValue || key) {
              $("#tax-amount-label_" + index + "_" + taxIndex).text(
                key +
                  ": " +
                  currencyCode +
                  " " +
                  NumberFormat(
                    Number(taxValue),
                    currencyCode ? currencyCode : "",
                    orgCurrencyList
                  )
              );
            } else {
              $("#tax-amount-label_" + index + "_" + taxIndex).text("");
            }
            if (
              props.itemFormErrors &&
              props.itemFormErrors[index]?.taxes &&
              props.itemFormErrors[index]?.taxes[taxIndex]?.tax_id
            ) {
              $("#tax-amount-label_" + index + "_" + taxIndex).text("");
            }
            ++taxIndex;
          });
          $("#taxable_amount_span_" + index).text(
            taxResponse.item_details.taxable_amount
              ? currencyCode +
                  " " +
                  NumberFormat(
                    Number(taxResponse.item_details.taxable_amount),
                    currencyCode ? currencyCode : "",
                    orgCurrencyList
                  )
              : currencyCode +
                  " " +
                  zeroDisplayFormat(
                    decimalPlaceOfCurrency(
                      currencyCode
                        ? currencyCode
                        : currentUserInfo.currency_code,
                      orgCurrencyList
                    )
                  )
          );
          if (taxResponse.item_details.tds_total) {
            $("#tds-amount-label" + index).text(
              "Tds: " +
                currencyCode +
                " " +
                NumberFormat(
                  taxResponse.item_details.tds_total,
                  currencyCode ? currencyCode : "",
                  orgCurrencyList
                )
            );
          } else {
            $("#tds-amount-label" + index).text("");
          }
        }
      }
    });
    const updatedFormItems = {
      item_list: filteredFormItems,
      tds_percentage: Number(totalTdsValues.tds_percentage),
      tds_amount: Number(totalTdsValues.tds_amount),
      tcs_percentage: Number(totalTcsValues.tcs_percentage),
      tcs_amount: Number(totalTcsValues.tcs_amount),
      currency_id: props.currencyId,
      roundoff_enabled: props.isRoundOffEnabled,
      is_total_editable: props.isTotalEditable,
      total: editedTotal,
      rcm_enabled: props.rcmEnabled ? props.rcmEnabled : false,
    };
    if (filteredFormItems.length) {
      const responseAction = await props.amountCalculation({
        values: updatedFormItems,
        orgId: currentUserInfo.organization_id,
        signal: signal,
      });
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          setTotalTaxableAmount(response.total_details.total_taxable_amount);
          response.total_details.gst_amounts &&
            setGstTotalObject({ ...response.total_details.gst_amounts });
          setTotalTaxArray(response.total_details.taxes);
          setTotal(response.total_details.total);
          props.getTotalValue &&
            props.getTotalValue(response.total_details.total);
          setTdsAmount(response.total_details.tds_total);
          setTcsAmount(response.total_details.tcs_total);

          response.total_details.gst_amounts?.gst_total !== 0
            ? setTotalTax(response.total_details.gst_amounts?.gst_total)
            : setTotalTax(response.total_details.tax_amount);

          setRoundOffValue((prevValue) => {
            return response.total_details.hasOwnProperty("roundoff_amount")
              ? response.total_details?.roundoff_amount
              : null;
          });
          if (props.updateCalculatedTotal) {
            props.updateCalculatedTotal(response.total_details.total);
          }
          // setManuallyEditedTotal(null);
        }
      }
    } else {
      setTotalTaxableAmount(0.0);
      setTotalTax(0.0);
      setTotal(0.0);
      props.getTotalValue && props.getTotalValue(0);
      setTotalTax(0);
      setTotalTaxArray([]);
      setTdsAmount(0);
      setTcsAmount(0);
      setRoundOffValue(null);
    }
  };

  const setAccountDropdown = () => {
    var leftScroll = 0;
    var scrollItem: any = document.querySelector(".item-det-container");
    let resizeFlag: boolean = false;
    scrollItem &&
      scrollItem.addEventListener("scroll", function (e: any) {
        setTableScrollLeft(scrollItem.scrollLeft);
      });
    $(".dropdown-toggle").on("show.bs.dropdown", function () {
      // setTimeout(() => {
      $(".items-entry-wrapper").addClass("overflow-initial");
      $(".item-det-container").removeClass("overflow-coloumn");
      $(".item-det-container .item-grid-account").css(
        "margin-left",
        "-" + tableScrolLeft + "px"
      );
      // }, 5);
    });
    $(".dropdown-toggle").on("hide.bs.dropdown", function () {
      // setTimeout(() => {
      $(".items-entry-wrapper").removeClass("overflow-initial");
      $(".item-det-container").addClass("overflow-coloumn");
      $(".item-det-container .item-grid-account").css("margin-left", 0);
      // }, 0);
    });

    window.addEventListener("resize", () => {
      if (!resizeFlag) {
        resizeFlag = true;
        ($(".dropdown-toggle") as any).dropdown("hide");
        setTimeout(() => {
          resizeFlag = false;
        }, 20);
      }
    });
  };
  $(() => {
    setAccountDropdown();
  });
  /**
   * calculate the min-width of the item detail grid required
   */
  const setTableMinWidth: any = () => {
    let gridMinWidth: number = 0;
    const tableCols: any = document.querySelectorAll(
      ".item-grid-account .item-row .item-col"
    );
    let tableWidth: number = 0;
    tableCols.forEach((item: any) => {
      tableWidth += item.offsetWidth;
    });
    gridMinWidth = tableWidth + 21;
    setMinWidth(gridMinWidth);
  };

  useEffect(() => {
    setTableMinWidth();
    let w = window.innerWidth;
    setWindowWidth(w);
  }, []);

  window.addEventListener(
    "resize",
    function (event) {
      let temp = windowWidth;
      let w = window.innerWidth;
      if (temp !== w) {
        setWindowWidth(w);
        setTableMinWidth();
      }
    },
    true
  );

  const handleItemChange = (e: any, index: number) => {
    let { name, value } = e.target;
    const items = [...itemFormValues];
    const numberRegex = /^([0-9,]{1,})?(\.)?([0-9]{1,})?$/;
    if (name === "quantity" || name === "rate" || name === "discount") {
      let decimalPointSplitArray: string[] = [];
      if (value.toString().includes(",")) {
        value = value.toString().split(",").join("");
      }
      decimalPointSplitArray.push(value);
      if (value.includes(".")) {
        decimalPointSplitArray = value.toString().split(".");
      }
      if (decimalPointSplitArray[0].length < 15) {
        if (value === "" || numberRegex.test(value)) {
          items[index] = { ...items[index], [name]: value };
          setItemFormValues(items);
        }
      }
      if (name === "rate") {
        items[index] = { ...items[index], ["is_user_edited_rate"]: true };
      }
    } else {
      items[index] = { ...items[index], [name]: value };
      setItemFormValues(items);
    }
    setIsItemEdited(true);
  };

  /**
   * Function to round off default input elements after out of focus.
   */
  const handleItemOnBlur = (name: string, amount: number, index: number) => {
    const items = [...itemFormValues];
    if (amount.toString().includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(amount);
      let decimals: number;
      switch (name) {
        case "rate":
        case "quantity":
          decimals = 6;
          break;
        case "discount":
        case "tds_amount":
          decimals = decimalPlaceOfCurrency(currencyCode, orgCurrencyList);
          break;
        default:
          decimals = decimalPlaceOfCurrency(currencyCode, orgCurrencyList);
      }

      if (fractionalPart.length > decimals) {
        const roundedAmount = getRoundOffAmount(amount, decimals);
        if ((name === "tds_amount" || name === "tds_percentage") && index < 0) {
          setTotalTdsValues((values) => ({
            ...values,
            tds_amount: roundedAmount,
          }));
        } else {
          items[index] = { ...items[index], [name]: roundedAmount };
          setItemFormValues(items);
        }
      }
    }
  };

  const handleItemAccountChange = (accountId: number, index: number) => {
    const items = [...itemFormValues];
    items[index] = { ...items[index], account_id: accountId };
    setItemFormValues(items);
  };
  useEffect(() => {
    let items = [...itemFormValues];
    items.map((item, index) => {
      if (item.is_user_edited_rate) {
        items[index] = {
          ...item,
          rate: userEnteredItemsList[`${index}`].value,
        };
      }
    });
    setItemFormValues([...items]);
  }, [JSON.stringify(itemFormValues)]);
  const handleItemDropdownChange = async (
    item: InventoryItemValues,
    index: number
  ) => {
    let gstTaxListArray: any = {};
    if (gstTaxList.length > 0) {
      gstTaxListArray = {
        payload: [...gstTaxList],
      };
    } else {
      dispatch(clearGstTaxList());
      gstTaxListArray = await dispatch(
        getGstTaxList({
          stateType: stateType ? stateType : "",
          orgId: currentUserInfo.organization_id,
          is_sez: false,
          transactionSource: "Invoices",
        })
      );
    }
    let taxObj = null;
    if (
      item.tax_preference === "NON_TAXABLE" ||
      item.tax_preference === "OUT_OF_SCOPE" ||
      item.tax_preference === "NON_GST_SUPPLY"
    ) {
      const taxPreference = EXEMPTION_TYPES.find(
        (type) => type.key === item.tax_preference
      )?.value;
      const gst_tax = gstTaxListArray.payload.filter((gstTax: any) => {
        return (
          gstTax.name === taxPreference &&
          gstTax.tax_type === "NONE" &&
          gstTax.rate === 0
        );
      });
      if (
        props.customerDetails?.customer_exemption_type !== "NON_TAXABLE" &&
        props.gstRegistrationType !== gstRegistrationTypes.sez &&
        props.gstRegistrationType !== gstRegistrationTypes.sezDeveloper &&
        props.gstRegistrationType !== gstRegistrationTypes.overseas
      ) {
        taxObj = await getGstTaxItem(gst_tax[0]);
      }
    }
    const shouldUpdate =
      props.customerDetails?.customer_exemption_type !== "NON_TAXABLE" &&
      props.gstRegistrationType !== gstRegistrationTypes.sez &&
      props.gstRegistrationType !== gstRegistrationTypes.sezDeveloper &&
      props.gstRegistrationType !== gstRegistrationTypes.overseas;
    const items = [...itemFormValuesRef.current];
    let taxArray: any[] = [];
    if (item.taxes) {
      item.taxes.map((tax) => {
        taxArray.push({
          tax_id: tax.tax_id,
          tax_percentage: tax.tax_percentage,
        });
      });
    }
    gstTaxOfItemsRef.current[index] = {
      intra_state_id: item?.intra_state_id ? item.intra_state_id : null,
      inter_state_id: item?.inter_state_id ? item.inter_state_id : null,
      is_intra_state_group: item?.is_intra_state_group
        ? item.is_intra_state_group
        : false,
      is_inter_state_group: item?.is_inter_state_group
        ? item.is_inter_state_group
        : false,
    };
    const defaultExemptionReasonId = exemptionDropdownList.find(
      (reason) => reason.exemption_reason === CUSTOMER_NON_TAXABLE
    )?.id;
    const itemExemptionType =
      props.customerDetails?.customer_exemption_type === "NON_TAXABLE"
        ? "NON_TAXABLE"
        : item.tax_preference;
    const itemExemptionReasonId =
      props.customerDetails?.customer_exemption_type === "NON_TAXABLE"
        ? defaultExemptionReasonId
        : item.exemption_reason_id;
    items[index] = {
      ...items[index],
      item_name: item.name,
      hsn_or_sac_code: item.hsn_or_sac_code,
      account_id: item.account_id,
      account_name: item.account_name ? item.account_name : "",
      rate: Number(item.rate),
      taxes: taxArray,
      inventory_item_id: item.id,
      gst_tax: taxObj ? { ...taxObj } : items[index].gst_tax,
      item_exemption_type: itemExemptionType,
      item_exemption_id: itemExemptionReasonId,
      type: item.type,
    };
    const taxItems = JSON.parse(JSON.stringify(taxFormValuesRef.current));
    taxItems[index] = {
      ...taxItems[index],
      item_name: item.name,
      hsn_or_sac_code: item.hsn_or_sac_code,
      account_id: item.account_id,
      rate: Number(item.rate),
      taxes: taxArray,
      gst_tax: taxObj ? { ...taxObj } : taxItems[index].gst_tax,
      item_exemption_type: itemExemptionType,
    };
    itemFormValuesRef.current = [...items];
    taxFormValuesRef.current = [...taxItems];
    setItemFormValues([...itemFormValuesRef.current]);
    setTaxFormValues([...taxFormValuesRef.current]);
    setIsItemEdited(true);
    populateDefaultGST();
    if (item.tax_preference === "TAXABLE") {
    }
  };

  const handleTdsLabelChange = (
    id: number,
    rate: number,
    name: string,
    index: number
  ) => {
    const items = [...itemFormValues];
    items[index] = {
      ...items[index],
      tds_id: Number(id) ? Number(id) : null,
      tds_account_id: Number(id),
      tds_amount: 0,
      tds_percentage: Number(rate),
      tds_name: name,
    };
    setItemFormValues([...items]);
    let itemFormErrors: any = props.itemFormErrors ? props.itemFormErrors : [];
    itemFormErrors[index] = {
      ...itemFormErrors[index],
      tds_name: "",
    };
    setIsItemEdited(true);
  };

  const clearTaxSelection = (index: number) => {
    const items = [...itemFormValues];
    items[index] = {
      ...items[index],
      tds_id: null,
      tds_account_id: 0,
      tds_amount: 0,
      tds_percentage: 0,
    };
    setItemFormValues([...items]);
    setIsItemEdited(true);
  };

  const clearGstSelection = (itemIndex: number) => {
    const items = JSON.parse(JSON.stringify(itemFormValues));
    const item = items[itemIndex];
    if (editId && item.gst_tax) deleteItemGst(item);
    setItemFormValues((prevValue) => {
      const itemValues = JSON.parse(JSON.stringify(prevValue));
      itemValues[itemIndex] = {
        ...itemValues[itemIndex],
        gst_tax: null,
      };
      return itemValues;
    });
    setTaxFormValues((prevValue) => {
      const itemValues = JSON.parse(JSON.stringify(prevValue));
      itemValues[itemIndex] = {
        ...itemValues[itemIndex],
        gst_tax: null,
      };
      return itemValues;
    });
  };
  const handleTotalTcsLabelChange = (
    id: number,
    rate: number,
    name: string,
    index: number
  ) => {
    setTotalTcsValues((values) => ({
      ...values,
      tcs_id: Number(id) ? Number(id) : null,
      tcs_account_id: Number(id),
      tcs_amount: 0,
      tcs_percentage: Number(rate),
      tcs_name: name,
    }));
    setIsItemEdited(true);
  };

  const handleTotalTdsLabelChange = (
    id: number,
    rate: number,
    name: string,
    index: number
  ) => {
    setTotalTdsValues((values) => ({
      ...values,
      tds_id: Number(id) ? Number(id) : null,
      tds_account_id: Number(id),
      tds_amount: 0,
      tds_percentage: Number(rate),
      tds_name: name,
    }));
  };

  const handleAmountChange = (name: string, e: any, index: number) => {
    let value = e.target.value;
    const numberRegex = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/;
    const items = [...itemFormValues];

    let decimalPointSplitArray: string[] = [];
    if (value.toString().includes(",")) {
      value = value.toString().split(",").join("");
    }
    decimalPointSplitArray.push(value);
    if (value.includes(".")) {
      decimalPointSplitArray = value.toString().split(".");
    }
    if (decimalPointSplitArray[0].length < 15) {
      if (value === "" || numberRegex.test(value)) {
        if (
          (name === "tds_percentage" && value <= 100) ||
          name !== "tds_percentage"
        ) {
          items[index] = { ...items[index], [name]: value ? value : 0 };
          setItemFormValues(items);
        }
      }
    }
    if (value <= itemFormValues[index].rate) {
      let itemFormErrors: any = props.itemFormErrors
        ? props.itemFormErrors
        : [];
      itemFormErrors[index] = {
        ...itemFormErrors[index],
        tds_amount: "",
      };
      props.setErrorOnItemTaxChange(itemFormErrors);
    }
    setIsItemEdited(true);
  };

  const handleTotalAmountChange = (
    name: string,
    e: any,
    index: number,
    taxType: string
  ) => {
    let value = e.target.value;
    const numberRegex = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/;
    let decimalPointSplitArray: string[] = [];
    if (value.toString().includes(",")) {
      value = value.toString().split(",").join("");
    }
    decimalPointSplitArray.push(value);
    if (value.includes(".")) {
      decimalPointSplitArray = value.toString().split(".");
    }
    if (decimalPointSplitArray[0].length < 15) {
      if (value === "" || numberRegex.test(value)) {
        if (taxType === "TCS") {
          setTotalTcsValues((values) => ({
            ...values,
            tcs_amount: value,
          }));
        } else {
          setTotalTdsValues((values) => ({
            ...values,
            [name]: value,
          }));
        }
      }
    }
    setIsItemEdited(true);
  };

  const handleTotalTcsAmountChange = (e: any) => {
    let value = e.target.value;
    const numberRegex = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/;
    let decimalPointSplitArray: string[] = [];
    if (value.toString().includes(",")) {
      value = value.toString().split(",").join("");
    }
    decimalPointSplitArray.push(value);
    if (value.includes(".")) {
      decimalPointSplitArray = value.toString().split(".");
    }
    if (decimalPointSplitArray[0].length < 15) {
      if (value === "" || numberRegex.test(value)) {
        setTotalTcsValues((values) => ({
          ...values,
          tcs_amount: value ? Number(value) : 0,
        }));
      }
    }
    setIsItemEdited(true);
  };

  const handleItemTaxChange = (e: any, itemIndex: number, taxIndex: number) => {
    const { name, value } = e.target;
    const numberRegex = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/;
    if (name === "tax_percentage" && value !== "") {
      let decimalPointSplitArray: string[] = [];
      decimalPointSplitArray.push(value);
      if (value.includes(".")) {
        decimalPointSplitArray = value.toString().split(".");
      }
      if (!numberRegex.test(value) || decimalPointSplitArray[0].length > 14) {
        return;
      }
    }
    const items = JSON.parse(JSON.stringify(itemFormValues));
    const taxItems = JSON.parse(JSON.stringify(taxFormValues));
    let taxExist = [];
    if (value) {
      taxExist = items[itemIndex].taxes.filter(
        (tax: any, index: number) => tax.tax_id === value && index !== taxIndex
      );
      let itemFormErrors: CustomerItemValues[] = props.itemFormErrors
        ? props.itemFormErrors
        : [];
      if (taxExist.length) {
        if (name === "tax_id") {
          if (typeof itemFormErrors[itemIndex] === "undefined") {
            itemFormErrors[itemIndex] = {
              ...(itemFormErrors[itemIndex] as any),
              taxes: [],
            };
          }
          itemFormErrors[itemIndex].taxes[taxIndex] = {
            tax_id: "",
            tax_percentage: 0,
          };
          itemFormErrors[itemIndex].taxes[taxIndex].tax_id =
            "Tax label already used";
          props.setErrorOnItemTaxChange(itemFormErrors);
          // setItemFormErrors([...itemFormErrors]);
        }
      } else {
        if (typeof itemFormErrors[itemIndex] !== "undefined") {
          if (name === "tax_id") {
            if (!itemFormErrors[itemIndex].taxes) {
              itemFormErrors[itemIndex] = {
                ...itemFormErrors[itemIndex],
                taxes: [],
              };
            }
            itemFormErrors[itemIndex].taxes[taxIndex] = {
              tax_id: "",
              tax_percentage: 0,
            };
            props.setErrorOnItemTaxChange(itemFormErrors);
            // setItemFormErrors([...itemFormErrors]);
          }
        }
      }
    }
    items[itemIndex].taxes[taxIndex] = {
      ...items[itemIndex].taxes[taxIndex],
      [name]: value,
    };
    taxItems[itemIndex].taxes[taxIndex] = {
      ...taxItems[itemIndex].taxes[taxIndex],
      [name]: value,
    };

    setTaxFormValues(taxItems);
    if (!taxExist.length) {
      setItemFormValues(items);
    } else {
      if (itemFormValues[itemIndex].taxes[taxIndex].tax_id !== "") {
        itemFormValues[itemIndex].taxes[taxIndex] = {
          tax_id: "",
          tax_percentage:
            itemFormValues[itemIndex].taxes[taxIndex].tax_percentage,
        };
        setItemFormValues([...itemFormValues]);
      }
    }
    setIsItemEdited(true);
  };
  useEffect(() => {
    if (props.isCustomerVendorChanged) {
      if (
        props.componentType === "invoice" ||
        props.componentType === "recurringInvoice" ||
        props.componentType === "creditNote"
      ) {
        if (props.customerDetails?.customer_exemption_type === "NON_TAXABLE") {
          let itemsCount = JSON.parse(JSON.stringify(itemFormValues)).length;
          let default_gst = getDefaultNonTaxableGst();

          if (default_gst.length > 0) {
            for (let index = 0; index < itemsCount; index++) {
              handleItemGstChange(default_gst[0], index, {
                item_exemption_type:
                  props.customerDetails?.customer_exemption_type,
                item_exemption_id: "",
              });
            }
          }
        } else if (
          (props.gstRegistrationType === gstRegistrationTypes.sez ||
            props.gstRegistrationType === gstRegistrationTypes.sezDeveloper ||
            props.gstRegistrationType === gstRegistrationTypes.overseas) &&
          (props.componentType === "invoice" ||
            props.componentType === "creditNote" ||
            props.componentType === "recurringInvoice")
        ) {
          let itemsCount = JSON.parse(JSON.stringify(itemFormValues)).length;
          let default_gst = getDefaultSezGst_forSales();
          if (default_gst.length > 0) {
            for (let index = 0; index < itemsCount; index++) {
              handleItemGstChange(default_gst[0], index, null);
            }
          }
        } else {
          let itemsCount = JSON.parse(JSON.stringify(itemFormValues)).length;
          for (let index = 0; index < itemsCount; index++) {
            handleItemGstChange(null, index, null);
          }
        }
      } else if (
        (props.gstRegistrationType === gstRegistrationTypes.sez ||
          props.gstRegistrationType === gstRegistrationTypes.sezDeveloper ||
          props.gstRegistrationType === gstRegistrationTypes.overseas) &&
        (props.componentType === "bill" || props.componentType === "debitNote")
      ) {
        let itemsCount = JSON.parse(JSON.stringify(itemFormValues)).length;
        let defaultTax = gstTaxList.filter(
          (tax: any) => tax.id === defaultPreference.inter_state_id
        );
        if (defaultTax.length > 0) {
          for (let index = 0; index < itemsCount; index++) {
            handleItemGstChange(defaultTax[0], index, null);
          }
        }
      } else if (
        props.gstRegistrationType !== gstRegistrationTypes.sez &&
        props.gstRegistrationType !== gstRegistrationTypes.sezDeveloper &&
        props.gstRegistrationType !== gstRegistrationTypes.overseas &&
        props.stateType === "Inter State"
      ) {
        let itemsCount = JSON.parse(JSON.stringify(itemFormValues)).length;
        let defaultTax = gstTaxList.filter(
          (tax: any) => tax.id === defaultPreference.inter_state_id
        );
        if (defaultTax.length > 0) {
          for (let index = 0; index < itemsCount; index++) {
            handleItemGstChange(defaultTax[0], index, null);
          }
        }
      } else if (
        props.gstRegistrationType !== gstRegistrationTypes.sez &&
        props.gstRegistrationType !== gstRegistrationTypes.sezDeveloper &&
        props.gstRegistrationType !== gstRegistrationTypes.overseas &&
        props.stateType === "Intra State"
      ) {
        let itemsCount = JSON.parse(JSON.stringify(itemFormValues)).length;
        let defaultTax = gstTaxList.filter(
          (tax: any) => tax.id === defaultPreference.intra_state_id
        );
        if (defaultTax.length > 0) {
          for (let index = 0; index < itemsCount; index++) {
            handleItemGstChange(defaultTax[0], index, null);
          }
        }
      } else {
        let itemsCount = JSON.parse(JSON.stringify(itemFormValues)).length;
        for (let index = 0; index < itemsCount; index++) {
          handleItemGstChange(null, index, null);
        }
      }
    }
  }, [
    props.gstRegistrationType,
    taxList,
    props.customerDetails?.customer_exemption_type,
    props.itemFormValues,
  ]);
  // }, [props.isSez]);

  useEffect(() => {
    setGstStateType(stateType);
    if (
      props.isStateChanged &&
      props.customerDetails?.customer_exemption_type !== "NON_TAXABLE"
    ) {
      populateDefaultGST();
    }
  }, [stateType]);

  const populateDefaultGST = () => {
    const gstTaxForItems = gstTaxOfItemsRef.current;
    if (
      taxList.length &&
      props?.gstRegistrationType !== gstRegistrationTypes.sez &&
      props?.gstRegistrationType !== gstRegistrationTypes.sezDeveloper &&
      props?.gstRegistrationType !== gstRegistrationTypes.overseas
    ) {
      let itemsCount = JSON.parse(JSON.stringify(itemFormValues)).length;
      for (let index = 0; index < itemsCount; index++) {
        if (
          itemFormValuesRef.current[index].item_exemption_type &&
          itemFormValuesRef.current[index].item_exemption_type !== "TAXABLE"
        ) {
          let taxObj = null;
          const taxPreference = EXEMPTION_TYPES.find(
            (type) => type.value === itemFormValuesRef.current[index].item_exemption_type
          )?.key;
          const gst_tax = gstTaxList.filter((gstTax: any) => {
            return (
              gstTax.name === taxPreference &&
              gstTax.tax_type === "NONE" &&
              gstTax.rate === 0
            );
          });
          taxObj = getGstTaxItem(gst_tax[0]);
          handleItemGstChange(gst_tax[0], index, {
            item_exemption_type: itemFormValues[index]?.item_exemption_type,
            item_exemption_id: itemFormValues[index]?.item_exemption_id,
          });
        } else {
          const interStateGst = gstTaxList.filter((gstTax: any) => {
            if (gstTaxForItems[index]) {
              if (gstTax.id === gstTaxForItems[index].inter_state_id) {
                return gstTax;
              }
            }
          });
          const intraStateGst = gstTaxList.filter((gstTax: any) => {
            if (gstTaxForItems[index]) {
              if (gstTax.id === gstTaxForItems[index].intra_state_id) {
                return gstTax;
              }
            }
          });
          if (
            props.customerDetails?.customer_exemption_type !== "NON_TAXABLE"
          ) {
            if (stateType === "Inter State" && interStateGst.length > 0) {
              handleItemGstChange(interStateGst[0], index, null);
            } else if (
              stateType === "Inter State" &&
              defaultPreference.inter_state_id
            ) {
              let defaultTax = taxList.filter(
                (tax) => tax.id === defaultPreference.inter_state_id
              );
              if (defaultTax.length > 0) {
                handleItemGstChange(defaultTax[0], index, null);
              }
            }
            if (stateType === "Intra State" && intraStateGst.length > 0) {
              handleItemGstChange(intraStateGst[0], index, null);
            } else if (
              stateType === "Intra State" &&
              defaultPreference.intra_state_id
            ) {
              let defaultTax = taxList.filter(
                (tax) => tax.id === defaultPreference.intra_state_id
              );
              if (defaultTax.length > 0) {
                handleItemGstChange(defaultTax[0], index, null);
              }
            }
          }
        }
      }
    }
  };

  const getDefaultNonTaxableGst = () => {
    let default_gst = gstTaxList.filter((gstTax: any) => {
      return (
        gstTax.name === "Non-Taxable" &&
        gstTax.tax_type === "NONE" &&
        gstTax.rate === 0
      );
    });
    return default_gst;
  };
  const getDefaultSezGst_forSales = () => {
    let default_gst = gstTaxList.filter((gstTax: any) => {
      return (
        gstTax.name === "IGST0" &&
        gstTax.tax_type === "IGST" &&
        gstTax.rate === 0
      );
    });
    return default_gst;
  };

  const handleItemGstChange = (
    tax: any,
    itemIndex: number,
    itemExemption: any
  ) => {
    // Updating deleteGstItems starts
    const items = JSON.parse(JSON.stringify(itemFormValues));
    const item = items[itemIndex];

    // if isSez customer, then allow only rate zero tax.
    if (
      (props.gstRegistrationType === gstRegistrationTypes.sez ||
        props.gstRegistrationType === gstRegistrationTypes.sezDeveloper) &&
      (props.componentType === "invoice" ||
        props.componentType === "creditNote" ||
        props.componentType === "recurringInvoice") &&
      tax?.rate !== 0
    ) {
      ErrorToaster(
        "The tax rate for supplies to SEZ when you have a letter of Undertaking/Bond should be IGST 0%."
      );
      return;
    } else if (
      props.gstRegistrationType === gstRegistrationTypes.overseas &&
      (props.componentType === "invoice" ||
        props.componentType === "creditNote" ||
        props.componentType === "recurringInvoice") &&
      (tax?.rate !== 0 || tax.is_tax_preference)
    ) {
      ErrorToaster("Only Zero Tax can be applied to overseas invoices.");
      return;
    }
    if (item.gst_tax) deleteItemGst(item);
    // Updating itemFormValues with new tax values start

    const taxObj = getGstTaxItem(tax);
    const isItemGstTaxable = [
      "Out of Scope",
      "Non-Taxable",
      "Non-GST Supply",
    ].includes(tax?.name);

    setItemFormValues((prevValue) => {
      const itemValues = JSON.parse(JSON.stringify(prevValue));
      const exemption = {
        item_exemption_type: itemExemption?.item_exemption_type
          ? itemExemption.item_exemption_type
          : isItemGstTaxable
          ? itemValues[itemIndex].item_exemption_type
          : "TAXABLE",
        item_exemption_id: itemExemption?.item_exemption_id
          ? itemExemption.item_exemption_id
          : isItemGstTaxable
          ? itemValues[itemIndex].item_exemption_id
          : "",
      };
      itemValues[itemIndex] = {
        ...itemValues[itemIndex],
        gst_tax: taxObj ? { ...taxObj } : null,
        ...exemption,
      };
      return itemValues;
    });
    setTaxFormValues((prevValue) => {
      const itemValues = JSON.parse(JSON.stringify(prevValue));
      const exemption = {
        item_exemption_type: itemExemption?.item_exemption_type
          ? itemExemption.item_exemption_type
          : isItemGstTaxable
          ? itemValues[itemIndex].item_exemption_type
          : "TAXABLE",
        item_exemption_id: itemExemption?.item_exemption_id
          ? itemExemption.item_exemption_id
          : isItemGstTaxable
          ? itemValues[itemIndex].item_exemption_id
          : "",
      };
      itemValues[itemIndex] = {
        ...itemValues[itemIndex],
        gst_tax: taxObj ? { ...taxObj } : null,
        ...exemption,
      };
      return itemValues;
    });
    // Updating itemFormValues ends
  };
  const getGstTaxItem = (tax: any) => {
    if (!tax) {
      return null;
    }
    let taxes = tax?.taxes;
    let gst_tax_item: ObjectType[] = [];
    if (taxes) {
      gst_tax_item = taxes.map((singleTax: ObjectType) => {
        return {
          tax_id: singleTax.id,
          tax_percentage: singleTax.rate,
          tax_type: singleTax?.tax_type,
        };
      });
    }
    const taxObj = {
      tax_id: tax.id,
      tax_percentage: tax.rate,
      is_group: tax.tax_group,
      tax_type: tax?.tax_type,
      gst_tax_item: gst_tax_item,
    };
    return taxObj;
  };
  const deleteItemGst = (item: any) => {
    if (item.id) {
      let gst_tax = item?.gst_tax;
      if (gst_tax?.is_group) {
        let prev_gst_tax_item = gst_tax?.gst_tax_item;
        if (prev_gst_tax_item !== null && prev_gst_tax_item.length > 0) {
          // looping gst_tax_item in the case of tax group
          prev_gst_tax_item.forEach((single_gst_tax_item: ObjectType) => {
            if (single_gst_tax_item.id) {
              setDeleteGstItems((prevDeleteGstItems) => {
                // checking tax id aleady exist in deleteGstItem or not
                let filteredDeleteGstItems = prevDeleteGstItems.filter(
                  (gstItem) =>
                    gstItem.itemId === item.id &&
                    gstItem.id === single_gst_tax_item.id
                );
                if (filteredDeleteGstItems.length === 0) {
                  prevDeleteGstItems.push({
                    itemId: item.id,
                    id: single_gst_tax_item.id,
                  });
                }
                return prevDeleteGstItems;
              });
            }
          });
        }
      } else if (!gst_tax.is_group && gst_tax.id) {
        setDeleteGstItems((prevDeleteGstItems) => {
          let filteredDeleteGstItems = prevDeleteGstItems.filter(
            (gstItem) => gstItem.itemId === item.id && gstItem.id === item.id
          );
          if (filteredDeleteGstItems.length === 0) {
            prevDeleteGstItems.push({ itemId: item.id, id: gst_tax.id });
          }
          return prevDeleteGstItems;
        });
      }
    }
  };
  /**
   * Append item tax container
   */
  const deleteItemTaxContainer = (
    itemIndex: number,
    taxIndex: number,
    id: number | undefined
  ) => {
    itemFormValuesForTax.map((item, index) => {
      if (index === itemIndex) {
        item.taxes.map((tax, taxIndex1) => {
          if (id && tax.id === id) {
            setDeleteTaxItems((prevItems) => [
              ...prevItems,
              { itemId: Number(item.id), taxItemId: Number(tax.id) },
            ]);
          }
        });
      }
    });
    const taxItems = JSON.parse(JSON.stringify(taxFormValues));
    taxItems[itemIndex].taxes.splice(taxIndex, 1);

    const items = JSON.parse(JSON.stringify(itemFormValues));
    items[itemIndex].taxes.splice(taxIndex, 1);
    setTaxFormValues(taxItems);
    setItemFormValues(items);

    if (props.itemFormErrors) {
      const taxErrors = [...props.itemFormErrors];
      taxErrors[itemIndex]?.taxes?.splice(taxIndex, 1);
      props.setErrorOnItemTaxChange(taxErrors);
      // setItemFormErrors(taxErrors);
    }
  };

  /**
   * Append new item container
   */
  const addItemContainer = () => {
    const itemObject = initialItemValues;
    const exemption = {
      item_exemption_type: props.customerDetails?.customer_exemption_type,
      item_exemption_id: "",
    };
    if (
      props.customerDetails?.customer_exemption_type === "NON_TAXABLE" &&
      (props.componentType === "invoice" ||
        props.componentType === "recurringInvoice" ||
        props.componentType === "creditNote")
    ) {
      let default_gst = getDefaultNonTaxableGst();
      const taxObj = getGstTaxItem(default_gst[0]);
      itemObject.gst_tax = { ...taxObj } as any;
      itemObject.item_exemption_type = exemption.item_exemption_type;
      itemObject.item_exemption_id = exemption.item_exemption_id;
    } else if (
      (props.gstRegistrationType === gstRegistrationTypes.sez ||
        props.gstRegistrationType === gstRegistrationTypes.sezDeveloper ||
        props.gstRegistrationType === gstRegistrationTypes.overseas) &&
      (props.componentType === "invoice" ||
        props.componentType === "recurringInvoice" ||
        props.componentType === "creditNote")
    ) {
      let default_gst = getDefaultSezGst_forSales() as any;
      const taxObj = getGstTaxItem(default_gst[0]);
      itemObject.gst_tax = { ...taxObj } as any;
    } else if (
      (props.gstRegistrationType === gstRegistrationTypes.sez ||
        props.gstRegistrationType === gstRegistrationTypes.sezDeveloper ||
        props.gstRegistrationType === gstRegistrationTypes.overseas) &&
      (props.componentType === "bill" || props.componentType === "debitNote")
    ) {
      let defaultTax = gstTaxList.filter(
        (tax: any) => tax.id === defaultPreference.inter_state_id
      );
      if (defaultTax.length > 0) {
        const taxObj = getGstTaxItem(defaultTax[0]);
        itemObject.gst_tax = { ...taxObj } as any;
      }
    } else {
      if (stateType === "Inter State" && defaultPreference.inter_state_id) {
        let defaultTax = taxList.filter(
          (tax) => tax.id === defaultPreference.inter_state_id
        );
        if (defaultTax.length > 0) {
          const taxObj = getGstTaxItem(defaultTax[0]);
          itemObject.gst_tax = { ...taxObj } as any;
        }
      }
      if (stateType === "Intra State" && defaultPreference.intra_state_id) {
        let defaultTax = taxList.filter(
          (tax) => tax.id === defaultPreference.intra_state_id
        );
        if (defaultTax.length > 0) {
          const taxObj = getGstTaxItem(defaultTax[0]);
          itemObject.gst_tax = { ...taxObj } as any;
        }
      }
    }
    // sales or purchase default update
    let consumerTradeDefault = props.consumerTradeDefault;
    if (consumerTradeDefault) {
      itemObject.discount_type = consumerTradeDefault.discount_type
        ? consumerTradeDefault.discount_type
        : "Absolute";
      itemObject.discount = consumerTradeDefault.discount;
      itemObject.discount_account_id = consumerTradeDefault.discount_account_id;
      itemObject.discount_account_name =
        consumerTradeDefault.discount_account_name;
      itemObject.account_id =
        props.componentType === "invoice" ||
        props.componentType === "creditNote"
          ? consumerTradeDefault.sales_account_id
          : consumerTradeDefault.purchase_account_id;
      itemObject.account_name =
        props.componentType === "invoice" ||
        props.componentType === "creditNote"
          ? consumerTradeDefault.sales_account_name
          : consumerTradeDefault.purchase_account_name;
    }

    setItemFormValues((itemFormValues) => [...itemFormValues, itemObject]);
    setTaxFormValues((taxFormValues) => [...taxFormValues, itemObject]);
  };

  /**
   * Delete item container
   */
  const deleteItemContainer = async (index: number) => {
    itemFormValues.map((items, indexes) => {
      if (indexes === index) {
        setDeleteItems((prevItems) => [
          ...prevItems,
          { itemId: Number(items?.id) },
        ]);
      }
    });
    const items = [...itemFormValues];
    const itemId: any = items[index].id;

    items.splice(index, 1);
    setItemFormValues(items);
    const taxItems = [...taxFormValues];
    taxItems.splice(index, 1);
    setTaxFormValues(taxItems);
    if (props.itemFormErrors) {
      const itemErrors = [...props.itemFormErrors];
      itemErrors.splice(index, 1);
      props.setErrorOnItemTaxChange(itemErrors);
      // setItemFormErrors(itemErrors);
    }
    setItemFormValuesForTax(items);
    setIsItemEdited(true);
  };
  /**
   * Append item tax container
   */
  const addItemTaxContainer = (itemIndex: number) => {
    const taxObject = initialTaxValues;
    const items = JSON.parse(JSON.stringify(itemFormValues));
    items[itemIndex].taxes.push(taxObject);
    setItemFormValues(items);
    const taxItems = JSON.parse(JSON.stringify(taxFormValues));
    taxItems[itemIndex].taxes.push(taxObject);
    setTaxFormValues(taxItems);
  };

  /**
   * Function to round off tax input elements after out of focus.
   */
  const handleTaxOnBlur = (
    name: string,
    amount: number,
    itemIndex: number,
    taxIndex: number
  ) => {
    const items = JSON.parse(JSON.stringify(itemFormValues));
    const taxItems = JSON.parse(JSON.stringify(taxFormValues));
    if (amount.toString().includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(amount);
      if (fractionalPart.length > 6) {
        let roundedAmount: number = getRoundOffAmount(amount, 6);
        items[itemIndex].taxes[taxIndex] = {
          ...items[itemIndex].taxes[taxIndex],
          [name]: roundedAmount,
        };
        taxItems[itemIndex].taxes[taxIndex] = {
          ...taxItems[itemIndex].taxes[taxIndex],
          [name]: roundedAmount,
        };
        setTaxFormValues(taxItems);
        setItemFormValues(items);
      }
    }
  };

  const getTdsTaxPreferenceDetails = async () => {
    const responseAction = await dispatch(
      tdsTaxPreference(currentUserInfo.organization_id)
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setTdsPreferenceValues(response);
      }
    }
  };

  const resetTaxValues = () => {
    setTotalTcsValues(initialTCSTotalValues);
    setTotalTdsValues(initialTDSTotalValues);
  };

  const updateTotal = (val: any) => {
    setManuallyEditedTotal(val);
  };

  const updateIsItemEdited = (val: boolean) => {
    setIsItemEdited(val);
  };

  return (
    <>
      <section className="item-details-wrapper" id="item-details-wrapper">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <span>
            <h2 className="mb-0">Item Details</h2>
            <span className="mt-2 error">{props.formErrors.items}</span>
          </span>
          <ExchangeRate
            hideExchangeRate={props.hideExchangeRate}
            currencyId={props.currencyId}
            currencyCode={currencyCode}
            editId={editId}
            conversionId={props.formValues.conversion_id}
            conversionCurrencyId={props.conversionCurrencyId}
            conversionDate={props.conversionDate}
            newConversionDate={props.newConversionDate}
            isInititialCustomConversionFetch={
              props.isInititialCustomConversionFetch
            }
            // isMutable={props.formValues.is_mutable}
          />
        </div>
        <div className="row g-0 mb-0 overflow-hidden">
          <div className="col items-entry-wrapper cn-items-entry-wrapper px-0 overflow-hidden">
            <div
              id="item-det-container"
              className="cn-item-container item-det-container overflow-coloumn"
            >
              <div className="row item-det-row cn-item-row g-0">
                <div className="col">
                  <div className="item-grid-account cdn-item-grid">
                    {/* row start */}
                    {itemFormValues.map((item, index) => {
                      return (
                        <>
                          <LineItem
                            key={index}
                            index={index}
                            item={item}
                            componentType={props.componentType}
                            currencyId={
                              props.currencyId ? props.currencyId : ""
                            }
                            currencyCode={currencyCode}
                            isHsnCode={props.isHsnCode}
                            taxFormValues={taxFormValues[index]}
                            handleItemChange={handleItemChange}
                            handleItemOnBlur={handleItemOnBlur}
                            handleItemAccountChange={handleItemAccountChange}
                            taxType={props.taxType}
                            lineItemBaseAccount={props.lineItemBaseAccount}
                            discountBaseAccount={props.discountBaseAccount}
                            handleItemDropdownChange={handleItemDropdownChange}
                            handleItemTaxChange={handleItemTaxChange}
                            handleItemGstChange={handleItemGstChange}
                            handleTaxOnBlur={handleTaxOnBlur}
                            addItemTaxContainer={addItemTaxContainer}
                            deleteItemTaxContainer={deleteItemTaxContainer}
                            deleteItemContainer={deleteItemContainer}
                            itemFormErrors={
                              props.itemFormErrors &&
                              props?.itemFormErrors[index]
                            }
                            handleAmountChange={handleAmountChange}
                            tdsLevel={props.formValues.tds_level}
                            sourceTaxType={props.sourceTaxType}
                            clearTaxSelection={clearTaxSelection}
                            totalTcsValues={totalTcsValues}
                            tdsIds={props.tdsIds}
                            tcsIds={props.tcsIds}
                            handleTdsLabelChange={handleTdsLabelChange}
                            clearGstSelection={clearGstSelection}
                            supplyState={
                              props.supplyState ? props.supplyState : ""
                            }
                            isSez={props.isSez}
                            gstRegistrationType={props.gstRegistrationType}
                            updateIsItemEdited={updateIsItemEdited}
                            customerDetails={props.customerDetails}
                            consumerTradeDefault={props.consumerTradeDefault}
                            rcmEnabled={
                              props.rcmEnabled ? props.rcmEnabled : false
                            }
                          />
                        </>
                      );
                    })}
                    {/* row ends */}
                  </div>
                </div>
              </div>
            </div>
            <AddLineItem onClick={addItemContainer} />

            <div className="row mb-0 total-sect-row">
              {props.children}
              <TotalSection
                totalTaxArray={totalTaxArray}
                currencyCode={currencyCode}
                subTotal={totalTaxableAmount ? totalTaxableAmount : "0.00"}
                totalTax={totalTax ? totalTax : "0.00"}
                total={total ? total : "0.00"}
                componentType={props.componentType}
                tdsAmount={tdsAmount}
                tcsAmount={tcsAmount}
                gstTotalObject={gstTotalObject}
                formErrors={props.formErrors}
                handleOnBlur={handleItemOnBlur}
                handleTcsAmountChange={handleTotalTcsAmountChange}
                totalTcsValues={totalTcsValues}
                handleAmountChange={handleTotalAmountChange}
                totalTdsValues={totalTdsValues}
                tdsLevel={props.formValues.tds_level}
                resetTaxValues={resetTaxValues}
                sourceTaxType={props.sourceTaxType}
                clearTaxSelection={resetTaxValues}
                itemFormValues={itemFormValues}
                tdsIds={props.tdsIds}
                tcsIds={props.tcsIds}
                handleTotalTcsLabelChange={handleTotalTcsLabelChange}
                handleTotalTdsLabelChange={handleTotalTdsLabelChange}
                roundOffValue={roundOffValue}
                isRoundOffEnabled={props.isRoundOffEnabled}
                isTotalEditable={props.isTotalEditable}
                updateTotal={updateTotal}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(forwardRef(ItemDetails));
