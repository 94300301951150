import { DeleteImg, ErrorImg } from "../../../assets/images";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { DateFormatHandler } from "../../../helpers/helper";
import { colors } from "../../constants/colors";
import { VoidIconBtn } from "./customSvgIcons/VoidIcon";
import "./void.css";

type Props = {
  className: string;
  deleteIcon: boolean;
  isMutable: boolean;
  onClick: () => void;
  isLocked: boolean;
  lockDate: string;
  name: string;
  assignedUserCount?: number;
  iconColor?: string;
  status: string;
  isButtonClick?: boolean;
};
export default function Void(props: Props) {
  return (
    <Link
    className={`${props?.status.toLowerCase() === "cancelled" ? "disable-btn": ""} ${props.className}`}
      style={{ textDecoration: "none" }}
      to="#"
      data-bs-toggle={props.isButtonClick ? "" :"modal"}
      data-bs-target={
        props.isMutable && !props.isLocked && !props.assignedUserCount
          ? "#voidMessageModal"
          : !props.isMutable && !props.isLocked
          ? "#notificationPopupModal"
          : props.assignedUserCount
          ? "#voidConfMessageModal"
          : ""
      }
      onClick={(e) => {
        e.preventDefault();
        if (props.isLocked) {
          toast.error(
            `Transactions before ${DateFormatHandler(
              props.lockDate
            )} have been locked. Hence action cannot be performed`,
            {
              icon: ({ theme, type }) => (
                <img src={ErrorImg} alt="error-img" width="16" height="14" />
              ),
              toastId: `void-failed`,
              closeButton: false,
              className: "toast-error",
              position: "top-center",
            }
          );
        } else if (
          !props.isMutable &&
          props.name !== "Customer" &&
          props.name !== "Vendor"
        ) {
          toast.error(
            `Unable to void ${props.name}, as there are transactions associated with it!`,
            {
              icon: ({ theme, type }) => (
                <img src={ErrorImg} alt="error-img" width="16" height="14" />
              ),
              toastId: `bill-void-failed`,
              closeButton: false,
              className: "toast-error",
              position: "top-center",
            }
          );
        } else {
          props.onClick();
        }
      }}
    >
      <span className="d-flex align-items-center">
        {props.deleteIcon ? 
            <VoidIconBtn
              color={props?.status.toLowerCase() === "cancelled" ? '#91919c': props.iconColor}
            />: null}
        Cancel
      </span>
    </Link>
  );
}
