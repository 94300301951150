import { Api } from "../../../constants";
import {
  get,
  post,
  postFormData,
  put,
  remove,
} from "../../../helpers/apiHelper";
import {
  DebitNoteAllocation,
  DebitNoteValues,
  TaxableAmountCalculationValues,
  TotalAmountCalculationValues,
} from "../../../types";

/**
 * API call for getting all debit notes
 */
export async function getDebitNoteList(data: {
  page: number;
  itemsPerPage: number;
  dateSortOrder: string;
  orgId: number;
  vendorList: number[];
  orgIds: number[];
  statusList: string[];
  billId?: number | null;
  startDate?: string;
  endDate?: string;
  startDueDate?: string;
  endDueDate?: string;
}) {
  let url =
    Api.debitNote +
    `debit/list?page_num=${data.page}&page_size=${
      data.itemsPerPage
    }&current_organization=${data.orgId} ${
      data.billId ? `&bill_obj_id=${data.billId}` : ""
    }${data.startDate ? `&start_date=${data.startDate.trim()}` : ""}${
      data.endDate
        ? `&end_date=${data.endDate.trim()}${
            data.startDueDate
              ? `&start_due_date=${data.startDueDate.trim()}`
              : ""
          }${data.endDueDate ? `&end_due_date=${data.endDueDate.trim()}` : ""}`
        : ""
    }`.trim();
  if (data.dateSortOrder !== "") {
    url += `&date_sort_order=${data.dateSortOrder}`;
  }
  if (data.vendorList.length !== 0) {
    const vendorIds = data.vendorList
      .map((vendorId) => {
        return `&vendor_ids=${vendorId}`;
      })
      .join("");
    url += vendorIds;
  }
  if (data.orgIds.length !== 0) {
    const orgIds = data.orgIds
      .map((orgId) => {
        return `&org_ids=${orgId}`;
      })
      .join("");
    url += orgIds;
  }
  if (data.statusList.length !== 0) {
    const statuses = data.statusList
      .map((status) => {
        return `&status_list=${status}`;
      })
      .join("");
    url += statuses;
  }
  const result = await get(url);
  return result;
}

/**
 * API call for Creating Debit Note
 */
export async function createNewDebitNote(data: {
  values: DebitNoteValues;
  status: string;
  orgId: number;
}) {
  const result = await post(
    Api.debitNote +
      `debit/create?debit_note_status=${data.status}&current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call for Updating Debit Note
 */
export async function editDebitNote(data: {
  debitNoteId: number;
  debitNoteStatus: string;
  values: DebitNoteValues;
  orgId: number;
}) {
  const result = await put(
    Api.debitNote +
      `debit/edit/${data.debitNoteId}?debit_note_status=${data.debitNoteStatus}&current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call for deleting debit note
 */
export async function removeDebitNote(data: {
  debitNoteId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.debitNote +
      `debit/delete/${data.debitNoteId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for getting Debit note Details
 */
export async function getDebitNoteDetails(data: {
  debitNoteId: number;
  orgId: number;
}) {
  const result = await get(
    Api.debitNote +
      `debit/detail/${data.debitNoteId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API cal for deleting Debit note item
 */
export async function removeDebitNoteItem(data: {
  debitNoteId: number;
  itemId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.debitNote +
      `debit-item/delete/${data.debitNoteId}/${data.itemId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for deleting Debit note item tax
 */
export async function removeDebitNoteItemTax(data: {
  itemId: number;
  taxId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.debitNote +
      `debit-item-tax/delete/${data.itemId}/${data.taxId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for delete debit note recipients
 */
export async function removeDebitNoteRecipient(data: {
  debitNoteId: number;
  emailIds: number[];
  orgId: number;
}) {
  const result = await remove(
    Api.debitNote +
      `debit-recipient/delete/${data.debitNoteId}?current_organization=${data.orgId}`,
    data.emailIds
  );
  return result;
}

/**
 * API call for getting debit note number
 */
export async function getDebitNoteNumber(orgId: number) {
  const result = await get(
    Api.debitNote + `new-debit_note-number?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call for checking the Debit Note number exist or not
 */
export async function debitNoteNumberExist(data: {
  debitNoteNumber: string;
  orgId: number;
  debitNoteId: number;
}) {
  const result = await get(
    Api.debitNote +
      `debit_note-number-exist?debit_note_number=${data.debitNoteNumber}&debit_note_id=${data.debitNoteId}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for calculating debit note item taxable amount
 */
export async function calculateDebitNoteTaxableAmount(data: {
  values: TaxableAmountCalculationValues;
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.debitNote +
      `debit-item/taxable-amount?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}

/**
 * API call for calculating debit note total amount
 */
export async function calculateDebitNoteTotalAmount(data: {
  values: TotalAmountCalculationValues;
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.debitNote +
      `debit/amount-calculation?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}

/**
 * API to upload debit note attachments
 */
export async function debitNoteAttachments(data: {
  file: FormData;
  orgId: number;
}) {
  const result = await postFormData(
    Api.debitNote + `debit-attachments?current_organization=${data.orgId}`,
    data.file
  );
  return result;
}

/**
 * API to delete debit note attachments
 */
export async function removeDebitNoteAttachments(data: {
  debitNoteId: number;
  fileId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.debitNote +
      `debit-attachments/${data.debitNoteId}?file_id=${data.fileId}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for sending debit note to contact details
 */
export async function sendDebitNote(data: {
  debitNoteId: number;
  emails: string[];
  orgId: number;
}) {
  const result = await post(
    Api.debitNote +
      `send-debit-note?debit_note_id=${data.debitNoteId}&current_organization=${data.orgId}`,
    data.emails
  );
  return result;
}

/**
 * API to get debit note pdf link
 */
export async function debitNotePdfLink(data: {
  debitNoteId: number;
  orgId: number;
}) {
  const result = await get(
    Api.debitNote +
      `download-link?debit_note_id=${data.debitNoteId}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for getting debit note allocation
 */
export async function debitNoteAllocation(data: {
  debitNoteId: number;
  orgId: number;
}) {
  const result = await get(
    Api.debitNote +
      `debit/allocation-detail?debit_note_id=${data.debitNoteId}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API to get the bills to allocate
 */
export async function billsToAllocate(data: {
  debitNoteId: number;
  orgId: number;
  billId?: number;
}) {
  const result = await get(
    Api.debitNote +
      `debit/bills-to-allocate?debit_note_id=${data.debitNoteId}${
        data.billId ? "&bill_id=" + data.billId : ""
      }&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to updated debit allocation
 */
export async function editDebitNoteAllocation(data: {
  debitNoteId: number;
  orgId: number;
  values: DebitNoteAllocation[];
}) {
  const result = await put(
    Api.debitNote +
      `debit/update-allocation?debit_note_id=${data.debitNoteId}&current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call to calculate debit note allocation
 */
export async function calculateDebitNoteAllocation(data: {
  debitNoteId: number;
  orgId: number;
  values: number[];
  signal?: AbortSignal;
}) {
  const result = await post(
    Api.debitNote +
      `allocation/calculation?debit_note_id=${data.debitNoteId}&current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}

/**
 * API call to delete debit note allocation
 */
export async function deleteDebitNoteAllocation(data: {
  debitNoteId: number;
  allocationId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.debitNote +
      `debit/delete-allocation/${data.debitNoteId}/${data.allocationId}?current_organization=${data.orgId}`
  );
  return result;
}
/**
 * Api to get the transaction limit status
 */
export async function transactionLimitStatus(orgId: number) {
  const result = await get(
    Api.debitNote + `transaction-limit-status?current_organization=${orgId}`
  );
  return result;
}

/**
 * Api to get journal for reporting tags
 */
export async function getReportingTagJournals(data: {
  debitNoteId: number;
  orgId: number;
}) {
  const result = await get(
    Api.debitNote +
      `reporting-tag/journal-generate?debit_note_id=${data.debitNoteId}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to update reporting tag
 */
export async function putReportingTags(data: {
  debitNoteId: number;
  orgId: number;
  payload: any;
}) {
  const result = await put(
    Api.debitNote +
      `reporting-tag/edit/${data.debitNoteId}?debit_note_id=${data.debitNoteId}&current_organization=${data.orgId}`,
    data.payload
  );
  return result;
}

/**
 * Api to mark debit note void
 */
export async function debitNoteVoidMarker(data: {
  debitNoteId: number | null;
  orgId: number;
}) {
  const result = await put(
    Api.debitNote +
      `debit/mark-void/${data.debitNoteId}?debit_note_id=${data.debitNoteId}&current_organization=${data.orgId}`
  );
  return result;
}
