import { PaymentReceivedJournalListPayload } from "../../../../types";

import {
  setLoaderState,
  useCommonData,
} from "../components/paymentReceivedForm/imports";
import { generateJournalListPR } from "../paymentReceivedSlice";
export const useFetchJournalListPR = () => {
  const { dispatch, currentUserInfo } = useCommonData();
  const getJournals = async (
    values: PaymentReceivedJournalListPayload,
    editId: number,
    setJournals: React.SetStateAction<any>
  ) => {
    dispatch(setLoaderState(true));
    const response = await dispatch(
      generateJournalListPR({
        prId: Number(editId),
        orgId: currentUserInfo.organization_id,
        values: { ...values },
      })
    );
    dispatch(setLoaderState(false));
    const responsePayload = response.payload;
    if (Object.keys(responsePayload).length && !("error" in responsePayload)) {
      setJournals(responsePayload);
    }else{
        dispatch(setLoaderState(false));
    }
  };
  return { getJournals };
};
