import { FormikProps } from "formik";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import { decimalPlaceOfCurrency } from "../../../../../../../helpers/decimalPlaceHelper";
import { getRoundOffAmount } from "../../../../../../../helpers/roundOffHelper";
import {
  currentUserSelector,
  orgCurrencyListSelector,
  setLoaderState,
} from "../../../../../../common/commonSlice";
import { AddCustomerModal } from "../../../../../customerManagement/components/addCustomerModal/AddCustomerModal";
import { customerDetailsForTransactions } from "../../../../../customerManagement/customerSlice";
import {
  CustomerSelect,
  getFormatedDate,
  unpaidInvoiceList,
} from "../../imports";
import { useState, useEffect } from "react";
import NotificationModal from "../../../../../../common/components/NotificationModal";
import { useParams } from "react-router-dom";
import { usePermissionHandler } from "../../../../../../hooks/usePermissionHandler";
import { usePr } from "../../usePr";

type Props = {
  formik: FormikProps<any>;
};
export const PaymentCustomer = (props: Props) => {
  const dispatch = useAppDispatch();
  const { customerId, editId } = useParams();
  const { customerCurrencyChangeHandler } = usePr({
    formik: props.formik,
  });
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [newCustomer, setNewCustomer] = useState({ value: 0, label: "" });
  const { customerRolePermission } = usePermissionHandler();
  const [isInitialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (props.formik.values.customer_id) {
      getUnpaidInvoices(props.formik.values.customer_id);
    }
  }, [props.formik.values.customer_id]);

  useEffect(() => {
    if (customerId) {
      getCustomerDetails(Number(customerId));
      getUnpaidInvoices(Number(customerId));
    }
  }, [customerId, JSON.stringify(orgCurrencyList)]);

  const roundAmountReceived = (currencyCode: string) => {
    const decimals = decimalPlaceOfCurrency(currencyCode, orgCurrencyList);
    const roundedAmount = getRoundOffAmount(
      props.formik.values.amount_received,
      decimals
    );
    props.formik.setFieldValue("amount_received", roundedAmount);
  };

  /**
   * Handling Vendor change in Create or Edit BIll Payment form
   */
  const handleCustomerChange = (e: any) => {
    if (editId && props.formik.initialValues.customer_id) {
      $("#notificationPopupModal").modal("show");
      setNewCustomer({ value: e.value, label: e.label });
    } else {
      if (e.value) {
        updateNewCustomer(e);
      }
    }
  };
  const updateNewCustomer = (e: { value: number; label: string }) => {
    props.formik.setFieldValue("customer_id", e.value);
    props.formik.setFieldValue("customer_name", e.label.trim());
    props.formik.setFieldValue("deposit_to_account_id", null);
    props.formik.setFieldValue("deposit_to_account_name", "");
    props.formik.setFieldValue("is_currency_changed", true);
    getCustomerDetails(e.value);
  };

  const popupModalSubmitHandler = () => {
    setInitialLoad(false);
    updateNewCustomer(newCustomer);
  };

  /**
   * Fetch customer details by id
   */
  const getCustomerDetails = async (customerId: number) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      customerDetailsForTransactions({
        customerId: customerId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        props.formik.setFieldValue(
          "currency_id",
          response.other_details.currency_id
        );
        let currencyId = response.other_details.currency_id;
        if (response.other_details.currency_id) {
          let currencyValues = orgCurrencyList
            .filter(
              (currency: any) => Number(currency.id) === Number(currencyId)
            )
            .map((currency) => currency);

          if (currencyValues.length) {
            props.formik.setFieldValue("currency_code", currencyValues[0].code);

            roundAmountReceived(currencyValues[0].code);
          }
        }
        customerCurrencyChangeHandler(response.other_details.currency_id);
      }
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };
  const getUnpaidInvoices = async (id: number) => {
    if (!isNaN(id) && id !== null) {
      const responseAction = await dispatch(
        unpaidInvoiceList({
          currentOrgId: currentUserInfo.organization_id,
          customerId: id,
        })
      );
      const response = responseAction.payload;
      if (
        editId &&
        props.formik.values.customer_id ===
          props.formik.initialValues.customer_id &&
        isInitialLoad
      ) {
        props.formik.setFieldValue("unpaid_invoice_lines", [
          ...props.formik.initialValues.unpaid_invoice_lines,
        ]);
        return;
      }
      if (Object.keys(response).length && !("error" in response)) {
        if (
          props.formik.values.customer_id !==
            props.formik.initialValues.customer_id ||
          customerId
        ) {
          let unpaidInvoices: any = [];
          unpaidInvoices =
            Array.isArray(response) &&
            response.map((invoices) => {
              return {
                invoice_id: invoices.id,
                amount_due: invoices.amount_due,
                invoice_amount: invoices.invoice_amount,
                invoice_date: invoices.invoice_date,
                invoice_number: invoices.invoice_number,
                is_deleted: false,
                id: null,
                payment: 0,
                conversion_id: invoices.conversion_id,
                allocation_date: getFormatedDate(),
                forex_amount: 0,
              };
            });
          props.formik.setFieldValue("unpaid_invoice_lines", unpaidInvoices);
        } else {
          props.formik.setFieldValue("unpaid_invoice_lines", [...response]);
        }
        dispatch(setLoaderState(false));
      } else {
        dispatch(setLoaderState(false));
        props.formik.setFieldValue("unpaid_invoice_lines", []);
      }
    }
  };
  return (
    <>
      <div className="col-12 col-lg-4 mw-325 customer-dropdown mb-3">
        <div className="link-label-grouped">
          <label htmlFor="customer_name" className="required">
            Customer Name
          </label>
          {customerRolePermission.includes("Create") && <AddCustomerModal />}
        </div>
        <div>
          <CustomerSelect
            customerId={props.formik.values.customer_id}
            errors={
              (props.formik.touched.customer_name &&
                props.formik.errors.customer_name?.toString()) ||
              ""
            }
            onChange={(val) => {
              handleCustomerChange(val);
            }}
          />
          <span className="error error_customer_id">
            {(props.formik.touched.customer_id &&
              props.formik.errors.customer_id?.toString()) ||
              ""}
          </span>
        </div>
      </div>
      <NotificationModal
        modalHeader="Record payment status update!"
        modalBody={`You are attempting to change the customer. This will update the payment status of existing invoices of ${props.formik.initialValues.customer_name}\n\nWould you like to continue?`}
        modalSubmit="Yes, Continue"
        modalCancel="Cancel"
        modalSubmitHandler={popupModalSubmitHandler}
      />
    </>
  );
};
