import { Card, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../../../../helpers/toastHelper";
import { TaxExemptionListItem } from "../../../../../../types";
import { setLoaderState } from "../../../../../common/commonSlice";
import { listboxCustomStyle } from "../../../../../common/components/ListBoxCustomStyle";
import { DeleteAlertModal } from "../../../../../common/components/deleteModal/DeleteAlertModal";
import KebabMenu from "../../../../../common/components/kebabMenu/KebabMenu";
import TableContainer from "../../../../../common/components/table/TableContainer";
import TableInfo from "../../../../../common/components/table/TableInfo";
import useCommonData from "../../../../../hooks/useCommon";
import useListData from "../../../../../hooks/useListData";
import { usePaginationDispatch } from "../../../../../hooks/usePaginationDispatch";
import PaginationContainer from "../../../../items/components/list/PaginationContainer";
import { deleteTaxExemption, getTaxExemptionList } from "../../../gstSlice";
import { TaxExemptionFormModal } from "../taxExemptionForm/TaxExemptionFormModal";
import EmptyReport from "../../../../../common/components/EmptyReport";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
export const TaxExemptionList = () => {
  const { dispatch, currentUserInfo } = useCommonData();
  const { page, itemsPerPage, startCount, endCount, totalList } = useListData();
  const { gstPermission } = usePermissionHandler();
  const { setStartCount, setEndCount, setItemsPerPage, setPage, setTotalList } =
    usePaginationDispatch();

  const [taxExemptionList, setTaxExemptionList] = useState<
    TaxExemptionListItem[]
  >([]);

  useEffect(() => {
    getTaxExemptions();
  }, [page, itemsPerPage, currentUserInfo.organization_id]);
  const getTaxExemptions = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      getTaxExemptionList({
        orgId: currentUserInfo.organization_id,
        pageNum: page,
        pageSize: itemsPerPage,
      })
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      setTaxExemptionList(response.list_view || []);
      setTotalList(response.total || 0);
      if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
      setEndCount(
        itemsPerPage * page < response.total
          ? itemsPerPage * page
          : response.total
      );
      dispatch(setLoaderState(false));
    }
    dispatch(setLoaderState(false));
  };
  const deleteExemption = async (id: number) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      deleteTaxExemption({
        orgId: currentUserInfo.organization_id,
        exemptionId: id,
      })
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      SuccessToaster(
        "Tax exemption deleted successfully",
        "tax-exemption-delete-success"
      );
      getTaxExemptions();
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };

  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Exemption Reason",
        accessor: "show.exemptionReason",
        sort: false,
        sortOrder: "",
        className: "exemption-reason",
      },
      {
        Header: "Associated With",
        accessor: "show.associatedWith",
        sort: false,
        sortOrder: "",
        className: "associated-with",
      },
      {
        Header: "Description",
        accessor: "show.description",
        sort: false,
        sortOrder: "",
        className: "description",
      },
      {
        Header: "",
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return (
            !value.data.is_default && (
              <KebabMenu>
                <li>
                  <TaxExemptionFormModal
                    isEdit={true}
                    editId={value.id}
                    exemptionDetails={value.data}
                    refreshList={getTaxExemptions}
                    isAssociated={value.isAssociated}
                  />
                </li>
                <li>
                  <DeleteAlertModal
                    onCancel={() => {}}
                    onSubmit={() => {
                      deleteExemption(value.id);
                    }}
                    isSubmitDisabled={!value.isMutable}
                    deleteMessageContent={
                      <span>
                        Are you sure you want to delete this{" "}
                        <b>Tax Exemption</b>?
                      </span>
                    }
                    deleteModalTitle="Delete Tax Exemption"
                    isButtonDisabled={value.isAssociated}
                    disabledActionFunction={() => {
                      ErrorToaster(
                        "Cannot delete this tax exemption, since this exemption is already associated with one or more transactions, customers or items"
                      );
                    }}
                  />
                </li>
              </KebabMenu>
            )
          );
        },
      },
    ],
    [currentUserInfo.organization_id, page, itemsPerPage]
  );
  /**
   * set table row data
   */
  const data: any = taxExemptionList.map((exemption) => ({
    show: {
      exemptionReason: exemption.exemption_reason,
      associatedWith: exemption.exemption_type,
      description: exemption.description,
      actions: {
        id: exemption.id,
        isMutable: true,
        isAssociated: exemption.is_associated,
        data: {
          exemption_reason: exemption.exemption_reason,
          exemption_type: exemption.exemption_type,
          description: exemption.description,
          is_default: exemption.is_default,
          is_associated: exemption.is_associated,
        },
      },
    },
  }));

  return (
    <div className="card card-user-management card-customer main-card overflowX-hidden h-100">
      <div className="card-header invoice-list-card-header list-card-header p-0 border-0">
        <h1>Tax Exemptions</h1>
        {gstPermission.includes("Create") &&
        <TaxExemptionFormModal isEdit={false} refreshList={getTaxExemptions} />}
      </div>
      <Card variant="outlined" sx={listboxCustomStyle.cardBox}>
        <Box
          component="div"
          id="invoice-table-wrapper"
          className={
            "list-table-wrapper dataTables_wrapper dt-bootstrap5 no-footer " +
            `${totalList > 0 ? "" : "no-data"}`
          }
        >
          <TableInfo
            total={totalList}
            startCount={startCount}
            endCount={endCount}
            itemsPerPage={itemsPerPage}
            setPageInfo={(countPerPage, page) => {
              setItemsPerPage(countPerPage);
              setPage(page);
            }}
          />
          <Grid>
            <TableContainer
              className="list-table table table-striped w-100"
              columns={columns}
              data={data}
              url={"/users"}
              sortHandler={() => {}}
              editPermission={false}
              isDetailViewExist={false}
            >
              <EmptyReport
                heading="No tax exemptions found!"
                alt="no tax exemption"
              />
            </TableContainer>
          </Grid>
        </Box>
      </Card>
      {totalList > itemsPerPage ? <PaginationContainer /> : ""}
    </div>
  );
};
