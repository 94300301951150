import "../../preferences/preferences.css";
import { Container } from "../../../common/components/htmlTags/Container";
import { SaveButton, SecondaryButton } from "../../../common/components";
import { Section } from "../../../common/components/htmlTags/Section";
import { Card } from "../../../common/components/htmlTags/Card/Card";
import { CardHeader } from "../../../common/components/htmlTags/Card/CardHeader";
import { Form } from "../../../common/components/htmlTags/Form";
import { ConfigureCouponSelection } from "./ConfigureCouponSelection";
import "./ConfigureCoupon.css";
import { message } from "../../../constants/messages";
import { useFormik } from "formik";
import { configureCouponInitialValue } from "../ConfigureCouponStateInitialization";
import { ConfigureCouponInitialValue } from "../configureCouponType";
import { useCallback, useEffect } from "react";
import useCommonData from "../../../hooks/useCommon";
import { setLoaderState } from "../../../common/commonSlice";
import {
  couponConfiguration,
  getCouponConfiguration,
  setCouponConfiguration,
  submitCouponConfiguration,
} from "../couponConfigSlice";
import { useAppSelector } from "../../../../app/hooks";
import { SuccessToaster } from "../../../../helpers/toastHelper";

const ConfigureCoupon = () => {
  const { dispatch, currentUserInfo } = useCommonData();
  const couponConfig = useAppSelector(couponConfiguration);
  const formik = useFormik({
    initialValues: {
      ...configureCouponInitialValue,
    } as ConfigureCouponInitialValue,
    enableReinitialize: false,
    onSubmit: async (values) => {
      let couponData = { ...values };
      couponData.multiple_coupons_enabled =
        values.multiple_coupons_enabled === "true" ? true : false;
      if (!couponData.multiple_coupons_enabled) {
        delete couponData.discount_application_method;
        delete couponData.order_of_application;
      }
      submitCouponConfigurationData(couponData);
    },
  });

  const multipleCoupons = [
    {
      label: (
        <div className="configure-coupon-options">
          <div>Enable</div>
          <div className="grey-color">
            This will allow redemption of multiple coupons of the same type.
          </div>
        </div>
      ),
      value: "true",
    },
    {
      label: (
        <div className="configure-coupon-options">
          <div>Disable</div>
          <div className="grey-color">{`This will only allow for a single coupon of the same type on a subscription at a time (ie. one percentage and one fixed amount coupon only allowed).`}</div>
        </div>
      ),
      value: "false",
    },
  ];

  const couponOrder = [
    {
      label: (
        <div className="configure-coupon-options">
          <div>Apply percentage first</div>
          <div className="grey-color">
            This will apply all percentage discounts first, followed by any
            fixed amount discounts.
          </div>
        </div>
      ),
      value: "PERCENTAGE_FIRST",
    },
    {
      label: (
        <div className="configure-coupon-options">
          <div>Apply fixed amount first</div>
          <div className="grey-color">
            This will apply all fixed amount discounts first, followed by any
            percentage discounts.
          </div>
        </div>
      ),
      value: "FIXED_AMOUNT_FIRST",
    },
  ];

  const multiplePercentageOrder = [
    {
      label: (
        <div className="configure-coupon-options">
          <div>Apply to the total taxable amount</div>
          <div className="grey-color">
            This will apply the total sum of all coupon discounts to the overall
            taxable amount.
          </div>
        </div>
      ),
      value: "FULL_TAXABLE_AMOUNT",
    },
    {
      label: (
        <div className="configure-coupon-options">
          <div>Compound the discounts</div>
          <div className="grey-color">
            This will apply coupon discounts one at a time, each discounting the
            previous taxable amount.
          </div>
        </div>
      ),
      value: "COMPOUND_DISCOUNTS",
    },
  ];

  useEffect(() => {
    fetchCouponConfiguration();
  }, [currentUserInfo.organization_id]);

  const setFormikValue = (data: any) => {
    formik.setValues({
      multiple_coupons_enabled: data.multiple_coupons_enabled,
      order_of_application: data.order_of_application,
      discount_application_method: data.discount_application_method,
    });
  };
  const fetchCouponConfiguration = useCallback(async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      getCouponConfiguration({ orgId: currentUserInfo.organization_id })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setFormikValue(response);
      } else if ("error" in response) {
      }
    }
  }, [currentUserInfo.organization_id]);

  const submitCouponConfigurationData = async (
    data: ConfigureCouponInitialValue
  ) => {
    dispatch(setLoaderState(true));
    let responseAction = await dispatch(
      submitCouponConfiguration({
        organization_id: currentUserInfo.organization_id,
        ...data,
      })
    );
    dispatch(setLoaderState(false));
    const response = responseAction.payload;
    if (!("error" in response)) {
      SuccessToaster(
        "Coupon Configuration updated successfully.",
        "coupon-config-success-toast"
      );
      if (formik.values.multiple_coupons_enabled) {
        setCouponConfiguration({ ...formik.values });
      } else setCouponConfiguration({ configureCouponInitialValue });
    }
  };
  return (
    <Card>
      <CardHeader>Coupon Configuration</CardHeader>
      <Form onSubmit={() => {}} className="preferences-settings-form w-100 p-3">
        <ConfigureCouponSelection
          selectedValue={`${formik.values.multiple_coupons_enabled}`}
          valuesArray={multipleCoupons}
          message={message().coupon.multiCoupon}
          heading="Multiple coupons for subscriptions"
          formik={formik}
          name="multiple_coupons_enabled"
        />
        {`${formik.values.multiple_coupons_enabled}` === "true" ? (
          <>
            <ConfigureCouponSelection
              selectedValue={formik.values.order_of_application || ""}
              valuesArray={couponOrder}
              message={message().coupon.couponOrder}
              heading="Coupon Order of Application"
              formik={formik}
              name="order_of_application"
            />
            <ConfigureCouponSelection
              selectedValue={formik.values.discount_application_method || ""}
              valuesArray={multiplePercentageOrder}
              message={message().coupon.percentageCouponOrder}
              heading="Multiple Percentage Discounts"
              formik={formik}
              name="discount_application_method"
            />
          </>
        ) : null}
        <Section className="form-button-section mt-3">
          <Container
            className="button-fields form-button-wrapper"
            id="form-btn-allocation-date"
          >
            <SaveButton
              type="submit"
              isDisabled={false}
              buttonContent="Save"
              buttonClassName=""
              onClick={(e) => {
                e.preventDefault();
                formik.submitForm();
              }}
            />
            <SecondaryButton
              type="button"
              isDisabled={false}
              buttonContent="Cancel"
              onClick={() => setFormikValue(couponConfig)}
            />
          </Container>
        </Section>
      </Form>
    </Card>
  );
};
export default ConfigureCoupon;
