import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { Prefixes } from "../../../../types";
import { currentUserSelector, getPrefix } from "../../../common/commonSlice";
import "../CustomizePrefix.css";
import CustomizePrifixItem from "./CustomizePrifixItem";

function CustomizePrefix() {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const dispatch = useAppDispatch();
  const [prefixes, setPrefixes] = useState<Prefixes>({
    bill_prefix: null,
    invoice_prefix: null,
    dn_prefix: null,
    cn_prefix: null,
    payments_prefix: null,
    receipts_prefix: null,
    journal_prefix: null,
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setPrefixes({ ...prefixes, [name]: value.toUpperCase() });
  };

  const getOrgPrefixes = async () => {
    const updatePrefixResponseAction = await dispatch(
      getPrefix({
        orgId: currentUserInfo.organization_id,
      })
    );
    
    const updatePrefixResponse = updatePrefixResponseAction.payload;
    if (
      Object.keys(updatePrefixResponseAction).length &&
      !("error" in updatePrefixResponseAction)
    ) {
      const { organization_id, id, ...rest } = updatePrefixResponse;
      setPrefixes({ ...rest });
    }
  };
  useEffect(() => {
    getOrgPrefixes();
  }, [currentUserInfo.organization_id]);

  const handleClose = () => {
    getOrgPrefixes();
  };

  return (
    <>
      <form
        action=""
        name="cutomize-prefix-form"
        id="cutomize-prefix-form"
        className="cutomize-prefix-form w-100"
      >
        <div className="fields-wrapper">
          {/* Invoice Prefix */}
          <div className="row g-0">
            <div className="col">
              <CustomizePrifixItem
                title="Invoice Prefix"
                currentValue={
                  prefixes.invoice_prefix ? prefixes.invoice_prefix : ""
                }
                inputId="inv-prefix"
                isTooltip={false}
                tooltipText=""
                name="invoice_prefix"
                handleChange={handleChange}
                handleClose={handleClose}
                prefixes={prefixes}
              />
            </div>
          </div>
          {/* Bill Prefix */}
          <div className="row g-0">
            <div className="col">
              <CustomizePrifixItem
                title="Bill Prefix"
                currentValue={prefixes.bill_prefix ? prefixes.bill_prefix : ""}
                inputId="bill-prefix"
                isTooltip={false}
                tooltipText=""
                name="bill_prefix"
                handleChange={handleChange}
                handleClose={handleClose}
                prefixes={prefixes}
              />
            </div>
          </div>
          {/* Payments Recieved Prefix */}
          <div className="row g-0">
            <div className="col">
              <CustomizePrifixItem
                title="Payments Recieved Prefix"
                currentValue={
                  prefixes.receipts_prefix ? prefixes.receipts_prefix : ""
                }
                inputId="pr-prefix"
                isTooltip={false}
                tooltipText="Lorem ipsum"
                name="receipts_prefix"
                handleClose={handleClose}
                handleChange={handleChange}
                prefixes={prefixes}
              />
            </div>
          </div>
          {/* Payments Made Prefix */}
          <div className="row g-0">
            <div className="col">
              <CustomizePrifixItem
                title="Payments Made Prefix"
                currentValue={
                  prefixes.payments_prefix ? prefixes.payments_prefix : ""
                }
                inputId="pm-prefix"
                isTooltip={false}
                tooltipText=""
                name="payments_prefix"
                handleClose={handleClose}
                handleChange={handleChange}
                prefixes={prefixes}
              />
            </div>
          </div>
          {/* Credit Note Prefix */}
          <div className="row g-0">
            <div className="col">
              <CustomizePrifixItem
                title="Credit Note Prefix"
                currentValue={prefixes.cn_prefix ? prefixes.cn_prefix : ""}
                inputId="cn-prefix"
                isTooltip={false}
                tooltipText=""
                name="cn_prefix"
                handleClose={handleClose}
                handleChange={handleChange}
                prefixes={prefixes}
              />
            </div>
          </div>
          {/* Debit Note Prefix */}
          <div className="row g-0">
            <div className="col">
              <CustomizePrifixItem
                title="Debit Note Prefix"
                currentValue={prefixes.dn_prefix ? prefixes.dn_prefix : ""}
                inputId="dn-prefix"
                isTooltip={false}
                tooltipText=""
                name="dn_prefix"
                handleClose={handleClose}
                handleChange={handleChange}
                prefixes={prefixes}
              />
            </div>
          </div>
          {/* Journal Voucher Prefix */}
          <div className="row g-0">
            <div className="col">
              <CustomizePrifixItem
                title="Journal Entry Prefix"
                currentValue={
                  prefixes.journal_prefix ? prefixes.journal_prefix : ""
                }
                inputId="jv-prefix"
                isTooltip={false}
                tooltipText=""
                name="journal_prefix"
                handleClose={handleClose}
                handleChange={handleChange}
                prefixes={prefixes}
              />
            </div>
          </div>
          {/* Subscription Prefix */}
          <div className="row g-0">
            <div className="col">
              <CustomizePrifixItem
                title="Subscription Prefix"
                currentValue={
                  prefixes.subscription_prefix ? prefixes.subscription_prefix : ""
                }
                inputId="jv-prefix"
                isTooltip={false}
                tooltipText=""
                name="subscription_prefix"
                handleClose={handleClose}
                handleChange={handleChange}
                prefixes={prefixes}
              />
            </div>
          </div>
        </div>
      </form>
      {/* {isLoading && <Loader />} */}
    </>
  );
}

export default CustomizePrefix;
