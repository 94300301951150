import * as React from "react";
import { colors } from "../../../constants/colors";
import { LineCouponProps, ObjectType } from "../../../../types";
import { DeleteIcon } from "../customSvgIcons/deleteIcon";
import { Label } from "../htmlTags/Label";
import InfoTooltip from "../InfoTooltip";
import { message } from "../../../constants/messages";

function LineCoupon({
  index,
  deleteCouponContainer,
  item,
  couponError,
}: LineCouponProps) {

  return (
    <div className="item-row" key={index}>
      <div className="row g-0 coupon-detail-div">
        <Label htmlFor="coupon_name" className="coupon-label" >
         {item.name}
        </Label>
        <div className="added-coupon-duration">
          {item.duration}
        </div>
        {couponError.length > 0
        && couponError.filter((data: ObjectType) => data.index === item.id).length > 0
        ? <span className="error m-t-20">{`* ${couponError.filter((data: ObjectType) => data.index === item.id)[0].coupon || ''}`}</span> : null
        }
        </div>
      <div className="row g-0">
        <div className="item-col tax-amt-col w-325 p-0">
          <div className="label-wrapper text-nowrap">
            <label htmlFor="">Amount</label>
            <InfoTooltip title={message().subscription.couponAmmount} />
          </div>
          <div className="d-flex taxable-amount align-items-center justify-content-between w-100 py-2 text-nowrap">
            <span
              className="amount-taxable-nil"
              id={"taxable_amount_span_" + index}
            >
              {`${item.discount} OFF`}
            </span>
            <a
              className="delete-taxable delete-btn"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                deleteCouponContainer(index);
              }}
            >
              <DeleteIcon color={colors.slate} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(LineCoupon);
