import { Api } from "../../../constants";
import {
  get,
  post,
  postFormData,
  put,
  remove,
} from "../../../helpers/apiHelper";
import {
  TaxableAmountCalculationValues,
  TotalAmountCalculationValues,
  BillValues,
  CreditApplyToBill,
} from "../../../types";

/**
 * API call for getting all bills.
 */
export async function getBillList(data: {
  page: number;
  itemsPerPage: number;
  dateSortOrder: string;
  orgId: number;
  vendorList: number[];
  orgIds: number[];
  statusList: string[];
  startDate?: string;
  endDate?: string;
  startDueDate?: string;
  endDueDate?: string;
}) {
  let url =
    Api.bills +
    "bill/list" +
    `?page_num=${data.page}&page_size=${
      data.itemsPerPage
    }&current_organization=${data.orgId}${
      data.startDate ? `&start_date=${data.startDate.trim()}` : ""
    }${data.endDate ? `&end_date=${data.endDate.trim()}` : ""}${
      data.startDueDate ? `&start_due_date=${data.startDueDate.trim()}` : ""
    }${
      data.endDueDate ? `&end_due_date=${data.endDueDate.trim()}` : ""
    }`.trim();
  if (data.dateSortOrder !== "") {
    url += `&date_sort_order=${data.dateSortOrder}`;
  }
  if (data.vendorList.length !== 0) {
    const vendorIds = data.vendorList
      .map((vendorId) => {
        return `&vendor_ids=${vendorId}`;
      })
      .join("");
    url += vendorIds;
  }
  if (data.orgIds.length !== 0) {
    const orgIds = data.orgIds
      .map((orgId) => {
        return `&org_ids=${orgId}`;
      })
      .join("");
    url += orgIds;
  }
  if (data.statusList.length !== 0) {
    const statuses = data.statusList
      .map((status) => {
        return `&status_list=${status}`;
      })
      .join("");
    url += statuses;
  }
  const result = await get(url);
  return result;
}

/**
 * API call for deleting bill
 */
export async function deleteBill(data: {
  deleteBillId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.bills +
      `bill/delete/${data.deleteBillId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for creating bill
 */
export async function createNewBill(data: {
  values: BillValues;
  status: string;
  orgId: number;
}) {
  const result = await post(
    Api.bills +
      `bill/create?bill_status=${data.status}&current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call for edit bill
 */
export async function editBill(data: {
  id: number;
  editBillInputs: BillValues;
  status: string;
  orgId: number;
}) {
  const result = await put(
    Api.bills +
      "bill/edit/" +
      data.id +
      `?bill_status=${data.status}&current_organization=${data.orgId}`,
    data.editBillInputs
  );
  return result;
}

/**
 * API call for delete bill item
 */
export async function deleteBillItem(data: {
  billId: number;
  itemId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.bills +
      "bill-item/delete/" +
      data.billId +
      "/" +
      data.itemId +
      `?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for delete bill recipients
 */
export async function deleteBillRecipient(data: {
  billId: number;
  emailIds: number[];
  orgId: number;
}) {
  const result = await remove(
    Api.bills +
      "bill-recipient/delete/" +
      data.billId +
      `?current_organization=${data.orgId}`,
    data.emailIds
  );
  return result;
}
/**
 * API call for get bill number
 */
export async function getBillNumber(orgId: number) {
  const result = await get(
    Api.bills + `new-bill-number?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call for checking the bill number exist or not
 */
export async function billNumberExist(data: {
  billNumber: string;
  orgId: number;
  billId: number;
}) {
  const result = await get(
    Api.bills +
      "bill-number-exist?bill_number=" +
      data.billNumber +
      `&bill_id=${data.billId}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for delete bill item tax
 */
export async function deleteBillItemTax(data: {
  itemId: number;
  taxId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.bills +
      "bill-item-tax/delete/" +
      data.itemId +
      "/" +
      data.taxId +
      `?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for getting bill detail
 */
export async function getBillDetails(data: { billId: number; orgId: number }) {
  const result = await get(
    Api.bills + `bill/detail/${data.billId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for sending bill to contact details
 */
export async function sendBill(data: {
  billId: number;
  emails: string[];
  orgId: number;
}) {
  const result = await post(
    Api.bills +
      `send-bill?bill_id=${data.billId}&current_organization=${data.orgId}`,
    data.emails
  );
  return result;
}

/**
 * API call for calculating taxable amount
 */

export async function calculateTaxableAmount(data: {
  values: TaxableAmountCalculationValues;
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.bills + `bill-item/taxable-amount?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}

/**
 * API call for calculating bill amount
 */
export async function calculateAmount(data: {
  values: TotalAmountCalculationValues;
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.bills + `bill/amount-calculation?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}

/**
 * Api to get bill pdf link
 */
export async function billPdfLink(data: { billId: number; orgId: number }) {
  const result = await get(
    Api.bills +
      "download-link?bill_id=" +
      data.billId +
      `&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to upload bill attachments
 */
export async function billAttachments(data: { file: FormData; orgId: number }) {
  const result = await postFormData(
    Api.bills + `bill-attachments?current_organization=${data.orgId}`,
    data.file
  );
  return result;
}

/**
 * Api to delete bill attachments
 */
export async function billAttachmentsDelete(data: {
  billId: number;
  fileId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.bills +
      `bill-attachments/${data.billId}?file_id=${data.fileId}&current_organization=${data.orgId}`
  );
  return result;
}
/**
 * Api to get the transaction limit status
 */
export async function transactionLimitStatus(orgId: number) {
  const result = await get(
    Api.bills + `transaction-limit-status?current_organization=${orgId}`
  );
  return result;
}

/**
 * Api to get apply credit list of bill
 */
export async function applyCreditList(data: { billId: number; orgId: number }) {
  const result = await get(
    Api.bills +
      `apply-debits/list?bill_id=${data.billId}&current_organization=${data.orgId}`
  );
  return result;
}
/**
 * Api to calculate credits
 */
export async function creditsCalculation(data: {
  orgId: number;
  values: {
    bill_balance: number;
    allocation_list: number[];
    currency_id: number | null;
  };
  signal?: AbortSignal;
}) {
  const result = await post(
    Api.bills + `apply-debits/calculate?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}
/**
 * Api to apply credits to bill
 */
export async function applyCreditsToBills(data: {
  bill_id: number;
  orgId: number;
  values: CreditApplyToBill[];
}) {
  const result = await put(
    Api.bills +
      `apply-debits/edit?bill_id=${data.bill_id}&current_organization=${data.orgId}`,
    data.values
  );
  return result;
}
/**
 * Api to create write off against bill
 */
export async function writeOffCreate(data: {
  orgId: number;
  write_off_values: {
    amount: string | number;
    account_id: number | null;
    bill_id: number | null;
    is_partial: boolean;
  };
}) {
  const result = await post(
    Api.bills + `write-off/create?current_organization=${data.orgId}`,
    data.write_off_values
  );
  return result;
}
/**
 * Api to update write off against a bill
 */
export async function writeOffUpdate(data: {
  orgId: number;
  write_off_id: number | null;
  write_off_values: {
    amount: string | number;
    is_partial: boolean;
  };
}) {
  const result = await put(
    Api.bills +
      `write-off/edit/${data.write_off_id}?current_organization=${data.orgId}`,
    data.write_off_values
  );
  return result;
}
/**
 * Api to update write off against a bill
 */
export async function writeOffDetails(data: {
  orgId: number;
  bill_id: number | null;
}) {
  const result = await get(
    Api.bills +
      `write-off/details?bill_id=${data.bill_id}&current_organization=${data.orgId}`
  );
  return result;
}
/**
 * Api to update write off against a bill
 */
export async function writeOffDelete(data: {
  orgId: number;
  write_off_id: number | null;
}) {
  const result = await remove(
    Api.bills +
      `write-off/cancel/${data.write_off_id}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to get journal for reporting tags
 */
export async function getReportingTagJournals(data: {
  billId: number;
  orgId: number;
}) {
  const result = await get(
    Api.bills +
      `reporting-tag/journal-generate?bill_id=${data.billId}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to update reporting tag
 */
export async function putReportingTags(data: {
  billId: number;
  orgId: number;
  payload: any;
}) {
  const result = await put(
    Api.bills +
      `reporting-tag/edit/${data.billId}?bill_id=${data.billId}&current_organization=${data.orgId}`,
    data.payload
  );
  return result;
}

/**
 * Api to get tags which are associated to a particular bill
 */
export async function getTagsOptionsAssociatedToBills(data: {
  billIds: number[];
  orgId: number;
}) {
  const idsUrl = data.billIds.map((id) => {
    return `bill_ids=${id}`;
  });
  const result = await get(
    Api.bills +
      `reporting-tag/bill/payment-association?current_organization=${
        data.orgId
      }${idsUrl.length > 0 ? `&${idsUrl.join("&")}` : ""}`
  );
  return result;
}

/**
 * Api to mark bill as void
 */
export async function billVoidMarker(data: {
  billId: number | null;
  orgId: number;
}) {
  const result = await put(
    Api.bills +
      `bill/mark-void/${data.billId}?bill_id=${data.billId}&current_organization=${data.orgId}`
  );
  return result;
}
