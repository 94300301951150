import { useCallback, useRef } from "react";
import { fetchAuditDetails } from "../../../../home/chartOfAccounts/coaSlice";
import useCommonData from "../../../../hooks/useCommon";

type History = {
  details: string;
  oldValue: string;
  newValue: string;
};
type Row = {
  dateAndTime: JSX.Element;
  action: string;
  updatedBy: string;
  history: History[];
};
type AuditTrailHookProps = {
  pageToken: string | null;
  setPrevPageToken: (value: any) => void;
  setHasMore: (value: any) => void;
  setIsLoading: (value: boolean) => void;
  setRows: (value: any) => void;
  transactionType: string;
  transactionId: number;
  rows: Row[];
};

export const useAuditTrail = () => {
  const { currentUserInfo, dispatch } = useCommonData();
  const isFetching = useRef(false);

  const auditTrail = useCallback(
    async (props: AuditTrailHookProps) => {
      if (isFetching.current) return;
      if (props.transactionId === 0 || !props.transactionId) return;
      if (props.pageToken === null && props.rows.length > 0) return;
      isFetching.current = true;
      props.setIsLoading(true);
      try {
        const responseAction = await dispatch(
          fetchAuditDetails({
            transactionType: props.transactionType,
            transactionId: props.transactionId,
            orgId: currentUserInfo.organization_id,
            pageToken: props.pageToken,
          })
        );

        if (responseAction.payload) {
          const response = responseAction.payload;
          const parsedRows = response.Changlog.map((log: any) => {
            const date = new Date(`${log.log_time}Z`);
            const dateString = date.toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            });
            const timeString = date.toLocaleTimeString("en-US", {
              hour12: false,
            });
            const dateAndTime = (
              <span>
                <span className="audit-log-date-string">{dateString}</span> -{" "}
                <span className="audit-log-time-string">{timeString}</span>
              </span>
            );

            const parseNestedChanges = (nestedObject: any) => {
              return Object.entries(nestedObject).map(
                ([key, value]: [string, any]) => {
                  const oldValue = value.old ?? "-";
                  const newValue = value.new ?? "-";
                  let prefix = "";
                  if (
                    key.includes("Removed") ||
                    key.includes("Added") ||
                    log.operation === "insert"
                  ) {
                    prefix = "";
                  } else if (oldValue === "-" && newValue !== "-") {
                    prefix = "Added";
                  } else if (oldValue !== "-" && newValue === "-") {
                    prefix = "Removed";
                  } else {
                    prefix = "Changed";
                  }
                  return {
                    details: `${prefix} ${key.replace(/_/g, " ")}`,
                    oldValue:
                      typeof oldValue === "boolean"
                        ? String(oldValue)
                        : oldValue,
                    newValue:
                      typeof newValue === "boolean"
                        ? String(newValue)
                        : newValue,
                  };
                }
              );
            };

            let history = Object.keys(log.changes).flatMap((key) => {
              const value = log.changes[key];
              if (Array.isArray(value)) {
                return value.flatMap(parseNestedChanges);
              } else {
                const keyWithoutUnderscores = key.replace(/_/g, " ");
                const oldValue = value.old ?? "-";
                const newValue = value.new ?? "-";
                let prefix = "";
                if (
                  keyWithoutUnderscores.includes("Removed") ||
                  keyWithoutUnderscores.includes("Added") ||
                  log.operation === "insert"
                ) {
                  prefix = "";
                } else if (oldValue === "-" && newValue !== "-") {
                  prefix = "Added";
                } else if (oldValue !== "-" && newValue === "-") {
                  prefix = "Removed";
                } else {
                  prefix = "Changed";
                }
                return [
                  {
                    details: `${prefix} ${keyWithoutUnderscores}`,
                    oldValue:
                      typeof oldValue === "boolean"
                        ? String(oldValue)
                        : oldValue,
                    newValue:
                      typeof newValue === "boolean"
                        ? String(newValue)
                        : newValue,
                  },
                ];
              }
            });

            return {
              dateAndTime,
              action: log.action,
              updatedBy: log.user_name,
              history,
            };
          });

          props.setRows((prevRows: any) => [...prevRows, ...parsedRows]);
          props.setPrevPageToken(response.prev_page_token || null);
          props.setHasMore(!!response.prev_page_token);
        }
      } catch (error) {
      } finally {
        props.setIsLoading(false);
        isFetching.current = false;
      }
    },
    [currentUserInfo.organization_id, dispatch]
  );

  return { auditTrail };
};
