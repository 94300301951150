import { ObjectType } from "../../../../../../../types";

export const getJVTableHeader = () => {
  return [
    {
      Header: "Account",
      accessor: "show.account_name",
      className: "rt-account",
    },
    {
      Header: "Debit",
      accessor: "show.debit",
      className: "rt-debit",
    },
    {
      Header: "Credit",
      accessor: "show.credit",
      className: "rt-credit",
    },
  ];
};

export const getUpdatedJournals = (
  journals: any,
  accountId: number,
  splitType: string,
  tagsList: any,
  index?: number | null
) => {
  let options_list = tagsList.map(
    (tag: { option_id: number; amount: number }) => {
      return {
        option_id: tag.option_id,
        amount: Number(tag.amount),
      };
    }
  );
  let newJournal = [...journals] as any;
  const journalIndex = index ? index : 0;
  newJournal[journalIndex].split_type = splitType;
  newJournal[journalIndex].options_list = options_list;
  return newJournal;
};
