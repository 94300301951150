import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useState,
  useRef,
} from "react";
import { currentUserSelector } from "../../commonSlice";
import { useAppSelector } from "../../../../app/hooks";
import { ArrowDropBlue } from "../../../../assets/images";
import { useAuditTrail } from "./hooks/useAuditTrail";
import AuditLogTableContainer from "./AuditLogTableContainer";
import Loader from "../../../home/components/Loader";
import "./AuditTrail.css";

type Props = {
  title: string;
  transactionType: string;
  transactionId: number;
  reRenderFlag?: number;
};
type History = {
  details: string;
  oldValue: string;
  newValue: string;
};
type Row = {
  dateAndTime: JSX.Element;
  action: string;
  updatedBy: string;
  history: History[];
};

const AuditTrail: ForwardRefRenderFunction<
  { auditTrail: () => Promise<void> },
  Props
> = (props, ref) => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [rows, setRows] = useState<Row[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [prevPageToken, setPrevPageToken] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const initialRender = useRef(true);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const { auditTrail } = useAuditTrail();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !isLoading) {
          auditTrail({
            pageToken: prevPageToken,
            setHasMore,
            setIsLoading,
            setPrevPageToken,
            setRows,
            rows,
            transactionId: props.transactionId,
            transactionType: props.transactionType,
          });
        }
      },
      { threshold: 1.0 }
    );

    const target = document.querySelector(".table-section");
    if (target) {
      observer.observe(target);
      observerRef.current = observer;
    }

    return () => {
      if (target && observerRef.current) {
        observerRef.current.unobserve(target);
        observerRef.current = null;
      }
    };
  }, [
    auditTrail,
    prevPageToken,
    hasMore,
    isLoading,
    props.transactionId,
    props.transactionType,
    rows,
  ]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      setRows([]);
      setPrevPageToken(null);
      setHasMore(true);
      auditTrail({
        pageToken: null,
        setHasMore,
        setIsLoading,
        setPrevPageToken,
        setRows,
        rows: [],
        transactionId: props.transactionId,
        transactionType: props.transactionType,
      });
    }
  }, [
    auditTrail,
    props.transactionType,
    props.transactionId,
    currentUserInfo.organization_id,
  ]);

  const headers = ["DATE & TIME", "ACTION", "UPDATED BY"];
  const viewDetailsText = "View Details";
  const hideDetailsText = "Hide Details";
  const viewDetailsImage = ArrowDropBlue;
  const detailsHeaders = ["DETAILS", "OLD VALUE", "NEW VALUE"];

  return (
    <div
      className={props.title === "Audit Trail" ? "audit-view-container" : ""}
      id="audit-view-container"
    >
      <div className="jv-postings-section">
        <h2>{props.title}</h2>
        <div className="table-section">
          {isLoading ? <Loader /> : null}
          <AuditLogTableContainer
            headers={headers}
            rows={rows}
            detailsColumn="details"
            viewDetailsText={viewDetailsText}
            hideDetailsText={hideDetailsText}
            viewDetailsImage={viewDetailsImage}
            detailsHeaders={detailsHeaders}
          />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(AuditTrail);
