import { DateFormatHandler } from "../../../../helpers/helper";
import { getValidPastDate } from "../../../../helpers/subscriptionHelper";
import { ObjectType } from "../../../../types";

/**
 * Validate reactivation form values
 */
export const validateReactivateForm = (
    reactivateValue: any,
    subscriptionPeriod: string
  ) => {
    const errors: ObjectType = {};
    if (
      reactivateValue.reactivation_date &&
      reactivateValue.reactivation_date === "Invalid date"
    ) {
      errors.reactivation_date = "Please enter valid reactivation date";
    }
    if (reactivateValue.reactivation_date === "") {
      errors.reactivation_date = "Please enter reactivation date";
    }
    if (
      reactivateValue.reactivation_date && reactivateValue.reactivation_date !== "Invalid date"
    ) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const targetDate = new Date(reactivateValue.reactivation_date);
      targetDate.setHours(0, 0, 0, 0);
      const pastValidDate =new Date(getValidPastDate(subscriptionPeriod))
      pastValidDate.setHours(0, 0, 0, 0);

      if (targetDate > currentDate || targetDate < pastValidDate)
      errors.reactivation_date = "Please enter valid reactivation date";
    }
    if (
      reactivateValue.trial_end_date &&
      reactivateValue.trial_end_date === "Invalid date"
    ) {
      errors.trial_end_date = "Please enter valid trial end date";
    }
    if (reactivateValue.invoicing_cycle === "FIXED") {
      if (
        !reactivateValue.invoicing_cycle_fixed_count ||
        reactivateValue.invoicing_cycle_fixed_count < 1
      ) {
        errors.invoicing_cycle_fixed_count = "Please enter a valid value";
      }
    }
    return errors;
  };
  
  /**
 * Validate cancel form values
 */
export const validateCancelForm = (
  cancelledValue: any,
  status: string,
  subscriptionEndDate: string | null
) => {
  const errors: ObjectType = {};
  if (
    cancelledValue.bank_charges_account_id === 0 && ['stopped', 'active'].includes(status.toLowerCase()) && cancelledValue.unpaid_amount === "WRITE_OFF"
  ) {
    errors.bank_charges_account_id = "Please choose account";
  }

  if ( cancelledValue.cancel_status_option === "SPECIFIC_DATE") {
    if(cancelledValue.cancel_date === "Invalid date") {
      errors.cancel_date = "Please enter a valid cancel date";
    }
    if(cancelledValue.cancel_date === "Empty date") {
      errors.cancel_date = "Please enter cancel date";
    }
  }
  
  if (!!subscriptionEndDate) {
    const endDate = new Date(DateFormatHandler(subscriptionEndDate));
    endDate.setHours(0, 0, 0, 0);
    const targetDate = new Date(cancelledValue.cancel_date);
    targetDate.setHours(0, 0, 0, 0);
    if (targetDate > endDate) {
      errors.cancel_date = "Cancel date can't be beyond to invoicing cycle";
    }
  }
  return errors;
};


  /**
 * Validate stop form values
 */
  export const validateStopForm = (
    stopValue: any,
  ) => {
    const errors: ObjectType = {};
    // need to integarted the validation for date
    return errors;
  };