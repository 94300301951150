import React, { useEffect, useState } from "react";
import {
  PlusSmallImg,
  AccountsIconSmall,
  PurchaseIconSmall,
  SalesIconSmall,
  UsersIconSmall,
  SubscriptionSmallIcon
} from "../../../../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import PlusIconMenuSmall from "../../../../common/components/PlusIconMenuSmall";
import { IsFeatureEnabled } from "../../../../../helpers/featureFlagHelper";
import "./addDropdown.css";
import { useMediaQuery } from "@react-hook/media-query";
type PermissionObject = {
  sales: string[];
  purchases: string[];
  accounts: string[];
  users: string[];
  sudscription: string[];
};
const AddDropdown = (
  orgId: any,
  userInfoId: any,
  disabledFeatureList: Object[]
) => {
  const mobDevices = useMediaQuery("(max-width: 767px)");
  const navigate = useNavigate();

  const [userPermissions, setPermissions] = useState<PermissionObject>({
    sales: [],
    purchases: [],
    accounts: [],
    users: [],
    sudscription: []
  });

  const settingPermission = () => {
    let organizationRole = [];
    organizationRole = JSON.parse(
      sessionStorage.getItem("organizationRole") || "{}"
    );
    let rolesSalesArray: string[] = [];
    let rolesPurchasesArray: string[] = [];
    let rolesAccountsArray: string[] = [];
    let rolesUserArray: string[] = [];
    let rolesSudscriptionArray: string[] = [];
    Array.isArray(organizationRole) &&
      organizationRole.map((orgPermission: any) => {
        if (orgPermission.module === "Sales") {
          if (orgPermission?.permission === "Create") {
            rolesSalesArray.push(orgPermission?.subModule);
          }
        }
        if (orgPermission.module === "Purchases") {
          if (orgPermission?.permission === "Create") {
            rolesPurchasesArray.push(orgPermission?.subModule);
          }
        }
        if (orgPermission.module === "Users and Roles") {
          if (orgPermission?.permission === "Create") {
            rolesUserArray.push(orgPermission?.subModule);
          }
        }
        if (orgPermission.module === "Subscription") {
          if (orgPermission?.permission === "Create") {
            rolesSudscriptionArray.push(orgPermission?.subModule);
          }
        }
        if (orgPermission.module === "Accounts") {
          if (orgPermission?.permission === "Create") {
            rolesAccountsArray.push(orgPermission?.subModule);
          }
        }
        if (orgPermission.module === "Contacts") {
          if (orgPermission?.permission === "Create") {
            if (orgPermission?.subModule === "Customer") {
              rolesSalesArray.push(orgPermission?.subModule);
            } else {
              rolesPurchasesArray.push(orgPermission?.subModule);
            }
          }
        }
      });
    setPermissions({
      sales: rolesSalesArray,
      purchases: rolesPurchasesArray,
      accounts: rolesAccountsArray,
      users: rolesUserArray, 
      sudscription: rolesSudscriptionArray
    });
  };
  useEffect(() => {
    settingPermission();
  }, [orgId, userInfoId]);

  $(function () {
    // if (mobDevices) {
    $("#megamenuButton").on("show.bs.dropdown", () => {
      if (mobDevices && $("body").css("overflow") !== "hidden") {
        $("body").css("overflow", "hidden");
      }
    });
    $("#megamenuButton").on("hide.bs.dropdown", () => {
      if (mobDevices && $("body").css("overflow") === "hidden") {
        $("body").css("overflow", "auto");
      }
    });
    // }
    // else {
    //   if($("body").css("overflow") === "hidden") {
    //     $("body").css("overflow", "auto");
    //   }
    // }
  });

  
  
  return userPermissions.sales.length >= 1 ||
    userPermissions?.purchases?.length >= 1 ||
    userPermissions?.accounts?.length >= 1 ||
    userPermissions?.users?.length >= 1 ? (
    <div className="nav-item dropdown-list-item add-items-link">
      <a
        className="nav-link dropdown-toggle nav-add-button position-relative m-0"
        href="#"
        role="button"
        id="megamenuButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={(e) => {
          e.preventDefault();
          settingPermission();
        }}
        style={{ width: "115px" }}
      >
        <img src={PlusSmallImg} className="Group" alt="plus-small-img" />
        <span className="nav-add-button-text">Add</span>
      </a>
      <div className="dropdown-menu mega-menu" aria-labelledby="megamenuButton">
        {" "}
        {userPermissions?.sales?.length >= 1 &&
        (IsFeatureEnabled("Customer", disabledFeatureList) ||
          IsFeatureEnabled("Payments Received", disabledFeatureList) ||
          IsFeatureEnabled("Invoices", disabledFeatureList) ||
          IsFeatureEnabled("Credit Notes", disabledFeatureList) ||
          IsFeatureEnabled("Recurring Invoices", disabledFeatureList)) ? (
          <ul className="mMenu-List">
            <li className="section-title">
              <h5>
                <img width="16" height="16" src={SalesIconSmall} alt="Sales" />
                Sales
              </h5>
            </li>
            <li>
              {userPermissions?.sales?.includes("Customer") &&
              IsFeatureEnabled("Customer", disabledFeatureList) ? (
                <Link to="/customers/create" className="mMenu-link">
                  <PlusIconMenuSmall />
                  <span className="link-text">Customers</span>
                </Link>
              ) : null}
            </li>
            <li>
              {userPermissions?.sales?.includes("Invoices") &&
              IsFeatureEnabled("Invoices", disabledFeatureList) ? (
                <Link to="/invoice/create" className="mMenu-link">
                  <PlusIconMenuSmall />
                  <span className="link-text">Sales Invoice</span>
                </Link>
              ) : null}
            </li>
            <li>
              {userPermissions?.sales?.includes("Credit Notes") &&
              IsFeatureEnabled("Credit Notes", disabledFeatureList) ? (
                <Link to="/credit-note/create" className="mMenu-link">
                  <PlusIconMenuSmall />
                  <span className="link-text">Credit Notes</span>
                </Link>
              ) : null}
            </li>
            <li>
              {userPermissions?.sales?.includes("Payments Received") &&
              IsFeatureEnabled("Payments Received", disabledFeatureList) ? (
                <Link to="/payment-received/create" className="mMenu-link">
                  <PlusIconMenuSmall />
                  <span className="link-text">Payment Received</span>
                </Link>
              ) : null}
            </li>
            <li>
              {userPermissions?.sales?.includes("Recurring Invoices") &&
              IsFeatureEnabled("Recurring Invoices", disabledFeatureList) ? (
                <Link to="/recurring-inv/create" className="mMenu-link">
                  <PlusIconMenuSmall />
                  <span className="link-text">Recurring Invoices</span>
                </Link>
              ) : null}
            </li>
          </ul>
        ) : null}
        {userPermissions?.purchases?.length >= 1 &&
        (IsFeatureEnabled("Vendor", disabledFeatureList) ||
          IsFeatureEnabled("Payments Made", disabledFeatureList) ||
          IsFeatureEnabled("Bills", disabledFeatureList) ||
          IsFeatureEnabled("Debit Notes", disabledFeatureList)) ? (
          <ul className="mMenu-List">
            <li className="section-title">
              <h5>
                <img
                  width="16"
                  height="16"
                  src={PurchaseIconSmall}
                  alt="Sales"
                />
                Purchases
              </h5>
            </li>
            <li>
              {userPermissions?.purchases?.includes("Vendor") &&
              IsFeatureEnabled("Vendor", disabledFeatureList) ? (
                <Link to="/vendors/create" className="mMenu-link">
                  <PlusIconMenuSmall />
                  <span className="link-text">Vendor</span>
                </Link>
              ) : null}
            </li>
            <li>
              {userPermissions?.purchases?.includes("Bills") &&
              IsFeatureEnabled("Bills", disabledFeatureList) ? (
                <Link to="/bill/create" className="mMenu-link">
                  <PlusIconMenuSmall />
                  <span className="link-text">Bills</span>
                </Link>
              ) : null}
            </li>
            <li>
              {userPermissions?.purchases?.includes("Debit Notes") &&
              IsFeatureEnabled("Debit Notes", disabledFeatureList) ? (
                <Link to="/debit-note/create" className="mMenu-link">
                  <PlusIconMenuSmall />
                  <span className="link-text">Debit Notes</span>
                </Link>
              ) : null}
            </li>
            <li>
              {userPermissions?.purchases?.includes("Payments Made") &&
              IsFeatureEnabled("Payments Made", disabledFeatureList) ? (
                <Link to="/payment-made/create" className="mMenu-link">
                  <PlusIconMenuSmall />
                  <span className="link-text">Payment Made</span>
                </Link>
              ) : null}
            </li>
          </ul>
        ) : null}
        {userPermissions?.accounts?.length >= 1 &&
        IsFeatureEnabled("Journal Voucher", disabledFeatureList) ? (
          <ul className="mMenu-List">
            <li className="section-title">
              <h5>
                <img
                  width="16"
                  height="16"
                  src={AccountsIconSmall}
                  alt="Sales"
                />
                Accounts
              </h5>
            </li>
            <li>
              {userPermissions?.accounts?.includes("Journal Entry") &&
              IsFeatureEnabled("Journal Entry", disabledFeatureList) ? (
                <Link to="/journal-entry/create" className="mMenu-link">
                  <PlusIconMenuSmall />
                  <span className="link-text">Journal Entry</span>
                </Link>
              ) : null}
            </li>
          </ul>
        ) : null}
        {userPermissions?.users?.includes("Users") &&
        IsFeatureEnabled("Users", disabledFeatureList) ? (
          <ul className="mMenu-List">
            <li className="section-title">
              <h5>
                <img width="16" height="16" src={UsersIconSmall} alt="Sales" />
                Users
              </h5>
            </li>
            <li>
              {userPermissions?.users?.includes("Users") &&
              IsFeatureEnabled("Users", disabledFeatureList) ? (
                <a
                  href="#"
                  className="mMenu-link"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/users", { state: { action: "create" } });
                  }}
                >
                  <PlusIconMenuSmall />
                  <span className="link-text">Invite Users</span>
                </a>
              ) : null}
            </li>
          </ul>
        ) : null}
        {userPermissions?.sudscription?.includes("Subscriptions") &&
        IsFeatureEnabled("Users", disabledFeatureList) ? (
          <ul className="mMenu-List">
            <li className="section-title">
              <h5>
                <img width="16" height="16" src={SubscriptionSmallIcon} alt="Sales" />
                Subscription
              </h5>
            </li>
            <li>
              {userPermissions?.sudscription?.includes("Plans") &&
              IsFeatureEnabled("Plans", disabledFeatureList) ? (
                <a
                  href="#"
                  className="mMenu-link"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/plan/create", { state: { action: "create" } });
                  }}
                >
                  <PlusIconMenuSmall />
                  <span className="link-text">Plans</span>
                </a>
              ) : null}
            </li>
            <li>
              {userPermissions?.sudscription?.includes("Addons") &&
              IsFeatureEnabled("Addons", disabledFeatureList) ? (
                <a
                  href="#"
                  className="mMenu-link"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/addons/create", { state: { action: "create" } });
                  }}
                >
                  <PlusIconMenuSmall />
                  <span className="link-text">Addons</span>
                </a>
              ) : null}
            </li>
            <li>
              {userPermissions?.sudscription?.includes("Coupons") &&
              IsFeatureEnabled("Coupons", disabledFeatureList) ? (
                <a
                  href="#"
                  className="mMenu-link"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/coupons/create", { state: { action: "create" } });
                  }}
                >
                  <PlusIconMenuSmall />
                  <span className="link-text">Coupons</span>
                </a>
              ) : null}
            </li>
            <li>
              {userPermissions?.sudscription?.includes("Subscriptions") &&
              IsFeatureEnabled("Subscriptions", disabledFeatureList) ? (
                <a
                  href="#"
                  className="mMenu-link"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/subscriptions/create", { state: { action: "create" } });
                  }}
                >
                  <PlusIconMenuSmall />
                  <span className="link-text">Subscriptions</span>
                </a>
              ) : null}
            </li>
          </ul>
        ) : null}
      </div>
    </div>
  ) : (
    <></>
  );
};
export default AddDropdown;
