import { ReportTagFormValues } from "../../../../types";
import { SettingsIcon } from "../../../common/components";
import { SideModal } from "../../../common/components/sideModal/SideModal";
import { ModalContext } from "../../../common/components/sideModal/sideModalContext";
import { colors } from "../../../constants/colors";

import { ReportingTagSettingsForm } from "./ReportingTagsSettingsForm";

type Props = {
  tagDetails?: ReportTagFormValues[];
}
export const ReportingTagSettingsFormModal = (props: Props) => {
  return (
    <SideModal
      modalTitle={"Configure Reporting Tags"}
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return <ReportingTagSettingsForm handleClose={onCloseModal} tagDetails={props.tagDetails} />;
          }}
        </ModalContext.Consumer>
      }
      buttonContent={
        <span>
          <SettingsIcon color={colors.booger} margin="0px 5px 0px 0px" />
          Configure Reporting Tags
        </span>
      }
      buttonClass={
        "no-appearance cancel-btn configure-reporting-tag-setting-btn "
      }
      //   wrapperClass={props.wrapperClass ? props.wrapperClass : "w-100"}
      //   onOpenFunction={props.onOpenFunction}
    />
  );
};
