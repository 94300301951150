import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import ReactivateModalSubscription from "../../../common/components/ReactivateModalSubscription";
import { changeSubscriptionStatus, reactivateSubscriptionAction } from "../subscriptionSlice";
import { useFormik } from "formik";
import { getFormatedDate } from "../../../../helpers/helper";
import { Card } from "../../../common/components/htmlTags/Card/Card";
import { CardHeader } from "../../../common/components/htmlTags/Card/CardHeader";
import Dialog from '@mui/material/Dialog';
import Grid from "@mui/material/Grid";
import { Dispatch, SetStateAction, useState } from "react";
import { CardBody } from "../../../common/components/htmlTags/Card/CardBody";
import CloseButton from "../../../common/components/button/CloseButton";
import { Form } from "../../../common/components/htmlTags/Form";
import { validateReactivateForm } from "./ValidationSubscriptionAction";
import { ObjectType } from "../../../../types";

type Props = {
  subscriptionId: number;
  subscriptionName: string;
  subscriptionStatus: string;
  refreshSubscription: () => void;
  organizationId: number;
  didAlertModalOpen?: boolean;
  setDidAlertModalOpen?: Dispatch<SetStateAction<boolean>>;
  subscriptionPeriod: string
};
const SubscriptionReactivate = (props: Props) => {
  const dispatch = useAppDispatch();
  const [reactivateFormErrors, setReactivateFormErrors] = useState<ObjectType>({})

  const modalCancelHandler = () => {
    formik.resetForm()
    if (props.setDidAlertModalOpen) props.setDidAlertModalOpen(false);
    setReactivateFormErrors({})
  };

  const formik = useFormik({
    initialValues: {
      reactivation_date: getFormatedDate(new Date()),
      trial_end_date: '',
      invoicing_cycle: 'FOREVER',
      invoicing_cycle_fixed_count: 1,
      invoicing_option: 'IMMEDIATELY',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      let errors = validateReactivateForm(values, props.subscriptionPeriod)
      if (Object.keys(errors).length) {
        setReactivateFormErrors(errors)
      } else {
        setReactivateFormErrors({})
        modalSubmitHandler(values)
      }
    },
  });

  // call below function to save
  const modalSubmitHandler = async (values: any) => {
    const reactivationData = {...values, subscription_id: props.subscriptionId}
    if (values.trial_end_date === ''){
      reactivationData.trial_end_date = null
    }
    if (values.invoicing_cycle === 'FOREVER'){
      reactivationData.invoicing_cycle_fixed_count = 0
    }
    const reactivateRoleResponseAction = await dispatch(
      reactivateSubscriptionAction({
        orgId: props.organizationId,
        reactivationData
      })
    );
    if (reactivateRoleResponseAction.payload) {
      const voidRoleResponse = reactivateRoleResponseAction.payload;
      if (Object.keys(voidRoleResponse).length && !("error" in voidRoleResponse)) {
        props.refreshSubscription();
        modalCancelHandler()
        toast.success("Subscription reactivated successfully!", {
          toastId: "role-reactive-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in voidRoleResponse) {
        modalCancelHandler()
      }
    }
  };
  return (
    <>
      <Dialog
        open={props?.didAlertModalOpen || false}
        className={`subscription-update-popover`}
        onClose={modalCancelHandler}
      >
        <Card wrapperClass="subscription-update">
          <CardHeader wrapperClass={`subscription-update-header-wrapper`}>
            <Grid container>
              <Grid item xs={10} className="p-t-15 header">
                {"Reactivate Subscription"}
              </Grid>
              <Grid item xs={2} className="popover-close-button">
                <CloseButton closeModalHandler={modalCancelHandler} />
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Form
              name="add_invoice_form"
              id="add_invoice_form"
              className=""
              tabIndex={-1}
            >
              <ReactivateModalSubscription
                modalCancelHandler={modalCancelHandler}
                status={props.subscriptionStatus}
                formik={formik}
                reactivateFormErrors={reactivateFormErrors}
                subscriptionPeriod={props.subscriptionPeriod || ''}
              />

              <div className="row mb-0">
                <div className="col">
                  <div
                    className={`form-button-wrapper w-100 'd-flex justify-content-end mt-3`}
                    id="form-btn"
                  >
                    <button onClick={(e: any) => {
                      e.preventDefault();
                      formik.submitForm()
                    }} className="save-button">
                      {'Update'}
                    </button>
                    <button
                      type="button"
                      onClick={modalCancelHandler}
                      className="text-decoration-none cancel-button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Dialog>
    </>
  );
};
export default SubscriptionReactivate;