import React from "react";
import Delete from "../../../../common/components/Delete";
import DownloadPdf from "../../../../common/components/kebabMenu/DownloadPdf";
import Edit from "../../../../common/components/kebabMenu/Edit";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import SendEmail from "../../../../common/components/kebabMenu/SendEmail";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";

type Props = {
  value: any;
  onClickSendMail: () => void;
  onClickDownloadPdf: () => void;
  onClickPayment: () => void;
  onClickDelete: () => void;
  fetchProformaInvoiceList: () => Promise<void>;
};
function ProformaListActions({
  value,
  onClickSendMail,
  onClickDownloadPdf,
  onClickPayment,
  onClickDelete,
  fetchProformaInvoiceList,
}: Props) {
  const { invoicesRolePermission, paymentsReceivedRolePermission } =
    usePermissionHandler();
  return (
    <KebabMenu>
      {invoicesRolePermission.includes("Update") ? (
        <li>
          <Edit
            url={`/proforma-inv/edit/${value.id}`}
            isMutable={true} // change to true after implementing edit of closed and partially paid invoice.
            name="Proforma Invoice"
            isLocked={value.isLocked}
            className={"dropdown-item"}
            lockDate={value.lockDate}
            isFiscalClosed={value.is_fiscal_closed}
            isVoid={value.status === "Void"}
          />
        </li>
      ) : null}
      {value.status !== "Draft" ? (
        <>
          <li>
            <SendEmail onClick={onClickSendMail} />
          </li>
          <li>
            <DownloadPdf onClick={onClickDownloadPdf} />
          </li>
        </>
      ) : null}
      {invoicesRolePermission.includes("Delete") ? (
        <li>
          <Delete
            className="dropdown-item delete"
            deleteIcon={true}
            isMutable={value.isMutable}
            onClick={onClickDelete}
            isLocked={value.isLocked}
            lockDate={value.lockDate}
            name="Invoice"
            isFiscalClosed={value.is_fiscal_closed}
          />
        </li>
      ) : null}
    </KebabMenu>
  );
}

export default React.memo(ProformaListActions);
