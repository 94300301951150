import { SecondaryButton, SaveButton } from "../button";
import "./TransactionEditTagRemovalAlert.css";

type Props = {
  module: string;
  onSubmit: () => void;
  onCancel: () => void;
};
export const TransactionEditTagRemovalAlert = (props: Props) => {
  return (
    <div className="transaction-edit-tag-removal-alert-modal-body-wrapper">
      <div className="transaction-edit-tag-removal-alert-content">
        <div className="modal-footer">
          {`Updating the ${props.module} will remove all previously assigned tags. Are you sure you wish to proceed?`}
          <div className="w-100 d-flex justify-content-end mt-3">
            {" "}
            <SaveButton
              type="button"
              onClick={() => {
                props.onSubmit();
                props.onCancel();
              }}
              isDisabled={false}
              buttonContent="Yes Proceed"
            />
            <SecondaryButton
              type="button"
              buttonContent="Cancel"
              onClick={() => {
                props.onCancel();
              }}
              isDisabled={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
