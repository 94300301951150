import React from "react";
import './form.css';

type Props = {
  children: React.ReactNode;
  wrapperClass?: string;
  name: string;
  id?: string;
};

const Form = (props: Props) => {
  return (
    <form
      name={props.name}
      id={props.id}
      className={`add-module-item-form ${props.wrapperClass}`}
      tabIndex={-1}
    >
      {props.children}
    </form>
  );
};
export default Form;
