import { useEffect, useMemo, useState } from "react";
import {
  DateFormatHandler,
  handleNegativeValue,
} from "../../../../../helpers/helper";
import EmptyReport from "../../../../common/components/EmptyReport";
import ReportHeader from "./reportHeader";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import DetailsTableContainer from "../../../../common/components/detailsPage/DetailsTableContainer";
import useCommonData from "../../../../hooks/useCommon";
import InfoBox from "../../../../common/components/infoBox/InfoBox";
import { message } from "../../../../constants/messages";
import { useSubModulePermission } from "../../../../hooks/useSubModulePermissions";
import { TableData } from "../../../../../types";
import { Cell, Row } from "react-table";

export default function StatementOfAccountsBody(props: any) {
  const { currentUserInfo, orgCurrencyList, navigate } = useCommonData();
  const [showReport, setShowReport] = useState(false);
  const [reportData, setReportData] = useState<any>([]);
  const [reportItems, setReportItems] = useState<any>([]);
  const subModulePermission = useSubModulePermission();

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "show.date",
        className: "trans-date",
      },
      {
        Header: "Customer",
        accessor:
          props.transactionBy === "customer" ? "show.customer" : "Customer",
        className: "consumer-name",
      },
      {
        Header: "Vendor",
        accessor: props.transactionBy === "vendor" ? "show.vendor" : "Vendor",
        className: "consumer-name",
      },
      {
        Header: "Organization",
        accessor:
          // props.transactionOrg === "Other" &&
          Object.values(currentUserInfo.user_access_mapping)
            .toString()
            .split(",").length > 1
            ? "show.organization"
            : "Organization",
        className: "org",
      },
      {
        Header: "Transaction Type",
        accessor: "show.transactionType",
        className: "transaction-type",
      },
      {
        Header: "Details",
        accessor: "show.details",
        className: "column-navigation transaction-details",
      },
      {
        Header: "Debit",
        accessor: "show.debit",
        className: "debit text-nowrap",
      },
      {
        Header: "Credit",
        accessor: "show.credit",
        className: "credit text-nowrap",
      },
      {
        Header: "Balance",
        accessor: "show.balance",
        className: "balance text-nowrap",
      },
      {
        Header: "action",
        accessor: "action",
        className: "",
      },
    ],
    [props.hiddenColumns, props.transactionBy, props.transactionOrg]
  );
  /**
   * set table row data
   */
  const data = reportItems
    ? reportItems.map((itemData: any, index: number) => ({
        show: {
          date: itemData.transaction_date,
          customer: itemData.customer_name,
          vendor: itemData.vendor_name,
          organization: itemData.organization_name,
          transactionType: itemData.transaction_type,
          details: itemData.detail,
          debit: itemData.debit,
          credit: itemData.credit,
          balance: itemData.balance,
        },
        action:
          itemData.transaction_type === "Invoice"
            ? {
                url: `/invoice/detail/${itemData.transaction_id}`,
                permission: subModulePermission.includes("Invoices"),
                customNavigation: true,
              }
            : itemData.transaction_type === "Credit Note"
            ? {
                url: `/credit-note/detail/${itemData.transaction_id}`,
                permission: subModulePermission.includes("Credit Notes"),
                customNavigation: true,
              }
            : itemData.transaction_type === "Payments Received"
            ? {
                url: `/payment-received/detail/${itemData.transaction_id}`,
                permission: subModulePermission.includes("Payments Received"),
                customNavigation: true,
              }
            : itemData.transaction_type === "Bill"
            ? {
                url: `/bill/detail/${itemData.transaction_id}`,
                permission: subModulePermission.includes("Bills"),
                customNavigation: true,
              }
            : itemData.transaction_type === "Debit Note"
            ? {
                url: `/debit-note/detail/${itemData.transaction_id}`,
                permission: subModulePermission.includes("Debit Notes"),
                customNavigation: true,
              }
            : itemData.transaction_type === "Payments Made"
            ? {
                url: `/payment-made/detail/${itemData.transaction_id}`,
                permission: subModulePermission.includes("Payments Made"),
                customNavigation: true,
              }
            : itemData.transaction_type === "Journal Entry"
            ? {
                url: `/journal-entry/detail/${itemData.transaction_id}`,
                permission: subModulePermission.includes("Journal Entry"),
                customNavigation: true,
              }
            : {
                url: "",
                permission: subModulePermission.includes("Invoices"),
                customNavigation: false,
              },
        className: itemData.navigation ? "row-navigation" : "",
      }))
    : [];

  useEffect(() => {
    setShowReport(props.showReport);
  }, [props.showReport]);

  useEffect(() => {
    if (props.showReport) {
      getReport(props.reportResponse);
    }
  }, [props.reportResponse]);

  const getReport = async (response: any) => {
    let formValues = props.filterValues;
    let report: any = [];
    setReportData(response);
    if (response?.items?.length) {
      let openningData = {
        transaction_date: "As on " + DateFormatHandler(formValues.start_date),
        customer_name: "",
        vendor_name: "",
        organization_name: "",
        transaction_type: "*** OPENING BALANCE***",
        detail: "",
        debit: "",
        credit: "",
        balance: handleNegativeValue(
          NumberFormat(
            Number(response.opening_balance),
            response.currency_code,
            orgCurrencyList
          ),
          ""
        ),
        transaction_id: "",
        navigation: false,
      };
      report.push(openningData);
      let items = response.items.map((item: any, index: number) => ({
        transaction_date: DateFormatHandler(item.transaction_date),
        customer_name: item.customer_name,
        vendor_name: item.vendor_name,
        organization_name: item.organization_name,
        transaction_type: item.transaction_type,
        detail: joinTransactionNumber(item.detail),
        debit:
          item.debit !== 0
            ? NumberFormat(
                Number(item.debit),
                response.currency_code,
                orgCurrencyList
              )
            : "",
        credit:
          item.credit !== 0
            ? NumberFormat(
                Number(item.credit),
                response.currency_code,
                orgCurrencyList
              )
            : "",
        balance: handleNegativeValue(
          NumberFormat(
            Number(item.balance),
            response.currency_code,
            orgCurrencyList
          ),
          ""
        ),
        transaction_id: item.transaction_id,
        navigation: true,
      }));
      report = [...report, ...items];
      let clossingData = [
        {
          transaction_date: "As on " + DateFormatHandler(formValues.end_date),
          customer_name: "",
          vendor_name: "",
          organization_name: "",
          transaction_type: "*** CLOSING BALANCE***",
          detail: "",
          debit: "",
          credit: "",
          balance: handleNegativeValue(
            NumberFormat(
              Number(response.closing_balance),
              response.currency_code,
              orgCurrencyList
            ),
            ""
          ),
          transaction_id: "",
          navigation: false,
        },
        {
          transaction_date: "",
          customer_name: "",
          vendor_name: "",
          organization_name: "",
          transaction_type: "",
          detail: "Total",
          debit: NumberFormat(
            Number(response.total_debit),
            response.currency_code,
            orgCurrencyList
          ),
          credit: NumberFormat(
            Number(response.total_credit),
            response.currency_code,
            orgCurrencyList
          ),
          balance: "",
          transaction_id: "",
          navigation: false,
        },
        {
          transaction_date: "",
          customer_name: "",
          vendor_name: "",
          organization_name: "",
          transaction_type: "",
          detail: "",
          debit: "",
          credit: "Balance Due:",
          balance: handleNegativeValue(
            NumberFormat(
              Number(response.balance_due),
              response.currency_code,
              orgCurrencyList
            ),
            ""
          ),
          transaction_id: "",
          navigation: false,
        },
      ];
      report = [...report, ...clossingData];
    }
    setReportItems(report);
    setShowReport(true);
  };
  const navigation = (
    cellIndex: number,
    row: Row<TableData>,
    e: React.MouseEvent,
    cell: Cell<TableData>
  ) => {
    if (cell.column.Header) {
      let action = row?.values?.action;
      if (cell.column.Header === "Details") {
        if (action?.url && action?.permission) {
          navigate(`?${props.filterString}`);
          navigate(`${action?.url}${props.filterString}`);
        }
      }
      //  else if (cell.column.Header === "Vendor Name") {
      //   if (action?.userUrl && action?.userPermission) {
      //     navigate(`?${filterString}`);
      //     navigate(action?.userUrl);
      //   }
      // }
    }
  };

  return (
    <>
      {showReport ? (
        <div className="report-section w-100">
          <div className="report-body-links">
            {/* hidding the send email, download pdf and export to excel options from SOA header as per ticket: https://app.clickup.com/t/85zrmgcae */}

            {/* <ReportHeaderActionLinks /> */}
          </div>
          <ReportHeader organizationData={reportData} />
          <div className="table-section report-table-section">
            <InfoBox
              message={message(reportData.currency_code).reportCurrencyInfo}
              className="report-note"
            />
            <div className="table-wrapper">
              <DetailsTableContainer
                columns={columns}
                data={data}
                className={
                  props.transactionBy === "customer"
                    ? "table report-table soa-table customer-table"
                    : props.transactionBy === "vendor"
                    ? "table report-table soa-table vendor-table"
                    : props.transactionOrg === "Other"
                    ? "table report-table soa-table other-table"
                    : "table report-table soa-table"
                }
                hiddenColumns={[...props.hiddenColumns, "action"]}
                haveColumnClassName={true}
                navigation={navigation}
              >
                <EmptyReport
                  heading="No transactions found!"
                  alt="Empty report"
                />
              </DetailsTableContainer>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
