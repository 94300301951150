import { useState } from "react";
import { ErrorToaster, SuccessToaster } from "../../../../../helpers/toastHelper";
import { AlertModal } from "../../../../common/components/alertModal/alertModal";
import { ModalContext } from "../../../../common/components/alertModal/alertModalContext";
import { WriteOffIcon } from "../../../../common/components/customSvgIcons/writeOffIcon";
import { WriteOffForm } from "../../../../common/components/writeOff/WriteOffForm";
import { colors } from "../../../../constants/colors";
import { setLoaderState } from "../../../../common/commonSlice";
import { getFormatedDate } from "../../../../../helpers/helper";
import {
  createWriteOff,
  deleteWriteOff,
  getWriteOffDetails,
  updateWriteOff,
} from "../../billsSlice";
import useCommonData from "../../../../hooks/useCommon";
import { message } from "../../../../constants/messages";

type WriteOffBillProps = {
  bill_id?: number | null;
  write_off_id?: number | null;
  bill_amount?: number | string | undefined | null;
  currency_code: string | null | undefined;
  transactionDate: string;
  isVoid?: boolean;
  fetchBillList?: () => Promise<void>;
  fetchBillDetail?: () => Promise<void>;
  onCloseFunction?: () => void;
};

export const WriteOffModal = (props: WriteOffBillProps) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const [writeOffId, setWriteOffId] = useState<number | null>(null);
  const [writeOffValues, setWriteOffValues] = useState<{
    amount: string;
    account_id: number | null;
    is_partial: boolean;
    write_off_date: string;
  }>({
    amount: "",
    account_id: null,
    is_partial: true,
    write_off_date:
      getFormatedDate(new Date()) < props.transactionDate
        ? props.transactionDate
        : getFormatedDate(new Date()),
  });
  const defaultWriteoffDate =
    getFormatedDate(new Date()) < props.transactionDate
      ? props.transactionDate
      : getFormatedDate(new Date());

  //  Write off details of already existing write off
  const fetchWriteOffValue = async () => {
    const responseAction = await dispatch(
      getWriteOffDetails({
        orgId: currentUserInfo.organization_id,
        bill_id: props.bill_id ? props.bill_id : null,
      })
    );

    const response = responseAction.payload;
    if (response && Object.keys(response).length && !("error" in response)) {
      if (response.id) {
        setWriteOffValues({
          amount: response.amount,
          is_partial: response.is_partial,
          account_id: response.account_id,
          write_off_date:
            response.write_off_date !== null
              ? response.write_off_date
              : defaultWriteoffDate,
        });
        setWriteOffId(response.id);
      }
    } else {
      setWriteOffValues({
        amount: "",
        is_partial: true,
        account_id: null,
        write_off_date: defaultWriteoffDate,
      });
      setWriteOffId(null);
    }
  };

  // Write off creation.
  const writeOffCreation = async (
    values: {
      amount: string;
      account_id: number | null;
      is_partial: boolean;
    },
    onCloseModal: () => void
  ) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      createWriteOff({
        orgId: currentUserInfo.organization_id,
        write_off_values: {
          ...values,
          bill_id: props.bill_id ? props.bill_id : null,
        },
      })
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      SuccessToaster(
        "Write off against bill created successfully!",
        "write-off-creation"
      );
      props.fetchBillDetail && props.fetchBillDetail();
      props.fetchBillList && props.fetchBillList();
      fetchWriteOffValue();
      onCloseModal();
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };

  // Write off updation.
  const writeOffUpdation = async (
    values: {
      amount: string;
      is_partial: boolean;
    },
    onCloseModal: () => void
  ) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      updateWriteOff({
        orgId: currentUserInfo.organization_id,
        write_off_id: writeOffId,
        write_off_values: values,
      })
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      SuccessToaster(
        "Write off against the bill updated successfully!",
        "write-off-updation"
      );
      props.fetchBillDetail && props.fetchBillDetail();
      props.fetchBillList && props.fetchBillList();
      fetchWriteOffValue();
      onCloseModal();
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };

  // Write off deletion.
  const cancelWriteOff = async (onCloseModal: () => void) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      deleteWriteOff({
        orgId: currentUserInfo.organization_id,
        write_off_id: writeOffId,
      })
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      SuccessToaster(
        "Write off against the bill deleted successfully!",
        "write-off-deletion"
      );
      props.fetchBillDetail && props.fetchBillDetail();
      props.fetchBillList && props.fetchBillList();
      onCloseModal();
      fetchWriteOffValue();
      dispatch(setLoaderState(false));
    } else {
      onCloseModal();
      dispatch(setLoaderState(false));
    }
  };
  const handleDisabledAction = () => {
    ErrorToaster(message().bill.writeOffVoidBill);
  };

  return (
    <AlertModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <WriteOffForm
                key={props.bill_id}
                handleClose={onCloseModal}
                createWriteOff={writeOffCreation}
                updateWriteOff={writeOffUpdation}
                deleteWriteOff={cancelWriteOff}
                maxAmount={props.bill_amount}
                itemId={props.bill_id}
                values={writeOffValues}
                writeOffId={writeOffId}
                currencyCode={props.currency_code}
                accountType={["Incomes"]}
                transactionDate={props.transactionDate}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={
        <span>
          <WriteOffIcon color={colors.battleshipGrey} width="16px" />
          Write Off
        </span>
      }
      buttonClass="no-appearance w-100 dropdown-item"
      modalTitle="Write Off"
      isCloseButtonEnabled={true}
      wrapperClass="write-off-modal"
      onOpenFunction={async () => {
        await fetchWriteOffValue();
      }}
      onCloseFunction={props.onCloseFunction}
      isButtonDisabled={props.isVoid ? true : false}
      disabledActionFunction={handleDisabledAction}
    />
  );
};
