import KebabMenu from "../../../../../common/components/kebabMenu/KebabMenu";
import Edit from "../../../../../common/components/kebabMenu/Edit";
import Delete from "../../../../../common/components/Delete";
import Void from "../../../../../common/components/Void";
import StopResume from "../../../../../common/components/kebabMenu/StopResume";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import Reactivate from "../../../../../common/components/Reactivate";

export type ListActionProps = {
  value: any;
  onClickDelete: () => void;
  onClickVoid: () => void;
  onStopResume: () => void;
};

function ListActions(props: ListActionProps) {
  const { subscriptionRolePermission } = usePermissionHandler();
  return (
    <KebabMenu>
      {subscriptionRolePermission.includes("Update") &&
      props.value.status &&
      !["stopped"].includes(props.value.status?.toLowerCase()) ? (
        <>
          <li>
            <Edit
              url={`/subscriptions/edit/${props.value.id}`}
              isMutable
              name="Plan"
              isLocked={false}
              className={"dropdown-item"}
              lockDate={""}
              isDisabled={false}
            />
          </li>
        </>
      ) : null}
      {subscriptionRolePermission.includes("Update") &&
      subscriptionRolePermission.includes("Cancel") ? (
        <>
          {props.value.status?.toLowerCase() === "cancelled" ? (
            <li>
              <Reactivate
                className="dropdown-item delete"
                reactivateIcon={true}
                isMutable
                onClick={props.onClickVoid}
                isLocked={false}
                lockDate={""}
                name="Invoice"
                status={props.value.status}
                isButtonClick={true}
              />
            </li>
          ) : (
            <li>
              <Void
                className="dropdown-item delete"
                deleteIcon={true}
                isMutable
                onClick={props.onClickVoid}
                isLocked={false}
                lockDate={""}
                name="Plan"
                status={props.value.status}
                isButtonClick={true}
              />
            </li>
          )}
        </>
      ) : null}
      {subscriptionRolePermission.includes("Update") &&
      subscriptionRolePermission.includes("Stop") &&
      props.value.status &&
      !["cancelled"].includes(props.value.status?.toLowerCase()) ? (
        <li>
          <StopResume
            className="dropdown-item delete"
            isDisabled={false}
            isStopped={props.value.status.toLowerCase() === "stopped"}
            onStopResumeClick={props.onStopResume}
          />
        </li>
      ) : null}

      {subscriptionRolePermission.includes("Delete") ? (
        <li>
          <Delete
            className="dropdown-item delete"
            deleteIcon={true}
            isMutable
            onClick={props.onClickDelete}
            isLocked={false}
            lockDate={""}
            name="Subscription"
            child_invoice_exists={
              props.value.invoice_exists
                ? "child_invoice_exists"
                : "child_invoice_not_exists"
            }
            isChildInvoice={true}
          />
        </li>
      ) : null}
    </KebabMenu>
  );
}

export default ListActions;
