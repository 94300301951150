import React, { useEffect, useMemo, useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  JournalList,
  ObjectType,
  Transactionlimit,
} from "../../../../../types";
import "../../JournalVoucher.css";
import "../../../../common/styles/ListTable.css";
import { useNavigate } from "react-router-dom";

import {
  getJournalLink,
  getTransactionLimitStatus,
  journalList,
  sendJournalData,
} from "../../journalVoucherSlice";

import {
  currentUserSelector,
  fiscalYearFilterSelector,
  orgCurrencyListSelector,
  reportOrgSelector,
} from "../../../../common/commonSlice";
import Pagination from "../../../../common/components/table/Pagination";

import DeleteJournal from "../Delete";

import {
  dateSortOrderSelector,
  endCountSelector,
  filterAppliedState,
  itemOffsetSelector,
  itemsPerPageSelector,
  listFilterSelector,
  pageCountSelector,
  pageSelector,
  totalListSelector,
  setListDateSortOrder,
  setListEndCount,
  setListFilters,
  setListItemOffset,
  setListItemsPerPage,
  setListPage,
  setListPageCount,
  setListStartCount,
  startCountSelector,
} from "../../../../../appSlice";

import {
  getModuleStatusFilter,
  getStatusFilterString,
} from "../../../../../helpers/statusFilterHelper";

import NotificationModal from "../../../../common/components/NotificationModal";

import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import useListData from "../../../../hooks/useListData";

import ListHeader from "../../../../common/components/list/ListHeader";
import JournalEntryTableContainer from "./JournalEntryTable/JournalEntryTableContainer";
import { usePaginationDispatch } from "../../../../hooks/usePaginationDispatch";

interface FiltersType {
  [category: string]: { id: number; name: string }[];
}
type OrgListObjectType = {
  id: number;
  name: string;
};

type SelectedFilterType = {
  Organizations: number[];
  Status: number[];
};

const JournalVoucherList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUserInfo = useAppSelector(currentUserSelector);
  // const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  // const page = useAppSelector(pageSelector);
  const { page, pageCount, itemsPerPage, totalList, dateSortOrder } =
    useListData();
  // const totalList = useAppSelector(totalListSelector);
  // const itemsPerPage = useAppSelector(itemsPerPageSelector);
 

  // const pageCount = useAppSelector(pageCountSelector);
  const { setPage, setItemOffset } = usePaginationDispatch();

  const fiscalYearFilter = useAppSelector(fiscalYearFilterSelector);
  // const dateSortOrder = useAppSelector(dateSortOrderSelector);
  const { journalVoucherRolePermission } = usePermissionHandler();
  const [journalData, setJournalData] = useState<JournalList[]>([]);
  const [totalJournals, setTotalJournals] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteJournalItemId, setDeleteJournalItemId] = useState("");
  const [deleteJournalId, setDeleteJournalId] = useState(-1);
  // const [emailList, setEmailList] = useState<string[] | []>([]);
  // const [journalId, setJournalId] = useState(0);
  // const [orgListFilter, setOrgListFilter] = useState<OrgListObjectType[]>([]);
  const selectedFilterData = useAppSelector(listFilterSelector);
  const isFilterAppliedState = useAppSelector(filterAppliedState);
  // const orgList = useAppSelector(reportOrgSelector);
  const [selectedFilters, setSelectedFilters] = useState<ObjectType>({});
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [transactionLimit, setTransactionLimit] = useState<Transactionlimit>({
    limit_exceed: false,
    limit_count: "",
  });
  const listRef = useRef<any>([]);
  const filterRef = useRef<any>([]);

  // const organizationRole = JSON.parse(
  //   sessionStorage.getItem("organizationRole") || "{}"
  // );
  // let journalVoucherPermission: string[] = organizationRole.length
  //   ? organizationRole
  //       .filter(
  //         (orgPermission: any) => orgPermission.subModule === "Journal Entry"
  //       )
  //       .map((permission: any) => {
  //         return permission.permission;
  //       })
  //   : [];

  useEffect(() => {
    if (!Object.keys(selectedFilterData).length) {
      initializeFilter();
    }
  }, []);

  useEffect(() => {
    fetchJournalList();
  }, [
    page,
    itemsPerPage,
    dateSortOrder,
    currentUserInfo.organization_id,
    JSON.stringify(fiscalYearFilter),
    // selectedFilterData,
  ]);

  useEffect(() => {
    if (isFilterAppliedState) fetchJournalList();
  }, [isFilterAppliedState, selectedFilterData]);

  // useEffect(() => {
  //   getOrgList();
  // }, [orgList]);

  useEffect(() => {
    setIsFilterApplied(false);
  }, [currentUserInfo.organization_id]);

  // const setPage = (param: number) => {
  //   dispatch(setListPage(param));
  // };
  // const setItemsPerPage = (param: number) => {
  //   dispatch(setListItemsPerPage(param));
  // };
  const setStartCount = (param: number) => {
    dispatch(setListStartCount(param));
  };
  const setEndCount = (param: number) => {
    dispatch(setListEndCount(param));
  };
  // const setPageCount = (param: number) => {
  //   dispatch(setListPageCount(param));
  // };
  // const setItemOffset = (param: number) => {
  //   dispatch(setListItemOffset(param));
  // };
  // const setDateSortOrder = (param: string) => {
  //   dispatch(setListDateSortOrder(param));
  // };
  /**
   * Fetch transaction limit
   */
  const fetchTransactionLimit = async () => {
    const responseAction = await dispatch(
      getTransactionLimitStatus(currentUserInfo.organization_id)
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setTransactionLimit({ ...transactionLimit, ...response });
    }
  };
  /**
   * Fetch all journal vouchers
   */
  const fetchJournalList = async () => {
    fetchTransactionLimit();
    setIsLoading(true);
    const responseAction = await dispatch(
      journalList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        orgIds: selectedFilterData.Organizations
          ? selectedFilterData.Organizations
          : [],
        statusList: selectedFilterData.Status
          ? getStatusFilterString(selectedFilterData.Status)
          : [],
        endDate: fiscalYearFilter.endDate || "",
        startDate: fiscalYearFilter.startDate || "",
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        setIsLoading(false);
      }, 500);

      const response = responseAction.payload as any;
      if (Object.keys(response).length && !("error" in response)) {
        setJournalData(response.journals);
        let length = response.journals.length;
        setTotalJournals(response.total);
        if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
        setEndCount((page - 1) * itemsPerPage + length);
      } else if ("error" in response) {
      }
    }
  };

  // useEffect(() => {
  //   // Fetch items from another resources.
  //   const endOffset = itemOffset + itemsPerPage;
  //   let pageCount = Math.ceil(totalJournals / itemsPerPage);
  //   setPageCount(pageCount);
  //   if (page >= pageCount && pageCount !== 0) {
  //     setPage(pageCount);
  //   }
  // }, [itemOffset, itemsPerPage, totalJournals, page]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalJournals;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  // const sortHandler = (column: string) => {
  //   if (column === "Date") {
  //     if (dateSortOrder === "ASC") {
  //       setDateSortOrder("DESC");
  //     } else {
  //       setDateSortOrder("ASC");
  //     }
  //   }
  // };

  /**
   * Set the contact emails
   */
  // const fetchContactEmail = (id: number, emailsList: any[]) => {
  //   let emails = emailsList.map((item) => {
  //     return item.email;
  //   });
  //   setEmailList(emails);
  //   setJournalId(id);
  // };

  // const downloadPdf = async (journalId: number) => {
  //   const responseAction = await dispatch(
  //     getJournalLink({
  //       journalId: journalId,
  //       orgId: currentUserInfo.organization_id,
  //     })
  //   );
  //   if (responseAction.payload) {
  //     const response = responseAction.payload;
  //     if (
  //       Object.keys(response).length &&
  //       !("error" in response) &&
  //       response.download_link?.length > 0
  //     ) {
  //       let result = await downloadUsingURL(
  //         response.download_link,
  //         journalId + ".pdf"
  //       );
  //       if (result) {
  //         toast.success("Journal entry note downloaded successfully!", {
  //           toastId: "jounal-voucher-download-success",
  //           closeButton: false,
  //           position: "top-center",
  //         });
  //       }
  //     } else {
  //       // toast.error("Failed to download PDF!", {
  //       //   icon: ({ theme, type }) => (
  //       //     <img src={ErrorImg} width="16" height="14" alt="error-img" />
  //       //   ),
  //       //   toastId: "pdf-download-failure",
  //       //   closeButton: false,
  //       //   className: "toast-error",
  //       //   position: "top-center",
  //       // });
  //     }
  //   }
  // };
  /**
   * Set delete data details
   */
  const deleteListData = (id: number, listNum: string) => {
    setDeleteJournalId(id);
    setDeleteJournalItemId(listNum);
  };
  // /**
  //  * set table column headings
  //  */
  // const columns = useMemo(
  //   () => [
  //     {
  //       Header: "Date",
  //       accessor: "show.date",
  //       sort: true,
  //       sortOrder:
  //         dateSortOrder === "ASC"
  //           ? "ascending"
  //           : dateSortOrder === "DESC"
  //           ? "descending"
  //           : "",
  //       className: "column-1",
  //     },
  //     {
  //       Header: "Journal Entry #",
  //       accessor: "show.journalVoucherNum",
  //       sort: false,
  //       sortOrder: "",
  //       className: "column-1",
  //     },
  //     {
  //       Header: "Reference #",
  //       accessor: "show.reference",
  //       sort: false,
  //       sortOrder: "",
  //       className: "column-1",
  //     },
  //     {
  //       Header: "Status",
  //       accessor: "show.status",
  //       sort: false,
  //       sortOrder: "",
  //       className: "column-1",
  //       Cell: ({ cell: { value } }: any) => {
  //         return (
  //           <span
  //             className={StatusColor(
  //               value?.includes("Overdue") ? "overdue" : value
  //             )}
  //           >
  //             {value}
  //           </span>
  //         );
  //       },
  //     },
  //     {
  //       Header: "Notes",
  //       accessor: "show.notes",
  //       sort: false,
  //       sortOrder: "",
  //       className: "column-1",
  //       Cell: ({ cell: { value } }: any) => {
  //         return (
  //           <CustomTooltips
  //             placement="bottom"
  //             title={value?.length > 15 ? value : ""}
  //           >
  //             <span className="jv_notes">
  //               {value && value.slice(0, 40).trim()}
  //               {value?.length > 40 ? "..." : ""}
  //             </span>
  //           </CustomTooltips>
  //         );
  //       },
  //     },

  //     {
  //       Header: "Amount",
  //       accessor: "show.amount",
  //       sort: false,
  //       sortOrder: "",
  //       className: "column-1",
  //     },
  //     {
  //       Header: "Created By",
  //       accessor: "show.createdBy",
  //       sort: false,
  //       sortOrder: "",
  //       className: "column-1",
  //     },
  //     {
  //       Header: "Organization",
  //       accessor: "show.organization",
  //       sort: false,
  //       sortOrder: "",
  //       className: "column-1",
  //     },
  //     {
  //       Header: "",
  //       accessor: "show.actions",
  //       sort: false,
  //       sortOrder: "",
  //       className: "action",
  //       Cell: ({ cell: { value } }: any) => {
  //         return (!journalVoucherPermission.includes("Update") &&
  //           value.status === "Draft") ||
  //           value.organizationId !== currentUserInfo.organization_id ? null : (
  //           <KebabMenu>
  //             {journalVoucherPermission.includes("Update") &&
  //             value.isMutable ? (
  //               <li>
  //                 <Edit
  //                   url={`/journal-entry/edit/${value.id}`}
  //                   isMutable={true}
  //                   name="Journal Voucher"
  //                   isLocked={value.isLocked}
  //                   className={"dropdown-item"}
  //                   lockDate={value.lockDate}
  //                   isFiscalClosed={value.is_fiscal_closed}
  //                 />
  //               </li>
  //             ) : null}
  //             {value.status !== "Draft" ? (
  //               <>
  //                 <li>
  //                   <SendEmail
  //                     onClick={() => fetchContactEmail(value.id, value.emailTo)}
  //                   />
  //                 </li>
  //                 <li>
  //                   <DownloadPdf onClick={() => downloadPdf(value.id)} />
  //                 </li>
  //               </>
  //             ) : null}
  //             {journalVoucherPermission.includes("Delete") &&
  //             value.isMutable ? (
  //               <li>
  //                 <Delete
  //                   className={"dropdown-item delete"}
  //                   deleteIcon={true}
  //                   isMutable={true}
  //                   onClick={() => deleteListData(value.id, value.listName)}
  //                   isLocked={value.isLocked}
  //                   lockDate={value.lockDate}
  //                   name="Journal Voucher"
  //                   isFiscalClosed={value.is_fiscal_closed}
  //                 />
  //               </li>
  //             ) : null}
  //           </KebabMenu>
  //         );
  //       },
  //     },
  //   ],
  //   [dateSortOrder, currentUserInfo.organization_id]
  // );
  /**
   * set table row data
   */
  // const data = journalData
  //   ? journalData.map((journal, index) => ({
  //       show: {
  //         date: DateFormatHandler(journal.date),
  //         journalVoucherNum: joinTransactionNumber(
  //           `${journal.journal_voucher_number}`
  //         ),
  //         reference: journal.reference_number,
  //         status: journal.journal_status,
  //         notes: journal.notes,
  //         amount:
  //           journal.total_amount !== "-"
  //             ? journal.currency_code +
  //               " " +
  //               NumberFormat(
  //                 Number(journal.total_amount),
  //                 journal.currency_code,
  //                 orgCurrencyList
  //               )
  //             : journal.total_amount,
  //         createdBy: journal.created_by,
  //         organization: journal.organization,
  //         actions: {
  //           id: journal.id,
  //           status: journal.journal_status,
  //           listName: journal.journal_voucher_number,
  //           emailTo: journal.email_to,
  //           organizationId: journal.organization_id,
  //           isLocked: journal.is_locked,
  //           lockDate: journal.lock_date,
  //           isMutable: journal.is_mutable,
  //           is_fiscal_closed: journal.is_fiscal_closed,
  //         },
  //       },
  //     }))
  //   : [];

  // const sendEmail = async (id: number, emailList: string[]) => {
  //   const responseAction = await dispatch(
  //     sendJournalData({
  //       journalId: id,
  //       emails: emailList,
  //       orgId: currentUserInfo.organization_id,
  //     })
  //   );
  //   if (responseAction.payload) {
  //     const response = responseAction.payload;
  //     if (Object.keys(response).length && !("error" in response)) {
  //       fetchJournalList();
  //     }
  //   }
  //   return responseAction;
  // };

  const initializeFilter = () => {
    setIsFilterApplied(false);
    let filterData: SelectedFilterType = {
      Organizations: [],
      Status: [],
    };
    Object.keys(selectedFilters).map((filter) => {
      filterData = {
        ...filterData,
        [Object.keys(filter)[0]]: Object.values(filter)[0],
      };
    });
    dispatch(setListFilters(filterData));
  };

  /**
   * fetching org list for filters
   */
  // const getOrgList = async () => {
  //   let orgsList: OrgListObjectType[] = [];
  //   if (orgList.length) {
  //     orgList.map((orgs: ObjectType) => {
  //       const { name, id } = orgs;
  //       orgsList.push({ name: name, id: id });
  //     });
  //   }
  //   setOrgListFilter(orgsList);
  // };

  /**
   * Setting filter options for the ListFilter Component
   */
  // const Filters: FiltersType[] = [
  //   {
  //     Organizations: orgListFilter,
  //   },
  //   {
  //     Status: getModuleStatusFilter("JV"),
  //   },
  // ];

  /**
   * fetching org selection onChange for Customer list API call
   */
  // const handleFiltersSelected = (selectedFilters: any) => {
  //   setSelectedFilters(selectedFilters);
  // };

  const handleApplyFilter = () => {
    setIsFilterApplied(true);
  };
  // const refreshList = () => {
  //   filterRef.current.refreshFilter();
  // };

  return (
    <>
      <div className="card card-user-management card-customer main-card overflowX-hidden h-100">
        {/* <div className="card-header invoice-list-card-header list-card-header p-0 border-0">
          <h1>Journal Entry</h1>
          <div className="top-action-items-wrapper">
            <ul className="nav nav-pills button-list top-action-lists float-start p-0 m-0">
              <li className="nav-item">
                <FiscalYearSelect />
              </li>
              <li className="nav-item">
                {journalVoucherPermission.includes("Create") ? (
                  <CreateButton
                    label={"New Journal Entry"}
                    onClick={() => {
                      transactionLimit.limit_exceed
                        ? $("#transactionLimitModal").modal("show")
                        : navigate("/journal-entry/create");
                    }}
                  />
                ) : null}
                <ListFilter
                  filters={Filters}
                  onFiltersSelected={handleFiltersSelected}
                  initializeFilter={initializeFilter}
                  isFilterApplied={handleApplyFilter}
                />
              </li>
            </ul>
          </div>
        </div> */}
        <ListHeader
          title="Journal Entry"
          haveSearchBox={false}
          haveCreateButton={true}
          createButtonLabel="New Journal Entry"
          createOnClick={() => {
            transactionLimit.limit_exceed
              ? $("#transactionLimitModal").modal("show")
              : navigate("/journal-entry/create");
          }}
          haveFilter={true}
          filterType="JV"
          applyFilter={handleApplyFilter}
          roles={journalVoucherRolePermission}
          ref={filterRef}
          module="JournalEntry"
          isFiscalYearFilterRequired={false}
        />
        <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
          <div className="card-body-wrapper">
            {/* <div className="card-body">
            <div
              id="invoice-table-wrapper"
              className={
                "list-table-wrapper creditnote-list-wrapper dataTables_wrapper dt-bootstrap5 no-footer " +
                `${totalJournals > 0 ? "" : "no-data"}`
              }
            >
              <TableInfo
                total={totalJournals}
                startCount={startCount}
                endCount={endCount}
                itemsPerPage={itemsPerPage}
                setPageInfo={(countPerpage, page) => {
                  setItemsPerPage(countPerpage);
                  setPage(page);
                }}
              />
              <div className="row">
                <div className="col-md-12">
                  <TableContainer
                    className="list-table cd-note-table table table-striped payment-action-table w-100"
                    columns={columns}
                    data={data}
                    url={"/journal-entry"}
                    sortHandler={sortHandler}
                    isLoading={isLoading}
                    editPermission={journalVoucherPermission.includes("Update")}
                    isDetailViewExist={true}
                  >
                    {!isFilterApplied ? (
                      <EmptyList
                        heading={
                          journalVoucherPermission.includes("Create")
                            ? "Create journal entry!"
                            : "No Journal entries found!"
                        }
                        subheading={
                          journalVoucherPermission.includes("Create")
                            ? "Keep a track of your transactions"
                            : "Oops, There is no Journal entry data available right now"
                        }
                        list="Journal Voucher"
                        alt="create journal entry preview"
                        buttonText="New Journal Entry"
                        navigateLink="/journal-entry/create"
                        isCrateAccess={journalVoucherPermission.includes(
                          "Create"
                        )}
                        icon={InvoiceImage}
                        transactionLimit={transactionLimit.limit_exceed}
                      />
                    ) : (
                      <FilterEmptyList
                        list="journal vouchers"
                        icon={InvoiceImage}
                        alt="list jv empty"
                        handleReset={initializeFilter}
                      />
                    )}
                  </TableContainer>
                  {isLoading ? <Loader /> : null}
                </div>
              </div>
            </div>
            
            <div className="card-footer clear-both">
              {totalJournals > itemsPerPage ? (
                <Pagination
                  pageCount={pageCount}
                  page={page}
                  handlePageClick={handlePageClick}
                />
              ) : (
                ""
              )}
            </div>
            
          </div> */}
            <JournalEntryTableContainer
              transactionLimit={transactionLimit}
              initializeFilter={initializeFilter}
              isFilterApplied={isFilterApplied}
              deleteListData={deleteListData}
              ref={listRef}
            />
            <div className="card-footer clear-both">
              {totalList > itemsPerPage ? (
                <Pagination
                  pageCount={pageCount}
                  page={page}
                  handlePageClick={handlePageClick}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {/* <SendEmailModal
          emailLists={emailList}
          id={journalId}
          sendEmail={(id: number, emailList: string[]) =>
            sendEmail(id, emailList)
          }
        /> */}
      </div>
      <DeleteJournal
        deleteJournalId={deleteJournalId}
        deleteJournalItemId={deleteJournalItemId}
        page={page}
        itemsPerPage={itemsPerPage}
        dateSortOrder={dateSortOrder}
        refreshJournals={fetchJournalList}
        organizationId={currentUserInfo.organization_id}
      />
      <NotificationModal
        modalId="transactionLimitModal"
        modalHeader={"Transaction limit exceeded!"}
        modalBody={`You have crossed the allotted transaction limit of ${transactionLimit.limit_count}. Please contact your administrator or get in touch with us for more assistance.`}
        modalSubmit="OK"
        modalCancel=""
        modalSubmitHandler={() => {}}
      />
    </>
  );
};

export default JournalVoucherList;
