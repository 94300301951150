import { FormSelectField } from "../../../../common/components";
import InfoTag from "../../../../common/components/InfoTag";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import { invoicingOptions } from "../../SubscriptionStateInitialization";
import "./cancelDetailsModalContent.css";

import { useFormik } from "formik";
import * as yup from "yup";

function CancelDetailsModalContent(props: any) {
  const cancelValidator = yup.object({
    comments: yup.string().required("Please enter any comment"),
  });

  const formik = useFormik({
    initialValues: {
      billing_cycle: invoicingOptions[0],
      comments: "",
    },
    validationSchema: cancelValidator,
    onSubmit: (values) => {
      //need to add logic here
    },
  });

  return (
    <div className="cancel-details-modal-content">
      <div className="container">
        <p>
          This subscription will be billed next on{" "}
          <strong>18-Sep-2024 00:00</strong> and will remain{" "}
          <InfoTag status={"active"} />
        </p>

        <div
          className={
            "col-12 col-lg-4 mw-400 cancel-scheduler-cancel-details-billing-cycle"
          }
        >
          <FormSelectField
            name="billing_cycle"
            id="billing_cycle"
            label="Billing Cycle"
            options={invoicingOptions}
            wrapperClass="mw-400 cancel-scheduler-cancel-details-billing-cycle"
            className="state-select custom-select"
            value={formik.values.billing_cycle}
            onChange={(option: any) => {
              formik.setFieldValue("billing_cycle", option);
            }}
            styles={customSelectStyle}
            placeholder={""}
            isSearchable={true}
            isDisabled={false}
            error={
              formik.errors.billing_cycle
                ? formik.errors.billing_cycle.value
                : ""
            }
            isRequired={false}
            isOnlyInRow={false}
            messagePlacement="top-end"
          />
        </div>

        <div className="row custom-row">
          <div className="col-12 col-lg-4 mw-400">
            <div className="mb-3">
              <label htmlFor="comments" className="form-label form-labels">
                Comments
                <span style={{ color: "red", paddingLeft: "3px" }}>*</span>
              </label>
              <textarea
                className={`form-control mw-400 cancel-scheduler-cancel-details-textarea`}
                id="comments"
                name="comments"
                value={formik.values.comments}
                placeholder="Add any comments here..."
                onChange={(e) =>
                  formik.setFieldValue("comments", e.target.value)
                }
              ></textarea>
              <span className="error cancel-scheduler-cancel-details-error">
                {formik.errors.comments ? formik.errors.comments : ""}
              </span>
            </div>
          </div>
        </div>

        <div className="buttons">
          <button
            onClick={props.onCloseModal}
            id="scheduler-cancel-btn"
            className="btn btn-cancel cancel-btn"
          >
            Dismiss
          </button>
          <button
            type="submit"
            onClick={() => formik.handleSubmit()}
            className="btn btn-cancel proceed-btn"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default CancelDetailsModalContent;
