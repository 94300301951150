import React, { memo, useEffect } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { ObjectType } from "../../../../types";
import { fetchAccountsGlCode } from "../../../home/chartOfAccounts/coaSlice";
import "../../../../index.css";
import "./CustomSearchSelect.css";

type Props = {
  itemAccount: number;
  itemAccountName?: string;
  organizationId: number;
  handleItemAccountChange: (e: any, index: number, accountName: string) => void;
  itemIndex: number;
  error: string;
  errorMessage?: string;
  baseAccount?: string;
  currencyFilter?: number;
  className?: string;
  isDisabled?: boolean;
  name?: string;
  id?: string;
  wrapperClass?:string;
};

let abortController = new AbortController();

const CustomSearchSelect = (props: Props) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = React.useState<number | null>();
  const [selectValue, setSelectValue] =
    React.useState<string | undefined>(undefined);
  const [accountList, setAccountList] = React.useState<ObjectType[]>([]);
  const [accountListFilter, setAccountListFilter] = React.useState<
    ObjectType[]
  >([]);

  useEffect(() => {
    setValue(props.itemAccount);
  }, []);

  useEffect(() => {
    getAccountList();
  }, [props.organizationId]);

  useEffect(() => {
    if (accountList.length && props.itemAccount) {
      let selectedAccount = accountList.filter(
        (account) => account.id === props.itemAccount
      );
      if (selectedAccount.length === 0) {
        setValue(null);
        props.handleItemAccountChange("", props.itemIndex, "");
      }
    } else if (!props.itemAccount) {
      setValue(Number(0));
    }
  }, [accountList, props.itemAccount]);

  useEffect(() => {
    if (props.currencyFilter) {
      let accounts = accountList.filter(
        (account) =>
          account.currency_id === props.currencyFilter ||
          account.currency_id === null
      );
      setAccountListFilter([...accounts]);
    } else {
      setAccountListFilter(accountList);
    }
  }, [props.currencyFilter, accountList]);

  useEffect(() => {
    if (
      (props.itemAccountName || props.itemAccountName == "") &&
      selectValue !== props.itemAccountName
    ) {
      setSelectValue(props.itemAccountName);
    }
    if (props.itemAccountName === null) {
      setSelectValue("");
    }
  }, [props.itemAccountName]);

  useEffect(() => {
    resetDropDown();
  }, []);
  useEffect(() => {
    $(".dropdown-toggle").dropdown("hide");
  }, [props.error]);

  const getAccountList = async () => {
    await getSearchAccountList("");
  };

  const handleSelectChange = (
    accountId: number | string,
    accountName: string,
    index: number
  ) => {
    setValue(Number(accountId));
    setSelectValue(accountName);
    props.handleItemAccountChange(accountId, index, accountName);
  };
  /**
   * Get account list based on search string
   */
  const getSearchAccountList = async (searchString: string) => {
    const signal = abortController.signal;
    const responseAction = await dispatch(
      fetchAccountsGlCode({
        accountSearchTerm: searchString,
        baseAccount: props.baseAccount ? props.baseAccount : "",
        orgId: props.organizationId,
        signal: signal,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setAccountList(response.account_list);
        let accounts: ObjectType[];
        if (props.currencyFilter) {
          accounts = response.account_list.filter((account: ObjectType) => {
            return (
              account.currency_id === props.currencyFilter ||
              account.currency_id === null
            );
          });
        } else {
          accounts = response.account_list;
        }
        return accounts;
      }
    }
    return [];
  };
  /**
   * Function to set search string
   */
  const searchAccount = (e: any) => {
    setSelectValue(e.target.value);
    abortController.abort();
    abortController = new AbortController();
    getSearchAccountList(e.target.value);
    if (e.target.value.trim().length === 0) {
      handleSelectChange("", "", props.itemIndex);
    }
  };

  const accountSearchOnBlur = () => {
    if (accountList.length && props.itemAccount) {
      let selectedAccount = accountList.filter(
        (account) => account.id === props.itemAccount
      );
      if (selectedAccount.length === 0) {
        setValue(null);
        props.handleItemAccountChange("", props.itemIndex, "");
      } else {
        setValue(Number(selectedAccount[0].id));
        setSelectValue(selectedAccount[0].account_name);
      }
    }
  };

  const resetDropDown = () => {
    $(".dropdown-toggle").on("hide.bs.dropdown", function (e) {
      let elem = $(this).parent().find(".dropdown-menu");
      elem.scrollTop(0);
    });
  };

  return (
    <>
      <div
        className={`btn-group accounts-dropdown ${
          props.wrapperClass ? props.wrapperClass : ""
        } ${props.error}`}
      >
        <input
          type="text"
          name={props.name ? props.name : "account_id"}
          id={props.id ? props.id : ""}
          className={`${props.error} search-dropdown dropdown-toggle  ${
            props.className || ""
          }`}
          placeholder="Choose account"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          disabled={props.isDisabled}
          value={
            selectValue !== undefined
              ? selectValue.toString()
              : props.itemAccountName
          }
          onChange={(e) => {
            searchAccount(e);
          }}
          autoComplete="off"
          onKeyDown={(e) => {
            let code = e.keyCode || e.which;
            if (code !== 9) {
              $(e.target).dropdown("show");
            }
          }}
          onBlur={accountSearchOnBlur}
        />
        {props.errorMessage && (
          <span className="error">{props.errorMessage}</span>
        )}
        <ul className="dropdown-menu dropdown-menu-start">
          {accountListFilter.length ? (
            accountListFilter.map((account, accountIndex) => {
              return (
                <li
                  onClick={() => {
                    handleSelectChange(
                      account.id,
                      account.account_name,
                      props.itemIndex
                    );
                    return true;
                  }}
                  key={accountIndex}
                >
                  <a
                    className={
                      value === account.id
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="account">{account.account_name}</span>
                    <span className="account-type">{account.account_type}</span>
                  </a>
                </li>
              );
            })
          ) : (
            <li className="no-result">
              <a
                className="dropdown-item no-result"
                href="#"
                onClick={(e) => e.preventDefault()}
              >
                <span className="no-result">No accounts found</span>
              </a>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default memo(CustomSearchSelect);
