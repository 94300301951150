import * as yup from "yup";

export const taxGroupValidation = (id: number, isChanged: boolean) =>
  yup.object({
    name: yup.string().required("Please enter tax group name"),
    tax_item_ids: yup
      .array()
      .of(yup.number())
      .min(2, "Please select at least two taxes")
      .test(
        "no-zero-tax",
        "You cannot create the tax group with Zero rate",
        (taxIds, context) => {
          const { tax_objects } = context.parent;
          const selectedTaxes =
            taxIds &&
            taxIds.map((taxId) =>
              tax_objects.find((tax: any) => tax.id === taxId)
            );
          const hasZeroRate =
            selectedTaxes && selectedTaxes.some((tax) => tax.rate === 0);
          const hasDifferentRates =
            selectedTaxes && selectedTaxes.some((tax) => tax.rate !== 0);
          return !(hasZeroRate && hasDifferentRates);
        }
      )
      .test(
        "validate-cess-igst",
        "You cannot group IGST with  CGST and/or SGST/UTGST",
        (taxIds, context) => {
          const { tax_objects } = context.parent;
          const selectedTaxes =
            taxIds &&
            taxIds.map((taxId) =>
              tax_objects.find((tax: any) => tax.id === taxId)
            );
          const hasCESS =
            selectedTaxes &&
            selectedTaxes.some((tax) => tax.tax_type === "CESS");
          const hasIGST =
            selectedTaxes &&
            selectedTaxes.some((tax) => tax.tax_type === "IGST");

          const hasCGST =
            selectedTaxes &&
            selectedTaxes.some((tax) => tax.tax_type === "CGST");

          const hasSGST =
            selectedTaxes &&
            selectedTaxes.some((tax) => tax.tax_type === "SGST");
          const hasUTGST =
            selectedTaxes &&
            selectedTaxes.some((tax) => tax.tax_type === "UTGST");

          return !(hasCESS && hasIGST && (hasCGST || hasSGST || hasUTGST));
        }
      )
      .test(
        "no-group-igst-cgst-sgst",
        "You cannot group IGST with CGST or SGST",
        (taxIds, context) => {
          const { tax_objects } = context.parent;
          const selectedTaxes =
            taxIds &&
            taxIds.map((taxId) =>
              tax_objects.find((tax: any) => tax.id === taxId)
            );

          const hasIGST =
            selectedTaxes &&
            selectedTaxes.some((tax) => tax.tax_type === "IGST");
          const hasCGSTorSGST =
            selectedTaxes &&
            selectedTaxes.some(
              (tax) => tax.tax_type === "CGST" || tax.tax_type === "SGST"
            );
          return !(hasIGST && hasCGSTorSGST);
        }
      )
      .test(
        "no-group-igst-utgst",
        "You cannot group IGST with UTGST",
        (taxIds, context) => {
          const { tax_objects } = context.parent;
          const selectedTaxes =
            taxIds &&
            taxIds.map((taxId) =>
              tax_objects.find((tax: any) => tax.id === taxId)
            );

          const hasIGST =
            selectedTaxes &&
            selectedTaxes.some((tax) => tax.tax_type === "IGST");
          const hasUTGST =
            selectedTaxes &&
            selectedTaxes.some((tax) => tax.tax_type === "UTGST");
          return !(hasIGST && hasUTGST);
        }
      )
      .test(
        "no-group-sgst-utgst",
        "You cannot group SGST with UTGST",
        (taxIds, context) => {
          const { tax_objects } = context.parent;
          const selectedTaxes =
            taxIds &&
            taxIds.map((taxId) =>
              tax_objects.find((tax: any) => tax.id === taxId)
            );

          const hasSGST =
            selectedTaxes &&
            selectedTaxes.some((tax) => tax.tax_type === "SGST");
          const hasUTGST =
            selectedTaxes &&
            selectedTaxes.some((tax) => tax.tax_type === "UTGST");
          return !(hasSGST && hasUTGST);
        }
      )
      .test(
        "validate-same-tax-types-two-time",
        "A tax group must contain CGST and SGST or UTGST tax rates. Add SGST or UTGST and try again",
        (taxIds, context) => {
          const { tax_objects } = context.parent;
          const selectedTaxes =
            taxIds &&
            taxIds.map((taxId) =>
              tax_objects.find((tax: any) => tax.id === taxId)
            );
          return !(
            selectedTaxes?.length === 2 &&
            selectedTaxes[0].tax_type === selectedTaxes[1].tax_type &&
            (selectedTaxes[0].tax_type === "CGST" ||
              selectedTaxes[0].tax_type === "SGST" ||
              selectedTaxes[0].tax_type === "UTGST")
          );
        }
      )
      .test(
        "validate-cess-utgst",
        "A tax group must contain CGST and SGST or UTGST tax rates. Add CGST and try again",
        (taxIds, context) => {
          const { tax_objects } = context.parent;
          const selectedTaxes =
            taxIds &&
            taxIds.map((taxId) =>
              tax_objects.find((tax: any) => tax.id === taxId)
            );
          let result = true;
          const hasCESS =
            selectedTaxes &&
            selectedTaxes.some((tax) => tax.tax_type === "CESS");
          const hasUTGST =
            selectedTaxes &&
            selectedTaxes.some((tax) => tax.tax_type === "UTGST");
          const hasCGST =
            selectedTaxes &&
            selectedTaxes.some((tax) => tax.tax_type === "CGST");
          const hasSGST =
            selectedTaxes &&
            selectedTaxes.some((tax) => tax.tax_type === "SGST");
          if (hasCESS && hasUTGST && !hasCGST) result = false;
          if (hasCESS && hasSGST && !hasCGST) result = false;
          return result;
        }
      )
      .test(
        "validate-cess-cgst-sgst",
        "A tax group must contain CGST and SGST or UTGST tax rates. Add SGST or UTGST and try again",
        (taxIds, context) => {
          const { tax_objects } = context.parent;
          const selectedTaxes =
            taxIds &&
            taxIds.map((taxId) =>
              tax_objects.find((tax: any) => tax.id === taxId)
            );
          const hasCESS =
            selectedTaxes &&
            selectedTaxes.some((tax) => tax.tax_type === "CESS");
          const hasUTGST =
            selectedTaxes &&
            selectedTaxes.some((tax) => tax.tax_type === "UTGST");
          const hasCGST =
            selectedTaxes &&
            selectedTaxes.some((tax) => tax.tax_type === "CGST");
          const hasSGST =
            selectedTaxes &&
            selectedTaxes.some((tax) => tax.tax_type === "SGST");
          return !(hasCESS && hasCGST && !hasUTGST && !hasSGST);
        }
      )
      .test(
        "validate-tax-types",
        "You cannot group same type of taxes in single tax group",
        (taxIds, context) => {
          const { tax_objects } = context.parent;
          const selectedTaxes =
            taxIds &&
            taxIds.map((taxId) =>
              tax_objects.find((tax: any) => tax.id === taxId)
            );
          const haveSameTaxType =
            selectedTaxes &&
            selectedTaxes.reduce((result, tax1, index, array) => {
              if (result) {
                return true;
              }
              return array.slice(index + 1).some((tax2) => {
                return tax1.tax_type === tax2.tax_type;
              });
            }, false);

          return !haveSameTaxType;
        }
      ),
    is_checked: yup.boolean().test({
      name: "is_checked",
      message: "Please read and accept the Terms and Conditions",
      test: function (value) {
        const checked = this.options.context?.is_checked;

        if (checked === false && isChanged && id) {
          return false;
        }
        return true;
      },
    }),
  });
