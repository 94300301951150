import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  currentUserSelector,
  paymentTermsList,
  paymentTermsSelector,
} from "../commonSlice";
import Select from "react-select";
import { SelectCustomStyleCustomer } from "./SelectCustomStyleCustomer";

type Props = {
  paymentTermId: number | string | null;
  haveCustom?: boolean;
  isCustom?: boolean;
  onChange: (data: { name: string; value: string | number }) => void;
  dropPosition?: any;
};
function PaymentTermSelect(props: Props) {
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const paymentTerms = useAppSelector(paymentTermsSelector);

  useEffect(() => {
    getPaymentsTerms();
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, [props.isCustom]);

  const getPaymentsTerms = async () => {
    dispatch(paymentTermsList(currentUserInfo.organization_id));
  };

  const paymentTermOptions = () => {
    let paymentTermArray: { label: string; value: string | number }[] = [];
    if (paymentTerms?.length > 0) {
      paymentTermArray = paymentTerms.map((term, index) => {
        return {
          label: term.term_name,
          value: term.id,
        };
      });
      if (props.haveCustom) {
        paymentTermArray.push({ label: "Custom", value: "Custom" });
      }
    }
    return paymentTermArray;
  };

  return (
    <Select
      name="payment_term_id"
      id="terms"
      options={paymentTermOptions()}
      className="currency-select form-select custom-select selectpicker"
      value={
        props.isCustom || props.paymentTermId === null
          ? {
              label: "Custom",
              value: "Custom",
            }
          : props.paymentTermId === 0
          ? { label: "", value: "" }
          : {
              label: paymentTerms?.filter((term) => {
                return term.id == props.paymentTermId;
              })[0]?.term_name,
              value: props.paymentTermId,
            }
      }
      onChange={(e) => {
        if (e?.value)
          props.onChange({ name: "payment_term_id", value: e.value });
      }}
      styles={SelectCustomStyleCustomer}
      isSearchable={false}
      menuPlacement={props.dropPosition? props.dropPosition: "top"}
    />
  );
}

export default PaymentTermSelect;
