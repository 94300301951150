import React, { useEffect, useState, useCallback } from "react";
import useCommonData from "../../hooks/useCommon";
import MigrationDateContainer from "./migrationDateContainer";
import Select, { SingleValue } from "react-select";
import { customSelectStyle } from "../../common/components/SelectCustomStyle";
import { fetchMigrationDate, saveMigrationDate } from "./migrationSlice";
import { SuccessToaster, ErrorToaster } from "../../../helpers/toastHelper";
import { monthOptions } from "../../constants/constants";
import { SelectOption } from "../../../types";
import { message } from "../../constants/messages";
import { useMeApiTrigger } from "../../hooks/useTriggerOrgDetails";
import "./MigrationDate.css";

const generateYearOptions = (numYears: number) => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i < numYears; i++) {
    const year = currentYear - i;
    years.push({ value: String(year), label: String(year) });
  }
  return years;
};
const yearOptions = generateYearOptions(15);

function MigrationDate() {
  const [selectedDate, setSelectedDate] = useState<{
    month: SingleValue<SelectOption>;
    year: SingleValue<SelectOption>;
  }>({ month: null, year: null });

  const isSaveDisabled = !selectedDate.month || !selectedDate.year;
  const [openingBalanceExists, setOpeningBalanceExists] = useState(true);
  const { dispatch, currentUserInfo } = useCommonData();
  const { triggerMeApi } = useMeApiTrigger();

  const getMigrationSettings = useCallback(async () => {
    const res = await dispatch(
      fetchMigrationDate({
        orgId: currentUserInfo.organization_id,
      })
    );
    let data = res.payload;
    if (data.migration_date) {
      const [year, month] = data.migration_date.split("-");
      setSelectedDate({
        month: monthOptions.find((option) => option.value === month) || null,
        year: yearOptions.find((option) => option.value === year) || null,
      });
      setOpeningBalanceExists(data.opening_balance_exists);
    }
  }, [currentUserInfo.organization_id, dispatch]);

  useEffect(() => {
    getMigrationSettings();
  }, [getMigrationSettings]);

  const handleSubmit = async (values: {
    month: SelectOption;
    year: SelectOption;
  }) => {
    const migrationDate = `${values.year.value}-${values.month.value}-01`;
    await dispatch(
      saveMigrationDate({
        orgId: currentUserInfo.organization_id,
        migration_date: migrationDate,
      })
    );
    triggerMeApi();
    SuccessToaster(
      "Migration date updated successfully!",
      "migrate-date-save-success"
    );
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!openingBalanceExists && selectedDate.month && selectedDate.year) {
      await handleSubmit({
        month: selectedDate.month,
        year: selectedDate.year,
      });
    } else {
      ErrorToaster(message().editMigrationDate);
    }
  };

  const getLastDayOfPrevMonth = (year: string, month: string) => {
    const date = new Date(parseInt(year), parseInt(month) - 1, 0);
    const day = String(date.getDate()).padStart(2, "0");
    const yearStr = date.getFullYear();
    return `${day} ${monthOptions[date.getMonth()].label} ${yearStr}`;
  };

  return (
    <MigrationDateContainer heading="Migration Date">
      <form
        action=""
        name="gst-settings-form"
        id="gst-settings-form"
        className="gst-settings-form w-100"
        onSubmit={handleFormSubmit}
      >
        <div className="migration-wrapper">
          <div className="migration-page-details">
            Enter the date that you began processing all your transactions in
            Finbooks
          </div>
          <div className="migration-month-and-year">
            <div className="migration-month">
              <label htmlFor="region">Month</label>
              <Select
                name="month"
                id="month"
                className="state-select-form-select-custom-select"
                placeholder={"Month"}
                styles={customSelectStyle}
                isSearchable={false}
                options={monthOptions}
                value={selectedDate.month}
                onChange={(selectedOption) =>
                  setSelectedDate((prev) => ({
                    ...prev,
                    month: selectedOption,
                  }))
                }
              />
              <span className="error"></span>
            </div>
            <div className="migration-month">
              <label htmlFor="year">Year</label>
              <Select
                name="year"
                id="year"
                className="state-select-form-select-custom-select"
                placeholder={"Year"}
                styles={customSelectStyle}
                isSearchable={false}
                options={yearOptions}
                value={selectedDate.year}
                onChange={(selectedOption) =>
                  setSelectedDate((prev) => ({ ...prev, year: selectedOption }))
                }
              />
              <span className="error"></span>
            </div>
          </div>
          {selectedDate.month && selectedDate.year && (
            <div className="opening-balances">
              For this migration date you need to enter opening balances as at:{" "}
              {getLastDayOfPrevMonth(
                selectedDate.year.value,
                selectedDate.month.value
              )}
            </div>
          )}
          <section className="form-button-section">
            <div
              className="button-fields form-button-wrapper"
              id="form-btn-gst-profile"
            >
              <button
                className="save-button"
                type="submit"
                disabled={isSaveDisabled}
              >
                Save
              </button>
              <button
                className="text-decoration-none cancel-button close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                type="button"
                onClick={getMigrationSettings}
              >
                Cancel
              </button>
            </div>
          </section>
        </div>
      </form>
    </MigrationDateContainer>
  );
}

export default MigrationDate;
