import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  currentUserSelector,
  getCountries,
  orgCurrencyListSelector,
  paymentTermsList,
  setLoaderState,
} from "../../../../common/commonSlice";
import { customerDetails } from "../../customerSlice";
import CustomerDetailHeader from "./CustomerDetailHeader";
import ConsumerStatement from "../../../../common/components/detailsPage/ConsumerStatement";
import "./CustomerDetail.css";
import { IsFeatureEnabled } from "../../../../../helpers/featureFlagHelper";
import { customerDetailsValues } from "../../../../../types";
import ConsumerOverView from "../../../../common/components/detailsPage/overView/ConsumerOverView";
import CustomerTransactions from "./transactions/CustomerTransactions";
import {
  addAddress,
  addressReset,
} from "../customerForm/AddressDetails/addressSlice";
import usePreservedLocation from "../../../../hooks/usePreservedLocation";
import { setErrorID } from "../../../../../appSlice";
import { noAccessErrorCodes } from "../../../../constants/constants";
import OutstandingAmount from "../../../../common/components/detailsPage/overView/OutstandingAmount";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { AccountsImg, SalesDefault } from "../../../../../assets/images";
import AuditTrail from "../../../../common/components/auditTrail/AuditTrail";

function CustomerDetail() {
  type LocationProps = {
    haveTransaction: boolean;
  };
  const location = usePreservedLocation();
  const dispatch = useAppDispatch();
  const { editId } = useParams();
  const locationState = location?.state as LocationProps;
  const existTransaction = locationState?.haveTransaction
    ? locationState.haveTransaction
    : false;
  const currentUserInfo = useAppSelector(currentUserSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const detailsInitialValues = {
    id: "",
    company_name: "",
    email: "",
    phone: "",
    display_name: "",
    short_code: "",
    address_details: [],
    contact_persons: [],
    is_mutable: true,
    other_details: {
      currency_id: "",
      currency_code: "",
      gst_registration_type: "",
      gstin: "",
      opening_balance: "",
      pan_number: "",
      payment_term_id: "",
      payment_term: "",
      tags: [],
      // tax_preference: "",
      // exemption_reason_id: 0,
      // exemption_reason_name: ""
    },
    sales_default: {
      sales_account_name: "",
      payment_term: "",
      discount_type: "",
      discount: 0,
      discount_account_name: "",
    },
    organization_id: 0,
    organization_name: "",
    balance_amount: 0,
    unused_amount: 0,
  };
  const [consumerData, setConsumerData] =
    useState<customerDetailsValues>(detailsInitialValues);
  const [subModulePermission, setSubModulePermission] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState("overview");

  let subModules: any[] = [];
  useEffect(() => {
    const organizationRole = JSON.parse(
      sessionStorage.getItem("organizationRole") || "{}"
    );
    subModules = organizationRole.length
      ? organizationRole.map((permission: any) => {
          return permission.subModule;
        })
      : [];
    let unique = Array.from(new Set(subModules));
    setSubModulePermission(unique);
  }, [
    currentUserInfo.organization_id,
    sessionStorage.getItem("organizationRole"),
  ]);

  useEffect(() => {
    if (editId) {
      getCustomerDetails(Number(editId));
    }
  }, [editId, currentUserInfo.organization_id, existTransaction]);
  useEffect(() => {
    const storedTab = sessionStorage.getItem("activeCustomerTab");
    if (storedTab) {
      setActiveTab(storedTab);
    }
    dispatch(addressReset());
  }, []);

  useEffect(() => {
    getAllCountries();
  }, [currentUserInfo.organization_id]);

  /**
   * Fetch all country list
   */
  const getAllCountries = async () => {
    await dispatch(getCountries());
  };
  /**
   * Setting active tab in session storage
   */
  function handleTabClick(tab: string) {
    setActiveTab(tab);
    sessionStorage.setItem("activeCustomerTab", tab);
  }
  /**
   * Fetch customer details by id
   */
  const getCustomerDetails = async (editId: number) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      customerDetails({
        id: editId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        let customerInfo = response;
        customerInfo["is_mutable"] = existTransaction ? false : true;
        customerInfo["display_name"] = response.customer_display_name;
        customerInfo["email"] = response.customer_email;
        customerInfo["phone"] = response.customer_phone;
        customerInfo["balance_amount"] = response.receivables;
        customerInfo["unused_amount"] = response.unused_credits;
        setConsumerData(customerInfo);
        let addressData = response.address_details;
        dispatch(addressReset());
        sessionStorage.setItem("consumerId", editId.toString());
        sessionStorage.setItem(
          "consumerCurrency",
          response.other_details.currency_code
        );
        addressData.forEach(async (addressDetails: any) => {
          let address = {
            country_id: addressDetails.country_id
              ? addressDetails.country_id
              : 0,
            address: addressDetails.address,
            city: addressDetails.city,
            state_id: addressDetails.state_id ? addressDetails.state_id : 0,
            zip_code: addressDetails.zip_code,
            phone: addressDetails.phone ? addressDetails.phone : null,
            is_billing: addressDetails.is_billing,
            is_shipping: addressDetails.is_shipping,
            is_primary: addressDetails.is_primary,
            id: addressDetails.id,
            state_name: addressDetails.state_name,
            country_name: addressDetails.country_name,
          };
          dispatch(addAddress(address));
        });
      } else {
        // navigate("/customers");
        dispatch(setErrorID(noAccessErrorCodes.customerDetail));
      }
    }
  };

  const getPaymentsTerms = async () => {
    dispatch(paymentTermsList(currentUserInfo.organization_id));
  };

  return (
    <div className="">
      <div
        className="card card-dashboard customer-detail-card w-100"
        id="customer-detail-card"
      >
        <CustomerDetailHeader consumerDetails={consumerData} />
        <div className="tab-container w-100 px-0">
          <ul
            className="nav nav-pills pills-tab mt-0"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className={
                  activeTab === "overview" ? "nav-link active" : "nav-link"
                }
                id="pills-overview-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-overview"
                type="button"
                role="tab"
                aria-controls="pills-overview"
                aria-selected="true"
                onClick={() => handleTabClick("overview")}
              >
                Overview
              </button>
            </li>
            {subModulePermission.includes("Statement of Accounts") &&
            IsFeatureEnabled(
              "Statement of Accounts",
              currentUserInfo.disabled_feature
            ) ? (
              <li className="nav-item" role="presentation">
                <button
                  className={
                    activeTab === "statement" ? "nav-link active" : "nav-link"
                  }
                  id="pills-statement-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-statement"
                  type="button"
                  role="tab"
                  aria-controls="pills-statement"
                  aria-selected="false"
                  onClick={() => handleTabClick("statement")}
                >
                  Statement
                </button>
              </li>
            ) : null}
            {(subModulePermission.includes("Invoices") &&
              IsFeatureEnabled("Invoices", currentUserInfo.disabled_feature)) ||
            (subModulePermission.includes("Credit Notes") &&
              IsFeatureEnabled(
                "Credit Notes",
                currentUserInfo.disabled_feature
              )) ||
            (subModulePermission.includes("Payments Received") &&
              IsFeatureEnabled(
                "Payments Received",
                currentUserInfo.disabled_feature
              )) ? (
              <li className="nav-item" role="presentation">
                <button
                  className={
                    activeTab === "transaction" ? "nav-link active" : "nav-link"
                  }
                  id="pills-transaction-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-transaction"
                  type="button"
                  role="tab"
                  aria-controls="pills-transaction"
                  aria-selected="false"
                  onClick={() => handleTabClick("transaction")}
                >
                  Transactions
                </button>
              </li>
            ) : null}
          </ul>
          <div className="tab-content pb-0" id="pills-tabContent">
            <div
              className={
                activeTab === "overview"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="pills-overview"
              role="tabpanel"
              aria-labelledby="pills-overview-tab"
              tabIndex={0}
            >
              <div className="tab-content-overview outstanding-data">
                <OutstandingAmount
                  title="Receivables & Credits"
                  data={[
                    {
                      label: "Receivables",
                      value: consumerData.balance_amount
                        ? consumerData.other_details.currency_code +
                          " " +
                          NumberFormat(
                            Number(consumerData.balance_amount),
                            consumerData.other_details.currency_code,
                            orgCurrencyList
                          )
                        : consumerData.other_details.currency_code + " " + 0,
                      className: "outstanding-red",
                    },
                    {
                      label: "Unused Credits",
                      value: consumerData.unused_amount
                        ? consumerData.other_details.currency_code +
                          " " +
                          NumberFormat(
                            Number(consumerData.unused_amount),
                            consumerData.other_details.currency_code,
                            orgCurrencyList
                          )
                        : consumerData.other_details.currency_code + " " + 0,
                      className: "outstanding-green",
                    },
                  ]}
                  imgSrc={AccountsImg}
                />
              </div>

              <div className="tab-content-overview outstanding-data">
                <OutstandingAmount
                  title="Sales Default"
                  data={[
                    {
                      label: "Sales Account",
                      value: consumerData.sales_default.sales_account_name,
                    },
                    {
                      label: "Payment Terms",
                      value: consumerData.other_details.payment_term,
                    },
                    {
                      label:
                        consumerData.sales_default.discount_type === "Absolute"
                          ? `Discount (${consumerData.other_details.currency_code})`
                          : "Discount (%)",
                      value: consumerData.sales_default.discount,
                    },
                    {
                      label: "Discount Account",
                      value: consumerData.sales_default.discount_account_name,
                    },
                  ]}
                  imgSrc={SalesDefault}
                />
              </div>
              <div className="tab-content-overview">
                <ConsumerOverView
                  consumerDetails={consumerData}
                  fetchConsumerDetail={getCustomerDetails}
                  consumerType={"customer"}
                />
              </div>
              <AuditTrail
                title="Audit Trail"
                transactionType="customers"
                transactionId={Number(consumerData.id)}
              />
            </div>
            {subModulePermission.includes("Statement of Accounts") ? (
              <div
                className={
                  activeTab === "statement"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="pills-statement"
                role="tabpanel"
                aria-labelledby="pills-statement-tab"
                tabIndex={0}
              >
                <ConsumerStatement
                  consumerDetails={consumerData}
                  consumerType="customer"
                />
              </div>
            ) : null}
            {(subModulePermission.includes("Invoices") &&
              IsFeatureEnabled("Invoices", currentUserInfo.disabled_feature)) ||
            (subModulePermission.includes("Credit Notes") &&
              IsFeatureEnabled(
                "Credit Notes",
                currentUserInfo.disabled_feature
              )) ||
            (subModulePermission.includes("Payments Received") &&
              IsFeatureEnabled(
                "Payments Received",
                currentUserInfo.disabled_feature
              )) ? (
              <div
                className={
                  activeTab === "transaction"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="pills-transaction"
                role="tabpanel"
                aria-labelledby="pills-transaction-tab"
                tabIndex={0}
              >
                <CustomerTransactions
                  isSameOrg={
                    consumerData.organization_id ===
                    currentUserInfo.organization_id
                  }
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerDetail;
