import {
  CreateAddressValues,
  CreateContactValues,
  EditCustomerValues,
} from "./../../../types";
import { Api } from "../../../constants";
import { get, post, put, remove } from "../../../helpers/apiHelper";
import { CreateCustomerValues } from "../../../types";

/**
 * API call for creating a new customer
 */
export async function createNewCustomer(data: {
  values: CreateCustomerValues;
  orgId: number;
}) {
  const result = await post(
    Api.customerManagement +
      Api.customer +
      `/create?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}
/**
 * API for checking the customer short code is already exist or not
 */
export async function shortCodeExist(data: {
  customerName: string;
  shortCode: string;
  orgId: number;
}) {
  const result = await get(
    Api.customerManagement +
      Api.customer +
      "/short-code-exist?customer_display_name=" +
      data.customerName +
      "&short_code=" +
      data.shortCode +
      `&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for Editing a customer
 */
export async function editCustomer(data: {
  id: number;
  editCustomerInputs: EditCustomerValues;
  orgId: number;
}) {
  const result = await put(
    Api.customerManagement +
      Api.customer +
      "/edit" +
      `/${data.id}?current_organization=${data.orgId}`,
    data.editCustomerInputs
  );
  return result;
}

/**
 * API call for getting all customers
 */
export async function getCustomersList(data: {
  page: number;
  itemsPerPage: number;
  customerName: string;
  orgId: number;
  orgIds: number[];
}) {
  const orgIds = data.orgIds.map((orgId) => {
    return `&org_ids=${orgId}`;
  });
  const result = await get(
    Api.customerManagement +
      Api.customer +
      "/list" +
      `?page_num=${data.page}&page_size=${data.itemsPerPage}&customer_name=${
        data.customerName
      }&current_organization=${data.orgId} ${orgIds.join("")}`
  );
  return result;
}

/**
 * API call for getting customer search suggestions
 */
export async function getSearchSuggestions(data: {
  customerName: string;
  orgId: number;
}) {
  const result = await get(
    Api.customerManagement +
      Api.customer +
      "/suggestion?customer_name=" +
      data.customerName +
      `&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for getting Customer details
 */
export async function getCustomerDetails(data: { id: number; orgId: number }) {
  const result = await get(
    Api.customerManagement +
      Api.customer +
      "/detail" +
      `/${data.id}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for getting customer details for other transactions
 */
export async function getCustomerDetailsForTransaction(data: {
  customerId: number;
  orgId: number;
}) {
  const result = await get(
    Api.customerManagement +
      Api.customer +
      `/transaction-detail/${data.customerId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for deleting Customer
 */
export async function deleteCustomer(data: { id: number; orgId: number }) {
  const result = await remove(
    Api.customerManagement +
      Api.customer +
      "/delete" +
      `/${data.id}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for creating a Customer Address
 */
export async function createNewCustomerAddress(data: {
  id: number;
  addressInputs: CreateAddressValues[];
  orgId: number;
}) {
  const result = await post(
    Api.customerManagement +
      Api.customerAddress +
      "/create" +
      `?customer_id=${data.id}&current_organization=${data.orgId}`,
    data.addressInputs
  );
  return result;
}

/**
 * API call for editing customer address
 */
export async function editCustomerAddress(data: {
  customerId: number;
  addressInputs: CreateAddressValues[];
  orgId: number;
}) {
  const result = await put(
    Api.customerManagement +
      Api.customerAddress +
      `/edit/${data.customerId}?current_organization=${data.orgId}`,
    data.addressInputs
  );
  return result;
}

/**
 * API call for deleting customer address
 */
export async function deleteAddress(data: {
  customerId: number;
  addressId: number[];
  orgId: number;
}) {
  const result = await remove(
    Api.customerManagement +
      Api.customerAddress +
      `/delete/${data.customerId}?current_organization=${data.orgId}`,
    data.addressId
  );
  return result;
}

/**
 * API call for creating new Customer Contact
 */
export async function createNewCustomerContact(data: {
  id: number;
  contactInputs: CreateContactValues;
  orgId: number;
}) {
  const result = await post(
    Api.customerManagement +
      Api.customerContact +
      "/create" +
      `?customer_id=${data.id}&current_organization=${data.orgId}`,
    data.contactInputs
  );
  return result;
}

/**
 * API call for edit customer contact
 */
export async function editCustomerContact(data: {
  customerId: number;
  contactId: number;
  editContactInputs: CreateContactValues;
  orgId: number;
}) {
  const result = await put(
    Api.customerManagement +
      Api.customerContact +
      "/edit" +
      `/${data.customerId}/${data.contactId}?current_organization=${data.orgId}`,
    data.editContactInputs
  );
  return result;
}

/**
 * API call for Deleting Customer Contact
 */

export async function deleteCustomerContact(data: {
  customerId: number;
  contactId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.customerManagement +
      Api.customerContact +
      "/delete" +
      `/${data.customerId}/${data.contactId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for getting all currency list
 */
export async function getCurrencyList(orgId: number) {
  const result = await get(
    Api.currency + `/list?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call for getting all GST Types list
 */
export async function getGstTypesList(orgId: number) {
  const result = await get(
    Api.customerManagement +
      Api.gstTypes +
      `/list?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call for getting customer drop down list
 */
export async function getCustomerDropdownList(orgId: number) {
  const result = await get(
    Api.customerManagement +
      Api.customer +
      `/dropdown-list?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call for getting customer list for filter
 */
export async function getCustomerFilterList(data: {
  orgId: number;
  organizationIds: number[];
}) {
  const orgIds = data.organizationIds.map((orgId) => {
    return `&organization_ids=${orgId}`;
  });
  const result = await get(
    Api.customerManagement +
      Api.customer +
      `/transaction-filter-dropdown?current_organization=${
        data.orgId
      } ${orgIds.join("")}`
  );
  return result;
}
/**
 * Api to get the transaction limit status
 */
export async function transactionLimitStatus(orgId: number) {
  const result = await get(
    Api.customerManagement +
      `transaction-limit-status?current_organization=${orgId}`
  );
  return result;
}
/**
 * API call for getting customer list for soa
 */
export async function getCustomerListForSoa(data: {
  orgId: number;
  currentOrgId: number;
  isIntegrated?: boolean;
}) {
  const result = await get(
    Api.customerManagement +
      Api.customer +
      `/dropdown-list-for-soa?organization_id=${data.orgId}&is_integrated=${
        data.isIntegrated ? data.isIntegrated : false
      }&current_organization=${data.currentOrgId}`
  );
  return result;
}
/**
 * API call for getting no-exemption reasons list for customer
 */
export async function getTaxExemptionReasonListForCustomer(data: {
  orgId: number;
  exemption_id?: number;
}) {
  const result = await get(
    Api.customerManagement +
      Api.taxExemption +
      // `/dropdown?organization_id=${data.orgId}&exemption_id=${data.exemption_id?data.exemption_id:""}`
      `/dropdown?current_organization=${data.orgId}${data.exemption_id?"&exemption_id="+data.exemption_id:""}`
  );
  return result;
}