import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { InventoryItemValues } from "../../../../../types";
import { CreateItemModal } from "../../../../home/items/components/createItemFormModal/createItemFormModal";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import "./itemSelect.css";
import { useItemSelect } from "./ItemSelectProvider";
import { Span } from "../../htmlTags/Span";

type Props = {
  selectedItem: { id?: number | null; name: string };
  itemIndex: number;
  error: string;
  wrapperClass?: string;
  onItemChange?: (item: InventoryItemValues, itemIndex: number) => void;
  onInputItemChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | {
          target: {
            name: string;
            value: string;
          };
        },
    index: number
  ) => void;
  placeholder?: string;
  onBlur?: (e: any) => void;
  disabled?: boolean;
};
function ItemSelect(props: Props, ref: any) {
  const { editId } = useParams();

  const [selectValue, setSelectValue] = useState("");
  const [selectId, setSelectId] = useState<number>();
  const { itemsRolePermission } = usePermissionHandler();
  const { itemDropdownList, fetchItemDropdown } = useItemSelect();

  useEffect(() => {
    if (props.selectedItem.id) {
      setSelectId(props.selectedItem.id);
    }
    if (props.selectedItem.name) {
      setSelectValue(props.selectedItem.name);
    }
  }, [props.selectedItem]);

  useEffect(() => {
    if (selectId) {
      let selectedItem = itemDropdownList.filter(
        (item: InventoryItemValues) => item.id === selectId
      )[0];
      if (selectedItem && props.onItemChange && !editId)
        props.onItemChange(selectedItem, props.itemIndex);
    }
  }, [JSON.stringify(itemDropdownList)]);

  const handleChange = (item: InventoryItemValues) => {
    if (props.onItemChange) {
      setSelectValue(item.name);
      if (item.id) setSelectId(item.id);
      props.onItemChange(item, props.itemIndex);
    }
  };
  useEffect(() => {
    resetDropDown();
  }, []);
  useEffect(() => {
    $(".dropdown-toggle").dropdown("hide");
  }, [props.error]);
  const resetDropDown = () => {
    $(".dropdown-toggle").on("hide.bs.dropdown", function (e) {
      let elem = $(this).parent().find(".dropdown-menu");
      elem.scrollTop(0);
    });
  };

  return (
    <>
      <div
        className={`btn-group itemselect-wrapper accounts-dropdown  ${
          props.wrapperClass ? props.wrapperClass : ""
        }`}
      >
        <input
          type="text"
          name="item_name"
          className={`${props.error ? "error " : ""} dropdown-toggle iteminput`}
          placeholder={props.placeholder ? props.placeholder : "Choose item"}
          data-bs-toggle="dropdown"
          aria-expanded="false"
          value={selectValue ? selectValue.toString() : ""}
          onChange={(e) => {
            // if (props.componentType !== "recurringInvoice") {
            setSelectValue(e.target.value);
            props.onInputItemChange(e, props.itemIndex);
            // }
          }}
          autoComplete="off"
          onKeyDown={(e) => {
            let code = e.keyCode || e.which;
            if (code !== 9) {
              $(e.target).dropdown("show");
            }
          }}
          onBlur={props.onBlur}
          disabled={props.disabled ? props.disabled : false}
        />
        {props.error && <Span className="error">{props.error}</Span>}
        <ul className="dropdown-menu dropdown-menu-start">
          {itemDropdownList.length ? (
            itemDropdownList.map((item: any, index: number) => {
              return (
                <li
                  onClick={() => {
                    handleChange(item);
                  }}
                  key={index}
                >
                  <a
                    className={
                      selectId === item.id
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="account">{item.name}</span>
                    <span className="account-type">{`${
                      item.hsn_or_sac_code
                        ? `HSN/SAC: ${item.hsn_or_sac_code} |`
                        : ``
                    }  Rate: ${item.currency_code} ${item.rate} `}</span>
                  </a>
                </li>
              );
            })
          ) : (
            <li className="no-result">
              <a
                className="dropdown-item no-result"
                href="#"
                onClick={(e) => e.preventDefault()}
              >
                <span className="no-result">No Items found</span>
              </a>
            </li>
          )}
          {itemsRolePermission.includes("Create") ? (
            <li>
              <Link
                className={"dropdown-item add-link-option"}
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <CreateItemModal fetchItemDropdown={fetchItemDropdown} />
              </Link>
            </li>
          ) : null}
        </ul>
      </div>
    </>
  );
}

export default ItemSelect;
