import { Api } from "../../../constants";
import {
  get,
  postFormData,
  put,
  post,
  remove,
} from "../../../helpers/apiHelper";
import {
  TaxableAmountCalculationValues,
  TotalAmountCalculationValues,
} from "../../../types";

/**
 * API call to get plan list
 */

export async function getSubscriptionList(data: {
  page: number;
  itemsPerPage: number;
  dateSortOrder: string;
  orgId: number;
  orgIds: number[];
  statusList: string[];
  CustomerList: number[];
  frequency: string[];
}) {
  let url =
    Api.subscription +
    "list" +
    `?page_num=${data.page}&page_size=${data.itemsPerPage}&current_organization=${data.orgId}`;

  if (data.orgIds.length !== 0) {
    const orgIds = data.orgIds
      .map((orgId) => {
        return `&org_ids=${orgId}`;
      })
      .join("");
    url += orgIds;
  }
  if (data.dateSortOrder !== "") {
    url += `&date_sort_order=${data.dateSortOrder}`;
  }

  if (data.statusList.length !== 0) {
    const statuses = data.statusList
      .map((status) => {
        return `&status_list=${status}`;
      })
      .join("");
    url += statuses;
  }

  if (data.CustomerList.length !== 0) {
    const customerIds = data.CustomerList.map((customerId) => {
      return `&customer_ids=${customerId}`;
    }).join("");
    url += customerIds;
  }

  if (data.frequency.length !== 0) {
    const frequencies = data.frequency
      .map((frequency) => {
        return `&frequency_list=${frequency}`;
      })
      .join("");
    url += frequencies;
  }
  const result = await get(url);
  return result;
}

/**
 * API call to update plan status (stop/resume/void)
 */
export async function updateSubscriptionStatus(data: {
  subscriptionId: number;
  status: string;
  orgId: number;
  change_status_option?: string;
  specific_date?: any;
  unbilled_charge?: string;
  scheduler_id?: number;
}) {
  const result = await put(
    Api.subscription +
      "change-status" +
      `/${data.subscriptionId}?current_organization=${data.orgId}${
        data.scheduler_id ? `&scheduler_id=${data.scheduler_id}` : ""
      }`,
    {
      status: data.status,
      change_status_option: data.change_status_option,
      specific_date: data.specific_date,
      charge_option: data.unbilled_charge,
    }
  );
  return result;
}

/**
 * API call to delete plan
 */

export async function deleteSubscription(data: {
  deleteSubscriptionId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.subscription +
      "delete" +
      `/${data.deleteSubscriptionId}?current_organization=${data.orgId}`
  );

  return result;
}

/**
 * API call to delete plan
 */

export async function deletePricing(data: {
  deletePricingId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.plan +
      "pricing/delete" +
      `/${data.deletePricingId}?current_organization=${data.orgId}`
  );

  return result;
}

/**
 * Api to get the transaction limit status
 */
export async function planTransactionLimitStatus(orgId: number) {
  const result = await get(
    Api.plan + `transaction-limit-status?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call to get plan dropdown list
 */

export async function getSubscriptionPlanDropdownList(data: {
  page: number;
  itemsPerPage: number;
  orgId: number;
}) {
  let url =
    Api.plan +
    "dropdown/plans" +
    `?page_num=${data.page}&page_size=${data.itemsPerPage}&current_organization=${data.orgId}`;

  const result = await get(url);
  return result;
}

/**
 * API call to get plan fequency dropdown list
 */

export async function getSubscriptionPlanFrequencyList(data: {
  planId: number;
  orgId: number;
}) {
  let url =
    Api.plan +
    `dropdown/billing-frequency/${data.planId}?current_organization=${data.orgId}`;

  const result = await get(url);
  return result;
}

/**
 * API call to get selected plan frequency Item details
 */

export async function getSubscriptionPlanFrequencyItems(data: {
  planId: number;
  pricingId: number;
  orgId: number;
  currencyId: number;
  conversionId: number;
}) {
  let url =
    Api.plan +
    `get-plan-item-detail?current_organization=${data.orgId}&plan_id=${data.planId}&pricing_id=${data.pricingId}&currency_id=${data.currencyId}&conversion_id=${data.conversionId}`;

  const result = await get(url);
  return result;
}
/**
 * API call to get Addon dropdown list
 */

export async function getSubscriptionAddonDropdownList(data: {
  page: number;
  itemsPerPage: number;
  orgId: number;
  frequency: string;
  addonIds: number[];
}) {
  let url =
    Api.addon +
    "dropdown/addons" +
    `?frequency=${data.frequency}&page_num=${data.page}&page_size=${data.itemsPerPage}&current_organization=${data.orgId}`;

  if (data.addonIds.length !== 0) {
    const addonId = data.addonIds
      .map((value) => {
        return `&addons_id=${value}`;
      })
      .join("");
    url += addonId;
  }
  const result = await get(url);
  return result;
}

/**
 * API call to get plan fequency dropdown list
 */

export async function getSubscriptionAddonFrequencyList(data: {
  addonId: number;
  orgId: number;
}) {
  let url =
    Api.addon +
    `dropdown/billing-frequency/${data.addonId}?current_organization=${data.orgId}`;

  const result = await get(url);
  return result;
}

/**
 * API call to get selected addon frequency Item details
 */

export async function getSubscriptionAddonFrequencyItems(data: {
  addonId: number;
  pricingId: number;
  orgId: number;
  currencyId: number;
  conversionId: number;
}) {
  let url =
    Api.addon +
    `get-addon-item-detail?current_organization=${data.orgId}&addon_id=${data.addonId}&pricing_id=${data.pricingId}&currency_id=${data.currencyId}&conversion_id=${data.conversionId}`;

  const result = await get(url);
  return result;
}

/**
 * API call to get Coupon Search list
 */

export async function getSubscriptionCouponList(data: {
  page: number;
  itemsPerPage: number;
  orgId: number;
  searchTerm: string;
  couponIds: number[];
  currencyId: number;
  conversionId: number;
  discountType: string;
}) {
  let url =
    Api.coupon +
    "dropdown" +
    `?page_num=${data.page}&page_size=${data.itemsPerPage}&current_organization=${data.orgId}&currency_id=${data.currencyId}&conversion_id=${data.conversionId}`;

  if (data.searchTerm && data.searchTerm !== "") {
    const searchTerm = `&coupon_name=${data.searchTerm}`;
    url += searchTerm;
  }

  if (data.couponIds.length !== 0) {
    const couponId = data.couponIds
      .map((value) => {
        return `&coupon_id=${value}`;
      })
      .join("");
    url += couponId;
  }

  if (!!data.discountType) {
    url += `&discount_type=${data.discountType}`;
  }
  const result = await get(url);
  return result;
}
export async function subscriptionTransactionLimitStatus(orgId: number) {
  const result = await get(
    Api.subscription + `transaction-limit-status?current_organization=${orgId}`
  );
  return result;
}

export async function getChildInvoiceList(data: {
  page: number;
  itemsPerPage: number;
  dateSortOrder: string;
  invoiceId: number;
  orgId: number;
}) {
  let url =
    Api.salesInvoice +
    "invoice/list" +
    `?page_num=${data.page}&page_size=${data.itemsPerPage}&recurring_invoice_id=${data.invoiceId}&current_organization=${data.orgId}`;
  //${data.invoiceId}

  if (data.dateSortOrder !== "") {
    url += `&date_sort_order=${data.dateSortOrder}`;
  }
  const result = await get(url);

  return result;
}

/**
 * API call for getting invoice detail
 */
export async function getSubscriptionOverviewDetails(data: {
  subscriptionId: number;
  orgId: number;
}) {
  const result = await get(
    Api.subscription +
      `overview/${data.subscriptionId}?current_organization=${data.orgId}`
    //${data.subscriptionId}24
  );
  return result;
}

export async function getSubscriptionDataForInvoice(data: {
  invoiceId: number;
  orgId: number;
}) {
  const result = await get(
    Api.salesInvoice +
      Api.invoice +
      `/subscription-data/${data.invoiceId}?current_organization=${data.orgId}`
  );
  return result;
}

export async function saveConfigurationProration(data: {
  orgId: number;
  isProrationEnabled: boolean;
}) {
  const result = await put(
    Api.subscription +
      "update-proration-preference" +
      `?current_organization=${data.orgId}`,
    {
      organization_id: data.orgId,
      is_proration_enabled: data.isProrationEnabled,
    }
  );
  return result;
}

export async function getProrationPreference(data: { orgId: number }) {
  const result = await get(
    Api.subscription +
      `get-proration-preference?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for getting subscription scheduler details
 */
export async function getSubscriptionSchedulerDetails(data: {
  subscriptionId: number;
  orgId: number;
}) {
  const result = await get(
    Api.subscription +
      `scheduler/details/${data.subscriptionId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to upload subscription attachments
 */
export async function subscriptionAttachments(data: {
  file: FormData;
  orgId: number;
}) {
  const result = await postFormData(
    Api.subscription +
      `subscriptions-attachments?current_organization=${data.orgId}`,
    data.file
  );
  return result;
}

/**
 * Api to delete subscription attachments
 */
export async function subscriptionAttachmentsDelete(data: {
  fileId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.subscription +
      `subscriptions-attachments/${data.fileId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for get subscription number
 */
export async function getSubscriptionNumber(orgId: number) {
  const result = await get(
    Api.subscription + `new-subscription-number?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call for calculating taxable amount
 */

export async function calculateTaxableAmount(data: {
  values: TaxableAmountCalculationValues;
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.subscription + `item/taxable-amount?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}

/**
 * API call for calculating invoice amount
 */

export async function calculateAmount(data: {
  values: TotalAmountCalculationValues;
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.subscription + `amount-calculation?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}

/**
 * API call for creating subscription
 */
export async function createNewSubscription(data: {
  values: any;
  orgId: number;
}) {
  const result = await post(
    Api.subscription + `create-subscription?current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call for checking the subscription number exist or not
 */
export async function subscriptionNumberExist(data: {
  subscriptionNumber: string;
  orgId: number;
  subscriptionId: number | null;
}) {
  const encodedName = encodeURIComponent(data.subscriptionNumber);
  const result = await get(
    Api.subscription +
      `subscription-number-exist?subscription_number=${encodedName}&subscription_id=${data.subscriptionId}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for geting next invoice date for the subscription
 */
export async function nextInvoiceDate(data: {
  invoicingoption: string;
  orgId: number;
  pricing_id: number;
  plan_id: number;
  start_date: string;
  total_amount: number;
  invoicing_cycle?: string;
  invoicing_cycle_fixed_count?: number;
  is_proration_enabled?: boolean;
}) {
  let url =
    Api.subscription +
    `next-invoice-date?invoicingoption=${data.invoicingoption}&plan_id=${data.plan_id}&pricing_id=${data.pricing_id}&start_date=${data.start_date}&total_amount=${data.total_amount}&current_organization=${data.orgId}`;

  if (data.invoicing_cycle) {
    url += `&invoicing_cycle=${data.invoicing_cycle}`;
  }

  if (
    data.invoicing_cycle &&
    data.invoicing_cycle_fixed_count &&
    data.invoicing_cycle === "FIXED"
  ) {
    url += `&invoicing_cycle_fixed_count=${data.invoicing_cycle_fixed_count}`;
  }

  if(data.is_proration_enabled) {
    url += `&proration=${data.is_proration_enabled}`
  }
  const result = await get(url);
  return result;
}

/**
 * API call for getting invoice edit detail
 */
export async function getSubscriptionDetails(data: {
  subscriptionId: number;
  orgId: number;
}) {
  const result = await get(
    Api.subscription +
      `edit-details/${data.subscriptionId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for  edit subscription
 */
export async function editSubscription(data: {
  id: number;
  editInvoiceInputs: any;
  orgId: number;
}) {
  const result = await put(
    Api.subscription +
      `edit-subscription/${data.id}?current_organization=${data.orgId}`,
    data.editInvoiceInputs
  );
  return result;
}

/**
 * API call for  cancel scheduler
 */
//1111
export async function cancelSchedulerHandler(data: {
  schedulerId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.subscription +
      `cancel/scheduler/${data.schedulerId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to get restore plan/addon frequency Item details
 */

export async function getSubscriptionItemsDetails(data: {
  itemId: number;
  pricingId: number;
  orgId: number;
  currencyId: number;
  conversionId: number;
  isPlan: boolean;
}) {
  let url =
    Api.subscription +
    `reset-pricing?current_organization=${data.orgId}&is_plan=${data.isPlan}&pricing_id=${data.pricingId}&item_id=${data.itemId}&currency_id=${data.currencyId}&conversion_id=${data.conversionId}`;

  const result = await get(url);
  return result;
}

/**
 * API call to get selected addon frequency converted Item details
 */

export async function getSubscriptionAddonConvertedItems(data: {
  addonId: number[];
  pricingId: number[];
  orgId: number;
  currencyId: number;
  conversionId: number;
}) {
  let url =
    Api.addon +
    `get-addon-detail-list?current_organization=${data.orgId}&currency_id=${data.currencyId}&conversion_id=${data.conversionId}`;

  if (data.addonId.length !== 0) {
    const addonId = data.addonId
      .map((addonId) => {
        return `&addon_id=${addonId}`;
      })
      .join("");
    url += addonId;
  }
  if (data.pricingId.length !== 0) {
    const pricingId = data.pricingId
      .map((pricingId) => {
        return `&pricing_id=${pricingId}`;
      })
      .join("");
    url += pricingId;
  }
  const result = await get(url);
  return result;
}

/**
 * API call to get converted Coupon list
 */

export async function getConvertedCouponList(data: {
  orgId: number;
  couponIds: number[];
  currencyId: number;
  conversionId: number;
}) {
  let url =
    Api.coupon +
    "get_coupons_list" +
    `?current_organization=${data.orgId}&currency_id=${data.currencyId}&conversion_id=${data.conversionId}`;

  if (data.couponIds.length !== 0) {
    const couponId = data.couponIds
      .map((value) => {
        return `&coupon_id=${value}`;
      })
      .join("");
    url += couponId;
  }

  const result = await get(url);
  return result;
}

/**
 * API to cancel the susbcription
 */

//11111
export async function cancelSubscription(data: {
  orgId: number;
  cancelData: any;
}) {
  let url =
    Api.subscription +
    "cancel-subscription" +
    `?current_organization=${data.orgId}`;

  const result = await put(url, data.cancelData);
  return result;
}

/**
 * API to reactivate the susbcription
 */

export async function reactivateSubscription(data: {
  orgId: number;
  reactivationData: any;
}) {
  let url =
    Api.subscription +
    "reactivate-subscription" +
    `?current_organization=${data.orgId}`;

  const result = await put(url, data.reactivationData);
  return result;
}

/**
 * API call for getting subscription scheduler details
 */
export async function getUnbilledChargesListDetails(data: {
  subscriptionId: number;
  orgId: number;
}) {
  const result = await get(
    Api.subscription +
      `unbilled-charges/list/${data.subscriptionId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call to delete unbilled charges
 */

export async function deleteUnbilledCharges(data: {
  deleteUnbilledId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.subscription +
      "unbilled-charges/delete" +
      `/${data.deleteUnbilledId}?current_organization=${data.orgId}`
  );

  return result;
}

/**
 * API call to invoice unbilled charges now
 */

export async function invoiceUnbilledChargesNow(data: {
  subscriptionId: number;
  orgId: number;
}) {
  const result = await post(
    Api.subscription +
      "unbilled-charges/invoice-now" +
      `/${data.subscriptionId}?current_organization=${data.orgId}`
  );

  return result;
}
