import { useParams } from "react-router-dom";
import CouponViewHeader from "./CouponViewHeader";
import { useEffect, useState } from "react";
import { CouponOverviewDataType, DeleteInitialData } from "../../couponType";
import { couponOverviewInitialData, deleteInitialData } from "../../CouponStateInitialization";
import { DocIconSquare } from "../../../../../assets/images";
import useCommonData from "../../../../hooks/useCommon";
import { useCouponOverViewDetail } from "../hooks/useCouponOverViewDetail";
import { DateFormatWithHyphen } from "../../../../../helpers/helper";

const CouponDetailBody = () => {
  const { couponDetailId } = useParams();
  const { dispatch, navigate, currentUserInfo, orgCurrencyList } = useCommonData();
  const [deleteAction, setDeleteAction] = useState<DeleteInitialData>(deleteInitialData);
  const [voidAction, setVoidAction] = useState<any>({ id: -1, couponName: '' });
  const [couponOverviewData, setCouponOverviewData] = useState<CouponOverviewDataType>(couponOverviewInitialData)
  const onClickDelete = (actionType: string, id: number, couponName: string) => (setDeleteAction({
    actionType: actionType,
    id: id,
    planName: couponName,
  }))
  const { fetchCouponOverviewDetails } = useCouponOverViewDetail();
  const onVoidClick = (id: number, couponName: string) => {
    $(".dropdown-ctrl").click();
    setVoidAction({ id, couponName });
  };

   /**
   * Fetch coupon Details
   */
   useEffect(() => {
    if (couponDetailId) {
      fetchCouponOverviewDetails(setCouponOverviewData);
    }
  }, [currentUserInfo.organization_id]);

  return (
    <div>
      <div
        className="card card-dashboard customer-detail-card w-100"
        id="customer-detail-card"
      >
        <CouponViewHeader
          couponDetailData={couponOverviewData}
          deleteAction={deleteAction}
          onClickDelete={onClickDelete}
          onVoidClick={onVoidClick}
          voidAction={voidAction}
        />
        <div className="tab-container w-100 px-0">
          <ul
            className="nav nav-pills pills-tab mt-0"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="pills-overview-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-overview"
                type="button"
                role="tab"
                aria-controls="pills-overview"
                aria-selected="true"
                onClick={() => { }}
              >
                Overview
              </button>
            </li>
          </ul>
          <div className="tab-content pb-0" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-overview"
              role="tabpanel"
              aria-labelledby="pills-overview-tab"
              tabIndex={0}
            >
              <div className="overview-wrapper tab-content-wrapper">
                <section className="section-blocks other-dets-section">
                  <h2>
                    <img
                      src={DocIconSquare}
                      width="24"
                      height="24"
                      alt="Other details"
                    />
                    Details
                  </h2>
                  <div className="other-wrapper section-content-wrapper">
                    <div className="row g-0">
                      <div className="col">
                        <div className="field-row">
                          <div className="field-title">
                            <span>Discount</span>
                          </div>
                          <div className="field-value">
                            <span>
                              {couponOverviewData.discount}
                            </span>
                          </div>
                        </div>
                        <div className="field-row">
                          <div className="field-title">
                            <span>Account</span>
                          </div>
                          <div className="field-value">
                            <span>
                              {couponOverviewData.account_name}
                            </span>
                          </div>
                        </div>
                        <div className="field-row">
                          <div className="field-title">
                            <span>Duration</span>
                          </div>
                          <div className="field-value">
                            <span>
                              {couponOverviewData.duration}
                            </span>
                          </div>
                        </div>
                        <div className="field-row">
                          <div className="field-title">
                            <span>Validity</span>
                          </div>
                          <div className="field-value">
                            <span>
                              {couponOverviewData.validity === "Forever" ? couponOverviewData.validity : DateFormatWithHyphen(couponOverviewData.validity)}
                            </span>
                          </div>
                        </div>
                        <div className="field-row">
                          <div className="field-title">
                            <span>Redemptions</span>
                          </div>
                          <div className="field-value">
                            <span>
                              {couponOverviewData.redemptions}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CouponDetailBody;
