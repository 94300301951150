import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import VoidModalSubscription from "../../../common/components/VoidModalSubscription";
import {
  cancelSubscriptionAction,
  changeSubscriptionStatus,
} from "../subscriptionSlice";
import { useFormik } from "formik";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { Card } from "../../../common/components/htmlTags/Card/Card";
import { CardHeader } from "../../../common/components/htmlTags/Card/CardHeader";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import { CardBody } from "../../../common/components/htmlTags/Card/CardBody";
import CloseButton from "../../../common/components/button/CloseButton";
import { Form } from "../../../common/components/htmlTags/Form";
import { getFormatedDate } from "../../../../helpers/helper";
import { ObjectType } from "../../../../types";
import { validateCancelForm } from "./ValidationSubscriptionAction";
import "./subscriptionVoid.css";
import info from "../../../../assets/images/email-info-msg-icon.svg";

type Props = {
  subscriptionId: number;
  subscriptionName: string;
  status: string;
  refreshPlan: () => void;
  organizationId: number;
  didAlertModalOpen?: boolean;
  setDidAlertModalOpen?: Dispatch<SetStateAction<boolean>>;
  nextRenewableDate: string;
  unpaidAmount: string;
  stopScheduledDate?: string;
  isEditScheduled: boolean;
  subscriptionEndDate: string | null;
  isCurrentCycleInvoicePaid: boolean;
  unused_credits_days?: number;
};
const SubscriptionVoid = (props: Props) => {
  const dispatch = useAppDispatch();
  const [cancelFormErrors, setCancelFormErrors] = useState<ObjectType>({});
  const placeOfSupplyUpdateModalRef = useRef<any>();
  const modalCancelHandler = () => {
    formik.resetForm();
    if (props.setDidAlertModalOpen) props.setDidAlertModalOpen(false);
    setCancelFormErrors({});
  };

  const formik = useFormik({
    initialValues: {
      cancel_status_option: "IMMEDIATELY",
      cancel_date: getFormatedDate(new Date()),
      credit_note: "NO_CREDIT",
      unpaid_amount: "RETAIN_AS_UNPAID",
      include_cancellation_date: true,
      bank_charges_account_id: 0,
      handle_paid_amount: "RETAIN_AS_PAID",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      let errors = validateCancelForm(
        values,
        props.status,
        props.subscriptionEndDate
      );
      if (Object.keys(errors).length) {
        setCancelFormErrors(errors);
      } else {
        setCancelFormErrors({});
        modalSubmitHandler(values);
      }
    },
  });

  const modalSubmitHandler = async (values: any) => {
    const cancelData = { ...values, subscription_id: props.subscriptionId };
    if (props.status === "FUTURE") {
      delete cancelData.credit_note;
      delete cancelData.unpaid_amount;
      delete cancelData.include_cancellation_date;
      delete cancelData.bank_charges_account_id;
      delete cancelData.handle_paid_amount;
    } else if (props.status === "STOPPED") {
      delete cancelData.credit_note;
      delete cancelData.include_cancellation_date;
      delete cancelData.handle_paid_amount;
    } else if (props.status === "ACTIVE") {
      cancelData.credit_note !== "PRORATED" &&
        delete cancelData.include_cancellation_date;
      cancelData.unpaid_amount !== "WRITE_OFF" &&
        delete cancelData.bank_charges_account_id;
    }
    if (cancelData.bank_charges_account_id) {
      cancelData.account_id = cancelData.bank_charges_account_id;
      delete cancelData.bank_charges_account_id;
    }

    if (cancelData.cancel_status_option === "END_OF_CURRENT_TERM") {
      cancelData.cancel_status_option = "ON_NEXT_RENEWAL";
      cancelData.cancel_date = cancelData.cancel_date;
    }

    if (cancelData.cancel_status_option === "SPECIFIC_DATE") {
      cancelData.cancel_date = getFormatedDate(cancelData.cancel_date);
    }
    if (!props.isCurrentCycleInvoicePaid) {
      cancelData.handle_paid_amount = null;
    }
    const voidRoleResponseAction = await dispatch(
      cancelSubscriptionAction({
        orgId: props.organizationId,
        cancelData,
      })
    );
    if (voidRoleResponseAction.payload) {
      const voidRoleResponse = voidRoleResponseAction.payload;
      if (
        Object.keys(voidRoleResponse).length &&
        !("error" in voidRoleResponse)
      ) {
        props.refreshPlan();
        modalCancelHandler();
        toast.success("Subscription cancelled successfully!", {
          toastId: "role-void-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in voidRoleResponse) {
        modalCancelHandler();
      }
    }
  };

  return (
    <>
      <Dialog
        open={props?.didAlertModalOpen || false}
        className={`subscription-update-popover`}
        onClose={modalCancelHandler}
      >
        <Card wrapperClass="subscription-update">
          <CardHeader wrapperClass={`subscription-update-header-wrapper`}>
            <Grid container>
              <Grid item xs={10} className="p-t-15 header">
                {"Cancel Subscription"}
              </Grid>
              <Grid item xs={2} className="popover-close-button">
                <CloseButton closeModalHandler={modalCancelHandler} />
              </Grid>
            </Grid>
          </CardHeader>
          <div className="h-100 subcription-void-form-field-wrapper">
            <CardBody>
              <Form
                name="add_invoice_form"
                id="add_invoice_form"
                className="subscription_cancel_modal_position"
                tabIndex={-1}
              >
                <VoidModalSubscription
                  modalCancelHandler={modalCancelHandler}
                  status={props.status}
                  formik={formik}
                  nextRenewableDate={props.nextRenewableDate}
                  cancelFormErrors={cancelFormErrors}
                  unpaidAmount={props.unpaidAmount}
                  isCurrentCycleInvoicePaid={props.isCurrentCycleInvoicePaid}
                  unused_credits_days={props.unused_credits_days}
                />
                {props.isEditScheduled || props.stopScheduledDate ? (
                  <Grid
                    container
                    className="m-t-24 subscription-impact-update-conatiner"
                    wrap="nowrap"
                  >
                    <Grid item xs="auto">
                      <img src={info} id="message-img" alt="clone-alt" />
                    </Grid>
                    <Grid item xs="auto">
                      <div className="m-l-10">
                        Edit/Stop scheduled will be removed if the cancel is
                        scheduled
                      </div>
                    </Grid>
                  </Grid>
                ) : null}
                <div className="row mb-0">
                  <div className="col">
                    <div
                      className={`form-button-wrapper w-100 justify-content-end mt-3 
                    ${
                      props.isEditScheduled || props.stopScheduledDate
                        ? "form-button-wrapper-cancelled-modal"
                        : ""
                    }`}
                      id="form-btn"
                    >
                      <button
                        onClick={(e: any) => {
                          e.preventDefault();
                          formik.submitForm();
                        }}
                        className="save-button"
                      >
                        {"Update"}
                      </button>
                      <button
                        type="button"
                        onClick={modalCancelHandler}
                        className="text-decoration-none cancel-button"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </CardBody>
          </div>
        </Card>
      </Dialog>
    </>
  );
};
export default SubscriptionVoid;
