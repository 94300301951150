import React, { useEffect } from "react";
import { ItemTaxValues, ObjectType } from "../../../../../types";
import TaxCode from "./taxCode/TaxCode";
import "./taxSelect.css";
import { colors } from "../../../../constants/colors";
import { RemoveIcon } from "../../customSvgIcons/RemoveIcon";
import { zeroDisplayFormat } from "../../../../../helpers/numberFormatHelper";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import useCommonData from "../../../../hooks/useCommon";
import { IsFeatureEnabled } from "../../../../../helpers/featureFlagHelper";
import { AddTagIcon } from "../../../../../assets/images";
import { Container } from "../../htmlTags/Container";
import { Input } from "../../ui";
import { BooleanValueNode } from "graphql";

type Props = {
  itemIndex: number;
  taxes: ItemTaxValues[];
  taxErrors: ObjectType[] | undefined;
  organizationId: number;
  onTaxLabelChange: (e: any, num: number, taxIndex: number) => void;
  onTaxValueChange: (e: any, num: number, taxIndex: number) => void;
  onItemTaxDelete: (
    itemIndex: number,
    taxIndex: number,
    id: number | undefined
  ) => void;
  onTaxBlur: (
    name: string,
    amount: number,
    itemIndex: number,
    taxIndex: number
  ) => void;
  disabled?: boolean;
  disabledFeature: ObjectType[];
  currencyCode: string | undefined;
  addItemTaxContainer: (index: number) => void;
};
const TaxSelect = (props: Props) => {
  const { orgCurrencyList, currentUserInfo } = useCommonData();

  return (
    <Container className="taxselect-wrapper">
      {props.taxes?.map((tax, taxIndex) => {
        return (
          <Container key={taxIndex}>
            <label
              htmlFor={"tax_id_" + props.itemIndex + "_" + taxIndex}
              className="hidden"
            >
              Tax
            </label>
            <label
              htmlFor={"tax_percentage_" + props.itemIndex + "_" + taxIndex}
              className="hidden"
            >
              Tax percentage
            </label>
            <Container
              className={
                props.taxErrors && props.taxErrors[taxIndex]?.tax_id
                  ? "d-flex tax-selector error align-items-center w-151 mt-0"
                  : "d-flex tax-selector align-items-center w-151 mt-0"
              }
              key={"itemtax-" + props.itemIndex + taxIndex}
            >
              <Input
                name="tax_percentage"
                className={
                  props.taxErrors &&
                  props.taxErrors[taxIndex]?.tax_id &&
                  props.taxErrors[taxIndex]?.tax_id === "Tax label already used"
                    ? "tax_percentage border-start-0 border-end-0 ps-1 customer-form-section-disable"
                    : "tax_percentage border-start-0 border-end-0 ps-1"
                }
                type="text"
                disabled={
                  props.disabled
                    ? props.disabled
                    : props.taxErrors &&
                      props.taxErrors[taxIndex]?.tax_id &&
                      props.taxErrors[taxIndex]?.tax_id ===
                        "Tax label already used"
                    ? true
                    : false
                }
                id={"tax_percentage_" + props.itemIndex + "_" + taxIndex}
                placeholder={zeroDisplayFormat(
                  decimalPlaceOfCurrency(
                    props.currencyCode
                      ? props.currencyCode
                      : currentUserInfo.currency_code,
                    orgCurrencyList
                  )
                )}
                value={
                  props.taxErrors &&
                  props.taxErrors[taxIndex]?.tax_id &&
                  props.taxErrors[taxIndex]?.tax_id === "Tax label already used"
                    ? ""
                    : tax.tax_percentage
                }
                onChange={(e) =>
                  props.onTaxValueChange(e, props.itemIndex, taxIndex)
                }
                onBlur={() =>
                  props.onTaxBlur(
                    "tax_percentage",
                    tax.tax_percentage ? Number(tax.tax_percentage) : 0,
                    props.itemIndex,
                    taxIndex
                  )
                }
              />
              <TaxCode
                taxIndex={taxIndex}
                taxes={props.taxes}
                onTaxLabelChange={props.onTaxLabelChange}
                itemIndex={props.itemIndex}
                organizationId={props.organizationId}
                disabled={props.disabled ? props.disabled : false}
                disabledFeature={props.disabledFeature}
              />
              <a
                href="#"
                className="removeItem"
                onClick={(e) => {
                  e.preventDefault();
                  props.onItemTaxDelete(props.itemIndex, taxIndex, tax.id);
                  $(
                    "#tax-amount-label_" + props.itemIndex + "_" + taxIndex
                  ).text("");
                }}
              >
                <RemoveIcon color={colors.battleshipGrey} />
              </a>
            </Container>
            <span
              key={"itemtaxlabel-" + props.itemIndex + taxIndex}
              className={
                props.taxErrors && props.taxErrors[taxIndex]?.tax_id
                  ? "display-none tax-amount-label p-0"
                  : "tax-amount-label p-0"
              }
              id={"tax-amount-label_" + props.itemIndex + "_" + taxIndex}
            ></span>
            <span className="p-0 error">
              {props.taxErrors &&
                (props.taxErrors[taxIndex]?.tax_id ||
                  props.taxErrors[taxIndex]?.tax_percentage ||
                  "")}
            </span>
          </Container>
        );
      })}
      {IsFeatureEnabled(
        "Tax Configuration",
        currentUserInfo.disabled_feature
      ) && (
        <button
          className="add-tags-button"
          onClick={(e) => {
            e.preventDefault();
            props.addItemTaxContainer(props.itemIndex);
          }}
        >
          <div className="d-flex align-items-center mx-0">
            <img className="add-tax-button" src={AddTagIcon} alt="" />
            <span className="px-2">Add tax </span>
          </div>
        </button>
      )}
    </Container>
  );
};

export default TaxSelect;
