import { useNavigate } from "react-router-dom";

type Props = {
  editId: string | undefined;
  isActive: boolean;
  rolePermission: any;
  isVoid?: boolean;
  onClickSubmit: () => void;
  onClickDelete: (e: any) => void;
};

function JournalListFooter({ isActive, isVoid, onClickSubmit }: Props) {
  const navigate = useNavigate();

  return (
    <div className="row mb-0">
      <div className="col">
        <div
          className={
            isActive
              ? "form-button-wrapper d-flex w-100 px-4 docked-button-container"
              : "form-button-wrapper d-flex w-100 px-4 docked-button-container customer-form-section-disable"
          }
          id="form-btn-invoice"
        >
          <div className="btn-group save-as-open-btn-group ps-3 ms-1">
            <button
              type="button"
              className="save-button save-only-button mx-0"
              onClick={() => onClickSubmit()}
              disabled={!isActive || isVoid}
            >
              Apply Tags
            </button>
          </div>
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="text-decoration-none cancel-button"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default JournalListFooter;
