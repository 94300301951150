import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { EditIconBlueImg } from "../../../assets/images";
import "../styles/ExchangeRate.css";
import {
  createExchangeRateList,
  getLatestCurrencyRate,
  currentUserSelector,
  getCustomConversions,
  latestCurrencyRateSelector,
  isLoadingCurrencyRateSelector,
  latestConversion,
  setLatestConversionRate,
  setExchangeApiSatus,
} from "../commonSlice";
import { DateFormatHandler, getFormatedDate } from "../../../helpers/helper";
import { ExchangeRateValues, ObjectType } from "../../../../src/types";
import { toast } from "react-toastify";
import { colors } from "../../constants/colors";
import InfoTooltip from "./InfoTooltip";
import { message } from "../../constants/messages";

type Props = {
  currencyId: number | undefined; // latest currency of the transaction
  currencyCode: string | undefined;
  editId: string | undefined; // curresponding transaction id, eg: invoice id
  conversionId: string | number; // saved conversion id, only have value in edit section
  conversionCurrencyId?: string | number; // currency of already saved conversion
  conversionDate: string; // date of already saved conversion
  newConversionDate: string; // new date for conversion
  module?: string; // to apply style based on module
  isInititialCustomConversionFetch?: boolean; // to fetch custom conversion on initial load when its not an edit case
  isMutable?: boolean;
  hideExchangeRate?: boolean;
  showTooltip?: boolean;
};

const ExchangeRate = (props: Props, ref?: any) => {
  const dispatch = useAppDispatch();
  const exchangeRateValues = useAppSelector(
    latestCurrencyRateSelector
  ).latest_conversion;

  const isLoadingCurrencyRate = useAppSelector(isLoadingCurrencyRateSelector);
  let initialFormValues = {
    base_currency: 0,
    to_currency: 0,
    rate: 0,
    by_user: false,
  };

  const [exchangeFormValues, setExchangeFormValues] =
    useState<ExchangeRateValues>(initialFormValues);
  const [showConversionPopup, setShowConversionPopup] =
    useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [conversionId, setConversionId] = useState<number | string>(
    props.conversionId ? props.conversionId : 0
  );
  const [isProcessing, setIsProcessing] = useState(true);

  /**
   * Reset the redux state when component unmount
   */
  useEffect(() => {
    return () => {
      dispatch(setLatestConversionRate(latestConversion));
    };
  }, []);
  useEffect(() => {
    if (props.conversionId && props.isInititialCustomConversionFetch === true) {
      fetchCustomConversions(props.conversionId as number);
    }
  }, []);
  /**
   * Setting latest exchange value to edit form values
   */
  useEffect(() => {
    setConversionId(exchangeRateValues.id);
    dispatch(setExchangeApiSatus(false));
    setExchangeFormValues({
      ...exchangeFormValues,
      rate: exchangeRateValues.rate,
    });
  }, [exchangeRateValues]);

  /**
   * In create conversionId is null. Need to fetch convertion rate if currency !== conversionCurrencyId
   */
  useEffect(() => {
    if (
      !props.conversionId ||
      (props.conversionCurrencyId !== props.currencyId &&
        !props.isInititialCustomConversionFetch)
    ) {
      getLatestCurrencyConversion();
    }
  }, [props.currencyId]);

  /**
   * Get custom conversion details if already have conversion id, edit id and already saved conversion currency is same and transaction or account currency
   */
  useEffect(() => {
    if (
      props.editId &&
      props.conversionId &&
      props.conversionCurrencyId === props.currencyId
    ) {
      fetchCustomConversions(props.conversionId as number);
    }
  }, [props.conversionId, props.currencyId]);

  /**
   * Caluculate conversion rate for new date.
   * if already saved conversion id === new conversion id fetch saved convesion info.
   * Otherwise fetch conversion rate for new currency
   */
  useEffect(() => {
    if (props.newConversionDate) {
      if (props.editId && props.newConversionDate === props.conversionDate) {
        fetchCustomConversions(props.conversionId as number);
      } else {
        !props.isInititialCustomConversionFetch &&
          getLatestCurrencyConversion();
      }
    }
  }, [props.newConversionDate, props.conversionDate]);

  //useEffect to handle outside click
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const exchangeRateEdit = document.getElementById("exchangeRateEdit");
      if (
        exchangeRateEdit &&
        !exchangeRateEdit.contains(event.target as Node)
      ) {
        closePopup();
      }
    };

    if (showConversionPopup) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showConversionPopup]);

  useImperativeHandle(
    ref,
    () => ({
      conversionId: conversionId,
    }),
    [conversionId]
  );

  /**
   * Fetch conversion rate
   */
  const getLatestCurrencyConversion = async () => {
    let orgCurrency = currentUserInfo.organization_currency;
    if (
      props.currencyId &&
      props.currencyId !== 0 &&
      orgCurrency &&
      (props.newConversionDate || props.conversionDate)
    ) {
      dispatch(setExchangeApiSatus(true));
      await dispatch(
        getLatestCurrencyRate({
          base_currency: Number(props.currencyId),
          to_currency: orgCurrency,
          date: props.newConversionDate
            ? new Date(props.newConversionDate) > new Date()
              ? getFormatedDate()
              : props.newConversionDate
            : new Date(props.conversionDate) > new Date()
            ? getFormatedDate()
            : props.conversionDate,
        })
      );
    }
  };
  /**
   * Fetch convesion details by conversion id
   */
  const fetchCustomConversions = async (id: number) => {
    if (id) {
      dispatch(setExchangeApiSatus(true));
      const customConversionResponse = await dispatch(
        getCustomConversions({
          conversionId: id,
        })
      );
      setIsProcessing(true);
      const response = customConversionResponse.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setConversionId(response.latest_conversion.id);
        dispatch(setExchangeApiSatus(false));
        setIsProcessing(false);
      }
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    const regex = /^-?([0-9,]{1,})?(\.)?([0-9]{1,})?$/;
    if (value === "" || regex.test(value)) {
      setExchangeFormValues({ ...exchangeFormValues, [name]: value });
    }
  };

  const validate = (values: ExchangeRateValues) => {
    const errors: ObjectType = {};
    if (!values.rate) {
      errors.rate = "Please enter a valid exchange rate";
    }
    return errors;
  };

  const submitHandler = async () => {
    let inputs: any = { ...exchangeFormValues };
    inputs = {
      ...inputs,
      base_currency: props.currencyId,
      to_currency: currentUserInfo.organization_currency,
      by_user: true,
      rate: Number(inputs.rate),
      timestamp: props.conversionDate,
    };
    let errors = validate(inputs);
    if (Object.keys(errors).length) {
      setFormErrors({ ...errors });
    } else {
      setFormErrors({});
      dispatch(setExchangeApiSatus(true));
      const createResponseAction = await dispatch(
        createExchangeRateList({ values: inputs })
      );
      const createResponse = createResponseAction.payload;
      if (Object.keys(createResponse).length && !("error" in createResponse)) {
        toast.success("Exchange rate updated successfully!", {
          toastId: "rate-create-success",
          closeButton: false,
          position: "top-center",
        });
        fetchCustomConversions(createResponse.id);
      }
      setShowConversionPopup(false);
    }
  };

  const closePopup = () => {
    setShowConversionPopup(false);
    setExchangeFormValues({
      ...exchangeFormValues,
      rate: exchangeRateValues.rate,
    });
  };

  return (
    <div className="exchange-rate-wrapper position-relative">
      {props.currencyId &&
      props.currencyId !== currentUserInfo.organization_currency &&
      exchangeRateValues.base_currency !== exchangeRateValues.to_currency &&
      exchangeRateValues.rate ? (<div className="exchange-rate-display-wrapper">
          {!isLoadingCurrencyRate && !props.hideExchangeRate && (
            <>
              <div
                className={
                  props.module === "COA"
                    ? "exchange-date-rate-wrapper-multiline"
                    : "exchange-date-rate-wrapper"
                }
              >
                <div className="exchange-date-wrapper">
                  (As on{" "}
                  {DateFormatHandler(
                    exchangeRateValues.timestamp.split(" ", 1).toString()
                  )}
                  )
                </div>
                <div className="fw-bold exchange-converted-rate-wrapper">
                  1 {" " + exchangeRateValues.base_currency} ={" "}
                  {exchangeRateValues.rate +
                    " " +
                    exchangeRateValues.to_currency}
                </div>
               {props.showTooltip ? <div className="info-tool-tip">
                <InfoTooltip title={message().subscription.exchangeRate} /> 
                </div>: null}
              </div>
              {props.isMutable === false ? null : (
                <div className="exchange-rate-icon-wrapper">
                  <a
                    className="edit-exchange-rate"
                    tabIndex={0}
                    onClick={() => setShowConversionPopup(!showConversionPopup)}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h16v16H0z" />
                        <path
                          d="m10.485 6.457-.942-.942-6.21 6.209v.943h.943l6.21-6.21zm.943-.942.943-.943-.943-.943-.943.943.943.943zM4.828 14H2v-2.829l8.957-8.956c.26-.26.682-.26.942 0L13.785 4.1c.26.26.26.682 0 .942L4.83 14h-.001z"
                          fill={colors.ceruleanBlue}
                          fillRule="nonzero"
                        />
                      </g>
                    </svg>
                  </a>
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        ""
      )}
      {props.editId &&
      !props.currencyId &&
      exchangeRateValues.rate !== 1 &&
      exchangeRateValues.base_currency !== exchangeRateValues.to_currency &&
      !isProcessing ? (
        <div className="exchange-rate-display-wrapper">
          {!props.hideExchangeRate ? (
            <div
              className={
                props.module === "COA"
                  ? "exchange-date-rate-wrapper-multiline"
                  : "exchange-date-rate-wrapper"
              }
            >
              <div className="exchange-date-wrapper">
                (As on{" "}
                {DateFormatHandler(
                  exchangeRateValues.timestamp.split(" ", 1).toString()
                )}
                )
              </div>
              <div className="fw-bold exchange-converted-rate-wrapper">
                1 {" " + exchangeRateValues.base_currency} ={" "}
                {exchangeRateValues.rate + " " + exchangeRateValues.to_currency}
              </div>
              <div className="exchange-rate-icon-wrapper"></div>
              <a onClick={() => setShowConversionPopup(!showConversionPopup)}>
                <img src={EditIconBlueImg} alt="" />
              </a>
            </div>
          ) : null}
        </div>
      ) : (
        ""
      )}
      {showConversionPopup == true && (
        <div
          className={props.module === "COA" ? "coa-exchange" : ""}
          id="exchangeRateEdit"
        >
          <div className="card card-body">
            <div className="row mb-0">
              <div className="col">
                <label
                  htmlFor=""
                  className={
                    props.module === "COA"
                      ? "required fw-bold mb-2 ml-0"
                      : "required"
                  }
                >
                  Edit Exchange Rate
                </label>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <div className="input-group-text" id="basic-addon1">
                      {exchangeRateValues.to_currency}
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    aria-label="exchange rate"
                    aria-describedby="basic-addon1"
                    name="rate"
                    value={exchangeFormValues?.rate}
                    onChange={handleInputChange}
                    onFocus={(e: any) =>
                      e.target.parentElement.classList.add("focused")
                    }
                    onBlur={(e: any) =>
                      e.target.parentElement.classList.remove("focused")
                    }
                  />
                  <span className="error">{formErrors?.rate}</span>
                </div>
                <div className="form-button-wrapper mt-4">
                  <button
                    type="button"
                    className="save-button"
                    data-bs-toggle="collapse"
                    onClick={submitHandler}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    data-bs-toggle="collapse"
                    className="cancel-button"
                    onClick={closePopup}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(forwardRef(ExchangeRate));
