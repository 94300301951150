import React, { forwardRef, useEffect, useMemo, useState } from "react";
import CreditNoteTable from "./CreditNoteTable";
import useCommonData from "../../../../../hooks/useCommon";
import { usePaginationDispatch } from "../../../../../hooks/usePaginationDispatch";
import useListData from "../../../../../hooks/useListData";
import { useAppSelector } from "../../../../../../app/hooks";
import {
  filterAppliedState,
  listFilterSelector,
} from "../../../../../../appSlice";
import {
  CreditNoteList as CreditNoteListType,
  Transactionlimit,
} from "../../../../../../types";
import {
  creditNoteList,
  getCreditNotePdfLink,
  sendCreditNoteData,
} from "../../../creditNoteSlice";
import { getStatusFilterString } from "../../../../../../helpers/statusFilterHelper";
import {
  fiscalYearFilterSelector,
  setLoaderState,
  dateListFilterSelector,
  dueDateListFilterSelector,
} from "../../../../../common/commonSlice";
import CustomTooltips from "../../../../../common/components/Tooltip";
import Popover from "../../../../../common/components/Popover";
import {
  DateFormatHandler,
  StatusColor,
  downloadUsingURL,
} from "../../../../../../helpers/helper";
import TableSetup from "../../../../../common/components/table/TableSetup";
import {
  creditNoteColumnValues,
  creditNoteDefaultValues,
  creditNoteMandatoryValues,
} from "../../../../../../helpers/tableCustomizationHelper";
import { joinTransactionNumber } from "../../../../../../helpers/prefixHelper";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../../helpers/numberFormatHelper";
import ListActions from "../ListActions";
import { toast } from "react-toastify";
import { useCustomizedTable } from "../../../../../hooks/useCustomizedTable";
import SendEmailModal from "../../../../../common/components/SendEmailModal";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import { decimalPlaceOfCurrency } from "../../../../../../helpers/decimalPlaceHelper";

type Props = {
  transactionLimit: Transactionlimit;
  initializeFilter: () => void;
  isFilterApplied: boolean;
  deleteListData: (id: number, listNum: string, status: string) => void;
};
function CreditNoteTableContainer(props: Props, ref: any) {
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const {
    setStartCount,
    setEndCount,
    setItemsPerPage,
    setPage,
    setDateSortOrder,
    setTotalList,
  } = usePaginationDispatch();
  const { page, itemsPerPage, startCount, endCount, totalList, dateSortOrder } =
    useListData();
  const { creditNoteRolePermission } = usePermissionHandler();
  const selectedFilterData = useAppSelector(listFilterSelector);
  const isFilterApplied = useAppSelector(filterAppliedState);
  const fiscalYearFilter = useAppSelector(fiscalYearFilterSelector);
  const dateListFilter = useAppSelector(dateListFilterSelector);
  const dueDateListFilter = useAppSelector(dueDateListFilterSelector);
  const [creditNoteData, setCreditNoteData] = useState<CreditNoteListType[]>(
    []
  );
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const { selectedColumns, fetchTableCustomizationFields } =
    useCustomizedTable();
  const [emailList, setEmailList] = useState<string[] | []>([]);
  const [creditNoteId, setCreditNoteId] = useState(0);

  useEffect(() => {
    fetchTableCustomizationFields(
      "Credit Notes",
      creditNoteDefaultValues,
      creditNoteMandatoryValues
    );
    fetchCreditNoteList();
  }, [
    page,
    itemsPerPage,
    dateSortOrder,
    currentUserInfo.organization_id,
    // JSON.stringify(fiscalYearFilter),
    // selectedFilterData,
    dateListFilter,
    dueDateListFilter,
  ]);

  useEffect(() => {
    if (isFilterApplied) fetchCreditNoteList();
  }, [selectedFilterData, isFilterApplied]);

  useEffect(() => {
    let hiddenItems: string[] = [];
    creditNoteColumnValues.forEach((column) => {
      if (!selectedColumns.includes(column)) {
        hiddenItems.push(column);
      }
    });
    setHiddenColumns([...hiddenItems]);
  }, [creditNoteData, selectedColumns]);
  /**
   * Fetch all credit notes
   */
  const fetchCreditNoteList = async () => {
    dispatch(setLoaderState(true));
    const customerList = selectedFilterData.hasOwnProperty("Customers")
      ? selectedFilterData?.Customers
      : [];
    const responseAction = await dispatch(
      creditNoteList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        orgIds: selectedFilterData.Organizations
          ? selectedFilterData.Organizations
          : [],
        statusList: selectedFilterData.Status
          ? getStatusFilterString(selectedFilterData.Status)
          : [],
        customerList: customerList,
        endDate: dateListFilter.date.endDate || "",
        startDate: dateListFilter.date.startDate || "",
        startDueDate: dueDateListFilter.dueDate.startDate || "",
        endDueDate: dueDateListFilter.dueDate.endDate || "",
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);

      const response = responseAction.payload as any;
      if (Object.keys(response).length && !("error" in response)) {
        setCreditNoteData(response.credit_notes);
        let length = response.credit_notes.length;
        setTotalList(response.total);
        if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
        setEndCount((page - 1) * itemsPerPage + length);
      } else if ("error" in response) {
      }
    }
  };
  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Credit Note Date",
        accessor: "show.date",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "itemdate",
      },
      {
        Header: "Credit Note Number",
        accessor: "show.creditNoteId",
        sort: false,
        sortOrder: "",
        className: "cr_note_num",
      },
      {
        Header: "Reference Number",
        accessor: selectedColumns.includes("Reference Number")
          ? "show.referenceNumber"
          : "Reference Number",
        sort: false,
        sortOrder: "",
        className: "ref_num",
      },
      {
        Header: "Customer Name",
        accessor: "show.customerName",
        sort: false,
        sortOrder: "",
        className: "cust_name",
        Cell: ({ cell: { value } }: any) => {
          return (
            <CustomTooltips
              placement="bottom"
              title={value?.length > 15 ? value : ""}
            >
              <span className="d-block ellipsis">{value}</span>
            </CustomTooltips>
          );
        },
      },
      {
        Header: "Invoice Number",
        accessor: selectedColumns.includes("Invoice Number")
          ? "show.invoice"
          : "Invoice Number",
        sort: false,
        sortOrder: "",
        className: "inv_num",
        Cell: ({ cell: { value } }: any) => {
          return value?.length ? <Popover values={value} tag={false} /> : "-";
        },
      },
      {
        Header: "Status",
        accessor: "show.status",
        sort: false,
        sortOrder: "",
        className: "cr_status",
        Cell: ({ cell: { value } }: any) => {
          return (
            <span
              className={StatusColor(
                value.includes("Overdue") ? "overdue" : value
              )}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "Total Taxable Amount",
        accessor: selectedColumns.includes("Total Taxable Amount")
          ? "show.totalTaxableAmount"
          : "Total Taxable Amount",
        sort: false,
        sortOrder: "",
        className: "total_taxable_amt",
      },
      {
        Header: "Credit Note Balance",
        accessor: selectedColumns.includes("Credit Note Balance")
          ? "show.balance"
          : "Credit Note Balance",
        sort: false,
        sortOrder: "",
        className: "cr_note_balance",
      },
      {
        Header: "Total",
        accessor: "show.amount",
        sort: false,
        sortOrder: "",
        className: "total",
      },
      {
        Header: "Organization",
        accessor: "show.organization",
        sort: false,
        sortOrder: "",
        className: "org",
      },
      {
        Header: (
          <TableSetup
            type="Credit Notes"
            tableColumns={selectedColumns}
            customizeColumnValues={creditNoteColumnValues}
            mandatoryColumns={creditNoteMandatoryValues}
            refreshList={() => {
              fetchTableCustomizationFields(
                "Credit Notes",
                creditNoteDefaultValues,
                creditNoteMandatoryValues
              );
              fetchCreditNoteList();
            }}
          />
        ),
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return (!creditNoteRolePermission.includes("Update") &&
            value.status === "Draft") ||
            value.organizationId !== currentUserInfo.organization_id ? null : (
            <ListActions
              value={value}
              onClickSendMail={() => fetchContactEmail(value.id, value.emailTo)}
              onClickDownloadPdf={() => downloadPdf(value.id)}
              onClickDelete={() => {
                props.deleteListData(value.id, value.listName, value.status);
              }}
            />
          );
        },
      },
    ],
    [
      dateSortOrder,
      selectedColumns,
      hiddenColumns,
      currentUserInfo.organization_id,
      // creditNoteRolePermission
    ]
  );
  const getCurrencyCode = (code: string) => (code === null ? "" : code);
  /**
   * set table row data
   */
  const data = creditNoteData
    ? creditNoteData.map((creditNote, index) => ({
        show: {
          date: DateFormatHandler(creditNote.credit_note_date),
          creditNoteId: joinTransactionNumber(creditNote.credit_note_no),
          referenceNumber: creditNote.reference_number,
          customerName: creditNote.customer_name,
          invoice: creditNote.invoice_numbers.map((inv_num) => {
            return {
              id: inv_num.id,
              name: joinTransactionNumber(inv_num.name),
            };
          }),
          status: creditNote.credit_note_status,
          totalTaxableAmount:
            getCurrencyCode(creditNote.currency_code) +
            " " +
            NumberFormat(
              creditNote.total_taxable_amount,
              creditNote.currency_code,
              orgCurrencyList
            ),
          amount:
            getCurrencyCode(creditNote.currency_code) +
            " " +
            NumberFormat(
              creditNote.total,
              creditNote.currency_code,
              orgCurrencyList
            ),
          balance: creditNote.balance_due
            ? getCurrencyCode(creditNote.currency_code) +
              " " +
              NumberFormat(
                creditNote.balance_due,
                creditNote.currency_code,
                orgCurrencyList
              )
            : getCurrencyCode(creditNote.currency_code) +
              " " +
              zeroDisplayFormat(
                decimalPlaceOfCurrency(
                  creditNote.currency_code
                    ? creditNote.currency_code
                    : currentUserInfo.currency_code,
                  orgCurrencyList
                )
              ),
          organization: creditNote.organization,
          actions: {
            id: creditNote.id,
            status: creditNote.credit_note_status,
            listName: creditNote.credit_note_no,
            emailTo: creditNote.email_to,
            isMutable: creditNote.invoice_numbers.length > 0 ? false : true,
            organizationId: creditNote.organization_id,
            isLocked: creditNote.is_locked,
            lockDate: creditNote.lock_date,
            is_fiscal_closed: creditNote.is_fiscal_closed,
          },
        },
      }))
    : [];

  const sortHandler = (column: string) => {
    if (column === "Credit Note Date") {
      if (dateSortOrder === "ASC") {
        setDateSortOrder("DESC");
      } else {
        setDateSortOrder("ASC");
      }
    }
  };
  /**
   * Set the contact email in a state of selected credit notes
   */
  const fetchContactEmail = (id: number, emailsList: any[]) => {
    let emails = emailsList.map((item) => {
      return item.email;
    });
    setEmailList(emails);
    setCreditNoteId(id);
  };

  const downloadPdf = async (creditNoteId: number) => {
    const responseAction = await dispatch(
      getCreditNotePdfLink({
        creditNoteId: creditNoteId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        response.download_link?.length > 0
      ) {
        let result = await downloadUsingURL(
          response.download_link,
          creditNoteId + ".pdf"
        );
        if (result) {
          toast.success("Credit note downloaded successfully!", {
            toastId: "credit-note-download-success",
            closeButton: false,
            position: "top-center",
          });
        }
      } else {
      }
    }
  };
  const sendEmail = async (id: number, emailList: string[]) => {
    const responseAction = await dispatch(
      sendCreditNoteData({
        creditNoteId: id,
        emails: emailList,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        fetchCreditNoteList();
      }
    }
    return responseAction;
  };

  return (
    <>
      <CreditNoteTable
        totalList={totalList}
        startCount={startCount}
        endCount={endCount}
        itemsPerPage={itemsPerPage}
        creditNoteRolePermission={creditNoteRolePermission}
        columns={columns}
        data={data}
        isFilterApplied={props.isFilterApplied}
        initializeFilter={props.initializeFilter}
        transactionLimit={props.transactionLimit}
        setPageInfo={(countPerPage, page) => {
          setItemsPerPage(countPerPage);
          setPage(page);
        }}
        sortHandler={sortHandler}
        hiddenColumns={hiddenColumns}
      />
      <SendEmailModal
        emailLists={emailList}
        id={creditNoteId}
        sendEmail={(id: number, emailList: string[]) =>
          sendEmail(id, emailList)
        }
      />
    </>
  );
}

export default React.memo(forwardRef(CreditNoteTableContainer));
