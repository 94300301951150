import { useEffect, useState } from "react";
import PlanViewHeader from "./PlanViewHeader";
import { DocIconSquare, Locator } from "../../../../../assets/images";
import PricingTable from "./PricingTable";
import "./PlanDetail.css"
import ItemDetails from "../../../../common/components/detailsPage/ItemDetails";
import { DeleteInitialData, PlanDetailData } from "../../planType";
import { deleteInitialData, planDetailInitialData } from "../../PlanStateInitialization";
import { useParams } from "react-router-dom";
import useCommonData from "../../../../hooks/useCommon";
import { setLoaderState } from "../../../../common/commonSlice";
import { planDetails } from "../../planSlice";
import { setErrorID } from "../../../../../appSlice";
import { noAccessErrorCodes } from "../../../../constants/constants";
import { pricingDefaultFrequency } from "../../../../../helpers/tableCustomizationHelper";
import { getModifiedPricingValue } from "../../../../../helpers/planHelper";
import AuditTrail from "../../../../common/components/auditTrail/AuditTrail";

const PlanDetailBody = () => {

const { planDetailId } = useParams();
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const [activeTab, setActiveTab] = useState<string>("overviewAndPricing");
  const [planDetailData, setPlanDetailData] = useState<PlanDetailData>(planDetailInitialData)
  const [deleteAction, setDeleteAction] = useState<DeleteInitialData>(deleteInitialData);
  /**
 * Setting active tab in session storage
 */
  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    sessionStorage.setItem("plan", tab);
  }

  useEffect(() => {
    const storedTab = sessionStorage.getItem("plan");
    if (storedTab) {
      setActiveTab(storedTab);
    }
  }, []);
  
  /**
   * Fetch Plan Details and Pricing List 
   */
  useEffect(() => {
    if (planDetailId) {
      fetchPlanDetail();
    }
  }, [currentUserInfo.organization_id]);
  
  const fetchPlanDetail = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      planDetails({
        planId: Number(planDetailId),
        orgId: currentUserInfo.organization_id,
      })
    );

    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;

      if (Object.keys(response).length && !("error" in response)) {
        const modifiedPricingValue = getModifiedPricingValue(response.pricings)

        const modifiedResponse = {...response, pricings: modifiedPricingValue} 
        
        setPlanDetailData(modifiedResponse);
      } else {
        dispatch(setErrorID(noAccessErrorCodes.invoiceDetail));
        navigate("/plan");
      }
    }
  };
  const onClickDelete = (actionType: string, id: number, planName: string, planExistsSubscription: boolean) => (setDeleteAction({
    actionType: actionType,
    id: id,
    planName: planName,
    planExistsSubscription: planExistsSubscription,
  }))

  return (
    <div>
      <div
        className="card card-dashboard customer-detail-card w-100"
        id="customer-detail-card"
      >
        <PlanViewHeader
          planDetailData={planDetailData}
          deleteAction={deleteAction}
          onClickDelete={onClickDelete}
          refreshPlan={fetchPlanDetail}
        />
        <div className="tab-container w-100 px-0">
          <ul
            className="nav nav-pills pills-tab mt-0"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className={
                  activeTab === "overviewAndPricing" ? "nav-link active" : "nav-link"
                }
                id="pills-overview-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-overview"
                type="button"
                role="tab"
                aria-controls="pills-overview"
                aria-selected="true"
                onClick={() => handleTabClick("overviewAndPricing")}
              >
                Overview & Pricing
              </button>
            </li>
          </ul>
          <div className="tab-content pb-0" id="pills-tabContent">
            <div
              className={
                activeTab === "overviewAndPricing"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="pills-overview"
              role="tabpanel"
              aria-labelledby="pills-overview-tab"
              tabIndex={0}
            >
              <div className="tab-content-overview plan-overview">
                <div className="overview-wrapper tab-content-wrapper">
                  <section className="section-blocks addr-section plan-blocks">
                    <h2>
                      <img
                        src={DocIconSquare}
                        width="24"
                        height="24"
                        alt="Other details"
                      />
                      Item Details
                    </h2>
                    <div className="address-wrapper section-content-wrapper flex-nowrap">
                      <div className="address-container">
                        {
                          planDetailData.item_detail
                          && planDetailData.item_detail.length > 0
                            ? planDetailData.item_detail.map((itemDetail, index) => (<ItemDetails itemDetail={itemDetail} index={index} currencyCode={planDetailData.currency_code}/>) )
                            : null
                        }
                      </div>
                    </div>
                  </section>
                  <section className="section-blocks ">
                    <h2>
                      <img
                        src={Locator}
                        width="24"
                        height="24"
                        alt="Other details"
                      />
                      Pricing
                    </h2>
                    <div className="main-card overflowX-hidden h-100">
                    <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
                      <PricingTable
                        pricingListData={planDetailData.pricings}
                        organizationId={planDetailData.organization_id}
                        frequencyRow={pricingDefaultFrequency}
                        onClickDelete={onClickDelete}
                        planId={planDetailData.id}
                        planStatus={planDetailData.plan_status}
                        currencyCode={planDetailData.currency_code}
                      />
                    </div>
                    </div>
                  </section>
                  {/* Need to pass the data related to Plan */}
                  {/* <section>
                    <AuditTrail
                      title="Audit Trail"
                      transactionType="sales_invoices"
                      transactionId={Number(29213)}
                    />
                  </section> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PlanDetailBody;
