import { CSSProperties } from "react";

type ContainerProps = {
  children: React.ReactNode;
  className?: string;
  id?: string;
  style?: CSSProperties;
  role?: string;
  ariaLabelledBy?: string;
};
export const Container = (props: ContainerProps) => {
  return (
    <div
      id={props.id}
      className={props.className}
      style={props.style}
      role={props.role}
      aria-labelledby={props.ariaLabelledBy}
    >
      {props.children}
    </div>
  );
};
