import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  DownloadBlueImg,
  InviteBlueImg,
  TagIconBlue,
} from "../../../../../assets/images";
import { downloadUsingURL } from "../../../../../helpers/helper";
import { JournalDetailValues } from "../../../../../types";
import Loader from "../../../components/Loader";
import {
  getJournalLink,
  journalList,
  sendJournalData,
  journalDetails,
} from "../../journalVoucherSlice";
import JournalVoucherDetailBody from "./JournalVoucherDetailBody";
import SendEmailModal from "../../../../common/components/SendEmailModal";
import DeleteJournal from "../Delete";
import Delete from "../../../../common/components/Delete";
import Header from "../../../../common/components/detailsPage/Header";
import HeaderBody from "../../../../common/components/detailsPage/HeaderBody";
import { setErrorID } from "../../../../../appSlice";
import { noAccessErrorCodes } from "../../../../constants/constants";
import Edit from "../../../../common/components/kebabMenu/Edit";
import { journalDetailsInitialValues } from "./StateInitialization";
import useCommonData from "../../../../hooks/useCommon";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { setLoaderState } from "../../../../common/commonSlice";
import TagDarkIcon from "../../../../common/components/customSvgIcons/tagDarkIcon";
import { SendEmailIcon } from "../../../../common/components/customSvgIcons/sendEmailIcon";
import { DownloadIcon } from "../../../../common/components";
import { colors } from "../../../../constants/colors";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import { TagIcon } from "../../../../common/components/customSvgIcons/TagIcon";

function JournalVoucherHeader() {
  const { dispatch, navigate, currentUserInfo, isLoading } = useCommonData();
  const { journalVoucherRolePermission } = usePermissionHandler();
  const { editId } = useParams();
  const [emailList, setEmailList] = useState<string[] | []>([]);
  const [deleteJournalId, setDeleteJournalId] = useState(-1);
  const [deleteJournalItemId, setDeleteJournalItemId] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [dateSortOrder, setDateSortOrder] = useState("ASC");
  const [journalData, setJournalData] = useState<JournalDetailValues>(
    journalDetailsInitialValues
  );
  const [isCashBased, setIsCashBased] = useState(false);
  const [writeOffCancelledJV, setWriteOffCancelledJV] = useState(false);

  useEffect(() => {
    if (editId) {
      journalDetail();
    }
  }, [currentUserInfo.organization_id]);
  /**
   * Fetch Bill Details
   */
  const journalDetail = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      journalDetails({
        journalId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (response.journal_status === "Draft") {
          navigate("/journal-entry");
        }
        let journalData = response;
        let itemTotalData = {
          account_id: "",
          description: "Total",
          debit: response.total_details?.total_amount_debit,
          credit: response.total_details?.total_amount_credit,
        };
        journalData.item_details.push(itemTotalData);
        setJournalData(journalData);
        setWriteOffCancelledJV(journalData.write_off_cancelled_jv);
        setIsCashBased(journalData.is_cash_based);
      } else {
        dispatch(setErrorID(noAccessErrorCodes.JVDetail));
        // navigate("/journal-voucher");
      }
    }
  };
  /**
   * Set the entered emails in the bill data to a state.
   */
  useEffect(() => {
    let emails = journalData.email_to.map((data) => {
      return data.email;
    });
    setEmailList(emails);
  }, [journalData]);

  /**
   * Back button handler from Bill details page
   */
  const backFromJournalViewHandler = () => {
    // if (editId) {
    //   navigate("/journal-voucher");
    // } else {
    //   navigate("/journal-voucher/create");
    // }
    navigate(-1);
  };

  const fetchJournalList = async (action: string) => {
    await dispatch(
      journalList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        orgIds: [],
        statusList: [],
      })
    );
    if (action === "delete") {
      navigate(-1);
    }
  };

  const downloadPdf = async () => {
    const responseAction = await dispatch(
      getJournalLink({
        journalId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        response.download_link?.length > 0
      ) {
        let result = await downloadUsingURL(
          response.download_link,
          Number(editId) + ".pdf"
        );
        if (result) {
          toast.success("Journal entry downloaded successfully!", {
            toastId: "journal-download-success",
            closeButton: false,
            position: "top-center",
          });
        }
      } else {
      }
    }
  };

  const sendEmail = async (id: number, emailList: string[]) => {
    const responseAction = await dispatch(
      sendJournalData({
        journalId: id,
        emails: emailList,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        fetchJournalList("send");
      }
    }
    return responseAction;
  };

  return (
    <>
      <Header
        onBackButtonClick={backFromJournalViewHandler}
        data={{
          num: journalData.journal_voucher_number,
          status: journalData.journal_status,
        }}
        dataId={editId}
      />
      <div className="card-body">
        <HeaderBody
          dataId={editId}
          data={{
            num: journalData.journal_voucher_number,
            status: journalData.journal_status,
            userName: journalData.created_by,
            date: journalData.date,
            orgName: journalData.organization_name,
          }}
          isSameOrg={
            journalData.organization_id === currentUserInfo.organization_id
          }
        >
          {journalVoucherRolePermission.includes("Update") &&
          journalData.is_mutable &&
          !journalData.is_fiscal_closed ? (
            <Edit
              url={`/journal-entry/edit/${journalData.id}?replace=true`}
              isMutable={true}
              name="Journal Voucher"
              isLocked={journalData.is_locked}
              className=""
              lockDate={journalData.lock_date}
            />
          ) : null}
          {/* <Link
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#emailToModal"
            style={{ textDecoration: "none" }}
          >
            <img src={InviteBlueImg} alt="invite-icon" /> Send Email
          </Link> */}
          {/* <Link to="#" onClick={downloadPdf} style={{ textDecoration: "none" }}>
            <img src={DownloadBlueImg} alt="download-icon" /> Download PDF
          </Link> */}
          {/* {currentUserInfo.is_tags_enabled === true &&
            isCashBased === false &&
            writeOffCancelledJV !== true && (
              <Link
                to={`/journal-voucher/edit/${editId}/reporting-tags`}
                style={{ textDecoration: "none" }}
              >
                <span>
                  <img src={TagIconBlue} alt="tag-icon" width={15} />
                  <span className="dropdown-item-label">Reporting Tags</span>
                </span>
              </Link>
            )} */}

          {journalVoucherRolePermission.includes("Delete") &&
          journalData.is_mutable &&
          !journalData.is_fiscal_closed ? (
            <Delete
              className="text-decoration-none delete-item"
              deleteIcon={true}
              isMutable={true}
              onClick={() => {
                setDeleteJournalId(Number(journalData.id));
                setDeleteJournalItemId(journalData.journal_voucher_number);
              }}
              isLocked={journalData.is_locked}
              lockDate={journalData.lock_date}
              name="Journal Voucher"
              isFiscalClosed={journalData.is_fiscal_closed}
            />
          ) : null}

          <KebabMenu wrapperClass="detail-top-action-menu kebab-menu-dropdown-wrap">
            <li>
              <button
                type="button"
                className="dropdown-item"
                // to="#"
                data-bs-toggle="modal"
                data-bs-target="#emailToModal"
                style={{ textDecoration: "none", color: colors.charcoalGrey }}
              >
                <span>
                  <SendEmailIcon
                    color={colors.battleshipGrey}
                    margin="0px 5px 0px 0px"
                  />
                  Send Email
                </span>
              </button>
            </li>
            <li>
              <button
                // to="#"
                type="button"
                onClick={downloadPdf}
                style={{ textDecoration: "none", color: colors.charcoalGrey }}
                className="dropdown-item"
              >
                <span>
                  <DownloadIcon
                    color={colors.battleshipGrey}
                    margin="0px 8px 0px 0px"
                  />
                  Download PDF
                </span>
              </button>
            </li>
            {currentUserInfo.is_tags_enabled === true && (
              <li>
                <button
                  type="button"
                  style={{
                    textDecoration: "none",
                    color: colors.charcoalGrey,
                    opacity:
                      isCashBased === false && writeOffCancelledJV !== true
                        ? 1
                        : 0.5,
                  }}
                  className={`dropdown-item`}
                  onClick={(e) => {
                    if (!isCashBased && writeOffCancelledJV) {
                      e.preventDefault();
                      e.stopPropagation();
                    } else {
                      navigate(`/journal-entry/edit/${editId}/reporting-tags`, {
                        replace: true,
                      });
                    }
                  }}
                >
                  <span>
                    <TagIcon
                      color={colors.battleshipGrey}
                      margin="0px 5px 0px 0px"
                    />
                    Reporting Tags
                  </span>
                </button>
              </li>
            )}
          </KebabMenu>
        </HeaderBody>
        {isLoading ? <Loader /> : null}
        <JournalVoucherDetailBody journalData={journalData} />
      </div>
      <SendEmailModal
        emailLists={emailList}
        id={Number(editId)}
        sendEmail={(id: number, emailList: string[]) =>
          sendEmail(id, emailList)
        }
      />
      <DeleteJournal
        deleteJournalId={deleteJournalId}
        deleteJournalItemId={deleteJournalItemId}
        page={page}
        itemsPerPage={itemsPerPage}
        dateSortOrder={dateSortOrder}
        refreshJournals={fetchJournalList}
        organizationId={currentUserInfo.organization_id}
      />
    </>
  );
}

export default JournalVoucherHeader;
