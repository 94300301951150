export const colors = {
    white: "#ffffff", 
    approxWhite: "#fefefe",   
    charcoalGrey:"#323e48",
    newWhisper: "#e7e7e7",
    ceruleanBlue: "#0d5ae5",
    battleshipGrey: "#747d84",
    slate: "#91919C",
    black: "#000000",
    blanchedAlmond: "#fbe7cf",
    nebulaBlue: "#6a6fff",
    mediumAquamarine: "#45e3bf",
    nebulaBlueNew: "#546eff",
    approxWhisper: "#eee",
    veryLightGrey: "#ccc",
    whisper: "#e9e9e9",
    newAliceBlue: "#f3f8ff",
    navyBlue: "#1660e6", 
    greenFlash: "#65c755",
    paradiseGreen: "#ade8a4",
    watermelon: "#fb4864",
    sweetSixteen: "#ffcbd3",
    yellowGreen: "#58cf41",
    tomato: "#ff5f4c",
    lavenderBlue:"#847aff",
    warmWhite: "#f2f2f2",
    lynch: "#747D84",
    booger: "#9ac23c",
    nobel: "#9b9b9b",
    arsenic: "#323E48",
    blackRGB: "0,0,0",
    charcoalGreyRGB: "50, 62, 72",
    ceruleanBlueRGB : "13, 90, 229",
    lavendarBlushRGB: "255, 99, 132",
    aliceBlueRGB: "53, 162, 235",
    scropionGreyRGB: "90, 90, 90",
    greyLightRGB: "210, 210, 210",
    scropionGrey_rgb: "rgb(90, 90, 90)",
    greyLight_rgb:"rgb(210, 210, 210)",
    black_shadow1: "rgba(0, 0, 0, 0.01)"
}