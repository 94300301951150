import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import { RowData } from "../../../../types";
import "./AuditTrail.css";

const Row: React.FC<{
  row: RowData;
  detailsColumn: string;
  colSpan: number;
  viewDetailsText: string;
  hideDetailsText: string;
  viewDetailsImage: string;
  detailsHeaders: string[];
  className?: string;
}> = ({
  row,
  detailsColumn,
  colSpan,
  viewDetailsText,
  hideDetailsText,
  viewDetailsImage,
  detailsHeaders,
}) => {
  const [open, setOpen] = React.useState(false);

  const StyledTableRow = styled(TableRow)({
    borderTop: "1px solid #eee !important",
    backgroundColor: `${!open ? "#fff" : "#f5f8fe"}`,
  });
  const StyledTableRow2 = styled(TableRow)({
    borderTop: "1px solid #eee !important",
    backgroundColor: "#fff",
  });

  return (
    <>
      <StyledTableRow className="audit-log-table-row">
        {Object.keys(row).map(
          (key) =>
            key !== "history" && (
              <TableCell
                key={key}
                align={key === detailsColumn ? "right" : "left"}
                className={`${
                  open
                    ? "audit-log-table-row-cell-open"
                    : "audit-log-table-row-cell-closed"
                }`}
              >
                {row[key]}
              </TableCell>
            )
        )}
        <TableCell
          align="right"
          onClick={() => setOpen(!open)}
          className="audit-trail-link"
        >
          {open ? hideDetailsText : viewDetailsText}{" "}
          <img
            src={viewDetailsImage}
            className={`ArrowDropBlue ${open ? "open" : "closed"}`}
            alt="dropIcon"
          />
        </TableCell>
      </StyledTableRow>
      <TableRow className="audit-log-table-collapse-row">
        <TableCell
          colSpan={4}
          sx={{
            padding: "0 !important",
            backgroundColor: "#f5f8fe",
          }}
          className="audit-details-table-wrapper-padding"
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            className="audit-details-table-wrapper"
          >
            <Table size="medium" className="audit-details-table">
              <TableHead className="audit-details-table-head">
                <TableRow>
                  {detailsHeaders.map((header, index) => (
                    <TableCell
                      key={index}
                      align={
                        index === detailsHeaders.length - 1 ? "right" : "left"
                      }
                      className="audit-details-table-head-cell-head"
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="audit-details-table-body">
                {row.history?.map((historyRow, index) => (
                  <StyledTableRow2 key={index}>
                    <TableCell
                      className="audit-details-table-head-cell"
                      scope="row"
                    >
                      {historyRow.details}
                    </TableCell>
                    <TableCell className="audit-details-table-head-cell">
                      {historyRow.oldValue}
                    </TableCell>
                    <TableCell
                      className="audit-details-table-head-cell"
                      align="right"
                    >
                      {historyRow.newValue}
                    </TableCell>
                  </StyledTableRow2>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Row;
