import { Api } from "../../../constants";
import {
  get,
  post,
  postFormData,
  put,
  remove,
} from "../../../helpers/apiHelper";
import {
  CreditApply,
  InvoiceValues,
  ProformaValues,
  TaxableAmountCalculationValues,
  TotalAmountCalculationValues,
} from "../../../types";

/**
 * API call for getting all invoices.
 */
export async function getProformaList(data: {
  page: number;
  itemsPerPage: number;
  dateSortOrder: string;
  orgId: number;
  customerList: number[];
  orgIds: number[];
  statusList: string[];
  issueStartDate?: string;
  issueEndDate?: string;
  expiryStartDate?: string;
  expiryEndDate?: string;
}) {
  let url =
    Api.proformaInvoice +
    Api.proforma +
    "/list" +
    `?page_num=${data.page}&page_size=${
      data.itemsPerPage
    }&current_organization=${data.orgId}${
      data.issueStartDate
        ? `&issue_start_date=${data.issueStartDate.trim()}`
        : ""
    }${data.issueEndDate ? `&issue_end_date=${data.issueEndDate.trim()}` : ""}${
      data.expiryStartDate
        ? `&expiry_start_date=${data.expiryStartDate.trim()}`
        : ""
    }${
      data.expiryEndDate ? `&expiry_end_date=${data.expiryEndDate.trim()}` : ""
    }`.trim();

  if (data.dateSortOrder !== "") {
    url += `&date_sort_order=${data.dateSortOrder}`;
  }
  if (data.customerList.length !== 0) {
    const customerIds = data.customerList
      .map((customerId) => {
        return `&customer_ids=${customerId}`;
      })
      .join("");
    url += customerIds;
  }
  if (data.orgIds.length !== 0) {
    const orgIds = data.orgIds
      .map((orgId) => {
        return `&org_ids=${orgId}`;
      })
      .join("");
    url += orgIds;
  }
  if (data.statusList.length !== 0) {
    const statuses = data.statusList
      .map((status) => {
        return `&status_list=${status}`;
      })
      .join("");
    url += statuses;
  }
  const result = await get(url);
  return result;
}

/**
 * API call for deleting invoice
 */
export async function deleteProforma(data: {
  deleteProformaId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.salesInvoice +
      Api.invoice +
      "/delete" +
      `/${data.deleteProformaId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for creating invoice
 */
export async function createNewProforma(data: {
  values: ProformaValues;
  status: string;
  isSendMail: boolean;
  isPrintOrDownload: boolean;
  orgId: number;
}) {
  const result = await post(
    Api.proformaInvoice +
      Api.proforma +
      `/create?proforma_status=${data.status}&is_send_mail=${data.isSendMail}&is_print_or_download=${data.isPrintOrDownload}&current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * API call for edit invoice
 */
export async function editProforma(data: {
  id: number;
  status: string;
  isSendMail: boolean;
  isPrintOrDownload: boolean;
  editProformaInputs: ProformaValues;
  orgId: number;
}) {
  const result = await put(
    Api.proformaInvoice +
      Api.proforma +
      "/edit/" +
      data.id +
      `?proforma_status=${data.status}&is_send_mail=${data.isSendMail}&is_print_or_download=${data.isPrintOrDownload}&current_organization=${data.orgId}`,
    data.editProformaInputs
  );
  return result;
}
/**
 * API call for delete invoice item
 */
export async function deleteProformaItem(data: {
  proformaId: number;
  itemId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.proformaInvoice +
      "invoice-item/delete/" +
      data.proformaId +
      "/" +
      data.itemId +
      `?current_organization=${data.orgId}`
  );
  return result;
}
/**
 * API call for delete invoice recipients
 */
export async function deleteProformaRecipient(data: {
  proformaId: number;
  emailIds: number[];
  orgId: number;
}) {
  const result = await remove(
    Api.proformaInvoice +
      "invoice-recipient/delete/" +
      data.proformaId +
      `?current_organization=${data.orgId}`,
    data.emailIds
  );
  return result;
}
/**
 * API call for get invoice number
 */
export async function getProformaNumber(orgId: number) {
  const result = await get(
    Api.proformaInvoice + `new-proforma-number?current_organization=${orgId}`
  );
  return result;
}

/**
 * API call for checking the invoice number exist or not
 */
export async function proformaNumberExist(data: {
  proformaNumber: string;
  orgId: number;
  proformaId: number | null;
  signal?: AbortSignal;
}) {
  const result = await get(
    Api.proformaInvoice +
      `proforma-number-exist?proforma_number=${data.proformaNumber}&invoice_id=${data.proformaId}&current_organization=${data.orgId}`,
    data.signal
  );
  return result;
}
/**
 * API call for delete invoice item tax
 */
export async function deleteProformaItemTax(data: {
  itemId: number;
  taxId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.proformaInvoice +
      "invoice-item-tax/delete/" +
      data.itemId +
      "/" +
      data.taxId +
      `?current_organization=${data.orgId}`
  );
  return result;
}
/**
 * API call for getting invoice detail
 */
export async function getProformaDetails(data: {
  proformaId: number;
  orgId: number;
}) {
  const result = await get(
    Api.proformaInvoice +
      Api.proforma +
      `/details/${data.proformaId}?current_organization=${data.orgId}`
  );
  return result;
}

/**
 * API call for sending invoice to contact details
 */
export async function sendProforma(data: {
  proformaId: number;
  emails: string[];
  orgId: number;
}) {
  const result = await post(
    Api.proformaInvoice +
      `send-invoice?invoice_id=${data.proformaId}&current_organization=${data.orgId}`,
    data.emails
  );
  return result;
}

/**
 * API call for calculating taxable amount
 */

export async function calculateTaxableAmount(data: {
  values: TaxableAmountCalculationValues;
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.proformaInvoice +
      `invoice-item/taxable-amount?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}

/**
 * API call for calculating invoice amount
 */

export async function calculateAmount(data: {
  values: TotalAmountCalculationValues;
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.proformaInvoice +
      Api.invoice +
      `/amount-calculation?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}

/**
 * Api to get invoice pdf link
 */
export async function proformaPdfLink(data: {
  proformaId: number;
  orgId: number;
}) {
  const result = await get(
    Api.proformaInvoice +
      "download-link?invoice_id=" +
      data.proformaId +
      `&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to upload invoice attachments
 */
export async function proformaAttachments(data: {
  file: FormData;
  orgId: number;
}) {
  const result = await postFormData(
    Api.proformaInvoice +
      `invoice-attachments?current_organization=${data.orgId}`,
    data.file
  );
  return result;
}

/**
 * Api to delete invoice attachments
 */
export async function proformaAttachmentsDelete(data: {
  proformaId: number;
  fileId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.proformaInvoice +
      `invoice-attachments/${data.proformaId}?file_id=${data.fileId}&current_organization=${data.orgId}`
  );
  return result;
}
/**
 * Api to get the transaction limit status
 */
export async function transactionLimitStatus(orgId: number) {
  const result = await get(
    Api.proformaInvoice +
      `transaction-limit-status?current_organization=${orgId}`
  );
  return result;
}
