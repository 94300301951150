import { getFormatedDate } from "../../../../../helpers/helper";
export const initialPMFormValues = {
  payment_number: "",
  payment_date: getFormatedDate(new Date()),
  is_date_changed:false,
  vendor_name: "",
  vendor_id: "",
  notes: "",
  paid_through_account_name: "",
  paid_through_account_id: "",
  amount_paid: "",
  amount_paid_bank_currency: "",
  bank_charges_amount: "",
  bank_charges_bank_currency: "",
  bank_charges_account_name: "",
  bank_charges_account_id: "",
  check_expiry: getFormatedDate(),
  check_date: getFormatedDate(),
  check_expiry_in: "",
  check_number: "",
  payment_mode: "Cash",
  file_ids: [],
  email_to: [], // contain emailIds
  delete_email_ids: [], // contain contact id to be deleted
  email_data: [], // contain contact data with id and email id
  unpaid_bill_lines: [],
  conversion_id: "",
  conversion_rate: "",
  conversion_timestamp:"",
  bank_conversion_id: null,
  bank_conversion_rate: null,
  bank_conversion_timestamp:"",
  currency_id: "",
  currency_code: "",
  is_currency_changed: false,
  bank_currency_id: null,
  reference_number: "",
  is_locked: false,
  lock_date: "",
  action: "",
  amount_excess: "",
  pm_status: "Open",
  amount_for_payment: "",
  files: [],
  custom_fields: {},
  
};
