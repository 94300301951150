import moment from "moment";
import React, {
  Dispatch,
  ForwardRefRenderFunction,
  SetStateAction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getFormatedDate } from "../../../../../helpers/helper";
import {
  joinForPayload,
  splitTransactionNumber,
} from "../../../../../helpers/prefixHelper";
import { ObjectType, PaymentTerms } from "../../../../../types";
import {
  currentUserSelector,
  paymentTermsSelector,
  setLoaderState,
} from "../../../../common/commonSlice";
import CustomerSelect from "../../../../common/components/CustomerSelect";
import PaymentTermSelect from "../../../../common/components/PaymentTermSelect";
import SupplyStates from "../../../../common/components/SupplyStates";
import { FormSelectField } from "../../../../common/components";
import { FormInputField } from "../../../../common/components/formInputField/FormInputField";
import { FormInputFieldsInRows } from "../../../../common/components/formInputFieldInRows/FormInputFieldInRows";
import { PaymentTermsModal } from "../../../../common/components/paymentTerms/paymentTermsModal";
import { useSubModulePermission } from "../../../../hooks/useSubModulePermissions";
import CustomDatepicker from "../../../../utils/atoms/datepicker";
import { AddCustomerModal } from "../../../customerManagement/components/addCustomerModal/AddCustomerModal";
import AlertModalPopup from "../../../../common/components/alertModalPopup/AlertModalPopup";
import { fetchGstSettings } from "../../../gst/gstSlice";
import useGst from "../../../../hooks/useGst";
import { gstRegistrationTypes } from "../../../../constants/constants";
import "./Subscription.css";
import {
  BasicDetailsNextInvoiceType,
  SubscriptionDefaultRef,
  SubscriptionDefaultValues,
  SubscriptionValues,
} from "../../subscriptionType";
import { PrefixInput } from "../../../paymentsReceived/components/paymentReceivedForm/imports";
import {
  initialDefaultValues,
  invoicingCycleOption,
  invoicingOptions,
  invoiceGenerationOptions,
  startSubscriptionOption,
} from "../../SubscriptionStateInitialization";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import { message } from "../../../../constants/messages";
import { getNewSubscriptionNumber } from "../../subscriptionSlice";
import { getNextDate } from "../../../../../helpers/subscriptionHelper";

type Props = {
  defaultFormValues: SubscriptionDefaultValues;
  subscriptionFormValues: SubscriptionValues;
  formErrors: any;
  handleCustomerChange: (e: any) => void;
  checkSubscriptionNumberExist: (profileName: string) => void;
  organizationId: number;
  handleNewConversionDate: (value: string) => void;
  customFieldValue: ObjectType;
  customFieldRef: any;
  getCustomFieldData: (data: ObjectType) => void;
  setStateType?: any;
  fetchGstTaxList: (
    stateType: string,
    gst_registration_type: string | undefined
  ) => Promise<void>;
  setSupplyState?: any;
  initialStateType: string;
  setIsStateChanged?: any;
  gstRegistrationType?: string;
  selectedInvoicingCycle: any;
  setBasicDetailsNextInvoice: Dispatch<
    SetStateAction<BasicDetailsNextInvoiceType>
  >;
};

const SubscriptionBasicDetails: ForwardRefRenderFunction<
  SubscriptionDefaultRef,
  Props
> = (props, ref) => {
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const { editId } = useParams();
  const paymentTerms = useAppSelector(paymentTermsSelector);
  const subModulePermission = useSubModulePermission();

  const [defaultFormValues, setDefaultFormValues] =
    useState<SubscriptionDefaultValues>(initialDefaultValues);
  const [subscriptionNumberObj, setSubscriptionNumberObj] =
    useState<ObjectType>({});
  const [
    placeOfSupplyUpdateModalTriggered,
    setPlaceOfSupplyUpdateModalTriggered,
  ] = useState<number>();

  const [showPlaceofSupplyFlag, setShowPlaceofSupplyFlag] = useState(true);
  const placeOfSupplyUpdateModalRef = useRef<any>();
  const { isGstOrg } = useGst();
  useEffect(() => {
    if (!editId) subscriptionNumber();
  }, [currentUserInfo.organization_id]);

  useImperativeHandle(
    ref,
    () => ({
      defaultData: defaultFormValues,
      subscriptionNumber: joinForPayload(subscriptionNumberObj),
    }),
    [defaultFormValues, subscriptionNumberObj]
  );
  useEffect(() => {
    if (!editId) dispatch(setLoaderState(false));
  }, []);

  const getGstSettings = async () => {
    let response = await dispatch(
      fetchGstSettings({
        orgId: currentUserInfo.organization_id,
      })
    );

    let data: any = response.payload;

    data?.gst_enabled
      ? setShowPlaceofSupplyFlag(true)
      : setShowPlaceofSupplyFlag(false);
  };

  useEffect(() => {
    getGstSettings();
  }, [currentUserInfo.organization_id, showPlaceofSupplyFlag]);

  useEffect(() => {
    setDefaultFormValues({ ...defaultFormValues, ...props.defaultFormValues });
    if (editId) {
      const transaction_obj = splitTransactionNumber(
        props.defaultFormValues.subscription_number
      );
      const subscription_obj = {
        subscription_prefix: transaction_obj["prefix"],
        subscription_number: transaction_obj["number"],
      };
      setSubscriptionNumberObj(subscription_obj);
    }
  }, [props.defaultFormValues]);

  useEffect(() => {
    placeOfSupplyUpdateModalTriggered &&
      placeOfSupplyUpdateModalRef.current?.openAlertModal &&
      placeOfSupplyUpdateModalRef.current?.openAlertModal();
  }, [placeOfSupplyUpdateModalTriggered]);
  /**
   * For checking if the subscription number exist on prefix change
   */
  useEffect(() => {
    props.checkSubscriptionNumberExist(joinForPayload(subscriptionNumberObj));
  }, [subscriptionNumberObj["subscription_prefix"]]);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, [paymentTerms, defaultFormValues]);

  useEffect(() => {
    if (!editId) {
      paymentTerms.map((term: PaymentTerms, index: number) => {
        if (term.term_name == "Due on receipt") {
          setDefaultFormValues((values) => ({
            ...values,
            payment_term_id: term.id,
          }));
        }
      });
    }
  }, [paymentTerms]);

  function isFloat(value: any) {
    const res = value.split(".");
    if (res[1]) {
      return true;
    }
    return false;
  }

  const subscriptionNumber = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      getNewSubscriptionNumber(currentUserInfo.organization_id)
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setSubscriptionNumberObj(response);
        let subscription_number = joinForPayload(response);
        setDefaultFormValues((values) => ({
          ...values,
          subscription_number: subscription_number ? subscription_number : "",
        }));
      }
    }
    dispatch(setLoaderState(false));
  };
  const onSelectFieldChanged = async (
    name: string,
    option: { label: string; value: string }
  ) => {
    if (name === "start_subscription" && option.value === "IMMEDIATELY") {
      setDefaultFormValues({
        ...defaultFormValues,
        [name]: option.value,
        start_date: getFormatedDate(new Date()),
      });
      onBasicDetailsChange({ start_date: getFormatedDate(new Date()) });
    } else if (
      name === "start_subscription" &&
      option.value === "CHOOSE_DATE"
    ) {
      setDefaultFormValues({
        ...defaultFormValues,
        [name]: option.value,
        start_date: getFormatedDate(getNextDate()),
      });
      onBasicDetailsChange({ start_date: getFormatedDate(getNextDate()) });
    } else if (name === "invoicing_cycle" && option.value === "FOREVER") {
      setDefaultFormValues({
        ...defaultFormValues,
        [name]: option.value,
        invoicing_cycle_fixed_count: 0,
      });
      onBasicDetailsChange({
        [name]: option.value,
        invoicing_cycle_fixed_count: 0,
      });
    } else if (name === "invoicing_option") {
      onBasicDetailsChange({ invoicingoption: option.value });
      setDefaultFormValues({ ...defaultFormValues, [name]: option.value });
    } else if (name === "invoice_generation") {
      onBasicDetailsChange({ invoicegeneration: option.value });
      setDefaultFormValues({ ...defaultFormValues, [name]: option.value });
    } else if (name === "invoicing_cycle" && option.value === "FIXED") {
      setDefaultFormValues({
        ...defaultFormValues,
        [name]: option.value,
        invoicing_cycle_fixed_count: 1,
      });
      onBasicDetailsChange({
        [name]: option.value,
        invoicing_cycle_fixed_count: 1,
      });
    } else setDefaultFormValues({ ...defaultFormValues, [name]: option.value });
  };
  /**
   * Set subscription default form values
   */
  const handleChange = (e: any) => {
    const { name, value, checked } = e.target;
    if (name === "subscription_number") {
      props.checkSubscriptionNumberExist(
        subscriptionNumberObj["subscription_prefix"] +
          "~" +
          value.replace("~", "").toUpperCase()
      );
      setDefaultFormValues({
        ...defaultFormValues,
        [name]:
          subscriptionNumberObj["subscription_prefix"] +
          "~" +
          value.replace("~", "").toUpperCase(),
      });
      setSubscriptionNumberObj({
        ...subscriptionNumberObj,
        [name]: value.replace("~", "").toUpperCase(),
      });
    } else if (name === "invoicing_cycle_fixed_count") {
      setDefaultFormValues({ ...defaultFormValues, [name]: value });
      onBasicDetailsChange({ [name]: value });
    }
  };

  const handleCustomerChange = (e: {
    label: string;
    value: string | number;
  }) => {
    setDefaultFormValues((values) => ({
      ...values,
      customer_id: e.value.toString(),
      customer_name: e.label.trim(),
    }));
    props.handleCustomerChange(e);
  };

  const onStateChanged = async (
    name: string,
    option: { label: string; value: string }
  ) => {
    let newDefaultFormValues = { ...defaultFormValues, [name]: option.value };
    setDefaultFormValues(newDefaultFormValues);
    props.setSupplyState(option.value);
    props.setIsStateChanged(true);

    if (
      newDefaultFormValues?.place_of_supply &&
      currentUserInfo.organization_gst_state
    ) {
      let stateType = "";
      if (
        newDefaultFormValues?.place_of_supply ===
        currentUserInfo.organization_gst_state
      ) {
        if (props.initialStateType === "Inter State") {
          setPlaceOfSupplyUpdateModalTriggered(
            Math.floor(Math.random() * 1000)
          );
        }
        stateType = "Intra State";
        props.setStateType("Intra State");
      } else {
        if (props.initialStateType === "Intra State") {
          setPlaceOfSupplyUpdateModalTriggered(
            Math.floor(Math.random() * 1000)
          );
        }
        stateType = "Inter State";
        props.setStateType("Inter State");
      }
      props.fetchGstTaxList(stateType, props?.gstRegistrationType);
    } else {
      props.setStateType("");
    }
  };
  const handlePaymentTermChange = (e: any) => {
    if (e.value) {
      let value = e.value;
      setDefaultFormValues((values) => ({ ...values, payment_term_id: value }));
    }
  };

  const handleDate = (date: Date | null, type: string) => {
    if (date) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const targetDate = new Date(date);
      targetDate.setHours(0, 0, 0, 0);
      if (date?.toString() === "Invalid Date" || currentDate > targetDate) {
        setDefaultFormValues((values) => ({
          ...values,
          [type]: "Invalid date",
        }));
      } else {
        const dateFormated = getFormatedDate(date);
        if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
          onBasicDetailsChange({ start_date: dateFormated });
          if (type === "start_date") {
            setDefaultFormValues((values) => ({
              ...values,
              [type]: dateFormated,
            }));
          }
        } else {
          setDefaultFormValues((values) => ({
            ...values,
            [type]: "Invalid date",
          }));
        }
      }
    } else if (type === "start_date" && date === null) {
      setDefaultFormValues((values) => ({
        ...values,
        [type]: "Empty date",
      }));
    }
  };

  /**
   * To get prefix from PrefixInput component.
   */
  const getPrefix = (value: string) => {
    setSubscriptionNumberObj({
      ...subscriptionNumberObj,
      ["subscription_prefix"]: value.toUpperCase(),
    });
  };

  useEffect(() => {
    if (!!props?.selectedInvoicingCycle?.invoicingCycle) {
      const slectedInvoiceCycle =
        invoicingCycleOption.filter(
          (data: any) =>
            data.value === props.selectedInvoicingCycle.invoicingCycle
        )[0] || "";
      setDefaultFormValues({
        ...defaultFormValues,
        invoicing_cycle: slectedInvoiceCycle.value || "",
        invoicing_cycle_fixed_count:
          props.selectedInvoicingCycle.invoiceCycleCount || 0,
      });
      onBasicDetailsChange({
        invoicing_cycle: slectedInvoiceCycle.value || "",
        invoicing_cycle_fixed_count:
          props.selectedInvoicingCycle.invoiceCycleCount || 0,
      });
    } else {
      setDefaultFormValues({
        ...defaultFormValues,
        invoicing_cycle: "",
        invoicing_cycle_fixed_count: 0,
      });
      onBasicDetailsChange({
        invoicing_cycle: "",
        invoicing_cycle_fixed_count: 0,
      });
    }
  }, [props.selectedInvoicingCycle]);

  const onBasicDetailsChange = (changeValue: ObjectType) => {
    props.setBasicDetailsNextInvoice((preValue) => ({
      ...preValue,
      ...changeValue,
    }));
  };
  return (
    <section className="form-top-section subscription-basic-deatils">
      <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325">
          <div className="link-label-grouped">
            <label htmlFor="customer" className="required">
              Customer Name
            </label>
            {editId ? null : <AddCustomerModal />}
          </div>
          <div>
            <CustomerSelect
              customerId={defaultFormValues.customer_id}
              errors={props.formErrors.customer_name}
              onChange={handleCustomerChange}
              isDisabled={editId ? true : false}
            />
            <span className="error">{props.formErrors.customer_name}</span>
          </div>
        </div>
        <div className="col-12 col-lg-4 mw-325">
          <label htmlFor="subscription_number" className="required">
            Subscription Number
          </label>
          <br />
          <PrefixInput
            prefix={defaultFormValues.subscription_number.split("~")[0]}
            name="subscription_number"
            id="subscription_number"
            className="form-control border-end-0"
            placeholder="Enter subscription number"
            value={defaultFormValues.subscription_number.split("~")[1]}
            disabled={false}
            error={
              props.formErrors.subscription_number
                ? props.formErrors.subscription_number
                : ""
            }
            maxLength={12}
            getPrefix={getPrefix}
            onChange={handleChange}
          />
          <span className="error">
            {props.formErrors.subscription_number
              ? props.formErrors.subscription_number
              : ""}
          </span>
        </div>
      </div>
      <div className="row custom-row subscription-field-alignment">
        <div className={"col-12 col-lg-4 mw-325 "}>
          <FormSelectField
            name="start_subscription"
            id="start_subscription"
            label="Start Subscription"
            options={startSubscriptionOption}
            wrapperClass="mw-325"
            className="state-select custom-select"
            value={
              startSubscriptionOption.filter(
                (data: any) =>
                  data.value === defaultFormValues.start_subscription
              )[0] || ""
            }
            onChange={(option: any) => {
              onSelectFieldChanged("start_subscription", option);
            }}
            styles={customSelectStyle}
            placeholder={"Choose start subscription"}
            isSearchable={true}
            isDisabled={editId ? true : false}
            error=""
            // error={props.formErrors.repeat_every}
            isRequired={false}
            isOnlyInRow={false}
          />
        </div>
        {defaultFormValues.start_subscription === "CHOOSE_DATE" ? (
          <div className={"col-12 col-lg-4 mw-325 "}>
            <label htmlFor="check_expiry" className="">
              Start Date
            </label>
            <div
              id="date"
              className={`date-selector-wrapper ${
                editId ? "update-subscription-date-disabled" : ""
              }`}
            >
              <CustomDatepicker
                date={
                  defaultFormValues.start_date
                    ? new Date(defaultFormValues.start_date)
                    : null
                }
                handleDate={handleDate}
                type="start_date"
                error={
                  props.formErrors.start_date ? props.formErrors.start_date : ""
                }
                id="start_date"
                zIndex={0}
                isDisabled={editId ? true : false}
                placeholder="Enter start date"
                minDate={getNextDate()}
                enableFutureDate={true}
              />
            </div>
            <span className="error error_date mb-2">
              {props.formErrors.start_date ? props.formErrors.start_date : ""}
            </span>
          </div>
        ) : null}
        <div className={"col-12 col-lg-4 mw-325 "}>
          <FormSelectField
            name="invoicing_cycle"
            id="invoicing_cycle"
            label="Invoicing Cycle"
            options={invoicingCycleOption}
            wrapperClass="mw-325"
            className="state-select custom-select"
            value={
              invoicingCycleOption.filter(
                (data: any) => data.value === defaultFormValues.invoicing_cycle
              )[0] || ""
            }
            onChange={(option: any) => {
              onSelectFieldChanged("invoicing_cycle", option);
            }}
            styles={customSelectStyle}
            placeholder={"Choose invoicing cycle"}
            isSearchable={true}
            isDisabled={
              !editId && !props.selectedInvoicingCycle?.invoicingCycle
            }
            error=""
            isRequired={false}
            isOnlyInRow={false}
            tooltipMessage={message().subscription.invoicingCycle}
            messagePlacement="top-end"
          />
        </div>
        {defaultFormValues.invoicing_cycle === "FIXED" ? (
          <FormInputField
            type="text"
            name="invoicing_cycle_fixed_count"
            id="ref_no"
            className={
              props.formErrors.invoicing_cycle_fixed_count
                ? "form-control error"
                : "form-control"
            }
            wrapperClass="no-of-cycle-wrapper h-89"
            label="Number of Cycles"
            placeholder="Enter number of cycles"
            maxLength={16}
            value={defaultFormValues.invoicing_cycle_fixed_count}
            onChange={handleChange}
            error={props.formErrors.invoicing_cycle_fixed_count || ""}
          />
        ) : null}
        <div className="row custom-row">
          <div className="col-12 col-lg-4 mw-325">
            <div className="link-label-grouped">
              <label htmlFor="terms">Payment terms</label>
              {subModulePermission.includes("Payments Terms") ? (
                <PaymentTermsModal />
              ) : null}
            </div>
            <PaymentTermSelect
              paymentTermId={defaultFormValues.payment_term_id}
              haveCustom={false}
              isCustom={false}
              onChange={(e: any) => {
                handlePaymentTermChange(e);
              }}
            />
          </div>
          {isGstOrg &&
            props.gstRegistrationType !== gstRegistrationTypes.overseas && (
              <div className="col-12 col-lg-4 mw-325">
                <SupplyStates
                  name="place_of_supply"
                  id="place_of_supply"
                  label="Place of Supply"
                  value={
                    defaultFormValues?.place_of_supply
                      ? {
                          value: defaultFormValues?.place_of_supply,
                          label: defaultFormValues?.place_of_supply,
                        }
                      : ""
                  }
                  onChange={(option: any) => {
                    onStateChanged("place_of_supply", option);
                  }}
                  error={props.formErrors.place_of_supply}
                />
              </div>
            )}
        </div>
        {!editId ? (
          <div className={"col-12 col-lg-4 mw-325"}>
            <FormSelectField
              name="invoicing_option"
              id="invoicing_option"
              label="Invoicing Option"
              options={invoicingOptions}
              wrapperClass="mw-325"
              className="state-select custom-select"
              value={
                invoicingOptions.filter(
                  (data: any) =>
                    data.value === defaultFormValues.invoicing_option
                )[0]
              }
              onChange={(option: any) => {
                onSelectFieldChanged("invoicing_option", option);
              }}
              styles={customSelectStyle}
              placeholder={""}
              isSearchable={true}
              isDisabled={editId ? true : false}
              error=""
              isRequired={false}
              isOnlyInRow={false}
              messagePlacement="top-end"
              tooltipMessage={message().subscription.invoicingOption}
            />
          </div>
        ) : null}

        {!editId ? (
          <div className={"col-12 col-lg-4 mw-325"}>
            <FormSelectField
              name="invoice_generation"
              id="invoice_generation"
              label="Invoice generation"
              options={invoiceGenerationOptions}
              wrapperClass="mw-325"
              className="state-select custom-select"
              value={
                invoiceGenerationOptions.filter(
                  (data: any) =>
                    data.value === defaultFormValues.invoice_generation
                )[0]
              }
              onChange={(option: any) => {
                onSelectFieldChanged("invoice_generation", option);
              }}
              styles={customSelectStyle}
              placeholder={""}
              isSearchable={true}
              isDisabled={editId ? true : false}
              error=""
              isRequired={false}
              isOnlyInRow={false}
              messagePlacement="top-end"
              tooltipMessage={message().subscription.invoiceGeneration}
            />
          </div>
        ) : null}
      </div>
      <AlertModalPopup
        message="We will update the taxes applied to the items in this transaction as you've changed the place of supply"
        modalTitle="Alert!"
        isCloseButtonEnabled={true}
        isSubmitEnabled={false}
        openRef={placeOfSupplyUpdateModalRef}
      />
    </section>
  );
};

export default React.memo(forwardRef(SubscriptionBasicDetails));
