import { TagIconBlue } from "../../../../../assets/images";
import { ModalContext } from "../../sideModal/sideModalContext";
import { SideModal } from "../../sideModal/SideModal";
import {
  AssociatedTagOptionsType,
  ObjectType,
  ReportTagFormValues,
} from "../../../../../types";
import SelectReportTagForm from "../SelectReportTagForm/SelectReportTagForm";
import CustomTooltips from "../../Tooltip";
import "./SelectReportingTag.css";

type Props = {
  editId?: number;
  wrapperClass?: string;
  buttonClass?: string;
  onOpenFunction?: () => void;
  onCloseFunction?: () => void;
  selectedTagsCount?: number;
  updateTagsSelection: (
    accountId: number,
    splitType: string,
    tagsList: any,
    index?: number | null,
    entityId?: number | null
  ) => void;
  selectedTags?: ReportTagFormValues;
  accountId: number;
  accountName: string;
  transactionType: string;
  transactionAmount: string | number;
  currency: {
    currency_id: number | null;
    currency_code: string;
  };
  optionsList: ObjectType[];
  splitType: string;
  entityId?: number | null;
  index?: number | null;
  specificReportingTagsOptions?: AssociatedTagOptionsType;
  isSpecialTagOptionsPresent?: boolean;
  isTagOptionsExists?: boolean;
  isButtonDisabled?: boolean;
  module?: string;
  isMutable?: boolean;
  maxTags?: number;
  maxOptions?: number;
  isVoid?: boolean;
};
const SelectReportingTag = (props: Props) => {
  const getFilteredTagOptions = (entityTags?: AssociatedTagOptionsType) => {
    return entityTags && entityTags[String(props.entityId)];
  };
  const getAssociatedModuleName = (module: string) => {
    switch (module) {
      case "Payments Received":
        return "Invoice";
      case "Payments Made":
        return "Bill";
      case "Invoice":
        return "Invoice";
      case "Bill":
        return "Biil";
      case "Credit Note":
        return "Credit Note";
      case "Debit Note":
        return "Debit Note";
      default:
        return "this module";
    }
  };

  const buttonContent = props.isTagOptionsExists ? (
    props.selectedTagsCount ? (
      <span>
        <img src={TagIconBlue} alt="tag-icon" width={15} />
        {props.selectedTagsCount} Tags selected
      </span>
    ) : (
      <span>Select Tag</span>
    )
  ) : (
    <CustomTooltips
      placement="bottom"
      title={`Tag cannot be selected for this account as the reporting tag is not associated with the ${getAssociatedModuleName(
        props.module || ""
      )}`}
    >
      <span>Select Tag</span>
    </CustomTooltips>
  );

  const isButtonDisabled =
    (!props.isTagOptionsExists || props.isVoid) &&
    props.selectedTagsCount === 0;

  return (
    <SideModal
      modalTitle={"Reporting Tags"}
      wrapperClass={"select-reporting-tag-modal"}
      drawerWrapperClass={"select-reporting-tag-drwaer button-bottom"}
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <SelectReportTagForm
                onClose={onCloseModal}
                isEdit={props.editId ? true : false}
                editId={props.editId}
                selectedTags={props.selectedTags}
                updateTagsSelection={props.updateTagsSelection}
                accountId={props.accountId}
                accountName={props.accountName}
                transactionType={props.transactionType}
                transactionAmount={props.transactionAmount}
                currency={props.currency}
                optionsList={props.optionsList}
                splitType={props.splitType}
                entityId={props.entityId}
                specialTagOptions={getFilteredTagOptions(
                  props.specificReportingTagsOptions
                )}
                isSpecialTagOptionsPresent={props.isSpecialTagOptionsPresent}
                index={props.index}
                isMutable={props.isMutable}
                module={props.module}
                maxTags={props.maxTags ? props.maxTags : null}
                maxOptions={props.maxOptions ? props.maxOptions : null}
                isVoid={props.isVoid}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      isButtonDisabled={isButtonDisabled}
      buttonContent={buttonContent}
      buttonClass={
        props.buttonClass
          ? props.buttonClass
          : "no-appearance open-reporting-tag-modal-btn m-0 "
      }
      onOpenFunction={props.onOpenFunction}
    />
  );
};

export default SelectReportingTag;
