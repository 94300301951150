import { Api } from "../../../constants";
import {
  get,
  post,
  postFormData,
  put,
  remove,
} from "../../../helpers/apiHelper";
import { JournalLineItemCalc, JournalValues } from "../../../types";
/**
 * API call for getting all journal vouchers.
 */
export async function getJournalList(data: {
  page: number;
  itemsPerPage: number;
  dateSortOrder: string;
  orgId: number;
  orgIds: number[];
  statusList: string[];
  startDate?: string;
  endDate?: string;
  startDueDate?: string;
  endDueDate?: string;
}) {
  let url =
    Api.journalVoucher +
    "journal/list" +
    `?page_num=${data.page}&page_size=${
      data.itemsPerPage
    }&current_organization=${data.orgId}${
      data.startDate ? `&start_date=${data.startDate.trim()}` : ""
    }${data.endDate ? `&end_date=${data.endDate.trim()}` : ""}${
      data.startDueDate ? `&start_due_date=${data.startDueDate.trim()}` : ""
    }${
      data.endDueDate ? `&end_due_date=${data.endDueDate.trim()}` : ""
    }`.trim();

  if (data.dateSortOrder !== "") {
    url += `&date_sort_order=${data.dateSortOrder}`;
  }
  if (data.orgIds.length !== 0) {
    const orgIds = data.orgIds
      .map((orgId) => {
        return `&org_ids=${orgId}`;
      })
      .join("");
    url += orgIds;
  }
  if (data.statusList.length !== 0) {
    const statuses = data.statusList
      .map((status) => {
        return `&status_list=${status}`;
      })
      .join("");
    url += statuses;
  }
  const result = await get(url);
  return result;
}
/**
 * API call for deleting journal voucher
 */
export async function deleteJournal(data: {
  journalId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.journalVoucher +
      "journal/delete" +
      `/${data.journalId}?current_organization=${data.orgId}`
  );
  return result;
}
/**
 * API call for creating journal voucher
 */
export async function createNewJournalVoucher(data: {
  values: JournalValues;
  status: string;
  orgId: number;
}) {
  const result = await post(
    Api.journalVoucher +
      `journal-voucher/create?journal_status=${data.status}&current_organization=${data.orgId}`,
    data.values
  );
  return result;
}
/**
 * API call for edit journal voucher
 */
export async function editJournalVoucher(data: {
  id: number;
  values: JournalValues;
  status: string;
  orgId: number;
}) {
  const result = await put(
    Api.journalVoucher +
      "journal/edit/" +
      data.id +
      `?journal_status=${data.status}&current_organization=${data.orgId}`,
    data.values
  );
  return result;
}
/**
 * API call for delete journal voucher item
 */
export async function deleteJournalItem(data: {
  journalId: number;
  itemId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.journalVoucher +
      "journal-item/delete/" +
      data.journalId +
      "/" +
      data.itemId +
      `?current_organization=${data.orgId}`
  );
  return result;
}
/**
 * API call for delete journal voucher recipients
 */
export async function deleteJournalRecipient(data: {
  journalId: number;
  emailIds: number[];
  orgId: number;
}) {
  const result = await remove(
    Api.journalVoucher +
      "journal-recipient/delete/" +
      data.journalId +
      `?current_organization=${data.orgId}`,
    data.emailIds
  );
  return result;
}
/**
 * API call for get journal voucher number
 */
export async function getJournalNumber(orgId: number) {
  const result = await get(
    Api.journalVoucher +
      `new-journal-voucher-number?current_organization=${orgId}`
  );
  return result;
}
/**
 * API call for checking the journal number exist or not
 */
export async function journalNumberExist(data: {
  journalNumber: string;
  orgId: number;
  journalId: number;
}) {
  const result = await get(
    Api.journalVoucher +
      "journal-voucher-number-available?journal_voucher_number=" +
      data.journalNumber +
      `&journal_voucher_id=${data.journalId}&current_organization=${data.orgId}`
  );
  return result;
}
/**
 * API call for getting journal voucher detail
 */
export async function getJournalDetails(data: {
  journalId: number;
  orgId: number;
}) {
  const result = await get(
    Api.journalVoucher +
      `journal/detail/${data.journalId}?current_organization=${data.orgId}`
  );
  return result;
}
/**
 * API call for calculating journal voucher amount
 */
export async function calculateAmount(data: {
  currencyId: number;
  values: JournalLineItemCalc[];
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.journalVoucher +
      `journal/amount-calculation?current_organization=${data.orgId}&currency_id=${data.currencyId}`,
    data.values,
    data.signal
  );
  return result;
}
/**
 * Api to upload journal voucher attachments
 */
export async function journalAttachments(data: {
  file: FormData;
  orgId: number;
}) {
  const result = await postFormData(
    Api.journalVoucher +
      `journal-attachments?current_organization=${data.orgId}`,
    data.file
  );
  return result;
}
/**
 * Api to delete journal attachments
 */
export async function journalAttachmentsDelete(data: {
  journalId: number;
  fileId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.journalVoucher +
      `journal-attachments/${data.journalId}?file_id=${data.fileId}&current_organization=${data.orgId}`
  );
  return result;
}
/**
 * Api to get journal pdf link
 */
export async function journalPdfLink(data: {
  journalId: number;
  orgId: number;
}) {
  const result = await get(
    Api.journalVoucher +
      "download-link?journal_voucher_id=" +
      data.journalId +
      `&current_organization=${data.orgId}`
  );
  return result;
}
/**
 * API call for sending journal voucher to contact details
 */
export async function sendJournal(data: {
  journalId: number;
  emails: string[];
  orgId: number;
}) {
  const result = await post(
    Api.journalVoucher +
      `send-journal-voucher?journal_voucher_id=${data.journalId}&current_organization=${data.orgId}`,
    data.emails
  );
  return result;
}
/**
 * Api to get the transaction limit status
 */
export async function transactionLimitStatus(orgId: number) {
  const result = await get(
    Api.journalVoucher +
      `transaction-limit-status?current_organization=${orgId}`
  );
  return result;
}

/**
 * Api to get journal for reporting tags
 */
export async function getReportingTagJournals(data: {
  journalVoucherId: number;
  orgId: number;
}) {
  const result = await get(
    Api.journalVoucher +
      `reporting-tag/journal-generate?journal_voucher_id=${data.journalVoucherId}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to update reporting tag
 */
export async function putReportingTags(data: {
  journalVoucherId: number;
  orgId: number;
  payload: any;
}) {
  const result = await put(
    Api.journalVoucher +
      `reporting-tag/edit/${data.journalVoucherId}?journal_voucher_id=${data.journalVoucherId}&current_organization=${data.orgId}`,
    data.payload
  );
  return result;
}
