import React from "react";
import useForm from "../formAdapter";
import { FormInputField } from "../../features/common/components/FormInputField";
import { Form } from "../../features/common/components/htmlTags/Form";
import * as yup from "yup";
import { ObjectType } from "../../types";

const yupValidationSchema = yup.object().shape({
  users: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Name is required"),
      email: yup
        .string()
        .email("Email is invalid")
        .required("Email is required"),
    })
  ),
});

const MyFormComponent: React.FC = () => {
  const { handleSubmit, handleChange, errors, values, setFieldValue } = useForm({
    initialValues: {
      org:'',
      place:'',
      users: [
        { name: "", email: "" },
        { name: "", email: "" },
        { name: "", email: "" },
        { name: "", email: "" },
      ],
    },
    validationSchema: yupValidationSchema,
    onSubmit: () => {
      console.log("values", values)
    },
  });

  const handleOrgchange = (e:any) => {
    setFieldValue("org", e.target.value);
  }

  return (
    <Form onSubmit={handleSubmit}>

      {JSON.stringify(values)}
      <FormInputField
              id={"value"}
              name={`org`}
              type="text"
              className=""
              value={values.org}
              onChange={handleOrgchange}
              onBlur={() => {}}
              label={`org`}
              placeholder={`Enter org`}
              error={""}
            />
      {values.users.map((value: ObjectType, index: number) => {
        return (
          <>
            <FormInputField
              id={"value" + index}
              name={`users[${index}].name`}
              type="text"
              className=""
              value={value.name}
              onChange={handleChange}
              onBlur={() => {}}
              label={`label ${index}`}
              placeholder={`Enter ${index}`}
              error={""}
            />
            {errors.users?.[index].name?.message && (
                <p>{errors.users[index].name?.message}</p>
              )}
            <FormInputField
              id={"value" + index}
              name={`users[${index}].email`}
              type="text"
              className=""
              value={value.email}
              onChange={handleChange}
              onBlur={() => {}}
              label={`label ${index}`}
              placeholder={`Enter ${index}`}
              error={""}
            />
            {errors.users?.[index]?.email?.message && (
                <p>{errors.users[index].email?.message}</p>
              )}
          </>
        );
      })}
      <button type="submit">Submit</button>
    </Form>
  );
};

export default MyFormComponent;
