import { useState, useEffect } from "react";
import CustomDatepicker from "../../../../utils/atoms/datepicker";
import { RadioButtonGroup } from "../../../../common/components/radioButtonGroup/radioButtonGroup";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { gstSettingsValidation } from "./gstSettingsValidation";
import useCommonData from "../../../../hooks/useCommon";
import { GstSettingsValues } from "../../../../../types";
import GstSettingsContainer from "./GstSettingsContainer";
import { getFormatedDate } from "../../../../../helpers/helper";
import { ErrorImg } from "../../../../../assets/images";
import { toast } from "react-toastify";

import moment from "moment";
import {
  SuccessToaster,
  ErrorToaster,
} from "../../../../../helpers/toastHelper";
import { fetchGstSettings, gstSettingsSave } from "../../gstSlice";
import { useFormik } from "formik";
import {
  currentUserDetails,
  setLoaderState,
} from "../../../../common/commonSlice";
import { message } from "../../../../constants/messages";
import useToast from "../../../../hooks/useToast";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";

const GstSettings = () => {
  const { dispatch, currentUserInfo } = useCommonData();
  const { isToastExistById } = useToast();
  const [formValues, setFormValues] = useState<GstSettingsValues>({
    gst_enabled: "No",
    gst: "",
    business_legal_name: "",
    business_trade_name: "",
    registered_on: null,
  });
  const [transactionExist, setTransactionExist] = useState(false);
  const { gstPermission } = usePermissionHandler();

  useEffect(() => {
    getGstSettings();
  }, []);

  useEffect(() => {
    getGstSettings();
  }, [currentUserInfo.organization_id]);

  const getGstSettings = async () => {
    try {
      dispatch(setLoaderState(true));
      let response = await dispatch(
        fetchGstSettings({
          orgId: currentUserInfo.organization_id,
        })
      );
      let data: any = response.payload;

      setFormValues((formValues) => {
        return {
          ...formValues,
          gst_enabled: data?.gst_enabled ? "Yes" : "No",
          gst: data?.gst,
          business_legal_name: data?.business_legal_name,
          business_trade_name: data?.business_trade_name,
          registered_on: data?.registered_on ? data?.registered_on : "",
        };
      });
      setTransactionExist(data?.transaction_exist);
      dispatch(setLoaderState(false));
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: gstSettingsValidation,
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: async (values) => {
      let responseAction = await dispatch(
        gstSettingsSave({
          orgId: currentUserInfo.organization_id,
          values: values,
        })
      );
      const response = responseAction.payload;
      if (!("error" in response)) {
        SuccessToaster(message().getSettingUpdateSuccess, "gst-success-toast");
        await dispatch(
          currentUserDetails({
            orgId: Number(sessionStorage.getItem("orgId"))
              ? Number(sessionStorage.getItem("orgId"))
              : 0,
            isOrgChanged: false,
          })
        );
      }
      // setting last saved data as initial formValues and form will reset with this values when user click on cancel button
      setFormValues((formValues) => {
        return {
          ...formValues,
          gst_enabled: values?.gst_enabled,
          gst: values?.gst_enabled === "Yes" ? values?.gst : "",
          business_legal_name:
            values?.gst_enabled === "Yes" ? values?.business_legal_name : "",
          business_trade_name:
            values?.gst_enabled === "Yes" ? values?.business_trade_name : "",
          registered_on:
            values?.gst_enabled === "Yes" ? values?.registered_on : "",
        };
      });

      formik.setFieldTouched("gst", false, false);
    },
    onReset: () => {
      // setEdit(false);
    },
  });

  const handleDate = (date: Date | null, type: string) => {
    if (date) {
      if (date?.toString() === "Invalid Date") {
        formik.setFieldValue("registered_on", "Invalid Date");
      } else {
        const dateFormated = getFormatedDate(date);
        if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
          formik.setFieldValue("registered_on", dateFormated);
        }
      }
    } else {
      formik.setFieldValue("registered_on", "");
    }
  };

  const gstEnabledChangeHandler = (val: string) => {
    if (val === "No" && transactionExist) {
      ErrorToaster(message().gstTransactionExist);
    } else {
      formik.setFieldValue("gst_enabled", val);
    }
  };

  const gstinChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (transactionExist && !isToastExistById("gstin-edit-transaction-exist")) {
      toast.error(message().gstinTransactionExist, {
        icon: ({ theme, type }) => (
          <img src={ErrorImg} width="20px" height="18px" alt="errorImage" />
        ),
        closeButton: false,
        toastId: "gstin-edit-transaction-exist",
        className: "toast-error",
        position: "top-center",
      });
    } else {
      formik.setFieldValue("gst", e.target.value);
    }
  };
  return (
    <>
      <GstSettingsContainer heading="GST Settings">
        <form
          action=""
          name="gst-settings-form"
          id="gst-settings-form"
          className="gst-settings-form w-100"
          onSubmit={formik.handleSubmit}
        >
          <div className="fields-wrapper">
            <section className="form-fields-section">
              <div className="form-fields">
                <div className="row g-0">
                  <div className="col">
                    <span className="gst-enable">
                      Is your business registered for GST?
                    </span>
                    <RadioButtonGroup
                      name="gst_enabled"
                      id="gst_enabled"
                      valuesArray={[
                        { label: "Yes", value: "Yes" },
                        { label: "No", value: "No" },
                      ]}
                      value={formik.values.gst_enabled}
                      icon={
                        <span>
                          <RadioButtonUncheckedIcon />
                        </span>
                      }
                      checkedIcon={
                        <span>
                          <RadioButtonCheckedIcon className="radio-btn-tds" />
                        </span>
                      }
                      isDisabled={
                        !(
                          gstPermission.includes("Create") &&
                          gstPermission.includes("Update") &&
                          gstPermission.includes("Delete") &&
                          gstPermission.includes("Read")
                        )
                      }
                      onClick={gstEnabledChangeHandler}
                    />
                  </div>
                </div>
                {formik.values.gst_enabled === "Yes" && (
                  <>
                    <div className="row g-0">
                      <div className="col ">
                        <label htmlFor="gst" className="required">
                          GSTIN
                        </label>
                        <span>(Maximum 15 digits)</span>
                        <input
                          type="text"
                          name="gst"
                          id="gst"
                          className={`form-control ${
                            formik.errors.gst && formik.touched.gst
                              ? "error"
                              : ""
                          }`}
                          placeholder="Ex:22AAAAA0000A1Z5"
                          value={formik.values.gst}
                          onChange={gstinChangeHandler}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.gst && formik.touched.gst && (
                          <span className="error">{formik.errors.gst}</span>
                        )}
                      </div>
                    </div>
                    <div className="row g-0">
                      <div className="col ">
                        <label htmlFor="business_legal_name">
                          Business Legal Name
                        </label>
                        <input
                          type="text"
                          name="business_legal_name"
                          id="business_legal_name"
                          className={`form-control`}
                          placeholder="Enter Business Legal Name"
                          value={formik?.values?.business_legal_name}
                          onChange={formik.handleChange}
                          maxLength={200}
                        />
                      </div>
                    </div>
                    <div className="row g-0">
                      <div className="col ">
                        <label htmlFor="business_trade_name">
                          Business Trade Name
                        </label>
                        <input
                          type="text"
                          name="business_trade_name"
                          id="business_trade_name"
                          className={`form-control`}
                          placeholder="Enter Business Trade Name"
                          value={formik?.values?.business_trade_name}
                          onChange={formik.handleChange}
                          maxLength={200}
                        />
                      </div>
                    </div>
                    <div className="row g-0">
                      <div className="col ">
                        <label htmlFor="registered_on">GST Registered On</label>
                        <div
                          id="registered_on_div"
                          className="date-selector-wrapper"
                        >
                          <CustomDatepicker
                            date={
                              formik.values.registered_on
                                ? new Date(formik.values.registered_on)
                                : null
                            }
                            handleDate={handleDate}
                            type="registered_on"
                            id="registered_on"
                            error={
                              formik.errors.registered_on &&
                              formik.touched.registered_on
                                ? formik.errors.registered_on
                                : ""
                            }
                            zIndex={0}
                          />
                        </div>
                        {formik.errors.registered_on &&
                          formik.touched.registered_on && (
                            <span className="error mt-1">
                              {formik.errors.registered_on}
                            </span>
                          )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </section>
          </div>
          <section className="form-button-section">
            <div
              className="button-fields form-button-wrapper"
              id="form-btn-gst-profile"
            >
              <button
                className="save-button"
                type="submit"
                disabled={
                  formik.isSubmitting ||
                  !(
                    gstPermission.includes("Create") &&
                    gstPermission.includes("Update") &&
                    gstPermission.includes("Delete") &&
                    gstPermission.includes("Read")
                  )
                }
              >
                Save
              </button>
              <button
                className="text-decoration-none cancel-button close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                type="button"
                onClick={() => formik.resetForm()}
              >
                Cancel
              </button>
            </div>
          </section>
        </form>
      </GstSettingsContainer>
    </>
  );
};

export default GstSettings;
