import { useAppDispatch } from "../../../../app/hooks";
import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import StopResumeSubscriptionModal from "../../../common/components/StopResumeSubscriptionModal";
import { toast } from "react-toastify";
import { cancelSubscriptionAction, changeSubscriptionStatus } from "../subscriptionSlice";
import { useFormik } from "formik";
import { validateStopForm } from "./ValidationSubscriptionAction";
import { useState } from "react";
import { ObjectType } from "../../../../types";

type Props = {
  is_edit_scheduled: boolean;
  subscriptionId: number;
  planName: string;
  triggerAction: string;
  next_renewal?: string;
  refreshPlan: () => void;
  organizationId: number;
  chargeOption: string;
  editStop: boolean;
  scheduledSubscriptionData?: any;
  subscriptionSchedulerDetails: () => void;
  listRef?: any;
  nextNenewal?: string;
  editStopHandler?: boolean;
  cancelScheduledDate?: string
  status: string
};
const SubscriptionStopResume = (props: Props) => {
  const dispatch = useAppDispatch();
  const [stopFormErrors, setStopFormErrors] = useState<ObjectType>({})
  const modalCancelHandler = () => {
    $(".close-btn").click();
    setStopFormErrors({})
  };

  function getCurrentDate() {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; 
    const year = currentDate.getFullYear();
    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');
    return year + '-' + formattedMonth + '-' + formattedDay;
  }

  function formatDate(date: any) {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const paddedMonth = month < 10 ? '0' + month : month;
    const paddedDay = day < 10 ? '0' + day : day;
    return `${year}-${paddedMonth}-${paddedDay}`;
  }

  const formik = useFormik({
    initialValues: {
      pause_type: "immediately",
      pause_date: getCurrentDate(),
      unbilled_charge: "RETAIN_AS_UNBILLED_CHARGES",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      let errors = validateStopForm(
        values,
        )
      if (Object.keys(errors).length) {
        setStopFormErrors(errors)
      } else {  
        setStopFormErrors({})
      let pauseDate = values.pause_date;
      if(typeof values.pause_date === "object") {
        pauseDate = formatDate(values.pause_date);
      }
      
      if(props.editStopHandler) {
        editCancelScheduleHandler(props.subscriptionId, values.pause_type, pauseDate);
      } else {
        if (props.triggerAction === "Stop") {
          stopHandler(props.subscriptionId, values.pause_type, pauseDate, values.unbilled_charge);
        }
        else {
          resumeHandler(props.subscriptionId, values.pause_type, pauseDate);
        }
      }
    }
    },
  });

  /**
   * Handler to resume the subscription
   */
  const resumeHandler = async (subscriptionId: number, change_status_option: string, specific_date: string) => {
    $("#delete-button").addClass("customer-form-section-disable");
    const responseAction = await dispatch(
      changeSubscriptionStatus({
        subscriptionId: subscriptionId,
        orgId: props.organizationId,
        status: "ACTIVE",
        change_status_option: change_status_option.toUpperCase(),
        specific_date: specific_date,
        scheduler_id: props.scheduledSubscriptionData && props.scheduledSubscriptionData.status_change_scheduler?.[0]?.id,
      })
    );
    $("#delete-button").removeClass("customer-form-section-disable");
    if (responseAction) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        props.refreshPlan();
        $(".close-btn").click();
        toast.success("Resumed Subscription successfully!", {
          toastId: "resume-plan-success",
          closeButton: false,
          position: "top-center",
        });
        props.subscriptionSchedulerDetails();
      } else if ("error" in response) {
        $(".close-btn").click();
      }
    }
  };

  /**
   *  Handler to stop the subscription
   */
  const stopHandler = async (subscriptionId: number, change_status_option: string, specific_date: string, unbilled_charge: string) => {
    $("#delete-button").addClass("customer-form-section-disable");
    const responseAction = await dispatch(
      changeSubscriptionStatus({
        subscriptionId: subscriptionId,
        orgId: props.organizationId,
        status: "STOPPED",
        change_status_option: change_status_option.toUpperCase(),
        specific_date: specific_date,
        unbilled_charge: unbilled_charge,
        scheduler_id: props.scheduledSubscriptionData && props.scheduledSubscriptionData.status_change_scheduler?.[0]?.id,
      })
    );
    $("#delete-button").removeClass("customer-form-section-disable");
    if (responseAction) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        props.refreshPlan();
        $(".close-btn").click();
        toast.success("Stopped Subscription successfully!", {
          toastId: "stop-plan-success",
          closeButton: false,
          position: "top-center",
        });
        props.subscriptionSchedulerDetails();
      } else if ("error" in response) {
        $(".close-btn").click();
      }
    }
  };

  const editCancelScheduleHandler = async (subscriptionId: number, change_status_option: string, specific_date: string) => {
    $("#delete-button").addClass("customer-form-section-disable");
    let change_status_option_modified = '';
    if(change_status_option === "immediately") {
      change_status_option_modified = "IMMEDIATELY";
    } else if(change_status_option === "end_of_current_term") {
      change_status_option_modified = "ON_NEXT_RENEWAL";
    } else {
      change_status_option_modified = "SPECIFIC_DATE"
    }

    const responseAction = await dispatch(
      cancelSubscriptionAction({
        orgId: props.organizationId,
        cancelData: {
          cancel_status_option: change_status_option_modified,
          cancel_date: specific_date,
          subscription_id: subscriptionId,
          scheduler_id: props.scheduledSubscriptionData && props.scheduledSubscriptionData.status_change_scheduler?.[0]?.id,
        }
      })
    );
    $("#delete-button").removeClass("customer-form-section-disable");
    if (responseAction) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        props.refreshPlan();
        $(".close-btn").click();
        toast.success("Cancel scheduler updated successfully!", {
          toastId: "stop-plan-success",
          closeButton: false,
          position: "top-center",
        });
        props.subscriptionSchedulerDetails();
      } else if ("error" in response) {
        $(".close-btn").click();
      }
    }
  } 

  const getNextRenewal = () => {
    if(props.listRef) {
      if(props.listRef?.current.subscriptionsListData) {
        if(props.subscriptionId) {
          return props.listRef.current.subscriptionsListData.filter(
            (subscription: any) => subscription.id === props.subscriptionId
          )[0]?.next_renewal
        }
      }
    }
    return props.nextNenewal;
  }
  

  return (
    <StopResumeSubscriptionModal
      heading={`${props.editStopHandler ? "Cancel" : props.triggerAction} Subscription!`}
      subHeading={`Are you sure you want to ${props.triggerAction.toLowerCase()} the subscription`}
      transactionName={joinTransactionNumber(props.planName)}
      next_renewal={getNextRenewal()}
      modalCancelHandler={modalCancelHandler}
      triggerAction={props.triggerAction}
      formik={formik}
      chargeOption={props.chargeOption}
      is_edit_scheduled={props.is_edit_scheduled?props.is_edit_scheduled:props.listRef?.current?.subscriptionsListData?.filter((subscription: any) => subscription.id === props.subscriptionId)[0]?.is_edit_scheduled}
      editStop={props.editStop}
      stopFormErrors={stopFormErrors}
      cancelScheduledDate={props.cancelScheduledDate}
      status={props.status}
    />
  );
};
export default SubscriptionStopResume;
