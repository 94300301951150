import { FormikProps } from "formik";
import { useParams } from "react-router-dom";
import { getFormatedDate } from "../../../../../helpers/helper";
import { SuccessToaster } from "../../../../../helpers/toastHelper";
import { CurrencyList } from "../../../../../types";
import {
  createExchangeRateList,
  getCustomConversions,
  getLatestCurrencyRate,
  setExchangeApiSatus,
} from "../../../../common/commonSlice";
import useCommonData from "../../../../hooks/useCommon";

type Props = {
  formik: FormikProps<any>;
};

export const usePm = (props: Props) => {
  const { dispatch, navigate, currentUserInfo, orgCurrencyList } =
    useCommonData();
  const { editId, vendorId } = useParams();

  const setBankExchangeData = (values: CurrencyList) => {
    props.formik.setValues((formikValues: any) => {
      return {
        ...formikValues,
        bank_conversion_id: values.id,
        bank_conversion_rate: values.rate,
        bank_conversion_timestamp: values.timestamp,
      };
    });
  };

  const setUserExchangeData = (values: CurrencyList) => {
    let bankConversionData =
      currentUserInfo.organization_currency ===
      props.formik.values.bank_currency_id
        ? {
            bank_conversion_id: values.id,
            bank_conversion_rate: values.rate,
            bank_conversion_timestamp: values.timestamp,
            by_user: true,
          }
        : {};

    props.formik.setValues((prevValue: any) => {
      return {
        ...prevValue,
        conversion_id: values.id,
        conversion_rate: values.rate,
        conversion_timestamp: values.timestamp,
        is_date_changed: false,
        ...bankConversionData,
      };
    });
  };

  /**
   * Fetch convesion details by conversion id
   */
  const fetchCustomConversions = async (id: number, conversionType: string) => {
    if (id) {
      dispatch(setExchangeApiSatus(true));
      const customConversionResponse = await dispatch(
        getCustomConversions({
          conversionId: id,
        })
      );
      const response = customConversionResponse.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (conversionType === "user") {
          setUserExchangeData({ ...response.latest_conversion });
        } else {
          setBankExchangeData({ ...response.latest_conversion });
        }
        dispatch(setExchangeApiSatus(false));
      }
    }
  };

  const paymentDateChangeHandler = async (dateFormated: string) => {
    props.formik.setFieldValue("payment_date", dateFormated);
    // updating user to org conversion rate
    dispatch(setExchangeApiSatus(true));
    if (
      props.formik.values.currency_id
      // &&
      // props.formik.values.currency_id !== currentUserInfo.organization_currency
    ) {
      const responseAction = await dispatch(
        getLatestCurrencyRate({
          base_currency: Number(props.formik.values.currency_id),
          to_currency: Number(currentUserInfo.organization_currency),
          date:
            new Date(dateFormated) > new Date()
              ? getFormatedDate()
              : dateFormated,
        })
      );
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setUserExchangeData(response.latest_conversion);
      }
    }

    if (
      props.formik.values.currency_id &&
      props.formik.values.bank_currency_id &&
      props.formik.values.currency_id !== props.formik.values.bank_currency_id
    ) {
      const responseAction = await dispatch(
        getLatestCurrencyRate({
          base_currency: Number(props.formik.values.currency_id),
          to_currency: Number(props.formik.values.bank_currency_id),
          date:
            new Date(dateFormated) > new Date()
              ? getFormatedDate()
              : dateFormated,
        })
      );
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setBankExchangeData(response.latest_conversion);
      }
    }
    dispatch(setExchangeApiSatus(false));
  };

  const userExchangeRateSubmitHandler = async (data: any) => {
    dispatch(setExchangeApiSatus(true));
    const createResponseAction = await dispatch(
      createExchangeRateList({
        values: {
          ...data,
          enable_rounding:
            Number(currentUserInfo.organization_currency) ===
              Number(props.formik.values.bank_currency_id) &&
            String(data.rate).includes(".") &&
            String(data.rate).split(".")[1].length > 6
              ? false
              : true,
        },
      })
    );
    const createResponse = createResponseAction.payload;
    if (Object.keys(createResponse).length && !("error" in createResponse)) {
      SuccessToaster(
        "Exchange rate updated successfully!",
        "rate-create-success"
      );
      fetchCustomConversions(createResponse.id, "user");
    }
  };

  const bankExchangeRateSubmitHandler = async (data: any) => {
    dispatch(setExchangeApiSatus(true));
    const createResponseAction = await dispatch(
      createExchangeRateList({ values: data })
    );
    const createResponse = createResponseAction.payload;
    if (Object.keys(createResponse).length && !("error" in createResponse)) {
      SuccessToaster(
        "Exchange rate updated successfully!",
        "rate-create-success"
      );
      fetchCustomConversions(createResponse.id, "bank");
    }
  };

  const vendorCurrencyChangeHandler = async (currencyId: number) => {
    const responseAction = await dispatch(
      getLatestCurrencyRate({
        base_currency: Number(currencyId),
        to_currency: Number(currentUserInfo.organization_currency),
        date:
          new Date(props.formik.values.payment_date) > new Date()
            ? getFormatedDate()
            : props.formik.values.payment_date,
      })
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setUserExchangeData(response.latest_conversion);
    }
    if (
      props.formik.values.bank_currency_id &&
      currencyId !== props.formik.values.bank_currency_id
    ) {
      const responseAction = await dispatch(
        getLatestCurrencyRate({
          base_currency: Number(currencyId),
          to_currency: Number(props.formik.values.bank_currency_id),
          date:
            new Date(props.formik.values.payment_date) > new Date()
              ? getFormatedDate()
              : props.formik.values.payment_date,
        })
      );
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setBankExchangeData(response.latest_conversion);
      }
    }
  };
  return {
    setBankExchangeData,
    setUserExchangeData,
    paymentDateChangeHandler,
    userExchangeRateSubmitHandler,
    bankExchangeRateSubmitHandler,
    vendorCurrencyChangeHandler,
    fetchCustomConversions,
  };
};
