import { ErrorToaster } from "../../../../../../helpers/toastHelper";
import { InvoiceDetailValues } from "../../../../../../types";
import { AlertModal } from "../../../../../common/components/alertModal/alertModal";
import { ModalContext } from "../../../../../common/components/alertModal/alertModalContext";
import { PlusIcon } from "../../../../../common/components/customSvgIcons/plusIcon";
import { colors } from "../../../../../constants/colors";
import { message } from "../../../../../constants/messages";
import { CreateCreditNoteAlert } from "./createCreditNoteAlert";

export const CreateCreditNoteAlertModal = (props: {
  creditNoteData: InvoiceDetailValues;
  isCreationPossible: boolean;
  isVoid?: boolean;
}) => {
  const handleDisabledAction = () => {
    ErrorToaster(message().invoice.createCNVoidInvoice);
  };

  return (
    <AlertModal
      modalTitle={
        props.isCreationPossible
          ? "Create credit note!"
          : "Cannot create credit note!"
      }
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <CreateCreditNoteAlert
                handleClose={onCloseModal}
                creditNoteData={props.creditNoteData}
                isCreationPossible={props.isCreationPossible}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={
        <span>
          <PlusIcon color={colors.charcoalGrey} />
          Create Credit Note
        </span>
      }
      buttonClass="dropdown-item no-appearance w-100"
      wrapperClass="create-credit-note-invoice-modal"
      isButtonDisabled={props.isVoid ? true : false}
      disabledActionFunction={handleDisabledAction}
    />
  );
};
