import { useParams, useSearchParams } from "react-router-dom";
import { ObjectType } from "../../../../../types";
import Header from "../../../../common/components/detailsPage/Header";
import useCommonData from "../../../../hooks/useCommon";
import Edit from "../../../../common/components/kebabMenu/Edit";
import Delete from "../../../../common/components/Delete";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import Void from "../../../../common/components/Void";
import StopResume from "../../../../common/components/kebabMenu/StopResume";
import OrgTag from "../../../../common/components/OrgTag";
import PlanDelete from "../PlanDelete";
import PlanStopResume from "../PlanStopResume";
import PlanVoid from "../PlanVoid";
import { DeleteInitialData, PlanDetailData } from "../../planType";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";

type Props = {
  planDetailData: PlanDetailData;
  deleteAction: DeleteInitialData;
  onClickDelete: (
    actionType: string,
    id: number,
    planName: string,
    planExistsSubscription: boolean
  ) => void;
  refreshPlan: () => void;
};

const PlanViewHeader = (props: Props) => {
  const { currentUserInfo, navigate } = useCommonData();
  const { planRolePermission } = usePermissionHandler();
  const backFromPlanViewHandler = () => {
    navigate("/plan");
  };
  return (
    <>
      <Header
        onBackButtonClick={backFromPlanViewHandler}
        data={{
          num: props.planDetailData.external_name,
          status:
            props.planDetailData.plan_status.charAt(0).toUpperCase() +
            props.planDetailData.plan_status.slice(1).toLowerCase(),
        }}
        dataId=""
      />
      <div className="card-body">
        <div className="invoice-actions-wrapper detail-pg-actions-wrapper d-flex justify-content-between">
          <div className="left-group plan-left-group">
            <div className="plan-org-tag">
              <OrgTag organization={props.planDetailData.organization_name} />
            </div>
            {props.planDetailData.description ? (
              <>
                <div className="biller-label ps-2 plan-description-label">
                  Description
                </div>
                <span className="plan-description">
                  {props.planDetailData.description || ""}
                </span>
              </>
            ) : null}
          </div>
          {!planRolePermission.includes("Update") ||
          props.planDetailData.plan_status.toLowerCase() === "cancelled" ||
          props.planDetailData.organization_id !==
            currentUserInfo.organization_id ? null : (
            <div className="actions-wrap d-flex flex-no-shrink">
              <Edit
                url={`/plan/edit/${props.planDetailData.id}`}
                isMutable
                name="Plan"
                className=""
                isLocked={false}
                lockDate={""}
                isDisabled={false}
              />
              {planRolePermission.includes("Delete") ? (
                <Delete
                  className="text-decoration-none delete-item"
                  deleteIcon={true}
                  isMutable
                  onClick={() => {
                    props.onClickDelete(
                      "Plan",
                      props.planDetailData.id,
                      props.planDetailData.external_name,
                      props.planDetailData.plan_exists_subscription
                        ? props.planDetailData.plan_exists_subscription
                        : false
                    );
                  }}
                  isLocked={false}
                  lockDate={""}
                  name="Plan"
                  isTransactionExist={
                    props.planDetailData.plan_exists_subscription
                  }
                  planExistsSubscription={
                    props.planDetailData.plan_exists_subscription
                  }
                />
              ) : null}
              <KebabMenu wrapperClass="plan-detail-header detail-top-action-menu">
                <>
                  {/* Commented the below code as it need to be integrate in future*/}
                  {/* <li>
                  <StopResume
                    className={"dropdown-item"}
                    isDisabled={false}
                    isStopped={props.planDetailData.plan_status.toLowerCase() === "stopped"}
                    onStopResumeClick={() => {}}
                  />
                </li> */}
                  {planRolePermission.includes("Cancel") ? (
                    <li>
                      <Void
                        className="dropdown-item delete"
                        deleteIcon={true}
                        isMutable
                        onClick={() => {}}
                        isLocked={false}
                        lockDate={""}
                        name="Plan"
                        status={props.planDetailData.plan_status}
                      />
                    </li>
                  ) : null}
                </>
              </KebabMenu>
            </div>
          )}
        </div>
      </div>
      <PlanDelete
        deletePlanId={props.deleteAction.id}
        deletePlanName={props.deleteAction.planName}
        deleteActionType={props.deleteAction.actionType}
        planExistsSubscription={props.deleteAction.planExistsSubscription}
        refreshPlan={
          props.deleteAction.actionType === "Price"
            ? props.refreshPlan
            : backFromPlanViewHandler
        }
        organizationId={currentUserInfo.organization_id}
      />
      <PlanStopResume
        planId={props.planDetailData.id}
        planName={props.planDetailData.external_name}
        triggerAction={
          props.planDetailData.plan_status.toLowerCase() === "stopped"
            ? "Resume"
            : "Stop"
        }
        refreshPlan={() => props.refreshPlan()}
        organizationId={currentUserInfo.organization_id}
      />
      <PlanVoid
        planId={props.planDetailData.id}
        planName={props.planDetailData.external_name}
        refreshPlan={backFromPlanViewHandler}
        organizationId={currentUserInfo.organization_id}
      />
    </>
  );
};
export default PlanViewHeader;
