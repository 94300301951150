import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { EditIconImg, ErrorImg } from "../../../../assets/images";
import { DateFormatHandler } from "../../../../helpers/helper";
import { EditDropdownIcon } from "../customSvgIcons/editDropdownIcon";
import checkForWord from "../../../utils/wordInString";

export default function Edit(props: {
  url: string;
  isMutable: boolean;
  name: string;
  type?: string;
  onClick?: () => void;
  isLocked: boolean;
  className: string;
  lockDate: string;
  isDisabled?: boolean;
  isFiscalClosed?: boolean;
  isVoid?: boolean;
}) {
  return (
    <Link
      style={{ textDecoration: "none" }}
      to={
        props.isMutable &&
        !props.isVoid &&
        !props.isLocked &&
        !props.isDisabled &&
        !props.isFiscalClosed
          ? props.url
          : ""
      }
      className={props.className}
      onClick={(e) => {
        if (props.isLocked) {
          toast.error(
            `Transactions before ${DateFormatHandler(
              props.lockDate
            )} have been locked. Hence action cannot be performed`,
            {
              icon: ({ theme, type }) => (
                <img src={ErrorImg} alt="error-img" width="16" height="14" />
              ),
              toastId: `${props.name}-edit-failed`,
              closeButton: false,
              className: "toast-error",
              position: "top-center",
            }
          );
        } else if (!props.isMutable) {
          toast.error(
            `Unable to edit ${props.name}, as there are transactions associated with it!`,
            {
              icon: ({ theme, type }) => (
                <img src={ErrorImg} alt="error-img" width="16" height="14" />
              ),
              toastId: `${props.name}-edit-failed`,
              closeButton: false,
              className: "toast-error",
              position: "top-center",
            }
          );
        } else if (props.isVoid) {
          toast.error(`You cannot edit a voided ${props.name.toLowerCase()}`, {
            icon: ({ theme, type }) => (
              <img src={ErrorImg} alt="error-img" width="16" height="14" />
            ),
            toastId: `${props.name}-edit-failed`,
            closeButton: false,
            className: "toast-error",
            position: "top-center",
          });
        } else if (props.isFiscalClosed) {
          toast.error(
            `Editing of previous fiscal year transactions is disabled.`,
            {
              icon: ({ theme, type }) => (
                <img src={ErrorImg} alt="error-img" width="16" height="14" />
              ),
              toastId: `${props.name}-edit-failed`,
              closeButton: false,
              className: "toast-error",
              position: "top-center",
            }
          );
        } else if (props.type === "modal" && props.onClick) {
          props.onClick();
        } else if (props.name === "Tax Rate") {
          e.preventDefault();
        }
      }}
    >
      <span>
        {props.className !== "" ? (
          checkForWord(props.className, "dropdown-item") ? (
            <>
              <EditDropdownIcon />
              Edit
            </>
          ) : (
            <>
              <EditDropdownIcon />
              &nbsp;Edit
            </>
          )
        ) : (
          <>
            <img src={EditIconImg} alt="edit-list-img" />
            <span>Edit</span>
          </>
        )}
      </span>
    </Link>
  );
}
