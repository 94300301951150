import SettingsIcon from "../../../../assets/images/settings-icon-blue-bg.svg";
import TaxForm from "../TaxForm";
import { ModalContext } from "../../../../../common/components/sideModal/sideModalContext";
import { SideModal } from "../../../../../common/components/sideModal/SideModal";
import TaxGroupForm from "./TaxGroupForm";
import { useRef } from "react";
import { TaxRateValues } from "../../../../../../types";

type Props = {
  taxGroupId: number;
  taxIdReset: () => void;
  organizationId: number;
  handleModalClose: () => void;
  isAccountDeleted: boolean;
  className?: string;
  data?: TaxRateValues;
  children: React.ReactNode;
};
export const TaxGroupModal = ({
  taxGroupId,
  taxIdReset,
  organizationId,
  handleModalClose,
  isAccountDeleted,
  className,
  data,
  children,
}: Props) => {
  const taxGroupRef = useRef<any>(null);
  const ButtonComponent = () => {
    return <>{children}</>;
  };
  return (
    <SideModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;

            return (
              <TaxGroupForm
                taxGroupId={taxGroupId}
                // taxIdReset={taxIdReset}
                organizationId={organizationId}
                handleModalClose={() => {
                  handleModalClose();
                  onCloseModal();
                }}
                // isAccountDeleted={isAccountDeleted}
                data={data}
                ref={taxGroupRef}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={<ButtonComponent />}
      modalTitle={taxGroupId > 0 ? "Edit Tax Group" : "Add Tax Group"}
      buttonClass={className ? className : "no-appearance w-100"}
      wrapperClass="tax-edit-side-modal w-100"
      onCloseFunction={() => {
        if (taxGroupRef?.current && taxGroupRef.current.resetForm)
          taxGroupRef?.current?.resetForm();
      }}
    />
  );
};
