import React, { forwardRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import PredefinedDateRangePicker from "../../../../home/reports/componets/PredefinedDateRange";
import { FormSelectField } from "../../formSelectField/FormSelectField";
import { customSelectStyle } from "../../SelectCustomStyle";
import { SaveButton } from "../../button/SaveButton";
import { SecondaryButton } from "../../button/SecondaryButton";
import { ObjectType } from "../../../../../types";
import useCommonData from "../../../../hooks/useCommon";
import InputWithDropdown from "../../inputWithDropdown/InputWithDropdown";
import {
  customerDropdownList,
  customerListSelector,
} from "../../../../home/customerManagement/customerSlice";
import { useAppSelector } from "../../../../../app/hooks";
import {
  vendorDropdownList,
  vendorListSelector,
} from "../../../../home/vendorManagement/vendorSlice";
import { customizeAgingReportValidation } from "./customizeAgingReportValidation";
import {
  createAgingCustomizations,
  editAgingCustomizations,
  getAgingCustomizations,
  reportCustomizationFilterSelector,
  reportTableFilterSelector,
  setReportCustomizationFilters,
  setReportCustomizationFlag,
  setReportTableFilters,
} from "../../../../home/reports/ReportSlice";
import { getFormatedDate } from "../../../../../helpers/helper";
import OrganizationDropDown from "../../../../home/reports/componets/OrganizationDropDown";
import CurrencySelect from "../../CurrencySelect";
import { FormInputField } from "../../formInputField/FormInputField";
import { AgingReportFilters } from "../../../../home/reports/types";
import { SuccessToaster } from "../../../../../helpers/toastHelper";
import {
  currenciesSelector,
  getOrganizationCurrencies,
  setLoaderState,
} from "../../../commonSlice";
import Select from "react-select";
import { Container } from "../../htmlTags/Container";

type Props = {
  handleClose: () => void;
  handleReport: (type: string, arg?: { [key: string]: any }) => void | null;
  reportData: ObjectType;
  type: string;
  module: string;
};
const CustomizeAgingReport = (props: Props, ref: any) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const customerList = useAppSelector(customerListSelector);
  const vendorList = useAppSelector(vendorListSelector);
  const tableFilters = useAppSelector(reportTableFilterSelector);
  const agingReportFilters = useAppSelector(
    reportCustomizationFilterSelector
  ).agingReportFilters;
  const currencyLists = useAppSelector(currenciesSelector);

  const [isSaved, setIsSaved] = useState(false);
  const [backendError, setBackendError] = useState<{ [key: string]: any }>({});
  const [currencyIds, setCurrencyIds] = useState<number[]>([]);
  useEffect(() => {
    getSearchCustomersList();
    getSearchVendorsList();
    fetchCurrency(currentUserInfo.organization_id);
  }, [currentUserInfo.organization_id, tableFilters.organization_id]);

  useEffect(() => {
    if (
      Object.keys(props.reportData).length &&
      props.reportData.id &&
      props.reportData.id > 0
    ) {
      setIsSaved(true);
    }
  }, [props.reportData]);

  const getSearchCustomersList = async () => {
    await dispatch(
      customerDropdownList(
        tableFilters.organization_id
          ? tableFilters.organization_id
          : currentUserInfo.organization_id
      )
    );
  };

  const getSearchVendorsList = async () => {
    await dispatch(
      vendorDropdownList(
        tableFilters.organization_id
          ? tableFilters.organization_id
          : currentUserInfo.organization_id
      )
    );
  };
  const editValues =
    Object.keys(props.reportData).length && props.reportData.id
      ? props.reportData.filters
      : {};

  const initialValues = {
    start_date: editValues.start_date
      ? editValues.start_date
      : tableFilters.start_date
      ? tableFilters.start_date
      : agingReportFilters.start_date,
    end_date: editValues.end_date
      ? editValues.end_date
      : tableFilters.end_date
      ? tableFilters.end_date
      : agingReportFilters.end_date,
    date_range: editValues.date_range
      ? editValues.date_range
      : tableFilters.date_range
      ? tableFilters.date_range
      : agingReportFilters.date_range,
    organization_id: editValues.organization_id
      ? editValues.organization_id
      : tableFilters.organization_id
      ? tableFilters.organization_id
      : agingReportFilters.organization_id,
    currency_id: editValues.currency_id
      ? Number(editValues.currency_id)
      : tableFilters.currency_id
      ? Number(tableFilters.currency_id)
      : Number(agingReportFilters.currency_id),
    aging_by: editValues.aging_by
      ? editValues.aging_by
      : agingReportFilters.aging_by,
    number_of_columns: editValues.number_of_columns
      ? editValues.number_of_columns.toString()
      : agingReportFilters.number_of_columns.toString(),
    interval_range: editValues.interval_range
      ? Number(editValues.interval_range)
      : Number(agingReportFilters.interval_range),
    interval_type: editValues.interval_type
      ? editValues.interval_type
      : agingReportFilters.interval_type,
    group_by: editValues.group_by
      ? editValues.group_by
      : agingReportFilters.group_by,
    show_by: editValues.show_by
      ? editValues.show_by
      : agingReportFilters.show_by,
    customer_id: editValues.customer_id
      ? Number(editValues.customer_id)
      : agingReportFilters.customer_id
      ? Number(agingReportFilters.customer_id)
      : 0,
    vendor_id: editValues.vendor_id
      ? Number(editValues.vendor_id)
      : agingReportFilters.vendor_id
      ? Number(agingReportFilters.vendor_id)
      : 0,
    name: editValues
      ? (props.reportData.name as string)
      : agingReportFilters.name,
    id: editValues ? props.reportData.id : agingReportFilters.id,
  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: customizeAgingReportValidation(isSaved),
    onSubmit: async (values) => {
      let backendErrorExist = false;
      if (isSaved) {
        backendErrorExist = await saveCustomization(values);
      }
      if ((isSaved && !backendErrorExist) || !isSaved) {
        dispatch(
          setReportTableFilters({
            ...tableFilters,
            start_date: values.start_date,
            end_date: values.end_date,
            date_range: values.date_range,
          })
        );
        dispatch(
          setReportCustomizationFilters({
            agingReportFilters: { ...values, name: "" },
          })
        );
        dispatch(setReportCustomizationFlag(true));
        props.handleReport("submit", { ...values });
        props.handleClose();
      }
    },
  });
  const saveCustomization = async (values: AgingReportFilters) => {
    dispatch(setLoaderState(true));
    if (values.name) {
      let { name: filterName, ...filterData } = values;
      let responseAction;
      if (Object.keys(props.reportData).length && props.reportData.id) {
        responseAction = await dispatch(
          editAgingCustomizations({
            orgId: currentUserInfo.organization_id,
            customizationId: props.reportData.id,
            customization: {
              name: filterName,
              filters: filterData,
            },
          })
        );
      } else {
        responseAction = await dispatch(
          createAgingCustomizations({
            orgId: currentUserInfo.organization_id,
            customization: {
              name: filterName,
              filters: filterData,
            },
          })
        );
      }
      const response = responseAction.payload;
      if (!("error" in response)) {
        if (Object.keys(props.reportData).length && props.reportData.id) {
          SuccessToaster(
            "Customization updated successfully",
            "customization-update-success"
          );
        } else {
          SuccessToaster(
            "Customization saved successfully",
            "customization-save-success"
          );
        }
        dispatch(setLoaderState(false));
        dispatch(
          getAgingCustomizations({ orgId: currentUserInfo.organization_id })
        );
        return false;
      } else {
        let message = "";
        if (response.error.response.detail[0].msg === "Duplicate filters") {
          message =
            "Cannot save customization: Duplicate filters already exist. Please review and adjust.";
        } else if (
          response.error.response.detail[0].msg ==
          "Duplicate customization name"
        ) {
          message =
            "Name already in use. Please enter a unique name for your customization.";
        }
        setBackendError({
          ...backendError,
          name: message,
        });
        dispatch(setLoaderState(false));
        return true;
      }
    }
    return false;
  };
  const customerName = customerList
    .filter((customer) => customer.id === Number(formik.values.customer_id))
    .map((customer) => customer.customer_display_name)[0];

  const vendorName = vendorList
    .filter((vendor) => vendor.id === Number(formik.values.vendor_id))
    .map((vendor) => vendor.vendor_display_name)[0];

  const options = {
    aging_by:
      props.type === "debtor"
        ? [
            {
              label: "Invoice Due Date",
              value: "Invoice Due Date",
            },
            {
              label: "Invoice Date",
              value: "Invoice Date",
            },
          ]
        : [
            {
              label: "Bill Due Date",
              value: "Bill Due Date",
            },
            {
              label: "Bill Date",
              value: "Bill Date",
            },
          ],
    number_of_columns: [
      {
        label: "2",
        value: "2",
      },
      {
        label: "3",
        value: "3",
      },
      {
        label: "4",
        value: "4",
      },
      {
        label: "5",
        value: "5",
      },
      {
        label: "6",
        value: "6",
      },
      {
        label: "7",
        value: "7",
      },
      {
        label: "8",
        value: "8",
      },
      {
        label: "9",
        value: "9",
      },
      {
        label: "10",
        value: "10",
      },
      {
        label: "11",
        value: "11",
      },
      {
        label: "12",
        value: "12",
      },
    ],
    interval_type: [
      { label: "Days", value: "Days" },
      { label: "Weeks", value: "Weeks" },
    ],
    consumer_id:
      props.type === "debtor"
        ? customerList.map((customer, index) => ({
            label: customer.customer_display_name,
            value: customer.id,
          }))
        : vendorList.map((vendor, index) => ({
            label: vendor.vendor_display_name,
            value: vendor.id,
          })),
    group_by: [
      props.type === "debtor"
        ? {
            label: "Customer",
            value: "Customer",
          }
        : { label: "Vendor", value: "Vendor" },
      {
        label: "Currency",
        value: "Currency",
      },
      props.module === "aging_details"
        ? {
            label: "None",
            value: "None",
          }
        : { label: "", value: "" },
    ],
    show_by:
      props.type === "debtor"
        ? [
            {
              label: "Outstanding Invoice Amount",
              value: "Outstanding Invoice Amount",
            },
            {
              label: "Invoice Count",
              value: "Invoice Count",
            },
          ]
        : [
            {
              label: "Outstanding Bill Amount",
              value: "Outstanding Bill Amount",
            },
            {
              label: "Bill Count",
              value: "Bill Count",
            },
          ],
  };
  const handleDateRange = (start: any, end: any, range: string) => {
    let dateRange = range.replace(" ", "_").toLowerCase();
    formik.setFieldValue("start_date", start);
    formik.setFieldValue("end_date", end);
    formik.setFieldValue("date_range", dateRange);
  };
  /**
   * Fetch organization currencies
   */
  const fetchCurrency = async (id: number, currencyId?: number) => {
    const responseAction = await dispatch(
      getOrganizationCurrencies({
        selectedOrgId: id,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setCurrencyIds(response);
      }
    }
  };

  /*
   * Get the currency name in the selection dropdown
   */
  const getName = (listOption: any[], value: any) => {
    return listOption
      .filter((listData) => listData.value === value)
      .map((listData) => ({
        label: listData.label,
        value: listData.value,
      }));
  };

  const currencyListOptions = currencyLists
    .filter((currency) => currencyIds.includes(currency.id))
    .map((currency, index) => ({
      label: currency.code,
      value: currency.id,
    }));

  return (
    <form onSubmit={formik.handleSubmit}>
      <Container className="aging-report-wrapper aging-report">
        <label className="fw-bold">As of</label>
        <PredefinedDateRangePicker
          isSingleDatePicker={true}
          isAdvanced={true}
          defaultValue="today"
          reportType="AR"
          resetType={""}
          handleDateRange={handleDateRange}
          filterdata={{
            startDate: formik.values.start_date,
            endDate: formik.values.end_date,
            dateRange: formik.values.date_range,
          }}
        />
        <Container className="row">
          <Container className="select-field-wrap col">
            <Container className="relative-wrapper">
              <label htmlFor="organization_id" className="fw-bold">
                Organization
              </label>
              <OrganizationDropDown
                fetchDependencies={fetchCurrency}
                selectedOrg={formik.values.organization_id}
              />
            </Container>
          </Container>
          <Container className="select-field-wrap col">
            <Container className="relative-wrapper">
              {/* <CurrencySelect
              label="Currency"
              currencyId={formik.values.currency_id}
              onChange={(e: any) => formik.setFieldValue(e.name, e.value)}
              isDisabled={false}
              error={formik.errors.currency_id ? formik.errors.currency_id : ""}
            /> */}
              <label htmlFor="c_currency" className="required">
                Currency
              </label>
              <Select
                name="currency_id"
                className={`form-select custom-select currency-select ${
                  formik.errors.currency_id ? "error" : ""
                }`}
                value={getName(currencyListOptions, formik.values.currency_id)}
                options={currencyListOptions}
                placeholder="Choose currency"
                isSearchable={false}
                onChange={(e: any) =>
                  formik.setFieldValue("currency_id", e.value)
                }
                styles={customSelectStyle}
              />
              <span className="error">{formik.errors.currency_id}</span>
            </Container>
          </Container>
        </Container>
        <Container className="row">
          <Container className="col">
            <FormSelectField
              name="aging_by"
              id="aging_by"
              className="form-select customize-report-select"
              isDisabled={false}
              isOnlyInRow={true}
              styles={customSelectStyle}
              placeholder="Choose"
              isRequired={false}
              isSearchable={true}
              error=""
              label="Aging By"
              onChange={(e: any) => formik.setFieldValue("aging_by", e.label)}
              options={options.aging_by}
              value={{
                label: formik.values.aging_by,
                value: formik.values.aging_by,
              }}
            />
          </Container>
          <Container className="col">
            <FormSelectField
              name="number_of_columns"
              id="number_of_columns"
              className="form-select customize-report-select"
              isDisabled={false}
              isOnlyInRow={true}
              styles={customSelectStyle}
              placeholder="Choose"
              isRequired={false}
              isSearchable={false}
              error=""
              label="Aging Intervals"
              onChange={(e: any) =>
                formik.setFieldValue("number_of_columns", e.label)
              }
              options={options.number_of_columns}
              value={{
                label: formik.values.number_of_columns,
                value: formik.values.number_of_columns,
              }}
            />
          </Container>
        </Container>

        <Container className="row ">
          <Container className="select-field-wrap w-100 float-left col">
            <InputWithDropdown
              inputType="text"
              placeholder="Enter the Interval"
              inputId="interval_range"
              label="Intervals of"
              data={options.interval_type}
              value={formik.values.interval_range as number}
              handleChange={(e) => {
                /^[0-9]*$/.test(e.target.value) && formik.handleChange(e);
              }}
              handleDropDownChange={(e: any) =>
                formik.setFieldValue("interval_type", e.label)
              }
              dropDownValue={{
                label: formik.values.interval_type,
                value: formik.values.interval_type,
              }}
              error={formik.errors.interval_range}
            />
          </Container>

          {props.module === "aging_details" && (
            <Container className="col">
              <FormSelectField
                name="consumer_id"
                id="consumer_id"
                className="form-select customize-report-select"
                isDisabled={false}
                isOnlyInRow={true}
                styles={customSelectStyle}
                placeholder="Choose"
                isRequired={false}
                isSearchable={true}
                error=""
                label={
                  props.type === "debtor" ? "Customer Name" : "Vendor Name"
                }
                onChange={(e: any) =>
                  props.type === "debtor"
                    ? formik.setFieldValue("customer_id", e.value)
                    : formik.setFieldValue("vendor_id", e.value)
                }
                options={options.consumer_id}
                value={
                  props.type === "debtor"
                    ? {
                        label: customerName,
                        value: agingReportFilters.customer_id
                          ? agingReportFilters.customer_id
                          : "",
                      }
                    : {
                        label: vendorName,
                        value: formik.values.vendor_id,
                      }
                }
                handleClearSelection={() =>
                  props.type === "debtor"
                    ? formik.setFieldValue("customer_id", null)
                    : formik.setFieldValue("vendor_id", null)
                }
                isClearable={true}
              />{" "}
            </Container>
          )}

          {props.module !== "aging_details" && (
            <Container className="col">
              <FormSelectField
                name="group_by"
                id="group_by"
                className="form-select customize-report-select"
                isDisabled={false}
                isOnlyInRow={true}
                styles={customSelectStyle}
                placeholder="Choose"
                isRequired={false}
                isSearchable={true}
                error=""
                label="Group By"
                onChange={(e: any) => formik.setFieldValue("group_by", e.label)}
                options={options.group_by.filter((item) => item.label !== "")}
                value={{
                  label: formik.values.group_by,
                  value: formik.values.group_by,
                }}
              />
            </Container>
          )}
        </Container>
        <Container className="row">
          {props.module === "aging_details" && (
            <Container className="col mw-325">
              <FormSelectField
                name="group_by"
                id="group_by"
                className="form-select customize-report-select"
                isDisabled={false}
                isOnlyInRow={true}
                styles={customSelectStyle}
                placeholder="Choose"
                isRequired={false}
                isSearchable={true}
                error=""
                label="Group By"
                onChange={(e: any) => formik.setFieldValue("group_by", e.label)}
                options={options.group_by.filter((item) => item.label !== "")}
                value={{
                  label: formik.values.group_by,
                  value: formik.values.group_by,
                }}
              />
            </Container>
          )}
          {props.module === "aging_summary" && (
            <Container className="col mw-325">
              {" "}
              <FormSelectField
                name="show_by"
                id="show_by"
                className="form-select customize-report-select"
                isDisabled={false}
                isOnlyInRow={true}
                styles={customSelectStyle}
                placeholder="Choose"
                isRequired={false}
                isSearchable={true}
                error=""
                label="Show By"
                onChange={(e: any) => formik.setFieldValue("show_by", e.label)}
                options={options.show_by}
                value={{
                  label: formik.values.show_by,
                  value: formik.values.show_by,
                }}
              />
            </Container>
          )}
        </Container>
        <Container className="checkbox-wrap w-100 float-left">
          <input
            type="checkbox"
            name="c_i_f_access"
            id="c_i_f_access"
            className="form-check-input"
            onChange={(e) => setIsSaved(e.target.checked)}
            checked={isSaved}
          />
          <span className="save-filter-label">Save this filter</span>
        </Container>
        {isSaved && (
          <Container className="select-field-wrap">
            <FormInputField
              type="text"
              name="name"
              id="name"
              wrapperClass="mw-325 ps-3"
              className={`w-100 mb-0`}
              onChange={(e) => {
                formik?.setFieldValue("name", e.target.value);
              }}
              placeholder="Enter filter name"
              maxLength={17}
              value={formik.values.name}
              label="Filter Name"
              error={
                formik.errors.name || backendError.name
                  ? formik.errors.name || backendError.name
                  : ""
              }
              isRequired={true}
              errorClass="mb-2"
            />
          </Container>
        )}
        <Container className="customize_report_btn_group">
          <SaveButton
            type="submit"
            buttonContent="Save"
            onClick={() => {}}
            isDisabled={false}
          />
          <SecondaryButton
            type={"button"}
            buttonContent="Cancel"
            onClick={props.handleClose}
            isDisabled={false}
          />
        </Container>
      </Container>
    </form>
  );
};

export default forwardRef(CustomizeAgingReport);
