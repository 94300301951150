import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../../app/hooks";
import { deleteTaxGroup, deleteTaxRate } from "../taxRateSlice";
import DeleteModal from "../../../../common/components/DeleteModal";
type Props = {
  deleteTaxId: number;
  deleteTaxGroup: boolean;
  organizationId: number;
  handleModalClose: () => void;
};
function DeleteTaxRate(props: Props) {
  const dispatch = useAppDispatch();

  /**
   * Function to handle delete tax
   */
  const modalSubmitHandler = async () => {
    $("#delete-button").addClass("customer-form-section-disable");
    const responseAction = props.deleteTaxGroup
      ? await dispatch(
          deleteTaxGroup({
            taxGroupId: props.deleteTaxId,
            orgId: props.organizationId,
          })
        )
      : await dispatch(
        deleteTaxRate({ taxId: props.deleteTaxId, orgId: props.organizationId })
        );
    $("#delete-button").removeClass("customer-form-section-disable");
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        $(".close-btn").click();
        let message = props.deleteTaxGroup
          ? "Tax group deleted successfully!"
          : "The record is deleted!";
        toast.success(message, {
          toastId: "tax-delete-success",
          closeButton: false,
          position: "top-center",
        });
        props.handleModalClose();
      } else if ("error" in response) {
        $(".close-btn").click();
      }
    }
  };

  /**
   * Cancel button handler in delete tax pop up
   */
  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  return (
    <DeleteModal
      heading={`Delete Tax ${props.deleteTaxGroup ? "Group" : ""}!`}
      subHeading={`Are you sure about deleting this tax  ${
        props.deleteTaxGroup ? "group" : ""
      }?`}
      transactionName=""
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={modalSubmitHandler}
    />
  );
}
export default DeleteTaxRate;
