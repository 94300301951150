import { useEffect, useRef, useState } from "react";
import ListHeader from "../../../../common/components/list/ListHeader";
import useCommonData from "../../../../hooks/useCommon";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import SubscriptionTableContainer from "./subscriptionTable/SubscriptionTableContainer";
import useListData from "../../../../hooks/useListData";
import Pagination from "../../../../common/components/table/Pagination";
import { usePaginationDispatch } from "../../../../hooks/usePaginationDispatch";
import SubscriptionDelete from "../SubscriptionDelete";
import SubscriptionStopResume from "../SubscriptionStopResume";
import SubscriptionVoid from "../SubscriptionVoid";
import {
  DeleteInitialData,
  SubscriptionStopResumeAction,
} from "../../subscriptionType";
import { deleteInitialData } from "../../SubscriptionStateInitialization";
import { getSubscriptionTransactionLimitStatus } from "../../subscriptionSlice";
import { Transactionlimit } from "../../../../../types";
import TransactionLimitModal from "../../../../common/components/TransactionLimitModal";
import SubscriptionReactivate from "../SubscriptionReactivation";
import NotificationModal from "../../../../common/components/NotificationModal";

export default function SubscriptionList() {
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const { subscriptionRolePermission } = usePermissionHandler();
  const { page, pageCount, itemsPerPage, totalList } = useListData();
  const { setPage, setItemOffset } = usePaginationDispatch();
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [didVoidAlertModalOpen, setDidVoidAlertModalOpen] = useState(false);
  const [didReactiveAlertModalOpen, setDidReactiveAlertModalOpen] =
    useState(false);
  const filterRef = useRef<any>([]);
  const listRef = useRef<any>([]);
  const [deleteAction, setDeleteAction] =
    useState<DeleteInitialData>(deleteInitialData);
  const [voidAction, setVoidAction] = useState<any>({
    id: -1,
    planName: "",
    status: "",
    nextRenewableDate: "",
    frequency: "",
    unpaidAmount: "",
    stopScheduledDate: "",
    isEditScheduled: false,
    subscription_end_date: null,
    isCurrentCycleInvoicePaid: false,
    unused_credits_days: 0,
  });
  const [statusChangeOnAction, setStatusChangeOnAction] =
    useState<SubscriptionStopResumeAction>({
      id: -1,
      planName: "",
      triggerAction: "",
      charge_option: "",
      is_edit_scheduled: false,
      next_renewal: "",
      cancelScheduledDate: "",
      status: "",
    });
  const [transactionLimit, setTransactionLimit] = useState<Transactionlimit>({
    limit_exceed: false,
    limit_count: "",
  });
  useEffect(() => {
    fetchTransactionLimit();
    setIsFilterApplied(false);
  }, [currentUserInfo?.organization_id]);

  const handleApplyFilter = (value: boolean) => {
    setIsFilterApplied(value);
  };

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
    sessionStorage.setItem("subscriptionActiveTab", "overview");
  }, []);

  /**
   * Fetch transaction limit
   */
  const fetchTransactionLimit = async () => {
    const responseAction = await dispatch(
      getSubscriptionTransactionLimitStatus(currentUserInfo.organization_id)
    );
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setTransactionLimit({ ...transactionLimit, ...response });
    }
  };

  /**
   * Invoke when user click to request another page.
   */
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalList;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  const refreshList = () => {
    //  listRef.current.fetchRecurringInvoiceList();
    filterRef.current.refreshFilter();
    if (listRef.current.fetchRecurringInvoiceList)
      listRef.current.fetchRecurringInvoiceList();
  };
  const deleteListData = (
    actionType: string,
    id: number,
    planName: string,
    invoice_exists: boolean
  ) =>
    setDeleteAction({
      actionType: actionType,
      id: id,
      planName: planName,
      invoice_exists: invoice_exists,
    });

  const voidListData = (
    id: number,
    planName: string,
    status: string,
    next_renewal: string,
    frequency: string,
    unpaidAmount: string,
    stopScheduledDate: string,
    is_edit_scheduled: boolean,
    subscription_end_date: string | null,
    isCurrentCycleInvoicePaid: boolean,
    unused_credits_days: number
  ) => {
    $(".dropdown-ctrl").click();
    setVoidAction({
      id: id,
      planName: planName,
      status: status,
      nextRenewableDate: next_renewal,
      frequency: frequency,
      unpaidAmount: unpaidAmount,
      stopScheduledDate: stopScheduledDate,
      isEditScheduled: is_edit_scheduled,
      subscription_end_date: subscription_end_date,
      isCurrentCycleInvoicePaid: isCurrentCycleInvoicePaid,
      unused_credits_days: unused_credits_days,
    });
    if (status.toLowerCase() === "cancelled") {
      setDidReactiveAlertModalOpen(true);
    } else setDidVoidAlertModalOpen(true);
  };

  const stopResumeListData = (
    id: number,
    planName: string,
    triggerAction: boolean,
    chargeOption: string,
    is_edit_scheduled: boolean,
    next_renewal: string,
    cancelScheduledDate: string,
    status: string
  ) => {
    $(".dropdown-ctrl").click();
    setStatusChangeOnAction({
      id: id,
      planName: planName,
      triggerAction: triggerAction ? "Stop" : "Resume",
      charge_option: chargeOption,
      is_edit_scheduled: is_edit_scheduled,
      next_renewal: next_renewal,
      cancelScheduledDate: cancelScheduledDate,
      status: status,
    });
  };

  return (
    <>
      <div className="plan-list card card-user-management card-customer main-card overflowX-hidden h-100">
        <ListHeader
          title="Subscriptions"
          haveSearchBox={false}
          haveCreateButton={true}
          createButtonLabel="Create Subscription"
          isFiscalYearFilterRequired={false}
          createOnClick={() => {
            transactionLimit.limit_exceed
              ? $("#transactionLimitModal").modal("show")
              : navigate("/subscriptions/create");
          }}
          haveFilter={true}
          filterType="Subscription"
          applyFilter={handleApplyFilter}
          roles={subscriptionRolePermission}
          ref={filterRef}
        />
        <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
          <SubscriptionTableContainer
            transactionLimit={transactionLimit}
            deleteListData={deleteListData}
            voidListData={voidListData}
            stopResumeListData={stopResumeListData}
            ref={listRef}
            isFilterApplied={isFilterApplied}
            initializeFilter={refreshList}
          />
          <div className="card-footer clear-both">
            {totalList > itemsPerPage ? (
              <Pagination
                pageCount={pageCount}
                page={page}
                handlePageClick={handlePageClick}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {!deleteAction.invoice_exists && (
        <SubscriptionDelete
          deleteSubscriptionId={deleteAction.id}
          deleteSubscriptionName={deleteAction.planName}
          deleteActionType={deleteAction.actionType}
          refreshPlan={refreshList}
          organizationId={currentUserInfo.organization_id}
        />
      )}
      <SubscriptionStopResume
        subscriptionId={statusChangeOnAction.id}
        planName={statusChangeOnAction.planName}
        next_renewal={statusChangeOnAction.next_renewal}
        status={statusChangeOnAction.status}
        listRef={listRef}
        triggerAction={
          statusChangeOnAction.triggerAction.toLowerCase() === "stop"
            ? "Resume"
            : "Stop"
        }
        refreshPlan={refreshList}
        organizationId={currentUserInfo.organization_id}
        chargeOption={statusChangeOnAction.charge_option}
        is_edit_scheduled={statusChangeOnAction.is_edit_scheduled}
        editStop={false}
        subscriptionSchedulerDetails={() => {}}
        cancelScheduledDate={statusChangeOnAction.cancelScheduledDate}
      />
      <SubscriptionVoid
        subscriptionId={voidAction.id}
        subscriptionName={voidAction.planName}
        status={voidAction.status}
        refreshPlan={refreshList}
        organizationId={currentUserInfo.organization_id}
        didAlertModalOpen={didVoidAlertModalOpen}
        setDidAlertModalOpen={setDidVoidAlertModalOpen}
        nextRenewableDate={voidAction.nextRenewableDate || ""}
        unpaidAmount={voidAction.unpaidAmount}
        stopScheduledDate={voidAction.stopScheduledDate}
        isEditScheduled={voidAction.isEditScheduled}
        subscriptionEndDate={voidAction.subscription_end_date}
        isCurrentCycleInvoicePaid={voidAction.isCurrentCycleInvoicePaid}
        unused_credits_days={voidAction.unused_credits_days}
      />
      <SubscriptionReactivate
        subscriptionId={voidAction.id}
        subscriptionName={voidAction.planName}
        subscriptionStatus={voidAction.status}
        refreshSubscription={refreshList}
        organizationId={currentUserInfo.organization_id}
        didAlertModalOpen={didReactiveAlertModalOpen}
        setDidAlertModalOpen={setDidReactiveAlertModalOpen}
        subscriptionPeriod={voidAction.frequency}
      />
      <TransactionLimitModal limit={transactionLimit.limit_count} />
    </>
  );
}
