import { ChangeEvent } from "react";

type InputProps = {
  type: string;
  id: string;
  name: string;
  className: string;
  wrapperClass?: string;
  placeholder: string;
  label: string;
  isRequired?: boolean;
  disabled?: boolean;
  value: any;
  maxLength?: number;
  minLength?: number;
  error: any;
  onChange: (e: any) => void;
  onBlur?: (e: any) => void;
  onClick?: (e: any) => void;
  onFocus?: (e: any) => void;
};
export const FormInputField = (props: InputProps) => {
  
  return (
    <div className={`col-12 col-lg-0 ml-0  mw-325 mb-3 ${props.wrapperClass}`} id={props.wrapperClass}>
      <div className="link-label-grouped">
        <label
          className={`fw-bold mb-2  ${props.isRequired ? "required" : ""}`}
          htmlFor={props.id}
        >
          {props.label}
        </label>
      </div>
      <input
        type={props.type}
        name={props.name}
        id={props.id}
        className={props.className}
        placeholder={props.placeholder}
        maxLength={props.maxLength}
        minLength={props.minLength}
        value={props.value}
        disabled={props.disabled ? true : false}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onBlur}
        onClick={props.onClick}
      />
      <span className="error">{props.error}</span>
    </div>
  );
};
