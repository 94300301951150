import CenterModal from "../../../../common/components/centerModal/CenterModal";
import "./cancelChangesModal.css";
import { useAppSelector } from "../../../../../app/hooks";
import { currentUserSelector } from "../../../../common/commonSlice";
import { useAppDispatch } from "../../../../../app/hooks";
import { Card } from "../../../../common/components/htmlTags/Card/Card";
import { CardHeader } from "../../../../common/components/htmlTags/Card/CardHeader";
import CloseButton from "../../../../common/components/button/CloseButton";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import { CardBody } from "../../../../common/components/htmlTags/Card/CardBody";
import { Form } from "../../../../common/components/htmlTags/Form";
import { useFormik } from "formik";
import { useState } from "react";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import { invoicingCycleOption } from "../../SubscriptionStateInitialization";
import {
  FormInputField,
  FormSelectField,
  NoteTextArea,
} from "../../../../common/components";
import InfoTag from "../../../../common/components/InfoTag";
import { coustomCaseWithRemoveUnderscore } from "../../../../../helpers/planHelper";
import * as yup from "yup";

export const CancelVoidScheduledModal = (props: any) => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const dispatch = useAppDispatch();
  const [didAlertModalOpen, setDidAlertModalOpen] = useState(false);

  const validate = yup.object({
    comments: yup.string().required("Please enter any comment"),
    invoicing_cycle_fixed_count: yup
      .number()
      .nullable()
      .when("invoicing_cycle", (invoicing_cycle, schema) => {
        if (invoicing_cycle[0] === "FIXED") {
          return schema.required("Invoicing cycle fixed count is required");
        }
        return schema.notRequired();
      }),
  });

  const formik = useFormik({
    initialValues: {
      invoicing_cycle: "FOREVER",
      comments: "",
      invoicing_cycle_fixed_count: 1,
    },
    enableReinitialize: true,
    validationSchema: validate,
    onSubmit: (values) => {
      modalCancelHandler();
    },
  });

  const modalCancelHandler = () => {
    formik.resetForm();
    setDidAlertModalOpen(false);
  };

  const cancelModalSubmitHandler = async () => {};

  const ConfigureButton = () => {
    return (
      <>
        <span
          className="subscription-scheduler-cancel subscription-scheduler-cancel-P-15"
          onClick={() => setDidAlertModalOpen(true)}
        >
          {" "}
          Remove Scheduled Cancellation
        </span>
      </>
    );
  };
  return (
    <>
      <ConfigureButton />
      <Dialog
        open={didAlertModalOpen}
        className={`subscription-update-popover`}
        onClose={modalCancelHandler}
      >
        <Card wrapperClass="subscription-update">
          <CardHeader wrapperClass={`subscription-update-header-wrapper`}>
            <Grid container>
              <Grid item xs={10} className="p-t-15 header">
                {"Remove Scheduled Cancellation?"}
              </Grid>
              <Grid item xs={2} className="popover-close-button">
                <CloseButton closeModalHandler={modalCancelHandler} />
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <div className="p-b-18 mw-325">
              {/* date and status need to be added on that specific date */}
              <div>
                This subscription will be next invoiced on{" "}
                <strong>{props.overviewSubscriptionData.next_renewal}</strong>{" "}
                and will remain{" "}
                <InfoTag
                  status={
                    props.subscriptionStatus &&
                    coustomCaseWithRemoveUnderscore(props.subscriptionStatus)
                  }
                />
              </div>
            </div>
            <Form
              name="add_invoice_form"
              id="add_invoice_form"
              className=""
              tabIndex={-1}
            >
              <div className="row custom-row">
                <div className={"col-12 col-lg-4 mw-325 "}>
                  <FormSelectField
                    name="invoicing_cycle"
                    id="invoicing_cycle"
                    label="Invoicing Cycle"
                    options={invoicingCycleOption}
                    wrapperClass="mw-325"
                    className="state-select custom-select"
                    value={
                      invoicingCycleOption.filter(
                        (data: any) =>
                          data.value === formik.values.invoicing_cycle
                      )[0] || ""
                    }
                    onChange={(option: any) => {
                      formik.setFieldValue("invoicing_cycle", option.value);
                    }}
                    styles={customSelectStyle}
                    placeholder={"Choose invoicing cycle"}
                    isSearchable={true}
                    isDisabled={false}
                    isRequired={false}
                    isOnlyInRow={false}
                    error={formik.errors.invoicing_cycle}
                  />
                </div>
                {formik.values.invoicing_cycle === "FIXED" ? (
                  <FormInputField
                    type="text"
                    name="invoicing_cycle_fixed_count"
                    id="ref_no"
                    className={"form-control"}
                    wrapperClass="no-of-cycle-wrapper h-89"
                    label="Number of Cycles"
                    placeholder="Enter number of cycles"
                    maxLength={16}
                    value={formik.values.invoicing_cycle_fixed_count}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "invoicing_cycle_fixed_count",
                        e.target.value
                      );
                    }}
                    error={formik.errors.invoicing_cycle_fixed_count}
                  />
                ) : null}
              </div>
              <div className="row custom-row m-t-10">
                <div className={"col-12 col-lg-4 mw-325 "}>
                  <NoteTextArea
                    label="Comments"
                    name="comments"
                    id="comments"
                    className="notes-textarea"
                    placeholder="Add your comments here..."
                    value={formik.values.comments || ""}
                    onChange={(e) => {
                      formik.setFieldValue("comments", e.target.value);
                    }}
                    formErrors={{}}
                    labelClassName="required"
                  />
                </div>
                {formik.errors.comments && (
                  <div className="col-12 col-lg-4 mw-325">
                    <span className="error ">{formik.errors.comments}</span>
                  </div>
                )}
              </div>
              <div className="row mb-0">
                <div className="col">
                  <div
                    className={`form-button-wrapper w-100 'd-flex justify-content-end mt-3`}
                    id="form-btn"
                  >
                    <button
                      onClick={(e: any) => {
                        e.preventDefault();
                        formik.submitForm();
                      }}
                      className="save-button"
                    >
                      {"Update"}
                    </button>
                    <button
                      type="button"
                      onClick={modalCancelHandler}
                      className="text-decoration-none cancel-button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Dialog>
    </>
  );
};
