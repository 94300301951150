import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { FormValues, IForm } from "./type";
import { ObjectType } from "../../types";

const useFormAdapter = ({
  initialValues,
  onSubmit,
  validationSchema,
  context,
}: IForm) => {
  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isSubmitting, isValid },
    reset,
    setError,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema, { context: context }),
    defaultValues: initialValues,
    shouldUseNativeValidation: true,
    reValidateMode: "onBlur",
    context: context,
    mode: "onTouched",
    criteriaMode:"all"
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.name, event.target.value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };
  const values = getValues();

  const initialValuesRef = useRef(initialValues);

  useEffect(() => {
    if (
      JSON.stringify(initialValuesRef.current) !== JSON.stringify(initialValues)
    ) {
      reset(initialValues);
      initialValuesRef.current = initialValues;
    }
  }, [initialValues, reset]);

  return {
    handleSubmit: handleSubmit(onSubmit as SubmitHandler<FormValues>),
    handleChange,
    values,
    errors,
    isSubmitting,
    isValid,
    setError,
    setFieldValue: (field: string, value: any) => {
      setValue(field, value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    setValues: (value: FormValues) => {
      Object.keys(value).forEach((field) =>
        setValue(field, value[field as keyof FormValues], {
          shouldValidate: true,
          shouldDirty: true,
        })
      );
    },
  };
};

export default useFormAdapter;
