import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form } from "../../htmlTags/Form";
import { Container } from "../../htmlTags/Container";
import { message } from "../../../../constants/messages";
import { useAppSelector } from "../../../../../app/hooks";
import { CancelBtnDark } from "../../../../../assets/images";
import { ErrorToaster } from "../../../../../helpers/toastHelper";
import { customSelectStyle } from "../../SelectCustomStyle";
import { DockedButtonGroup } from "../../button/DockedButtonGroup";
import { getRoundOffAmount } from "../../../../../helpers/roundOffHelper";
import { splitNumberByDecimalPoint } from "../../../../../helpers/helper";
import { orgCurrencyListSelector, setLoaderState } from "../../../commonSlice";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import { ReportTagDropDown } from "../../reportingTagDropDown/reportingTagDropDown";
import {
  getSplitAmount,
  reportingTagsListSelector,
} from "../../../../home/reportingTags/reportingTagAPIFiles/reportingTagSlice";
import {
  CheckedBox,
  ObjectType,
  ReportTagAllocation,
  ReportTagFormValues,
  ReportingTagItem,
} from "../../../../../types";
import {
  INITIAL_SPLIT_TYPE_OPTION,
  SPLIT_TYPES,
  SPLIT_TYPES_OPTIONS,
} from "../../../../constants/constants";
import Select from "react-select";
import useCommonData from "../../../../hooks/useCommon";
import "./SelectReportTagForm.css";

type TagOptions = {
  [key: string]: ReportingTagItem;
};
type Props = {
  onClose: () => void;
  isEdit?: boolean;
  editId?: number;
  updateTagsSelection: (
    accountId: number,
    splitType: string,
    tagsList: any,
    index?: number | null,
    entityId?: number | null | undefined
  ) => void;
  selectedTags?: ReportTagFormValues;
  accountId: number;
  accountName: string;
  transactionType: string;
  transactionAmount: string | number;
  currency: {
    currency_id: number | null;
    currency_code: string;
  };
  optionsList: ObjectType[];
  splitType: string;
  index?: number | null;
  entityId?: number | null | undefined;
  specialTagOptions?: TagOptions;
  isSpecialTagOptionsPresent?: boolean;
  isMutable?: boolean;
  module?: string;
  maxTags?: number | null;
  maxOptions?: number | null;
  isVoid?: boolean;
};
const SelectReportingTagBody = (props: Props) => {
  const [checked, setChecked] = useState<CheckedBox[]>([]);
  const [splitType, setSplitType] = useState<ObjectType>(
    INITIAL_SPLIT_TYPE_OPTION
  );
  const [tagListingFormValues, setTagListingFormValues] = useState<
    ReportTagAllocation[]
  >([]);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [errors, setErrors] = useState<ObjectType>([{ checked: "" }]);
  const reportingTagsDropdownList = useAppSelector(reportingTagsListSelector);
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);

  const getTagOptionsForInvoices = () => {
    return Object.values(props.specialTagOptions || {});
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Check if the pressed key is a functional key (e.g., Ctrl, Shift, Alt) or a printable character (0-9, .)
    if (event.key.length === 1 && !/^(\d*\.?\d*)$/.test(event.key)) {
      event.preventDefault(); // Prevent the input of special characters
    }
  };
  useEffect(() => {
    let newChecked = [] as CheckedBox[];
    let newTagListingFormValues = [] as ReportTagAllocation[];
    props.optionsList.map((option: ObjectType) => {
      let optionId = option.option_id;
      reportingTagsDropdownList.map((tag: any) => {
        tag.options.map((tagOption: ObjectType) => {
          if (tagOption.id === optionId) {
            newTagListingFormValues.push({
              tag_name: tag.name,
              option_name: tagOption.name,
              option_id: optionId,
              amount: option.amount,
            });
            let newCheckedTagIndex = newChecked.findIndex(
              (singleChecked: CheckedBox) => {
                return singleChecked.tagId === tag.id;
              }
            );
            if (newCheckedTagIndex === -1) {
              newChecked.push({ tagId: tag.id, optionIDs: [optionId] });
            } else {
              newChecked[newCheckedTagIndex].optionIDs.push(optionId);
            }
          }
        });
      });
    });
    setChecked(newChecked);
    setTagListingFormValues(newTagListingFormValues);
    let newRemaining = getRemainingAmount(
      Number(props.transactionAmount),
      newTagListingFormValues
    );
    setRemainingAmount(newRemaining);
    setSplitType({
      label: props.splitType,
      value: props.splitType,
    });
  }, [props.optionsList]);

  const getRemainingAmount = (
    transactionAmount: number,
    tagListing: ReportTagAllocation[]
  ) => {
    let newTotal = 0;
    tagListing.map((taglist) => {
      newTotal += Number(taglist.amount);
    });
    let newRemaining = Number(transactionAmount) - newTotal;
    newRemaining = Math.ceil(newRemaining * 1000000) / 1000000;
    if (newRemaining.toString().includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(newRemaining);
      let decimals = decimalPlaceOfCurrency(
        props.currency.currency_code,
        orgCurrencyList
      );
      if (fractionalPart.length > decimals) {
        newRemaining = getRoundOffAmount(newRemaining, decimals);
      }
    }
    return newRemaining;
  };
  const getSplitList = async (
    checkedValues: CheckedBox[],
    splitTypeValue: string
  ) => {
    let tags_list = [] as { option_id: number; amount: number }[];
    if (checkedValues.length === 0) {
      setErrors({ checked: "Please select a reporting tag" });
      return;
    }
    setErrors({ checked: "" });
    checkedValues.map((tag: CheckedBox) => {
      tag.optionIDs.map((optionID: number) => {
        tags_list.push({
          option_id: optionID,
          amount: 0,
        });
      });
    });
    if (tags_list.length === 0) return;
    dispatch(setLoaderState(true));
    let response = await dispatch(
      getSplitAmount({
        orgId: currentUserInfo.organization_id,
        splitType: splitTypeValue,
        transactionType: "Invoices",
        payload: {
          currency_id: props.currency.currency_id,
          transaction_amount: props.transactionAmount,
          tags_list: tags_list,
        },
      })
    );
    dispatch(setLoaderState(false));
    setTagListingFormValues(response.payload.tag_listing);
    setRemainingAmount(Number(props.transactionAmount));
  };

  const amountChangeHandler = (val: number | string, index: number) => {
    var numberRegex = /^\d*\.?\d*$/;
    let valStr = val.toString();
    if (!numberRegex.test(valStr)) {
      return false;
    }
    let newTagListingFormValues = [
      ...tagListingFormValues,
    ] as ReportTagAllocation[];
    newTagListingFormValues[index].amount = val;
    setTagListingFormValues(newTagListingFormValues);
    let newRemaining = getRemainingAmount(
      Number(props.transactionAmount),
      newTagListingFormValues
    );
    setRemainingAmount(newRemaining);
  };

  const splitTypeChangeHandler = (option: ObjectType) => {
    setSplitType(option);
    getSplitList(checked, option.value);
  };

  const applyTagsList = async () => {
    if (splitType.value === SPLIT_TYPES.specific_amount) {
      let tags_list = tagListingFormValues.map((tag: ReportTagAllocation) => {
        return {
          option_id: tag.option_id,
          amount: Number(tag.amount),
        };
      });
      if (tags_list.length > 0) {
        dispatch(setLoaderState(true));
        let response = await dispatch(
          getSplitAmount({
            orgId: currentUserInfo.organization_id,
            splitType: splitType.value,
            transactionType: "Invoices",
            payload: {
              currency_id: props.currency.currency_id,
              transaction_amount: props.transactionAmount,
              tags_list: tags_list,
            },
          })
        );
        dispatch(setLoaderState(false));
        if (response.payload.amount_match === false) {
          ErrorToaster(message().reportingTag.splitAmountShouldMatch);
          return;
        }
      }
    }
    props.updateTagsSelection(
      props.accountId,
      splitType.value,
      tagListingFormValues,
      props.index ? props.index : null,
      props.entityId
    );
    props.onClose();
  };

  const removeTag = (removingOptionID: number) => {
    let newTagListingFormValues = tagListingFormValues.filter(
      (tag: ReportTagAllocation) => tag.option_id !== removingOptionID
    );
    setTagListingFormValues(newTagListingFormValues);
    let newChecked = checked
      .map((tag: CheckedBox) => {
        tag.optionIDs = tag.optionIDs.filter(
          (optionID: number) => optionID !== removingOptionID
        );
        return tag;
      })
      .filter((tag: CheckedBox) => tag.optionIDs.length > 0);
    setChecked(newChecked);
    if (splitType.value === SPLIT_TYPES.split_equally) {
      getSplitList(newChecked, splitType.value);
    }
  };

  /**
   * Function to round off default input elements after out of focus.
   */
  const handleAmountOnBlur = (amount: number, index: number) => {
    if (amount.toString().includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(amount);
      let decimals = decimalPlaceOfCurrency(
        props.currency.currency_code,
        orgCurrencyList
      );
      if (fractionalPart.length > decimals) {
        const roundedAmount = getRoundOffAmount(amount, decimals);
        let newTagListingFormValues = [
          ...tagListingFormValues,
        ] as ReportTagAllocation[];
        newTagListingFormValues[index].amount = roundedAmount;
        setTagListingFormValues(newTagListingFormValues);
        let newRemaining = getRemainingAmount(
          Number(props.transactionAmount),
          newTagListingFormValues
        );
        setRemainingAmount(newRemaining);
      }
    }
  };

  return (
    <>
      <Container className="reporting-tags-form-container w-100">
        <Form
          id=""
          className="reporting-tags-form select-reporting-tags-form w-100"
          onSubmit={() => {}}
        >
          <h3>
            {props.accountName} (
            <span className="small-text">
              <span className="reporting-tag-transaction-type">
                {props.transactionType}
              </span>{" "}
              {props.currency.currency_code} {props.transactionAmount}
            </span>
            )
          </h3>
          {splitType.value === SPLIT_TYPES.specific_amount && (
            <span className="show-amount-small p-0">
              Amount Remaining for allocation: {remainingAmount}
            </span>
          )}

          <Container className="reporting-tag-wrapper select-reporting-tag-wrapper">
            <Container className="select-tag-wrapper d-flex">
              <Container className="select-tags column">
                <label htmlFor="reporting_tags">Reporting Tags</label>
                <ReportTagDropDown
                  data={
                    props.isSpecialTagOptionsPresent === true
                      ? getTagOptionsForInvoices()
                      : reportingTagsDropdownList
                  }
                  checked={checked}
                  setChecked={setChecked}
                  error={errors.checked}
                  isMutable={props.isMutable}
                  module={props.module}
                  maxOptions={props.maxOptions ? props.maxOptions : null}
                  maxTags={props.maxTags ? props.maxTags : null}
                  disable={props.isVoid}
                />
              </Container>
              <Container className="select-type column">
                <Select
                  name="amt_split_type"
                  id={"amt_split_type"}
                  options={
                    SPLIT_TYPES_OPTIONS?.length > 0
                      ? SPLIT_TYPES_OPTIONS.map((item) => {
                          return {
                            label: item.label,
                            value: item.value,
                          };
                        })
                      : []
                  }
                  value={splitType}
                  className={`amt-split-type form-select custom-select`}
                  onFocus={() => {
                    if (props.isMutable === false) {
                      ErrorToaster(
                        `Unable to edit reporting tags, as there are transactions associated with this ${props.module}!`
                      );
                    }
                  }}
                  onChange={(e: any) => {
                    if (props.isMutable === false) {
                      return;
                    } else {
                      splitTypeChangeHandler(e);
                    }
                  }}
                  openMenuOnClick={props.isMutable === false ? false : true}
                  styles={customSelectStyle}
                  placeholder={"Split amount type"}
                  isSearchable={false}
                  isDisabled={props.isVoid}
                  classNamePrefix="custom-select"
                />
              </Container>
              <Container className="button-conatiner column">
                <button
                  type="button"
                  className="save-button save-only-button mx-0"
                  onClick={() => {
                    if (props.isMutable === false) {
                      ErrorToaster(
                        `Unable to edit reporting tags, as there are transactions associated with this ${props.module}!`
                      );
                    } else {
                      getSplitList(checked, splitType.value);
                    }
                  }}
                  disabled={props.isVoid}
                >
                  Apply
                </button>
              </Container>
            </Container>
            <Container className="selected-tags-wrapper w-100">
              {tagListingFormValues?.length > 0 && (
                <div className="form-controls row g-0">
                  <div className="col">
                    <label>Reporting Tags</label>
                  </div>
                  <div className="col">
                    <label>Amount Allocation</label>
                  </div>
                  <div className="col"></div>
                </div>
              )}
              {tagListingFormValues?.map((tag: ReportTagAllocation, index) => {
                return (
                  <div className="form-controls row g-0">
                    <div className="col">
                      <span className="reporting_tag_name">
                        {tag.tag_name}: {tag.option_name}
                      </span>
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        name="split-amt"
                        className="split-amt no-appearance"
                        value={tag.amount}
                        onKeyDown={handleKeyPress}
                        placeholder="Enter amount"
                        onChange={(e) => {
                          amountChangeHandler(e.target.value, index);
                        }}
                        disabled={
                          splitType.value === "Split Equally" ||
                          props.isMutable === false
                            ? true
                            : false
                        }
                        onBlur={() => {
                          handleAmountOnBlur(Number(tag.amount), index);
                        }}
                      />
                    </div>
                    <div className="col">
                      {!props.isVoid ? (
                        <Link
                          to="#"
                          className="removeItem"
                          onClick={() => {
                            if (props.isMutable === false) {
                              ErrorToaster(
                                `Unable to edit reporting tags, as there are transactions associated with this ${props.module}!`
                              );
                            } else {
                              removeTag(tag.option_id);
                            }
                          }}
                        >
                          <img
                            src={CancelBtnDark}
                            alt="delete tag"
                            width={18}
                            height={18}
                          />
                        </Link>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                );
              })}
            </Container>
          </Container>
          <DockedButtonGroup
            onSave={(e) => {
              if (props.isMutable === false) {
                e.preventDefault();
                ErrorToaster(
                  `Unable to edit reporting tags, as there are transactions associated with this ${props.module}!`
                );
                props.onClose();
              } else {
                e.preventDefault();
                applyTagsList();
              }
            }}
            onCancel={() => {
              props.onClose();
            }}
            onDelete={() => {
              //deleteTag(Number(props.editId), props.setReportingTagsList);
            }}
            isDeleteButtonDisabled={false}
            isDeleteButtonEnabled={false}
            saveButtonType="submit"
            isSaveDisabled={props.isVoid ?? false}
            isEdit={props.isEdit === true}
            isModal={true}
            shouldDockToBottom={true}
            buttonText="Done"
          />
        </Form>
      </Container>
    </>
  );
};

export default SelectReportingTagBody;
