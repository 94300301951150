import React from "react";
import { DateFormatHandler } from "../../../../helpers/helper";
import { ObjectType } from "../../../../types";
import OrgTag from "../OrgTag";
import "./HeaderBody.css";

type Props = {
  data: ObjectType;
  dataId: string | undefined;
  isSameOrg: boolean;
  children: React.ReactNode;
};
export default function HeaderBody(props: Props) {
  return (
    <div className="invoice-actions-wrapper detail-pg-actions-wrapper d-flex justify-content-between">
      {props.dataId ? (
        <>
          <div className="left-group">
            <span className="biller-label ps-2">{props.data.userName}</span>
            <span>|</span>
            <span className="bill-date pe-2 ps-2">
              {DateFormatHandler(props.data.date)}
            </span>
            <span>|</span>
            <span>
              <OrgTag organization={props.data.orgName} />
            </span>
          </div>
          {props.isSameOrg && (
            <div className="actions-wrap d-flex">
              {props.children}
            </div>
          )}
        </>
      ) : null}
    </div>
  );
}
