import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IsFeatureEnabled } from "../../../../../helpers/featureFlagHelper";
import { downloadUsingURL } from "../../../../../helpers/helper";
import { CreditNoteDetailValues } from "../../../../../types";
import { DownloadIcon, EmailIcon } from "../../../../common/components";
import { VoidConfirmationModal } from "../../../../common/components/voidConfirmationPopup/VoidConfirmationModal";
import { getCreditNotePdfLink } from "../../creditNoteSlice";
import { colors } from "../../../../constants/colors";
import { message } from "../../../../constants/messages";
import { ErrorToaster } from "../../../../../helpers/toastHelper";
import TagDarkIcon from "../../../../common/components/customSvgIcons/tagDarkIcon";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import ApplyToInvoices from "./ApplyToInvoices";
import useCommonData from "../../../../hooks/useCommon";
import { replace } from "lodash";

type Props = {
  creditNoteData: CreditNoteDetailValues;
  creditNoteRolePermission: any[];
  isVoid?: boolean;
  isTagsAssociated?: boolean;
  refreshCNDetails: () => void;
};
export const MoreActions = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const navigate = useNavigate();
  const { editId } = useParams();

  const downloadPdf = async () => {
    const responseAction = await dispatch(
      getCreditNotePdfLink({
        creditNoteId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        response.download_link?.length > 0
      ) {
        let result = await downloadUsingURL(
          response.download_link,
          Number(editId) + ".pdf"
        );
        if (result) {
          toast.success("Credit note downloaded successfully!", {
            toastId: "credit-note-download-success",
            closeButton: false,
            position: "top-center",
          });
        }
      }
    }
  };
  return (
    <KebabMenu wrapperClass="detail-top-action-menu">
      <li>
        <button
          className="dropdown-item"
          data-bs-toggle="modal"
          data-bs-target="#emailToModal"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <span>
            <EmailIcon color={colors.battleshipGrey} />
            Send Email
          </span>
        </button>
      </li>
      <li>
        <button type="button" onClick={downloadPdf} className="dropdown-item">
          <span>
            <DownloadIcon color={colors.battleshipGrey} />
            Download PDF
          </span>
        </button>
      </li>
      {props.creditNoteRolePermission.includes("Update") &&
      props.creditNoteData.organization_id ===
        currentUserInfo.organization_id &&
      IsFeatureEnabled("Invoices", currentUserInfo.disabled_feature) ? (
        <li>
          <ApplyToInvoices
            creditNoteData={props.creditNoteData}
            refreshCNDetails={props.refreshCNDetails}
            isVoid={props.isVoid}
          />
        </li>
      ) : null}
      {currentUserInfo.is_tags_enabled === true && (
        <li>
          <button
            type="button"
            onClick={() => {
              if (props.isVoid && !props.isTagsAssociated) {
                ErrorToaster(message().creditNote.associateTagsToVoidCN);
              } else {
                navigate(`/credit-note/edit/${Number(editId)}/reporting-tags`, {
                  replace: true,
                });
              }
            }}
            className="dropdown-item"
          >
            <span>
              <TagDarkIcon color={colors.battleshipGrey} />
              Reporting Tags
            </span>
          </button>
        </li>
      )}
      {props.creditNoteRolePermission.includes("Update") && (
        <li>
          <VoidConfirmationModal
            transactionNumber={props.creditNoteData.credit_note_number}
            transactionStatus={props.creditNoteData.credit_note_status}
            module="Credit Note"
            isTransactionExist={
              Number(props.creditNoteData.total_details.credits_used) !== 0
            }
            transactionId={Number(props.creditNoteData.id)}
            onSubmit={async () => {
              props.refreshCNDetails();
            }}
            isFiscalClosed={props.creditNoteData.is_fiscal_closed}
          />
        </li>
      )}
    </KebabMenu>
  );
};
