import { ObjectType } from "../../../../../../types";
import {
  CouponDefaultValue,
  ValidDurationCountLimitType,
} from "../../../couponType";

const ValidDurationCountLimit: ValidDurationCountLimitType = {
  DAYS: 5470,
  WEEKS: 780,
  MONTHS: 180,
  YEARS: 15,
};

export const validationDurationCountMsg = (
  duration_value: number | undefined,
  duration_frequency: string
) => {
  if (
    duration_value &&
    Number(duration_value) > ValidDurationCountLimit[duration_frequency]
  ) {
    return `Duration cannot be more than ${
      ValidDurationCountLimit[duration_frequency]
    } ${duration_frequency.toLowerCase()}`;
  }
  return "";
};
/**
 * Validate form values
 */
export const validate = (
  CouponFormValues: CouponDefaultValue,
  isCouponNameExist: string
) => {

  const errors: ObjectType = {};
  if (!CouponFormValues.name) {
    errors.name = "Please enter coupon name";
  }

  if (CouponFormValues.redeemed_count) {
    if (
      CouponFormValues.maximum_redemptions < CouponFormValues.redeemed_count
    ) {
      errors.maximum_redemptions = "Redemption number cannot be less that the used number";
    }
  }

  if (Number(CouponFormValues.account_id) === 0) {
    errors.account_id = "Please enter account";
  }

  if (CouponFormValues.discount_value <= 0)
    errors.discount_value = "Please enter a valid value";
  if (CouponFormValues.duration_type === "LIMITED_PERIOD") {
    if (CouponFormValues.limited_duration_value <= 0)
      errors.limited_duration_value = "Please enter a valid value";
    else if (
      CouponFormValues.limited_duration_value &&
      validationDurationCountMsg(
        CouponFormValues.limited_duration_value,
        CouponFormValues.limited_duration_unit
      ) !== ""
    ) {
      errors.limited_duration_value = validationDurationCountMsg(
        CouponFormValues.limited_duration_value,
        CouponFormValues.limited_duration_unit
      );
    }
  }
  if (isCouponNameExist) {
    errors.name = "Coupon Name already exists. Please choose a different one";
  }
  if (CouponFormValues.valid_till === "Invalid date")
    errors.valid_till = "Please enter a valid value";
  return errors;
};
