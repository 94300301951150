import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import { ErrorImg } from "../../../../assets/images";
import { journalList, deleteJournalDetails } from "../journalVoucherSlice";
import { joinTransactionNumber } from "../../../../helpers/prefixHelper";
import DeleteModal from "../../../common/components/DeleteModal";

type Props = {
  deleteJournalId: number;
  deleteJournalItemId: string;
  page: number;
  itemsPerPage: number;
  dateSortOrder: string;
  refreshJournals: (action: string) => void;
  organizationId: number;
};
const DeleteJournal = (props: Props) => {
  const dispatch = useAppDispatch();
  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  const modalSubmitHandler = async () => {
    $("#delete-button").addClass("customer-form-section-disable");
    const deleteResponseAction = await dispatch(
      deleteJournalDetails({
        journalId: props.deleteJournalId,
        orgId: props.organizationId,
      })
    );
    $("#delete-button").removeClass("customer-form-section-disable");
    if (deleteResponseAction.payload) {
      const deleteResponse = deleteResponseAction.payload;
      if (Object.keys(deleteResponse).length) {
        props.refreshJournals("delete");
        $(".close-btn").click();
        await dispatch(
          journalList({
            page: props.page,
            itemsPerPage: props.itemsPerPage,
            dateSortOrder: props.dateSortOrder,
            orgId: props.organizationId,
            orgIds: [],
            statusList: [],
          })
        );
        toast.success("Journal entry deleted successfully!", {
          toastId: "journal-delete-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in deleteResponse) {
        $(".close-btn").click();
        // toast.error("Delete in journal voucher action failed!", {
        //   icon: ({ theme, type }) => (
        //     <img src={ErrorImg} width="16" height="14" alt="error-img" />
        //   ),
        //   toastId: "delete-journal-voucher-failed-error",
        //   closeButton: false,
        //   className: "toast-error",
        //   position: "top-center",
        // });
      }
    }
  };
  return (
    <DeleteModal
      heading="Delete Journal Entry!"
      subHeading="Are you sure you want to delete the journal entry"
      transactionName={joinTransactionNumber(props.deleteJournalItemId)}
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={modalSubmitHandler}
    />
  );
};
export default DeleteJournal;
