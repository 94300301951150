import { HorizontalTab } from "../../../../common/components/HorizontalTab";
import { CreditNoteAndPaymentReceivedTable } from "./CNandPRTable/CNandPRTable";
import "./CNandPRDetails.css";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import useListData from "../../../../hooks/useListData";
import { usePaginationDispatch } from "../../../../hooks/usePaginationDispatch";
import {
  currentUserSelector,
  setLoaderState,
} from "../../../../common/commonSlice";
import { invoicePaymentList } from "../../../paymentsReceived/paymentReceivedSlice";
import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  CreditNoteList,
  PaymentReceivedListValues,
} from "../../../../../types";
import { creditNoteList } from "../../../creditNote/creditNoteSlice";
import { useParams } from "react-router-dom";

type Props = {
  invoiceId: number | null;
  invoiceDetail: () => void;
  orgId: number | null;
};
const CreditNoteAndPaymentReceivedDetails: ForwardRefRenderFunction<
  any,
  Props
> = (props, ref) => {
  const dispatch = useAppDispatch();
  const page = 1;
  const itemsPerPage = 20;
  const id = useParams().editInvoiceId;
  const currentUserInfo = useAppSelector(currentUserSelector);
  const { dateSortOrder } = useListData();
  const { setDateSortOrder } = usePaginationDispatch();
  const [PRList, setPRList] = useState<PaymentReceivedListValues[]>();
  const [CNList, setCNList] = useState<CreditNoteList[]>();
  const [isParent, setIsParent] = useState(false);
  const [reRenderFlag, setReRenderFlag] = useState<number>();
  const [CNandPRTotalCount, setCNandPRTotalCount] = useState<{
    CNTotalCount: number;
    PRTotalCount: number;
  }>({ CNTotalCount: 0, PRTotalCount: 0 });

  useEffect(() => {
    if (Number(props.orgId) !== currentUserInfo.organization_id) {
      setIsParent(true);
    } else {
      setIsParent(false);
    }
  }, [props.orgId, currentUserInfo.organization_id]);

  useImperativeHandle(
    ref,
    () => ({
      fetchCNPRList: fetchCNPRList,
    }),
    [props.invoiceId]
  );
  useEffect(() => {
    if (props.invoiceId) {
      fetchCNPRList();
    }
  }, [dateSortOrder, props.invoiceId, reRenderFlag]);
  const fetchCNPRList = () => {
    getCNList();
    getPRList();
  };
  const getPRList = async () => {
    dispatch(setLoaderState(true));
    const PRresponseAction = await dispatch(
      invoicePaymentList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        customerList: [],
        orgIds: [],
        statusList: [],
        invoiceId: Number(props.invoiceId),
      })
    );
    const PRresponse = PRresponseAction.payload as any;
    if (Object.keys(PRresponse).length && !("error" in PRresponse)) {
      dispatch(setLoaderState(false));
      setPRList(PRresponse.payments_received);
      const length = PRresponse.payments_received?.length;
      setCNandPRTotalCount({
        ...CNandPRTotalCount,
        PRTotalCount: PRresponse.total,
      });
    } else if ("error" in PRresponse) {
      dispatch(setLoaderState(false));
    }
  };
  const getCNList = async () => {
    dispatch(setLoaderState(true));
    const CNresponseAction = await dispatch(
      creditNoteList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        orgIds: [],
        statusList: [],
        customerList: [],
        invoiceId: Number(props.invoiceId),
      })
    );
    const CNresponse = CNresponseAction.payload as any;
    if (Object.keys(CNresponse).length && !("error" in CNresponse)) {
      dispatch(setLoaderState(false));
      setCNList(CNresponse.credit_notes);
      const length = CNresponse.credit_notes?.length;
      setCNandPRTotalCount({
        ...CNandPRTotalCount,
        CNTotalCount: CNresponse.total,
      });
    } else if ("error" in CNresponse) {
      dispatch(setLoaderState(false));
    }
  };

  /**
   * Date sort handler
   */
  const sortHandler = (column: string) => {
    if (["CREDIT NOTE DATE", "PAYMENT DATE"].includes(column)) {
      if (dateSortOrder === "ASC") {
        setDateSortOrder("DESC");
      } else {
        setDateSortOrder("ASC");
      }
    }
  };
  const getRerenderFlag = (flag: number) => {
    setReRenderFlag(flag);
    props.invoiceDetail();
  };
  return (
    <div className="cn-pr-detail-invoice" key={`${isParent}`}>
      <HorizontalTab
        tabHeaders={[
          `Payments Received (${PRList?.length ? PRList?.length : 0})`,
          `Credits Applied (${CNList?.length ? CNList?.length : 0})`,
        ]}
        tabBody={[
          <CreditNoteAndPaymentReceivedTable
            CNList={CNList}
            PRList={PRList}
            module="PR"
            sortHandler={sortHandler}
            dateSortOrder={dateSortOrder}
            invoiceId={Number(id)}
            isParent={isParent}
            key={"1"}
            getRerenderFlag={getRerenderFlag}
          />,
          <CreditNoteAndPaymentReceivedTable
            CNList={CNList}
            PRList={PRList}
            module="CN"
            sortHandler={sortHandler}
            dateSortOrder={dateSortOrder}
            invoiceId={Number(id)}
            isParent={isParent}
            key={"2"}
            getRerenderFlag={getRerenderFlag}
          />,
        ]}
        wrapperClass="invoice-cn-pr-detail-tab"
        disableRerender={true}
      />
    </div>
  );
};
export default React.memo(forwardRef(CreditNoteAndPaymentReceivedDetails));
