  import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
  import {
    getAddonOverviewDetails,
    checkInternalName,
    createNewAddon,
    getAddonForEdit,
    editAddonApi,
    deletePricing,
    getPlanItemList,
    createPlanPrice,
    getEditPriceDetails,
    editPlanPrice,
    getAddonList,
    deleteAddon,
    updateAddonStatus,
    createAddonPrice,
    addonPlanItemList,
    addonGetEditPriceDetails,
    editAddonPrice,
    checkPriceInternalName,
    addonTransactionLimitStatus
  } from "./addonAPI";
  import { 
    PlanDefaultValues,
    TaxableAmountCalculationValues,
    TotalAmountCalculationValues,
    PlanPriceDefaultValue
  } from "../../../types";

  export const checkInternalNameExist = createAsyncThunk(
    "InternalName",
    async (data: {
      internalName: string;
      orgId: number;
      addon_id: Object;
    }) => {
      return await checkInternalName(data);
    }
  );

  export const createAddon = createAsyncThunk(
    "plan/addons/create",
    async (data: {
      values: PlanDefaultValues;
      orgId: number;
    }) => {
      return await createNewAddon(data);
    }
  );
  
  export const addonList = createAsyncThunk(
    "addonList",
    async (data: {
      page: number;
      itemsPerPage: number;
      orgId: number;
      orgIds: number[];
      statusList: string[];
    }) => {
      return await getAddonList(data);
    }
  );

  export const addonDetailsForEdit = createAsyncThunk(
    "addonEditView",
    async (data: {
      addonId: number | string;
      orgId: number
    }) => {
      return await getAddonForEdit(data);
    }
  );

  export const planItemList = createAsyncThunk(
    "planItemList",
    async (data: {
      planId: any;
      orgId: number;
    }) => {
      return await getPlanItemList(data);
    }
  );


  export const addonItemList = createAsyncThunk(
    "addonItemList",
    async (data: {
      addonId: any;
      orgId: number;
    }) => {
      return await addonPlanItemList(data);
    }
  );


  export const planPricePointCreate = createAsyncThunk(
    "planPricePointCreate",
    async (data: {
      values: PlanPriceDefaultValue;
      orgId: number;
    }) => {
      return await createPlanPrice(data);
    }
  );

  export const addonPricePointCreate = createAsyncThunk(
    "addonPricePointCreate",
    async (data: {
      values: PlanPriceDefaultValue;
      orgId: number;
    }) => {
      return await createAddonPrice(data);
    }
  );
  
 
  export const editAddon = createAsyncThunk(
    "plan/edit",
    async (data: {
      values: PlanDefaultValues;
      orgId: number;
      addonId: string | undefined;
    }) => {
      return await editAddonApi(data);
    }
  );
  
  export const addonDetails = createAsyncThunk(
    "addonDetail",
    async (data: { addonId: number; orgId: number }) => {
      return await getAddonOverviewDetails(data);
    }
  );
  
  export const changeAddonStatus = createAsyncThunk(
    "addon/update-status",
    async (data: { id: number; orgId: number; status: string }) => {
      return await updateAddonStatus(data);
    }
  );

  export const deleteAddonDetails = createAsyncThunk(
    "addon/delete",
    async (data: { deleteAddonId: number; orgId: number }) => {
      return await deleteAddon(data);
    }
  );
  
  export const deletePricingData = createAsyncThunk(
    "pricing/delete",
    async (data: { deletePricingId: number; orgId: number }) => {
      return await deletePricing(data);
    }
  );

  export const priceGetEditDetails = createAsyncThunk(
    "pricePlan",
    async (data: {
      priceId: number;
      orgId: number
    }) => {
      return await getEditPriceDetails(data);
    }
  );

  export const addonPriceGetEditDetails = createAsyncThunk(
    "addonPricePlan",
    async (data: {
      priceId: number;
      orgId: number;
    }) => {
      return await addonGetEditPriceDetails(data);
    }
  );

  export const planPricePointEdit = createAsyncThunk(
    "planPricePointEdit",
    async (data: {
      values: PlanPriceDefaultValue;
      orgId: number;
      priceId: any;
    }) => {
      return await editPlanPrice(data);
    }
  );

  export const addonPricePointEdit = createAsyncThunk(
    "addonPricePointEdit",
    async (data: {
      values: PlanPriceDefaultValue;
      orgId: number;
      priceId: any;
    }) => {
      return await editAddonPrice(data);
    }
  );
  
  const initialState = {};
  
  export const invoiceSlice = createSlice({
    name: "PlanManagement",
    initialState,
    reducers: {},
  });

  export const checkPriceInternalNameExist = createAsyncThunk(
    "AddonPriceInternalName",
    async (data: {
      internalName: string;
      orgId: number;
      addon_id: number;
      pricing_id: string
    }) => {
      return await checkPriceInternalName(data);
    }
  );
  
  export const getAddonTransactionLimitStatus = createAsyncThunk(
    "addon/limit/status",
    async (orgId: number) => {
      return await addonTransactionLimitStatus(orgId);
    }
  );
  export default invoiceSlice.reducer;
  