import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CreditNoteDetailValues, ObjectType } from "../../../../../../types";
import { getJVTableHeader, getUpdatedJournals } from "./utils/helper";
import { creditNoteDetailsInitialValues } from "../StateInitialization";
import { getReportingTagDropdown } from "../../../../reportingTags/reportingTagAPIFiles/reportingTagSlice";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import { setLoaderState } from "../../../../../common/commonSlice";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";
import { setErrorID } from "../../../../../../appSlice";
import { message } from "../../../../../constants/messages";
import {
  MAX_TAGS_LIMIT,
  Max_OPTIONS_LIMIT,
  noAccessErrorCodes,
} from "../../../../../constants/constants";
import {
  generateJournal,
  creditNoteDetails,
  updateReportingTags,
} from "../../../creditNoteSlice";
import Header from "../../../../../common/components/detailsPage/Header";
import Table from "../../../../../common/components/table/Table";
import SelectReportingTag from "../../../../../common/components/ReportingTags/SelectReportingTag/SelectReportingTag";
import JournalListFooter from "../../../../../common/components/ReportingTags/FooterBtn/JournalListFooter";
import useCommonData from "../../../../../hooks/useCommon";
import "./journalList.css";

const JournalList = (props: any) => {
  const { editId } = useParams();
  const { dispatch, navigate, currentUserInfo, orgCurrencyList } =
    useCommonData();
  const [journals, setJournals] = useState([]);
  const [currency, setCurrency] = useState({
    currency_id: null,
    currency_code: "",
  });
  const [creditNoteData, setCreditNoteData] = useState<CreditNoteDetailValues>(
    creditNoteDetailsInitialValues
  );
  const [total, setTotal] = useState({
    total_credit: 0,
    total_debit: 0,
  });
  const { creditNoteRolePermission } = usePermissionHandler();

  useEffect(() => {
    getJournals();
    getCreditNoteDetails();
    dispatch(
      getReportingTagDropdown({
        orgId: currentUserInfo.organization_id,
      })
    );
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    if (editId) {
      creditNoteDetail();
    }
  }, [editId, currentUserInfo.organization_id]);
  /**
   * Fetch Bill Details
   */
  const creditNoteDetail = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      creditNoteDetails({
        creditNoteId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (response.credit_note_status === "Draft") {
          navigate("/credit-note");
        }
        setCreditNoteData(response);
      } else {
        dispatch(setErrorID(noAccessErrorCodes.CNDetail));
      }
    }
  };

  const getJournals = async () => {
    dispatch(setLoaderState(true));
    const response = await dispatch(
      generateJournal({
        creditNoteId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    dispatch(setLoaderState(false));
    const responsePayload = response.payload;
    if (Object.keys(responsePayload).length && !("error" in responsePayload)) {
      setJournals(responsePayload.transaction);
      setTotal({
        total_credit: responsePayload.total_credit,
        total_debit: responsePayload.total_debit,
      });
    }
  };

  const getCreditNoteDetails = async () => {
    const responseAction = await dispatch(
      creditNoteDetails({
        creditNoteId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    setCurrency({
      currency_id: responseAction.payload.currency_id,
      currency_code: responseAction.payload.currency_code,
    });
  };

  const updateTagsSelection = (
    accountId: number,
    splitType: string,
    tagsList: any,
    index?: number | null
  ) => {
    if (index) {
      let newJournal = getUpdatedJournals(
        journals,
        accountId,
        splitType,
        tagsList,
        index
      );
      setJournals(newJournal);
    } else {
      let newJournal = getUpdatedJournals(
        journals,
        accountId,
        splitType,
        tagsList,
        0
      );
      setJournals(newJournal);
    }
  };

  /**
   * set table column headings
   */
  const data = journals
    ? journals.map((journal: ObjectType, index) => ({
        show: {
          account_name: (
            <>
              <span className="account_name ellipsis">
                {journal.account_name}
              </span>
              <SelectReportingTag
                accountId={journal.account_id}
                accountName={journal.account_name}
                transactionType={journal.transaction_type}
                transactionAmount={journal.transaction_amount}
                selectedTagsCount={journal.options_list.length}
                currency={currency}
                updateTagsSelection={updateTagsSelection}
                optionsList={journal.options_list}
                splitType={journal.split_type}
                isTagOptionsExists={true}
                index={index}
                maxTags={MAX_TAGS_LIMIT}
                maxOptions={Max_OPTIONS_LIMIT}
                isVoid={creditNoteData.credit_note_status === "Void"}
              />
            </>
          ),
          debit:
            journal.transaction_type === "DEBIT"
              ? NumberFormat(
                  journal.transaction_amount,
                  currency.currency_code,
                  orgCurrencyList
                )
              : "",
          credit:
            journal.transaction_type === "CREDIT"
              ? NumberFormat(
                  journal.transaction_amount,
                  currency.currency_code,
                  orgCurrencyList
                )
              : "",
        },
      }))
    : [];

  data.push({
    show: {
      account_name: <></>,
      debit: `${currency.currency_code} ${NumberFormat(
        total.total_debit,
        currency.currency_code,
        orgCurrencyList
      )}`,
      credit: `${currency.currency_code} ${NumberFormat(
        total.total_credit,
        currency.currency_code,
        orgCurrencyList
      )}`,
    },
  });

  /**
   * set table column headings
   */
  const columns = useMemo(() => {
    return getJVTableHeader();
  }, []);

  const submitHandler = async () => {
    let payload = {
      transaction_list: journals,
    };
    dispatch(setLoaderState(true));
    let response = await dispatch(
      updateReportingTags({
        creditNoteId: Number(editId),
        orgId: currentUserInfo.organization_id,
        payload: payload,
      })
    );
    dispatch(setLoaderState(false));
    let updateResponse = response.payload;
    if (Object.keys(updateResponse).length && !("error" in updateResponse)) {
      toast.success(message().reportingTag.reportingTagUpdateSuccess, {
        toastId: "reporting-tag-success",
        closeButton: false,
        position: "top-center",
      });
      navigate(`/credit-note/detail/${editId}`, {
        replace: true,
      });
    }
  };

  return (
    <>
      <div
        className="card card-user-management card-customer card-create-item main-card overflowX-hidden h-100 reportingtag-jv"
        id="invoice-reportingtag"
      >
        <Header
          onBackButtonClick={() => {
            navigate(`/credit-note/detail/${editId}`);
          }}
          data={{
            num: "Apply Reporting tags to accounts",
            status: "",
          }}
          dataId={editId}
        />
        <div className="card-body">
          <div className="table-section">
            <div className="table-wrap">
              <Table
                id={""}
                columns={columns}
                data={data}
                tableClassName={"table inv-preview-table report-tags-table"}
                dataSort={false}
                isLoading={false}
                haveColumnClassName={false}
                rowClassName={"table-navigation"}
                emptyClassName={"text-center dataTables_empty"}
                tbodyRowOnClick={() => {}}
                tbodyColumnClick={() => {}}
              >
                {props.children}
              </Table>
            </div>
          </div>
        </div>
        <JournalListFooter
          editId={editId}
          isActive={true}
          onClickSubmit={submitHandler}
          rolePermission={creditNoteRolePermission}
          onClickDelete={() => {}}
          isVoid={creditNoteData.credit_note_status === "Void"}
        />
      </div>
    </>
  );
};

export default JournalList;
