import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { DateFormatHandler } from "../../../../../helpers/helper";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import { SuccessToaster } from "../../../../../helpers/toastHelper";
import { BillPaymentDetailValues } from "../../../../../types";
import {
  currentUserSelector,
  orgCurrencyListSelector,
} from "../../../../common/commonSlice";
import { EditIcon } from "../../../../common/components";
import AuthorizedSignature from "../../../../common/components/AuthorizedSignature";
import { DeleteIconModal } from "../../../../common/components/DeleteIconModal/deleteIconModal";
import AddressSection from "../../../../common/components/detailsPage/AddressSection";
import CompanyDetails from "../../../../common/components/detailsPage/CompanyDeatails";
import Notes from "../../../../common/components/detailsPage/Notes";
import FileList from "../../../../common/components/FileList";
import Table from "../../../../common/components/table/Table";
import JournelView from "../../../../common/components/JournelView";
import { RowWiseFieldView } from "../../../../common/components/rowWiseFieldsView/rowWiseFieldsView";
import { removeRefund } from "../../paymentsMadeSlice";
import RefundModal from "../refund/RefundModal";
import { HorizontalTab } from "../../../../common/components/HorizontalTab";
import { useMemo, useState } from "react";
import { getFiscalYear } from "../../../../../helpers/fiscalYearHelper";
import AuditTrail from "../../../../common/components/auditTrail/AuditTrail";

type Props = {
  paymentMadeData: BillPaymentDetailValues;
  paymentMadeDetailResponse: () => void;
};

const PaymentMadeDetailBody: React.FC<Props> = ({
  paymentMadeData,
  paymentMadeDetailResponse,
}) => {
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [isDeleteAttempted, setIsDeletedAttempted] = useState(false);

  const dispatch = useAppDispatch();

  const fiscal_year_date = useMemo(() => {
    return getFiscalYear(
      currentUserInfo.organization_fiscal_year || "April-March",
      "current"
    );
  }, [currentUserInfo.organization_fiscal_year]);

  const deleteRefund = async (refundId: number) => {
    let responseAction = await dispatch(
      removeRefund({
        refundId: refundId,
        orgId: currentUserInfo.organization_id,
      })
    );
    let response = responseAction.payload;

    if (Object.keys(response).length && !("error" in response)) {
      await paymentMadeDetailResponse();
      SuccessToaster("Refund removed successfully", "refund-remove-success");
      setIsDeletedAttempted(true);
    }
  };

  const paymentMadeFor = (
    <>
      {paymentMadeData.unpaid_bill_lines.filter(
        (bill) => bill.payment_amount !== 0
      ).length ? (
        <div
          className="payment-detail-table-wrapper mt-0"
          style={{ border: "none" }}
        >
          <div className="table-container table-responsive">
            <table
              className="table payment-detail-table m-0"
              style={{ border: "none" }}
            >
              <thead>
                <tr>
                  <th>bill number</th>
                  <th>bill date</th>
                  <th>BILL AMOUNT</th>
                  <th>Payment Amount</th>
                  <th>FOREX GAIN/LOSS</th>
                </tr>
              </thead>
              <tbody>
                {paymentMadeData.unpaid_bill_lines
                  .filter((bill) => bill.payment_amount !== 0)
                  .map((unpaidBills, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {joinTransactionNumber(unpaidBills.bill_number)}
                        </td>
                        <td>{DateFormatHandler(unpaidBills.bill_date)}</td>
                        <td>
                          {NumberFormat(
                            Number(unpaidBills.bill_amount),
                            paymentMadeData.currency_code
                              ? paymentMadeData.currency_code
                              : "",
                            orgCurrencyList
                          )}
                        </td>
                        <td>
                          {NumberFormat(
                            Number(unpaidBills.payment_amount),
                            paymentMadeData.currency_code
                              ? paymentMadeData.currency_code
                              : "",
                            orgCurrencyList
                          )}
                        </td>
                        <td>
                          {unpaidBills.forex_amount.toString().indexOf("-") <
                            0 && Number(unpaidBills.forex_amount) !== 0
                            ? "+" + unpaidBills.forex_amount
                            : unpaidBills.forex_amount}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );

  const refundColumns = [
    {
      Header: "Date",
      accessor: "show.refunded_on",
      sort: false,
      sortOrder: "",
      className: "refund-date",
    },
    {
      Header: "Payment Mode",
      accessor: "show.payment_mode",
      sort: false,
      sortOrder: "",
      className: "credit_note_no",
    },
    {
      Header: "Amount Refunded",
      accessor: "show.amount",
      sort: false,
      sortOrder: "",
      className: "credit_applied",
    },
    {
      Header: "Forex gain/loss",
      accessor: "show.forex_amount",
      sort: false,
      sortOrder: "",
      className: "credit_applied",
    },
    {
      Header: "",
      accessor: "show.action",
      sort: false,
      sortOrder: "",
      className: "action",
      Cell: ({ cell: { value } }: any) => {
        return (
          <>
            {paymentMadeData.organization_id ===
              currentUserInfo.organization_id &&
            fiscal_year_date.startDate <= value.refunded_on ? (
              <span className="cn-pr-action-btn pull-right">
                <RefundModal
                  paymentMadeData={paymentMadeData}
                  editId={value.id}
                  refreshList={paymentMadeDetailResponse}
                >
                  <button
                    className="no-appearance"
                    onClick={(e) => {
                      e.preventDefault();
                      // handleEditRedirection(value.id);
                    }}
                  >
                    <EditIcon color="blue" />
                  </button>
                </RefundModal>
                <DeleteIconModal
                  title="Delete Refund"
                  content="Are you sure about deleting the refund made from this excess payment?"
                  onDeleteConfirm={() => {
                    deleteRefund(value.id);
                  }}
                />
              </span>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];
  const refundData = paymentMadeData.refund_history
    ? paymentMadeData.refund_history.map((refund) => {
        return {
          show: {
            refunded_on: DateFormatHandler(refund.refunded_on),
            payment_mode: refund.payment_mode,
            amount: paymentMadeData.currency_code + " " + refund.amount,
            forex_amount:
              currentUserInfo.currency_code + " " + refund.forex_amount,
            action: {
              id: refund.id,
              refunded_on: refund.refunded_on,
            },
          },
        };
      })
    : [];

  const refundHistory = (
    <>
      <Table
        id={"pr_refund_table"}
        columns={refundColumns}
        data={refundData}
        tableClassName={
          "list-table table table-striped w-100 pr-details-refund-table"
        }
        dataSort={true}
        isLoading={undefined}
        haveColumnClassName={true}
        onSortClick={() => {}}
        rowClassName={""}
        emptyClassName={"text-center dataTables_empty"}
        hiddenColumns={[]}
        tbodyRowOnClick={() => {}}
        tbodyColumnClick={() => {}}
      >
        No Refund History
      </Table>
    </>
  );

  const tabHeaders = [`Payments made for`];
  if (
    (paymentMadeData.refund_history &&
      paymentMadeData.refund_history.length > 0) ||
    isDeleteAttempted
  ) {
    tabHeaders.push(`Refund History`);
  }

  return (
    <>
      <div id="invoice" className="cn-preview transaction-preview">
        <div className="invoice-header d-flex justify-content-between">
          <CompanyDetails
            organizationData={paymentMadeData.organization_details}
          />
          <div className="invoice-details align-right">
            <div className="label-invoice">Payments Made</div>
            <div className="bill-addr-label">To</div>
            <div className="bill-address">
              {paymentMadeData.vendor_name}
              {paymentMadeData.vendor_billing_address ? (
                <AddressSection
                  addressData={paymentMadeData.vendor_billing_address}
                />
              ) : (
                ""
              )}
            </div>
            {paymentMadeData.reference_number ? (
              <>
                <div className="bill-addr-label">Reference Number</div>
                <div className="invoice-number">
                  {paymentMadeData.reference_number}
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className="table-section payment-detail-section">
          <div className="row pay-detail-row g-0">
            <div className="col pay-date-col">
              <div className="pay-item-text w-100">Payment Date:</div>
              <div className="pay-item-value w-100">
                {DateFormatHandler(paymentMadeData.payment_date)}
              </div>
            </div>
            <div className="col pay-item-col">
              <div className="pay-item-text w-100">Payment Number:</div>
              <div className="pay-item-value w-100">
                {joinTransactionNumber(paymentMadeData.payment_number)}
              </div>
            </div>
            <div className="col pay-mode-col">
              <div className="pay-item-text w-100">Payment Mode:</div>
              <div className="pay-item-value w-100">
                {paymentMadeData.payment_mode}
              </div>
            </div>
            <div className="col pay-words-col">
              <div className="pay-item-text w-100">Amount Paid (words):</div>
              <div className="pay-item-value w-100">
                {paymentMadeData.amount_in_words} Only
              </div>
            </div>
          </div>
          <RowWiseFieldView
            mapObject={paymentMadeData.custom_fields}
            module="Payments Made"
          />
          {paymentMadeData.notes ? <Notes notes={paymentMadeData.notes} /> : ""}
          <div className="row g-0">
            <div className="col d-flex amt-received-column">
              <div className="amout-received-digits d-flex">
                <span className="element-label">Amount Paid:</span>
                <div style={{ padding: "0px 14px 14px 0px" }}>
                  <span className="amount">
                    {paymentMadeData.currency_code}{" "}
                    {NumberFormat(
                      Number(
                        Number(paymentMadeData.amount_paid) +
                          Number(paymentMadeData.bank_charges_amount)
                      ),
                      paymentMadeData.currency_code
                        ? paymentMadeData.currency_code
                        : "",
                      orgCurrencyList
                    )}
                  </span>
                  {paymentMadeData.refund_history &&
                    paymentMadeData.refund_history.length > 0 && (
                      <span className="refund-amount-wrapper">
                        <span className="refund-label">Refunded: </span>
                        <span className="refund-amount">
                          {paymentMadeData.currency_code}{" "}
                          {NumberFormat(
                            Number(paymentMadeData.total_refunded_amount),
                            paymentMadeData.currency_code,
                            orgCurrencyList
                          )}
                        </span>
                      </span>
                    )}
                </div>
              </div>
              <div className="show-excess-amt">
                <span className="excess-label">Excess Amount:</span>
                <span className="excess-amt">
                  {paymentMadeData.currency_code}{" "}
                  {NumberFormat(
                    Number(paymentMadeData.amount_excess),
                    paymentMadeData.currency_code
                      ? paymentMadeData.currency_code
                      : "",
                    orgCurrencyList
                  )}
                </span>
              </div>
            </div>
          </div>
          <div
            className="row g-0 pay-item-details"
            style={{ border: "1px solid var(--warm-white)" }}
          >
            {/* <div className="customer-name">
                <div className="pay-item-text">Paid To:</div>
                <div className="pay-item-value">Aller Systems</div>
              </div> */}
            <HorizontalTab
              tabHeaders={tabHeaders}
              tabBody={[paymentMadeFor, refundHistory]}
              wrapperClass="invoice-cn-pr-detail-tab"
              disableRerender={true}
            />
          </div>
          <div className="row g-0">
            <div className="col">
              <AuthorizedSignature />
            </div>
          </div>
        </div>
      </div>
      {paymentMadeData.files.length ? (
        <FileList files={paymentMadeData.files} />
      ) : null}
      <JournelView
        title="Vendor Payments"
        transactionType="Payments Made"
        transactionId={Number(paymentMadeData.id)}
      />
      <AuditTrail
        title="Audit Trail"
        transactionType="payment_made"
        transactionId={Number(paymentMadeData.id)}
      />
    </>
  );
};
export default PaymentMadeDetailBody;
