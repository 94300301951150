import { useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../../../../app/hooks";
import {
  DateFormatHandler,
} from "../../../../../../helpers/helper";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";
import { joinTransactionNumber } from "../../../../../../helpers/prefixHelper";
import {
  CreditNoteList,
  ObjectType,
  PaymentReceivedListValues,
} from "../../../../../../types";
import { orgCurrencyListSelector } from "../../../../../common/commonSlice";
import { EditIcon } from "../../../../../common/components/customSvgIcons/editIcon";
import TableContainer from "../../../../../common/components/table/TableContainer";
import { DeleteAllocationModal } from "../DeleteAllocation/deleteAllocationModal";

type Props = {
  CNList: CreditNoteList[] | undefined;
  PRList: PaymentReceivedListValues[] | undefined;
  dateSortOrder: string;
  sortHandler: (column: string) => void;
  module: string;
  invoiceId: number | null;
  key: string;
  getRerenderFlag: (flag: number) => void;
  isParent: boolean;
};
export const CreditNoteAndPaymentReceivedTable = ({
  CNList,
  PRList,
  dateSortOrder,
  sortHandler,
  module,
  invoiceId,
  isParent,
  key,
  getRerenderFlag,
}: Props) => {
  const CNDetailRedirection = "/credit-note/";
  const PRDetailRedirection = "/payment-received/";
  const invoiceBackRedirection = "/invoice/detail/" + invoiceId;
  const invId = useParams().editInvoiceId;
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const navigate = useNavigate();
  const handleEditRedirection = (id: number | null) => {
    navigate(
      module === "CN"
        ? CNDetailRedirection + "edit/" + id
        : PRDetailRedirection + "edit/" + id,
      {
        state: {
          redirectTo: invoiceBackRedirection,
        },
      }
    );
  };

  /**
   * set table column headings for PR
   */
  const PRcolumns = useMemo(
    () => [
      {
        Header: "PAYMENT DATE",
        accessor: "show.date",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "itemdate",
      },
      {
        Header: "PAYMENT NUMBER",
        accessor: "show.payment_number",
        sort: false,
        sortOrder: "",
        className: "payment_number",
        Cell: ({ cell: { value } }: any) => {
          return (
            <Link
              to={`/payment-received/detail/${value.id}`}
              className="transaction-number-cn-pr"
            >
              {value.transactionNumber}
            </Link>
          );
        },
      },
      {
        Header: "PAYMENT MODE",
        accessor: "show.payment_mode",
        sort: false,
        sortOrder: "",
        className: "payment_mode",
      },
      {
        Header: "REFERENCE NUMBER",
        accessor: "show.reference_number",
        sort: false,
        sortOrder: "",
        className: "reference_number",
      },
      {
        Header: "ALLOCATION DATE",
        accessor: "show.allocation_date",
        sort: false,
        sortOrder: "",
        className: "allocation_date",
      },
      {
        Header: "AMOUNT",
        accessor: "show.amount_received",
        sort: false,
        sortOrder: "",
        className: "amount_received",
      },
      {
        Header: "",
        accessor: "show.action",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return !isParent ? (
            <span className="cn-pr-action-btn">
              {!value.isDisabled && !value.is_fiscal_closed ? (
                <button
                  className="no-appearance"
                  onClick={(e) => {
                    e.preventDefault();
                    handleEditRedirection(value.id);
                  }}
                >
                  <EditIcon color="blue" />
                </button>
              ) : (
                <></>
              )}
              {!value.is_fiscal_closed && (
                <DeleteAllocationModal
                  id={value.id}
                  invId={Number(invId)}
                  itemId={value.itemId}
                  module={value.module}
                  transactionNumber={value.transactionNumber}
                  getRerenderFlag={getRerenderFlag}
                />
              )}
            </span>
          ) : (
            <></>
          );
        },
      },
    ],
    [PRList]
  );
  /**
   * set table column headings for CN
   */
  const CNcolumns = useMemo(
    () => [
      {
        Header: "CREDIT NOTE DATE",
        accessor: "show.date",
        sort: true,
        sortOrder:
          dateSortOrder === "ASC"
            ? "ascending"
            : dateSortOrder === "DESC"
            ? "descending"
            : "",
        className: "itemdate",
      },
      {
        Header: "CREDIT NOTE NUMBER",
        accessor: "show.credit_note_no",
        sort: false,
        sortOrder: "",
        className: "credit_note_no",
        Cell: ({ cell: { value } }: any) => {
          return (
            <Link
              to={`/credit-note/detail/${value.id}`}
              className="transaction-number-cn-pr"
            >
              {value.transactionNumber}
            </Link>
          );
        },
      },
      {
        Header: "CREDITS APPLIED",
        accessor: "show.credit_applied",
        sort: false,
        sortOrder: "",
        className: "credit_applied",
      },
      {
        Header: "",
        accessor: "show.action",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return !isParent ? (
            <span className="cn-pr-action-btn">
              {!value.isDisabled ? (
                <button
                  className="no-appearance"
                  onClick={(e) => {
                    e.preventDefault();
                    handleEditRedirection(value.id);
                  }}
                >
                  <EditIcon color="blue" />
                </button>
              ) : (
                <></>
              )}
              <DeleteAllocationModal
                id={value.id}
                invId={Number(invId)}
                itemId={value.itemId}
                module={value.module}
                transactionNumber={value.transactionNumber}
                getRerenderFlag={getRerenderFlag}
              />
            </span>
          ) : (
            <></>
          );
        },
      },
    ],
    [CNList]
  );
  const CNdata = CNList
    ? CNList.map((item) => {
        const invNos = item.invoice_numbers?.filter(
          (invItem) => Number(invId) === Number(invItem.id)
        );
        const cols = invNos.map((invoice: ObjectType) => ({
          show: {
            date: DateFormatHandler(item.credit_note_date),
            credit_note_no: {
              transactionNumber: joinTransactionNumber(item.credit_note_no),
              id: item.id,
            },
            credit_applied:
              item.currency_code +
              " " +
              NumberFormat(
                Number(invoice?.credits_applied),
                item.currency_code,
                orgCurrencyList
              ),
            action: {
              id: item.id,
              isDisabled: true,
              transactionNumber: joinTransactionNumber(item.credit_note_no),
              module: "CN",
            },
          },
        }));
        return cols;
      }).flat()
    : [];
  const PRdata = PRList
    ? PRList.map((item) => ({
        show: {
          date: DateFormatHandler(item.payment_date),
          payment_number: {
            transactionNumber: joinTransactionNumber(item.payment_number),
            id: item.id,
          },
          payment_mode: item.payment_mode,
          allocation_date:
            DateFormatHandler(
              item.item_list.filter(
                (item: {
                  id: number | null;
                  invoice_id: number | null;
                  allocation_date?: string;
                }) => Number(item.invoice_id) === Number(invId)
              )[0]?.allocation_date || ""
            ) || "",
          reference_number: item.reference_number || "-",
          amount_received:
            item.currency_code +
            " " +
            NumberFormat(
              Number(item.allocated_amount || 0),
              item.currency_code,
              orgCurrencyList
            ),
          action: {
            id: item.id,
            isDisabled: false,
            transactionNumber: joinTransactionNumber(item.payment_number),
            itemId: item.item_list.filter(
              (item: {
                id: number | null;
                invoice_id: number | null;
                allocation_date?: string;
              }) => Number(item.invoice_id) === Number(invId)
            )[0]?.id,
            module: "PR",
            is_fiscal_closed: item.is_fiscal_closed,
          },
        },
      }))
    : [];
  return (
    <TableContainer
      columns={module === "CN" ? CNcolumns : PRcolumns}
      data={module === "CN" ? CNdata : PRdata}
      id="cn-pr-list-table"
      className="list-table table table-striped w-100"
      url={""}
      sortHandler={sortHandler}
      editPermission={true}
      isDetailViewExist={false}
      isNavigate={false}
      hiddenColumns={["Actions"]}
      key={key}
    >
      <div className="no-allocation">No allocations found!</div>
    </TableContainer>
  );
};
