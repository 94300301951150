import { useState, useEffect, useRef } from "react";
import { RadioButtonGroup } from "../../../../common/components/radioButtonGroup/radioButtonGroup";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import useCommonData from "../../../../hooks/useCommon";
import { DefaultPreferenceValues, ObjectType } from "../../../../../types";
import GstSettingsContainer from "../settings/GstSettingsContainer";
import {
  SuccessToaster,
  ErrorToaster,
} from "../../../../../helpers/toastHelper";
import {
  defaultTaxPreference,
  stateTaxRates,
  updateDefaultTaxPreference,
} from "../../gstSlice";
import { useFormik } from "formik";
import { setLoaderState } from "../../../../common/commonSlice";
import { message } from "../../../../constants/messages";
import Select from "react-select";
import { defaultPreferenceValidation } from "./defaultPreferenceValidation";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";

const DefaultPreference = () => {
  const { dispatch, currentUserInfo } = useCommonData();
  const { gstPermission } = usePermissionHandler();

  // stores options array for Intra State Tax Rate select
  const [intraStateTaxRates, setIntraStateTaxRates] = useState<ObjectType[]>(
    []
  );

  // stores options array for Inter State Tax Rate select
  const [interStateTaxRates, setInterStateTaxRates] = useState<ObjectType[]>(
    []
  );

  // ref for storing inter state tax rates data,
  // Used to pass is_inter_state_group in put api payload
  const interStateRef = useRef([]);
  const intraStateRef = useRef([]);

  const [defaultFormValues, setDefaultFormValues] =
    useState<DefaultPreferenceValues>({
      default_preference_enabled: "No",
      intra_state_id: null,
      inter_state_id: null,
    });

  useEffect(() => {
    initialFetch();
  }, [currentUserInfo.organization_id]);

  const initialFetch = async () => {
    if (!currentUserInfo.organization_id) {
      return;
    }
    let intraStateOptions = (await fetchStateTaxRates(
      "Intra State"
    )) as ObjectType[];
    let interStateOptions = (await fetchStateTaxRates(
      "Inter State"
    )) as ObjectType[];

    dispatch(setLoaderState(true));
    let response = await dispatch(
      defaultTaxPreference({
        orgId: currentUserInfo.organization_id,
      })
    );
    let data: any = response.payload;
    let intra_state_id = intraStateOptions.filter(
      (tax) => tax.value == data.intra_state_id
    );
    let inter_state_id = interStateOptions.filter(
      (tax) => tax.value == data.inter_state_id
    );

    setDefaultFormValues((defaultFormValues) => {
      return {
        ...defaultFormValues,
        default_preference_enabled: data.default_preference_enabled
          ? "Yes"
          : "No",
        intra_state_id: intra_state_id[0],
        inter_state_id: inter_state_id[0],
      };
    });
    dispatch(setLoaderState(false));
  };

  const fetchStateTaxRates = (stateType: string) => {
    return new Promise(async (resolve, reject) => {
      dispatch(setLoaderState(true));
      let response = await dispatch(
        stateTaxRates({
          orgId: currentUserInfo.organization_id,
          stateType: stateType,
        })
      );
      let options: any[] = [];
      response?.payload.forEach((element: ObjectType) => {
        options.push({
          label: `${element.name} [${element.rate}%]`,
          value: element.id,
        });
      });

      if (stateType == "Intra State") {
        intraStateRef.current = response?.payload;
        setIntraStateTaxRates([...options]);
      } else if (stateType == "Inter State") {
        interStateRef.current = response?.payload;
        setInterStateTaxRates([...options]);
      }

      dispatch(setLoaderState(false));
      resolve(options);
    });
  };

  const formik = useFormik({
    initialValues: defaultFormValues,
    enableReinitialize: true,
    validationSchema: defaultPreferenceValidation,
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: async (values) => {
      const intra_state_id_value =
        typeof values.intra_state_id === "object"
          ? values.intra_state_id?.value
          : 0;

      const inter_state_id_value =
        typeof values.inter_state_id === "object"
          ? values.inter_state_id?.value
          : 0;

      let is_inter_state_group = false;
      let is_intra_state_group = false;
      if (inter_state_id_value) {
        const inter_state_id_obj = interStateRef.current?.filter(
          (taxRate: ObjectType) => {
            return taxRate.id === inter_state_id_value;
          }
        )[0] as ObjectType;

        is_inter_state_group = inter_state_id_obj.tax_group;
      }
      if (intra_state_id_value) {
        const intra_state_id_obj = intraStateRef.current?.filter(
          (taxRate: ObjectType) => {
            return taxRate.id === intra_state_id_value;
          }
        )[0] as ObjectType;

        is_intra_state_group = intra_state_id_obj.tax_group;
      }

      // creating payload to be send to PUT API
      let payload = {
        default_preference_enabled:
          values.default_preference_enabled === "Yes" ? true : false,
        intra_state_id:
          values.default_preference_enabled === "Yes"
            ? intra_state_id_value
            : 0,
        inter_state_id:
          values.default_preference_enabled === "Yes"
            ? inter_state_id_value
            : 0,
        is_inter_state_group: is_inter_state_group,
        is_intra_state_group: is_intra_state_group,
      };

      let responseAction = await dispatch(
        updateDefaultTaxPreference({
          orgId: currentUserInfo.organization_id,
          values: payload,
        })
      );
      const response = responseAction.payload as ObjectType;
      if (!("error" in response)) {
        SuccessToaster(
          message().gstDefaultUpdateSuccess,
          "gst-default-success-toast"
        );
        // clearing intra_state and inter_state dropdown values and its error messages
        // after submitting the form with default preference === N0
        setDefaultFormValues({
          default_preference_enabled: values.default_preference_enabled,
          intra_state_id:
            values.default_preference_enabled === "Yes"
              ? values.intra_state_id
              : null,
          inter_state_id:
            values.default_preference_enabled === "Yes"
              ? values.inter_state_id
              : null,
        });
        if (values.default_preference_enabled === "No") {
          formik.setFieldValue("intra_state_id", "");
          formik.setFieldTouched("intra_state_id", false, false);

          formik.setFieldValue("inter_state_id", "");
          formik.setFieldTouched("inter_state_id", false, false);
        }
      }
    },
    onReset: () => {
      // setEdit(false);
    },
  });

  const resetForm = () => {
    formik.resetForm();
    formik.setFieldValue("intra_state_id", defaultFormValues.intra_state_id);
    formik.setFieldValue("inter_state_id", defaultFormValues.inter_state_id);
  };

  return (
    <>
      <GstSettingsContainer heading="Default Tax Preferences">
        <form
          action=""
          name="gst-settings-form"
          id="gst-settings-form"
          className="gst-settings-form w-100"
          onSubmit={formik.handleSubmit}
        >
          <div className="fields-wrapper">
            <section className="form-fields-section">
              <div className="form-fields">
                <div className="row g-0">
                  <div className="col">
                    <span className="gst-enable">
                      Enable default tax preferences
                    </span>
                    <RadioButtonGroup
                      name="default_preference_enabled"
                      id="default_preference_enabled"
                      valuesArray={[
                        { label: "Yes", value: "Yes" },
                        { label: "No", value: "No" },
                      ]}
                      value={formik.values.default_preference_enabled}
                      icon={
                        <span>
                          <RadioButtonUncheckedIcon />
                        </span>
                      }
                      checkedIcon={
                        <span>
                          <RadioButtonCheckedIcon className="radio-btn-tds" />
                        </span>
                      }
                      isDisabled={
                        !(
                          gstPermission.includes("Create") &&
                          gstPermission.includes("Update") &&
                          gstPermission.includes("Delete") &&
                          gstPermission.includes("Read")
                        )
                      }
                      formik={formik}
                      // onClick={defaultEnabledChangeHandler}
                    />
                  </div>
                </div>
                {formik.values.default_preference_enabled === "Yes" && (
                  <>
                    <div className="row g-0">
                      <div className="col ">
                        <label htmlFor="gst" className="required">
                          Intra State Tax Rate
                        </label>
                        <span className="sub-label">(Within State)</span>
                        <Select
                          name="intra_state_id"
                          id="intra_state_id"
                          // menuPlacement={"top"}
                          menuShouldScrollIntoView={true}
                          options={intraStateTaxRates}
                          className={`form-select custom-select fiscal-year-select mt-0 ${
                            formik.errors.intra_state_id &&
                            formik.touched.intra_state_id
                              ? "error"
                              : ""
                          }`}
                          value={formik.values.intra_state_id}
                          placeholder="Choose Intra State Tax Rate"
                          onChange={(e) =>
                            formik.setFieldValue("intra_state_id", e)
                          }
                          styles={customSelectStyle}
                          isSearchable={false}
                          maxMenuHeight={250}
                        />
                        {formik.errors.intra_state_id &&
                          formik.touched.intra_state_id && (
                            <span className="error">
                              {formik.errors.intra_state_id}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="row g-0">
                      <div className="col ">
                        <label htmlFor="gst" className="required">
                          Inter State Tax Rate
                        </label>
                        <span className="sub-label">(Outside State)</span>
                        <Select
                          name="inter_state_id"
                          id="inter_state_id"
                          // menuPlacement={"top"}
                          menuShouldScrollIntoView={true}
                          options={interStateTaxRates}
                          className={`form-select custom-select fiscal-year-select mt-0 ${
                            formik.errors.inter_state_id &&
                            formik.touched.inter_state_id
                              ? "error"
                              : ""
                          }`}
                          value={formik.values.inter_state_id}
                          placeholder="Choose Inter State Tax Rate"
                          onChange={(e) =>
                            formik.setFieldValue("inter_state_id", e)
                          }
                          styles={customSelectStyle}
                          isSearchable={false}
                          maxMenuHeight={250}
                        />
                        {formik.errors.inter_state_id &&
                          formik.touched.inter_state_id && (
                            <span className="error">
                              {formik.errors.inter_state_id}
                            </span>
                          )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </section>
          </div>
          <section className="form-button-section">
            <div
              className="button-fields form-button-wrapper"
              id="form-btn-gst-profile"
            >
              <button
                className="save-button"
                type="submit"
                disabled={
                  formik.isSubmitting ||
                  !(
                    gstPermission.includes("Create") &&
                    gstPermission.includes("Update") &&
                    gstPermission.includes("Delete") &&
                    gstPermission.includes("Read")
                  )
                }
              >
                Save
              </button>
              <button
                className="text-decoration-none cancel-button close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                type="button"
                onClick={resetForm}
              >
                Cancel
              </button>
            </div>
          </section>
        </form>
      </GstSettingsContainer>
    </>
  );
};

export default DefaultPreference;
