import Edit from "../../../../common/components/kebabMenu/Edit";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { PlanBillingSideMenuModal } from "../../planCalendarBillingSideMenu/PlanBillingSideMenuModal";

export type ListActionProps = {
  value: any;
  getPlanSpecificBilling: () => void
};

function ListActions(props: ListActionProps) {
  const { subscriptionRolePermission } = usePermissionHandler();
  return (
    <>
      {
        subscriptionRolePermission.includes("Update") ? (
          <KebabMenu>
            <li>
              <PlanBillingSideMenuModal id={props.value.id} refreshPlanSpecificList={props.getPlanSpecificBilling} />
            </li>
          </KebabMenu>
        ) : null
      }
    </>
  );
}

export default ListActions;
