import { SettingsIcon } from "../../../common/components";
import { SideModal } from "../../../common/components/sideModal/SideModal";
import { ModalContext } from "../../../common/components/sideModal/sideModalContext";
import { colors } from "../../../constants/colors";
import { ConfigureProrationForm } from "./ConfigureProrationForm";

export const ConfigureProrationFormModal = () => {
  return (
    <SideModal
      modalTitle={"Configure Proration"}
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return <ConfigureProrationForm handleClose={onCloseModal}/>
          }}
        </ModalContext.Consumer>
      }
      buttonContent={
        <span>
          <SettingsIcon color={colors.booger} margin="0px 5px 0px 0px" />
          Configure Proration
        </span>
      }
      buttonClass={
        "no-appearance cancel-btn configure-proration-setting-btn"
      }
    />
  );
};
