import "./tableInfo.css";
import info from "../../../../../assets/images/email-info-msg-icon.svg";
import { Grid } from "@mui/material";
import { ImpactDetailsInfoModal } from "./ImpactDetailsInfoModal";
import { CancelChangesModal } from "./CancelChangesModal";

export default function ImpactDetailsInfo(props: any) {
  return (
    <>
      <Grid
        container
        className="subscription-impact-details-container"
      >
        <Grid item xs="auto">
          <Grid container>
            <Grid item xs="auto">
              <img src={info} id="message-img" alt="clone-alt" />
            </Grid>
            <Grid item xs="auto">
              <div className="m-l-10">
                This subscription is scheduled for change on{" "}
                {
                  props.scheduledSubscriptionData.edit_scheduler
                    ?.scheduled_date
                }
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs="auto">
          <ImpactDetailsInfoModal
            scheduledSubscriptionData={props.scheduledSubscriptionData}
          />
        </Grid>
        <Grid item xs="auto">
          <CancelChangesModal
            type="subscriptionCancel"
            subStatus={props.subscriptionStatus}
            schedulerId={props.scheduledSubscriptionData.edit_scheduler?.id}
            subscriptionSchedulerDetails={props.subscriptionSchedulerDetails}
          />
        </Grid>
      </Grid>
    </>
  );
}
