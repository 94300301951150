import { EditIcon, PlusIcon } from "../../../../../common/components";
import { SideModal } from "../../../../../common/components/sideModal/SideModal";
import { AlertModal } from "../../../../../common/components/alertModal/alertModal";
import { ModalContext } from "../../../../../common/components/sideModal/sideModalContext";
import { colors } from "../../../../../constants/colors";
import { TaxExemptionForm } from "./TaxExemptionForm";
import "./TaxExemption.css";
import { TaxExemptionFormValue } from "../../../../../../types";

type Props = {
  isEdit: boolean;
  editId?: number;
  exemptionDetails?: TaxExemptionFormValue;
  isAssociated?: boolean;
  refreshList: () => void;
};
export const TaxExemptionFormModal = (props: Props) => {
  return (
    <SideModal
      modalTitle={props.isEdit ? "Edit Tax Exemption" : "New Tax Exemption"}
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <TaxExemptionForm
                handleClose={onCloseModal}
                isEdit={props.isEdit}
                editId={props.editId}
                exemptionDetails={props.exemptionDetails}
                refreshList={props.refreshList}
                isAssociated={props.isAssociated}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={
        props.isEdit ? (
          <span
            className="dropdown-item d-flex align-items-center "
            style={{ textDecoration: "none" }}
          >
            <EditIcon margin="0px 5px 0px 0px" color={colors.battleshipGrey} />{" "}
            Edit
          </span>
        ) : (
          <span style={{ color: colors.white, fontWeight: "600" }}>
            <PlusIcon color={colors.white} /> New Tax Exemption{" "}
          </span>
        )
      }
      buttonClass={
        props.isEdit
          ? "no-appearance w-100 p-0"
          : "btn green-button no-appearance"
      }
      wrapperClass=""
    />
  );
};
