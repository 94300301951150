export const deleteInitialData = {
    actionType: '',
    id: -1,
    planName: '',
    planExistsSubscription: false,
  }

export const planDetailInitialData = {
  description: '',
  external_name: '',
  id: 0,
  item_detail: [],
  organization_id: 0,
  organization_name: '',
  plan_status: '',
  currency_code: '',
  pricings: null
}

export const defaultFrequencyCol = {
      frequency: "",
      pricing_model: "",
      invoicing_cycle: "",
      trial: false,
      trial_count: 0,
      trial_frequency: '',
      isPriceSet: false,
      item_price: [],
      pricing_id: 0,
}
