import React, {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  ObjectType,
  TaxGroupValues,
  TaxRateValues,
} from "../../../../../../types";
import { useFormik } from "formik";
import { taxGroupValidation } from "./TaxGroupValidation";
import {
  createTaxGroup,
  editTaxGroup,
  taxGroupBackendValidation,
  taxRateSelector,
  taxesList,
  taxesRateList,
} from "../../taxRateSlice";
import useCommonData from "../../../../../hooks/useCommon";
import { setLoaderState } from "../../../../../common/commonSlice";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../../../app/hooks";
import { FourDotIcon } from "../../../../../../assets/images";
import Checkbox from "@mui/material/Checkbox";

type Props = {
  taxGroupId: number;
  // taxIdReset: () => void;
  organizationId: number;
  handleModalClose: () => void;
  // isAccountDeleted: boolean;
  data?: TaxRateValues;
};
const TaxGroupForm: ForwardRefRenderFunction<any, Props> = (props, ref) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const taxRates = useAppSelector(taxRateSelector);
  const [initialFormikTaxes, setInitialFormikTaxes] = useState<number[]>([]);
  const [errorTaxIds, setErrorTaxIds] = useState<number[]>([]);
  const [backendError, setBackendError] = useState<{ [key: string]: any }>({});
  const [isTaxFormChanged, setIsTaxFormChanged] = useState(false);
  const editName = useRef(props.data?.name);

  const initialTaxValues = {
    name: "",
    tax_item_ids: initialFormikTaxes,
    tax_objects: taxRates,
    is_checked: true,
  };
  const editTaxValues = {
    name: props.data?.name ? props.data?.name : "",
    tax_item_ids: props.data?.tax_ids ? props.data?.tax_ids : [],
    tax_objects: taxRates,
    is_checked: false,
  };

  const formik = useFormik({
    initialValues:
      props.taxGroupId && props.data ? editTaxValues : initialTaxValues,
    enableReinitialize: true,
    validationSchema: taxGroupValidation(props.taxGroupId, isTaxFormChanged),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values) => {
      $("#form-btn-tax").addClass("customer-form-section-disable");
      let { validate, backendErrorValue } = await validateTaxGroupNameOrCombination(false);
      if (validate && Object.keys(backendErrorValue).length === 0) {
        let { tax_objects, ...taxGroupData } = values;
        dispatch(setLoaderState(true));
        const responseAction = !props.taxGroupId
          ? await dispatch(
              createTaxGroup({
                orgId: currentUserInfo.organization_id,
                values: taxGroupData,
              })
            )
          : await dispatch(
              editTaxGroup({
                taxGroupId: props.taxGroupId,
                orgId: currentUserInfo.organization_id,
                values: taxGroupData,
              })
            );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            let message = props.taxGroupId
              ? "Tax Group information is saved!"
              : "Tax group created successfully!";
            toast.success(message, {
              toastId: "tax-group-success",
              closeButton: false,
              position: "top-center",
            });
          }
        }
        handleCancel();
        dispatch(setLoaderState(false));
      } else {
        $("#form-btn-tax").removeClass("customer-form-section-disable");
      }
    },
  });

  useImperativeHandle(
    ref,
    () => ({
      resetForm: resetForm,
    }),
    [formik]
  );

  useEffect(() => {
    if (formik.errors.tax_item_ids) {
      setErrorTaxIds(formik.values.tax_item_ids);
    } else {
      setErrorTaxIds([]);
    }
  }, [formik.errors]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (
      ((editName.current !== formik.values.name && props.taxGroupId) ||
        !props.taxGroupId) &&
      formik.values.name.length > 0
    ) {
      validateTaxGroupNameOrCombination(true, signal);
    }
    return () => {
      controller.abort();
    };
  }, [formik.values.name]);

  useEffect(() => {
    if (props.taxGroupId && props.data?.tax_ids) {
      let isGroupIdsEqual = true;
      if (props.data?.tax_ids.length !== formik.values.tax_item_ids.length) {
        isGroupIdsEqual = false; // If the lengths are different, the arrays are not equal.
      }

      for (let i = 0; i < props.data?.tax_ids.length; i++) {
        if (props.data?.tax_ids[i] !== formik.values.tax_item_ids[i]) {
          isGroupIdsEqual = false; // If any elements don't match, the arrays are not equal.
        }
      }
      if (props.data?.name === formik.values.name && isGroupIdsEqual) {
        setIsTaxFormChanged(false);
      } else {
        setIsTaxFormChanged(true);
      }
    }
  }, [formik.values.name, formik.values.tax_item_ids]);

  const validateTaxGroupNameOrCombination = async (
    titleValidation: boolean,
    signal?: AbortSignal
  ) => {
    let isValid = true;
    let groupName = formik.values.name;
    let taxIds = formik.values.tax_item_ids;
    const responseAction = await dispatch(
      taxGroupBackendValidation({
        titleValidation: titleValidation,
        groupName: groupName,
        taxIds: titleValidation ? [] : taxIds,
        orgId: currentUserInfo.organization_id,
        signal: signal,
      })
    );
    let backendErrorValue = backendError
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (titleValidation) {
          if (!response.is_valid_group) {
            setBackendError({
              ...backendError,
              name: "Tax Group Name Already Exists",
            });
            backendErrorValue = {
              ...backendError,
              name: "Tax Group Name Already Exists",
            };
          }
          else {
            const { name, ...rest } = backendError;
            setBackendError(rest);
            backendErrorValue = rest;
          }
        } else {
          if (!response.is_valid_group) {
            setBackendError({
              ...backendError,
              tax_item_ids: "Duplicate Tax Combination",
            });
            backendErrorValue = backendErrorValue = {
              ...backendError,
              tax_item_ids: "Duplicate Tax Combination",
            };
          }
          else {
            const { tax_item_ids, ...rest } = backendError;
            setBackendError(rest);
            backendErrorValue = rest;
          }
        }
        isValid = response.is_valid_group;
      }
    }
    const validate = isValid;
    return { validate, backendErrorValue };
  };

  const handleChange = (e: any) => {
    formik.setFieldValue("is_checked", e.target.checked);
  };

  const handleCancel = async () => {
    formik.resetForm();
    setErrorTaxIds([]);
    props.handleModalClose();
    setBackendError({});
    $("#form-btn-tax").removeClass("customer-form-section-disable");
  };

  const resetForm = () => {
    formik.resetForm();
    setErrorTaxIds([]);
  };
  return (
    <>
      <div id="taxCreateModal" className="add-tax-form">
        <form
          className={`create-user-form-wrap create-settings-form-wrap tax-group-form`}
          onSubmit={formik.handleSubmit}
        >
          <div className="form-outline mb-4">
            <label className="form-label" htmlFor="name">
              Tax Group Name<span className="mandatory">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formik.values.name}
              className={
                formik.errors.name || backendError.name
                  ? "form-control error"
                  : "form-control"
              }
              placeholder="Enter tax group name"
              onChange={formik.handleChange}
              maxLength={12}
            />
            <span className="error">
              {formik.errors.name || backendError.name}
            </span>
          </div>
          <div className="form-outline mb-4">
            <label className="form-label" htmlFor="tax_item_ids">
              Associate Taxes<span className="mandatory">*</span>
            </label>
            <br />
            <ul className="col-list">
              {taxRates?.map((tax: any, index: number) => {
                return (
                  <li
                    className={
                      ""
                      // formik.errors.tax_item_ids && errorTaxIds.includes(tax.id)
                      //   ? "error"
                      //   : ""
                    }
                    key={`custom-column-${index}`}
                  >
                    <div className="d-flex column-item w-100 align-items-center">
                      <div className="form-check d-flex align-items-center">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`check${index}`}
                          name={`tax_item_ids`}
                          value={tax.id}
                          checked={
                            Array.isArray(formik.values?.tax_item_ids)
                              ? formik.values?.tax_item_ids?.includes(tax.id)
                              : false
                          }
                          onChange={(e) => {
                            let assocArray: number[] = [];
                            if (
                              formik.values &&
                              formik.values.tax_item_ids &&
                              Array.isArray(formik.values.tax_item_ids)
                            ) {
                              assocArray = [...formik.values.tax_item_ids];
                              if (
                                e.target.checked &&
                                !assocArray.includes(Number(e.target.value))
                              )
                                assocArray.push(Number(e.target.value));
                              else if (
                                e.target.checked === false &&
                                assocArray.includes(Number(e.target.value))
                              )
                                assocArray = assocArray.filter(
                                  (taxId) => taxId !== tax.id
                                );
                              formik.setFieldValue("tax_item_ids", [
                                ...assocArray,
                              ]);
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`check${index}`}
                        >
                          {`${tax.name} (${tax.rate}%) `}
                        </label>
                      </div>
                    </div>
                    <img
                      className="dot-icon"
                      src={FourDotIcon}
                      alt="six-dots"
                      width="10"
                    />
                  </li>
                );
              })}
            </ul>
            <span className="error">
              {formik.errors.tax_item_ids || backendError.tax_item_ids}
            </span>
          </div>
          {props.taxGroupId && isTaxFormChanged ? (
            <>
              <p className="tds-terms-and-condition">Terms and Conditions</p>
              <div className="tds-form-input-wrapper tds-checkbox-wrapper">
                <Checkbox
                  name="c_i_f_access"
                  id="c_i_f_access"
                  className="form-check-input tds-terms-checkbox"
                  onChange={handleChange}
                  checked={formik.values.is_checked}
                />
                <span className="terms-and-condition-label">
                  I understand that updating the tax will mark the existing tax
                  inactive, create a new tax. New Tax will be applicable only
                  for future transactions.
                </span>
              </div>
              <span className="error">{formik.errors.is_checked}</span>
            </>
          ) : null}
          <div id="form-btn-tax">
            <button
              className="btn btn-block fa-lg invite-btn save-butn mb-4"
              type="submit"
              disabled={false} //!isSelectTax}
            >
              {props.taxGroupId > 0 ? "Update" : "Save"}
            </button>
            <button
              className="btn btn-block fa-lg cancel-btn mb-4"
              type="button"
              onClick={() => {
                // $(".tax-close-btn").click();
                handleCancel();
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default React.forwardRef(TaxGroupForm);
