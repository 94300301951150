import React, { useEffect, useState } from "react";
import { Drawer } from "@mui/material";
import CloseButton from "../button/CloseButton";
import { ModalContext } from "./sideModalContext";
import useToast from "../../../hooks/useToast";
import "../../../../index.css";
import "./SideModal.css";
import CustomTooltips from "../Tooltip";
import { AddTagIcon } from "../../../../assets/images";

export type ReusableModalProps = {
  buttonContent: React.ReactNode;
  modalContent: React.ReactNode;
  modalTitleComponent?: React.ReactNode;
  modalTitle: string;
  wrapperClass?: string;
  shouldGreyOut?: boolean;
  drawerWrapperClass?: string;
  buttonClass?: string;
  onOpenFunction?: () => void;
  onCloseFunction?: () => void;
  disabledActionFunction?: () => void;
  isButtonDisabled?: boolean;
  tooltipMessage?: string;
  tooltipPlacement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top"
    | undefined;
  isAddIconEnabled?: boolean;
};

export const SideModal = ({
  buttonContent,
  modalContent,
  modalTitleComponent,
  modalTitle,
  wrapperClass,
  drawerWrapperClass,
  buttonClass,
  onOpenFunction,
  onCloseFunction,
  disabledActionFunction,
  isButtonDisabled,
  tooltipMessage,
  tooltipPlacement,
  shouldGreyOut,
  isAddIconEnabled,
}: ReusableModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { clearToast } = useToast();

  const openModal = () => {
    setIsOpen(true);
    // document.body.style.overflow = "hidden";
    onOpenFunction && onOpenFunction();
    ($(".dropdown-ctrl") as any).dropdown("hide");
    clearToast("error");
  };

  const closeModal = () => {
    setIsOpen(false);
    // document.body.style.overflow = 'auto';
    onCloseFunction && onCloseFunction();
  };

  const saveClose = () => {
    setIsOpen(false);
  };
  
  return (
    <ModalContext.Provider
      value={{ onCloseModal: closeModal, onSaveClose: saveClose }}
    >
      <div className={`${wrapperClass ? wrapperClass : ""}`}>
        <div className="reusable-modal-btn">
          {isAddIconEnabled ? (
            <img
              src={AddTagIcon}
              alt="add icon"
              // onClick={(e) => e.preventDefault()}
              className={`${
                isButtonDisabled === true && shouldGreyOut !== false
                  ? "is-disabled-side-modal-btn"
                  : "add-icon-pointer"
              } ${buttonClass}`}
            />
          ) : null}
          <button
            type="button"
            disabled={isButtonDisabled && shouldGreyOut  ? true : false}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              if (isButtonDisabled === true) {
                e.preventDefault();
                disabledActionFunction &&
                  !tooltipMessage &&
                  disabledActionFunction();
              } else {
                e.preventDefault();
                openModal();
              }
            }}
            className={`${
              isButtonDisabled === true && shouldGreyOut !== false
                ? "is-disabled-side-modal-btn"
                : ""
            } ${buttonClass}`}
          >
            {tooltipMessage ? (
              <CustomTooltips
                placement={tooltipPlacement || "bottom"}
                title={tooltipMessage}
              >
                {buttonContent}
              </CustomTooltips>
            ) : (
              buttonContent
            )}
          </button>
        </div>
        <Drawer
          anchor="right"
          open={isOpen}
          onClose={closeModal}
          className="drawer"
        >
          <div
            className={
              drawerWrapperClass
                ? `${drawerWrapperClass} drawer-content`
                : "drawer-content"
            }
            // className="drawer-content"
            id="sidemodal-content"
          >
            <span className="modal-close-btn">
              <CloseButton closeModalHandler={closeModal} />
            </span>
            <div className="side-modal-title ">
              <h2>{modalTitle}</h2>
              <div>{modalTitleComponent}</div>
            </div>
            <div className="modal-body mt-4 " id="reusableSideModalBody">
              {modalContent}
            </div>
          </div>
        </Drawer>
      </div>
    </ModalContext.Provider>
  );
};
