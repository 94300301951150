import React from "react";
import "../styles/EmptyList.css";
import { PlusImage } from "../../../../src/assets/images";
import { useNavigate } from "react-router-dom";
import { ObjectType } from "../../../types";

type Props = {
  heading: string;
  subheading: string;
  list: string;
  buttonText: string;
  alt: string;
  navigateLink: string;
  navigateState?: ObjectType;
  isCrateAccess: boolean;
  icon: string;
  transactionLimit?: boolean;
  navigateType?: string;
  onClick?: () => void;
};

const EmptyList = (props: Props) => {
  const navigate = useNavigate();

  return (
    <div className="create-invoice-container align-center">
      <img
        className="preview-img"
        src={props.icon}
        alt={props.alt}
        width="108"
        height="120"
      />
      <h3>{props.heading}</h3>
      <p>{props.subheading}</p>
      {props.isCrateAccess ? (
        <button
          className="btn create-invoice-button green-button"
          type="button"
          onClick={() => {
            if (props.navigateType === "modal") {
              if (!props.transactionLimit && props.onClick) {
                props.onClick();
              }
            } else {
              props.transactionLimit
                ? $("#transactionLimitModal").modal("show")
                : navigate(
                    props.navigateLink,
                    props.navigateState ? props.navigateState : {}
                  );
            }
          }}
        >
          <img src={PlusImage} className="Group" alt="plusImage"></img>
          <span className="create-invoice-button-text green-button-text">
            {props.buttonText}
          </span>
        </button>
      ) : null}
    </div>
  );
};
export default EmptyList;
