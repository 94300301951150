import { OptionType } from "../../types";

/**
 * Status filters for filter in different modules
 */
export const statusFilters = [
  { id: 1, name: "Open" },
  { id: 2, name: "Closed" },
  { id: 3, name: "Overdue" },
  { id: 4, name: "Partially Paid" },
  { id: 5, name: "Draft" },
  { id: 6, name: "Excess" },
  { id: 7, name: "Allocated" },
  { id: 8, name: "Published" },
  { id: 9, name: "Partial Write Off" },
  { id: 10, name: "Full Write Off" },
  { id: 11, name: "Active" },
  { id: 12, name: "Expired" },
  { id: 13, name: "Stopped" },
  { id: 14, name: "Cancelled" },
  { id: 15, name: "Inactive" },
  { id: 16, name: "Future" },
  { id: 17, name: "In Trial" },
  { id: 18, name: "Non Renewing" },
  { id: 19, name: "Refund" },
  { id: 20, name: "Void" },
  { id: 21, name: "Exhausted" }
];
export const monthOptions = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];
export const fiscalYearOptions: OptionType[] = [
  { value: "January-December", label: "January-December" },
  { value: "February-January", label: "February-January" },
  { value: "March-February", label: "March-February" },
  { value: "April-March", label: "April-March" },
  { value: "May-April", label: "May-April" },
  { value: "June-May", label: "June-May" },
  { value: "July-June", label: "July-June" },
  { value: "August-July", label: "August-July" },
  { value: "September-August", label: "September-August" },
  { value: "October-September", label: "October-September" },
  { value: "November-October", label: "November-October" },
  { value: "December-November", label: "December-November" },
];
export const searchModules = [
  { value: "global_search", label: "All" },
  { value: "customers", label: "Customer" },
  { value: "invoices", label: "Invoice" },
  { value: "credit_notes", label: "Credit Note" },
  { value: "payment_received", label: "Payment Received" },
  { value: "vendors", label: "Vendor" },
  { value: "bills", label: "Bill" },
  { value: "debit_notes", label: "Debit Note" },
  { value: "payment_made", label: "Payment Made" },
  { value: "journal_voucher", label: "Journal Entry" },
  // { value: "chart_of_accounts", label: "Chart of Accounts" },
];

export const ITEM_TYPES = {
  service: "Service",
  goods: "Goods",
};

export const itemType = [
  { id: 1, name: ITEM_TYPES.service },
  { id: 2, name: ITEM_TYPES.goods },
];
export const TAX_TYPES = {
  PAYABLE: "payable",
  RECEIVABLE: "receivable",
};
export const TRANSACTION_TYPES = {
  SALES: "SALES",
  PURCHASE: "PURCHASE",
};
export const transactionCategory = [
  { id: 1, name: "Sales" },
  { id: 2, name: "Purchase" },
];

export const EXCEPTION_TYPES = {
  TAXABLE: "TAXABLE",
  NON_TAXABLE: "NON_TAXABLE",
  OUT_OF_SCOPE: "OUT_OF_SCOPE",
  NON_GST_SUPPLY: "NON_GST_SUPPLY",
};
/**
 * Error codes for unhandled errors in backend
 
 * Error code ranges
  550-599: Subscription
  600-649: Dashboard
  650-699: Sales
  700-749: Purchase
  750-799: Accounts
  800-849: Reports
  850-899: Users and Roles
  900-949: Org hierarchy
  950-999: Settings
  */
export const errorCodes = [
  {
    error_code: 601,
    error_name: "Total Payables",
    error_module: "",
    status_code: 500,
  },
  {
    error_code: 602,
    error_name: "Total Receivables",
    error_module: "",
    status_code: 500,
  },
  {
    error_code: 603,
    error_name: "Total Income",
    error_module: "",
    status_code: 500,
  },
  {
    error_code: 604,
    error_name: "Total Expense",
    error_module: "",
    status_code: 500,
  },
  {
    error_code: 651,
    error_name: "Invalid payload in customers",
    error_module: "customers",
    status_code: 422,
  },
  {
    error_code: 652,
    error_name: "Invalid payload in invoice",
    error_module: "invoice",
    status_code: 422,
  },
  {
    error_code: 653,
    error_name: "Invalid payload in credit-note",
    error_module: "credit-note",
    status_code: 422,
  },
  {
    error_code: 654,
    error_name: "Invalid payload in payment-received",
    error_module: "payment-received",
    status_code: 422,
  },
  {
    error_code: 655,
    error_name: "Internal server error in customers",
    error_module: "customers",
    status_code: 500,
  },
  {
    error_code: 656,
    error_name: "Internal server error invoice",
    error_module: "invoice",
    status_code: 500,
  },
  {
    error_code: 657,
    error_name: "Internal server error in credit-note",
    error_module: "credit-note",
    status_code: 500,
  },
  {
    error_code: 658,
    error_name: "Internal server error in payment-received",
    error_module: "payment-received",
    status_code: 500,
  },
  {
    error_code: 659,
    error_name: "Invalid payload in recurring invoice",
    error_module: "recurring-inv",
    status_code: 422,
  },
  {
    error_code: 660,
    error_name: "Internal server error recurring invoice",
    error_module: "recurring-inv",
    status_code: 500,
  },
  {
    error_code: 701,
    error_name: "Invalid payload in vendors",
    error_module: "vendors",
    status_code: 422,
  },
  {
    error_code: 702,
    error_name: "Invalid payload in bill",
    error_module: "bill",
    status_code: 422,
  },
  {
    error_code: 703,
    error_name: "Invalid payload in debit-note",
    error_module: "debit-note",
    status_code: 422,
  },
  {
    error_code: 704,
    error_name: "Invalid payload in payment-made",
    error_module: "payment-made",
    status_code: 422,
  },
  {
    error_code: 705,
    error_name: "Internal server error in vendors",
    error_module: "vendors",
    status_code: 500,
  },
  {
    error_code: 706,
    error_name: "Internal server error bill",
    error_module: "bill",
    status_code: 500,
  },
  {
    error_code: 707,
    error_name: "Internal server error in debit-note",
    error_module: "debit-note",
    status_code: 500,
  },
  {
    error_code: 708,
    error_name: "Internal server error in payment-made",
    error_module: "payment-made",
    status_code: 500,
  },
  {
    error_code: 751,
    error_name: "Invalid payload in accounts",
    error_module: "accounts",
    status_code: 422,
  },
  {
    error_code: 752,
    error_name: "Invalid payload in journal-voucher",
    error_module: "journal-voucher",
    status_code: 422,
  },
  {
    error_code: 753,
    error_name: "Internal server error in accounts",
    error_module: "accounts",
    status_code: 500,
  },
  {
    error_code: 754,
    error_name: "Internal server error in journal-voucher",
    error_module: "journal-voucher",
    status_code: 500,
  },
  {
    error_code: 801,
    error_name: "Invalid payload in soa",
    error_module: "soa",
    status_code: 422,
  },
  {
    error_code: 802,
    error_name: "Invalid payload in trial-balance",
    error_module: "trial-balance",
    status_code: 422,
  },
  {
    error_code: 803,
    error_name: "Invalid payload in profit-and-loss",
    error_module: "profit-and-loss",
    status_code: 422,
  },
  {
    error_code: 804,
    error_name: "Invalid payload in balance-sheet",
    error_module: "balance-sheet",
    status_code: 422,
  },
  {
    error_code: 805,
    error_name: "Invalid payload in account-transactions",
    error_module: "account-transactions",
    status_code: 422,
  },
  {
    error_code: 806,
    error_name: "Invalid payload in day-book",
    error_module: "day-book",
    status_code: 422,
  },
  {
    error_code: 807,
    error_name: "Internal server error in soa",
    error_module: "soa",
    status_code: 500,
  },
  {
    error_code: 808,
    error_name: "Internal server error in trial-balance",
    error_module: "trial-balance",
    status_code: 500,
  },
  {
    error_code: 809,
    error_name: "Internal server error in profit-and-loss",
    error_module: "profit-and-loss",
    status_code: 500,
  },
  {
    error_code: 810,
    error_name: "Internal server error in balance-sheet",
    error_module: "balance-sheet",
    status_code: 500,
  },
  {
    error_code: 811,
    error_name: "Internal server error in account-transactions",
    error_module: "account-transactions",
    status_code: 500,
  },
  {
    error_code: 812,
    error_name: "Internal server error in day-book",
    error_module: "day-book",
    status_code: 500,
  },
  {
    error_code: 851,
    error_name: "Invalid payload in users and roles",
    error_module: "users",
    status_code: 422,
  },
  {
    error_code: 852,
    error_name: "Internal server error in users and roles",
    error_module: "users",
    status_code: 500,
  },
  {
    error_code: 853,
    error_name: "Invalid payload in users and roles",
    error_module: "roles",
    status_code: 422,
  },
  {
    error_code: 854,
    error_name: "Internal server error in users and roles",
    error_module: "roles",
    status_code: 500,
  },
  {
    error_code: 901,
    error_name: "Invalid payload in organization hierarchy",
    error_module: "hierarchy",
    status_code: 422,
  },
  {
    error_code: 902,
    error_name: "Internal server error in organization hierarchy",
    error_module: "hierarchy",
    status_code: 500,
  },
  {
    error_code: 951,
    error_name: "Invalid payload in organization-profile",
    error_module: "organization-profile",
    status_code: 422,
  },
  {
    error_code: 952,
    error_name: "Invalid payload in tax-configuration",
    error_module: "tax-configuration",
    status_code: 422,
  },
  {
    error_code: 953,
    error_name: "Invalid payload in custom-prefix",
    error_module: "custom-prefix",
    status_code: 422,
  },
  {
    error_code: 954,
    error_name: "Invalid payload in currency-configuration",
    error_module: "currency-configuration",
    status_code: 422,
  },
  {
    error_code: 955,
    error_name: "Internal server error in organization-profile",
    error_module: "organization-profile",
    status_code: 500,
  },
  {
    error_code: 956,
    error_name: "Internal server error tax-configuration",
    error_module: "tax-configuration",
    status_code: 500,
  },
  {
    error_code: 957,
    error_name: "Internal server error in custom-prefix",
    error_module: "custom-prefix",
    status_code: 500,
  },
  {
    error_code: 958,
    error_name: "Internal server error in currency-configuration",
    error_module: "currency-configuration",
    status_code: 500,
  },
  {
    error_code: 551,
    error_name: "Invalid payload in plan",
    error_module: "plan",
    status_code: 422,
  },
  {
    error_code: 552,
    error_name: "Internal server error plan",
    error_module: "plan",
    status_code: 500,
  },
  {
    error_code: 553,
    error_name: "Invalid payload in addon",
    error_module: "addons",
    status_code: 422,
  },
  {
    error_code: 554,
    error_name: "Internal server error addon",
    error_module: "addons",
    status_code: 500,
  },
  {
    error_code: 555,
    error_name: "Invalid payload in coupon",
    error_module: "coupons",
    status_code: 422,
  },
  {
    error_code: 556,
    error_name: "Internal server error coupon",
    error_module: "coupons",
    status_code: 500,
  },
  {
    error_code: 557,
    error_name: "Invalid payload in subscriptions",
    error_module: "subscriptions",
    status_code: 422,
  },
  {
    error_code: 558,
    error_name: "Internal server error subscriptions",
    error_module: "subscriptions",
    status_code: 500,
  },
];

export const conversionMinDate = "01-01-2000";

export const noAccessErrorCodes = {
  invoiceEdit: 3015,
  billEdit: 4012,
  CNEdit: 3513,
  DNEdit: 4513,
  PREdit: 8011,
  PMEdit: 2030,
  customerEdit: 7012,
  vendorEdit: 6014,
  JVEdit: 9010,
  invoiceDetail: 3045,
  billDetail: 4041,
  CNDetail: 3541,
  DNDetail: 4541,
  PRDetail: 8031,
  PMDetail: 2021,
  customerDetail: 7034,
  vendorDetail: 6041,
  JVDetail: 9032,
};

export const sourceTaxTypes = {
  TDS: "TDS",
  TCS: "TCS",
  NO_SOURCE_TAX: "NO_TAX",
};

export const tdsApplyLevel = {
  TRANSACTION_LEVEL: "TRANSACTION_LEVEL",
  LINE_ITEM_LEVEL: "LINE_LEVEL",
  NO_TDS: "NO_TDS",
};

export const invoiceStatus = {
  open: "Open",
  closed: "Closed",
  overdue: "Overdue",
  partiallyPaid: "Partially Paid",
  draft: "Draft",
};

export const creditNoteStatus = {
  open: "Open",
  closed: "Closed",
  void: "Void",
  draft: "Draft",
};
export const defaultPermissions = 4; //Read, Create, Edit, Delete

export const decimalFormatValues = [
  {
    decimalPlaces: 0,
    value: {
      "12,34,567": "##,##,##,###",
      "1,234,567": "###,###,###",
      "1.234.567": "###.###.###",
      "1 234 567": "### ### ###",
    },
  },
  {
    decimalPlaces: 2,
    value: {
      "12,34,567.89": "##,##,##,###.##",
      "1,234,567.89": "###,###,###.##",
      "1.234.567,89": "###.###.###,##",
      "1 234 567, 89": "### ### ###,##",
    },
  },
  {
    decimalPlaces: 3,
    value: {
      "12,34,567.899": "##,##,##,###.###",
      "1,234,567.899": "###,###,###.###",
      "1.234.567,899": "###.###.###,###",
      "1 234 567, 899": "### ### ###,###",
    },
  },
];

export const billStatus = {
  open: "Open",
  closed: "Closed",
  overdue: "Overdue",
  partiallyPaid: "Partially Paid",
  draft: "Draft",
};

export const gstRegistrationTypes = {
  overseas: "Overseas",
  sez: "Special Economic Zone(SEZ)",
  sezDeveloper: "SEZ Developer",
  composition: "GST registered - composition",
  deemedExp: "Deemed exports - EOUs, STPs, EHTPs",
};

export const TAX_SYSTEM = {
  INDIAN_TAX_SYSTEM: "INDIAN_TAX_SYSTEM",
  NORMAL_TAX_SYSTEM: "NORMAL_TAX_SYSTEM",
};

export const SPLIT_TYPES = {
  split_equally: "Split Equally",
  specific_amount: "Specific Amount",
};

export const SPLIT_TYPES_OPTIONS = [
  {
    label: SPLIT_TYPES.split_equally,
    value: SPLIT_TYPES.split_equally,
  },
  {
    label: SPLIT_TYPES.specific_amount,
    value: SPLIT_TYPES.specific_amount,
  },
];

export const INITIAL_SPLIT_TYPE_OPTION = {
  label: SPLIT_TYPES.split_equally,
  value: SPLIT_TYPES.split_equally,
};

export const GST_STATE_TYPES = {
  INTER_STATE: "Inter State",
  INTRA_STATE: "Intra State",
};

export const GST_TAX_TYPES = {
  CGST: "CGST",
  SGST: "SGST",
  CESS: "CESS",
  IGST: "IGST",
  UTGST: "UTGST",
};

// Maximum tags and option count for reporting tag dropdown.
export const MAX_TAGS_LIMIT = 20;
export const Max_OPTIONS_LIMIT = 10;

export const PAYMENT_TERMS = {
  due_on_receipt: 3,
};

export const AMOUNT_IN_WORDS = [
  { id: 1, value: "Invoices", key: "is_invoices_enabled" },
  { id: 2, value: "Credit Notes", key: "is_credit_notes_enabled" },
  { id: 3, value: "Bills", key: "is_bills_enabled" },
  { id: 4, value: "Debit Notes", key: "is_debit_notes_enabled" },
  { id: 5, value: "Journal Entry", key: "is_journal_entry_enabled" },
  { id: 5, value: "Recurring Invoice", key: "is_recurring_invoice_enabled" },
  { id: 5, value: "Subscriptions", key: "is_subscription_enabled" },
];
